<template>
  <div
    class="i_select-coach"
    v-if="!!defaultCoach?.fullName"
  >
    <iNavbarSimple />

    <div class="header">
      <div class="badge">Our Recommendation</div>
      <div class="title">We found your perfect match</div>
      <div
        class="sub"
        v-if="!!defaultCoach?.fullName"
      >
        Based on your input, you have been matched with {{ defaultCoach?.fullName }} at a
        <strong>{{ defaultCoach?.percentMatch }}% match score</strong>.
      </div>
    </div>

    <section class="section_recommended-coach">
      <div class="container">
        <div class="wrapper">
          <div class="grid-2col">
            <div class="left">
              <div class="top">
                <div class="top-pick badge">Top pick: {{ defaultCoach?.percentMatch }}% Match</div>
                <img
                  class="image"
                  :src="defaultCoach?.profilePhotoUrl || ''"
                />

                <template v-if="!!defaultCoach.introVideoUrl">
                  <img
                    class="play"
                    :src="iconPlay"
                    @click="data.lightBoxVideo = defaultCoach.introVideoUrl || ''"
                  />
                </template>
              </div>

              <iBaseButton
                v-if="!!defaultCoach?.fullName && !!defaultCoach?.calcomUsername && isTabletOrLarger"
                class="button"
                v-bind="BUTTON_STYLE"
                size="medium"
                :text="`Check ${defaultCoach?.fullName.split(' ')[0]}'s Availability`"
                @clicked="openCalcomModal(defaultCoach?.calcomUsername)"
              />
            </div>

            <div class="right">
              <div class="coach">{{ defaultCoach?.fullName || "Your Coach" }} <img :src="iconCheckBlue" /></div>
              <div class="text">Career Coach</div>
              <div class="text rating">
                <img :src="iconStars1" />
                {{ defaultCoach?.rating }} stars
                <span
                  class="review-cta"
                  @click="data.showReviewModal = true"
                  >(<u>{{ numberOfReviews }} reviews</u>)</span
                >
              </div>
              <div class="stats">
                <div class="stat">
                  <img :src="iconBlueCircle" />
                  <span
                    ><strong>{{ defaultCoach?.clientsServed || 280 }} clients</strong> landed their dream careers by
                    working with {{ defaultCoachfirstName || "this coach" }}.</span
                  >
                </div>
                <div class="stat">
                  <img :src="iconBlueCircle" />
                  <span
                    >{{ defaultCoachfirstName || "this coach" }}’s clients achieved
                    <strong>{{ defaultCoach?.salaryBoostPercent || 30 }}%</strong> in average salary boost.</span
                  >
                </div>
                <div class="stat">
                  <img :src="iconBlueCircle" />
                  <span
                    ><strong>99%</strong> of {{ defaultCoachfirstName || "this coach" }}’s clients received a job
                    interview within 3 months.</span
                  >
                </div>
              </div>
              <iBaseExpandableParagraph
                :text="defaultCoach?.biography || ''"
                :text-size="descriptionFontSize"
                text-name="full biography"
                :collapsed-length="descriptionMaxlength"
              />
              <iBaseButton
                v-if="!!defaultCoach?.fullName && !!defaultCoach?.calcomUsername && !isTabletOrLarger"
                class="button"
                v-bind="BUTTON_STYLE"
                size="medium"
                :text="`Check ${defaultCoach?.fullName.split(' ')[0]}'s Availability`"
                @clicked="openCalcomModal(defaultCoach?.calcomUsername)"
              />
            </div>
          </div>
        </div>

        <div class="help">
          <div
            class="option"
            @click="showInfoModal(0)"
          >
            How much is the coaching session?
          </div>
          <div
            class="option"
            @click="showInfoModal(1)"
          >
            I am not ready to make a decision yet.
          </div>
          <div
            class="option"
            @click="showInfoModal(2)"
          >
            I can’t find a coach with the right availability.
          </div>
        </div>
      </div>
    </section>

    <section class="section_coaches-list">
      <div class="container">
        <div class="wrapper">
          <div class="title">Want to explore other coaches?</div>
          <div class="subtitle">
            Jobtest.org only accepts <strong>less than 1% of coaches</strong> to join our platform. Any of other coaches
            below can help you achieve your career milestones.
          </div>

          <div class="grid-coaches">
            <template
              v-for="coach in coaches"
              :key="coach.fullName"
            >
              <iCoachProfileCard
                v-if="coach.profilePhotoUrl && coach.biography && coach.calcomUsername"
                :profilePhotoUrl="coach.profilePhotoUrl"
                :fullName="coach.fullName"
                :biography="coach.biography"
                :introVideoUrl="coach.introVideoUrl || ''"
                :rating="coach.rating"
                outlined
                @clickedCta="openCalcomModal(coach.calcomUsername)"
                @playVideo="data.lightBoxVideo = coach.introVideoUrl || ''"
              />
            </template>
          </div>
        </div>
      </div>
    </section>

    <template v-if="data.lightBoxVideo">
      <iBaseLightBox @close="data.lightBoxVideo = ''">
        <video
          :src="data.lightBoxVideo"
          autoplay
          controls
          class="light-box-video"
          type="video/mp4"
        ></video>
      </iBaseLightBox>
    </template>

    <iBaseModal
      :visible="data.showCalcomEmbedModal"
      :fully-customisable="true"
      max-width="1120px"
      @click-on-close="data.showCalcomEmbedModal = false"
    >
      <div class="confirmation-modal">
        <iCalcomScheduler
          :on-booking-successful="goToSelectPlan"
          :embed-link="data.calcomEmbedLink"
          version="inline"
        />
      </div>
    </iBaseModal>

    <iBaseModal
      visible
      class="zigpoll-modal"
      :class="{ visible: data.zigpollModalVisible }"
      @clickOnClose="data.zigpollModalVisible = false"
    >
      <div class="zigpoll-modal-wrap">
        <div class="zigpoll-modal-text">You can close this modal now!</div>
        <div id="zigpoll-embed-target"></div>
      </div>
    </iBaseModal>

    <iBaseModal
      :visible="data.showReviewModal"
      :fully-customisable="true"
      @clickOnClose="data.showReviewModal = false"
      max-width="840px"
    >
      <div class="review-modal">
        <Carousel
          :autoplay="30000"
          :items-to-scroll="1"
          :items-to-show="isDesktopOrLarger ? 2 : 1"
          class="reviews-carousel"
          pause-autoplay-on-hover
          wrap-around
          snap-align="start"
        >
          <Slide
            v-for="(review, index) in reviewData"
            :key="index"
          >
            <div class="review">
              <div class="info">
                <img :src="review.image" /><strong>{{ review.name }}</strong>
              </div>
              <div
                class="progress"
                v-html="review.progress"
              ></div>
              <img
                class="stars"
                :src="iconStars"
              />
              <div class="comment">{{ review.comment }}</div>
            </div>
          </Slide>
          <template #addons>
            <navigation>
              <template #next>
                <span>
                  <img
                    :src="iconArrowRightGray"
                    alt="next-icon"
                  />
                </span>
              </template>
              <template #prev>
                <span>
                  <img
                    :src="iconArrowLeftGray"
                    alt="prev-icon"
                  />
                </span>
              </template>
            </navigation>
          </template>
        </Carousel>
      </div>
    </iBaseModal>

    <iBaseModal
      :visible="data.showInfoModal"
      :fully-customisable="true"
      @clickOnClose="data.showInfoModal = false"
      :max-width="isMobileOrLarger ? '600px' : '350px'"
    >
      <div class="info-modal">
        <div class="top">
          <div class="caption">{{ INFO_DATA[data.activeInfoIndex].caption }}</div>
          <div v-html="INFO_DATA[data.activeInfoIndex].content"></div>
        </div>
        <div class="bottom">
          <iBaseButton
            v-bind="BUTTON_STYLE"
            width="fit-content"
            text="Close"
            @clicked="data.showInfoModal = false"
          />
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue"
import { Carousel, Navigation, Slide } from "vue3-carousel"
import { useRouter } from "vue-router"
import { useCoachingStore } from "@/stores/coaching"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { azureRadiance700, white } from "@/styles/colors"
import { breakpointLg, breakpointMd, breakpointSm, breakpointXl } from "@/styles/variables"

import useRandomNumber from "@/composables/useRandomNumber"
import useIsScreenSize from "@/composables/useIsScreenSize"

import { GlobalVariables } from "@/helpers/globalVariables"
import UrlService from "@/services/urlService"

import iBaseModal from "@/components/base/BaseModal.vue"
import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseLightBox from "@/components/base/BaseLightBox.vue"
import iBaseExpandableParagraph from "@/components/base/BaseExpandableParagraph.vue"
import iCalcomScheduler from "@/components/global/CalcomScheduler.vue"
import iCoachProfileCard from "@/components/coaching/selectCoach/CoachProfileCard.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"

// ASSETS
import iconCheckBlue from "@/assets/icons/icon-rounded-blue-tick.svg"
import iconPlay from "@/assets/icons/icon-play-v2-white.svg"
import iconStars1 from "@/assets/icons/icon-five-slashed-stars.svg"
import iconBlueCircle from "@/assets/icons/icon-blue-circle.svg"
import imageJulie from "@/assets/images/Julie-trustpilot.png"
import imageTristan from "@/assets/images/Tristan-trustpilot.png"
import imageBrenden from "@/assets/images/Brenden-trustpilot.png"
import imageDaniel from "@/assets/images/Daniel-trustpilot.png"
import imageCory from "@/assets/images/Cory-trustpilot.png"
import imageDavid from "@/assets/images/David-trustpilot.png"
import imageChristine from "@/assets/images/Christine-trustpilot.png"
import iconStars from "@/assets/icons/icon-five-stars.svg"
import iconArrowRightGray from "@/assets/icons/icon-arrow-right-gray.svg"
import iconArrowLeftGray from "@/assets/icons/icon-arrow-left-gray.svg"

// STORES AND ROUTES
const coachingStore = useCoachingStore()
const reportStore = useReportStore()
const userStore = useUserStore()
const router = useRouter()

// COMPOSABLES
const isMobileOrLarger = useIsScreenSize(breakpointSm)
const isTabletOrLarger = useIsScreenSize(breakpointMd)
const isDesktopOrLarger = useIsScreenSize(breakpointLg)
const isMassiveOrLarger = useIsScreenSize(breakpointXl)
const numberOfReviews = useRandomNumber(userStore.getFullName || "Full Name", 130, 300)

// CONSTANTS
const BUTTON_STYLE = {
  backgroundColor: azureRadiance700,
  borderRadius: "8px",
  fontWeight: "500",
  textColor: white,
  width: "100%",
  padding: "16px 24px"
}
const INFO_DATA = [
  {
    caption: "How much is the coaching session?",
    content:
      "Our coaching sessions are designed to be flexible and tailored to your needs. You can start with a single 60-minute introductory session or opt for a more frequent schedule with weekly sessions. <strong>Once you've selected the time and date for your first session</strong>, you'll have the opportunity to customize your coaching plan to best suit your goals.<br/><br/>All of our coaches at jobtest.org offer their expertise at a consistent rate. Depending on the plan you choose, <strong>pricing ranges from $149 for a 60-minute introductory session to $279 for a weekly session plan</strong>."
  },
  {
    caption: "Not ready to make a decision yet?",
    content:
      "We completely understand that making a decision about coaching is a big step. It’s important to feel confident and informed before committing. Here are a few things that might help you:<br/><br/><strong>Introductory Session:</strong> Consider starting with a no-obligation 60-minute introductory session. This session is designed to give you a firsthand experience of what our coaching can offer, helping you decide if it’s the right fit for you.<br/><br/><strong>Flexible Plans:</strong>  Our coaching plans are flexible and customizable. Whether you're ready to dive in or just want to start with a single session, you can choose a plan that matches your comfort level.<br/><br/><strong>100% Money-Back Guarantee:</strong>  If you're still unsure, remember that we offer a 100% money-back guarantee. This means you can try out our coaching services risk-free."
  },
  {
    caption: "Can’t find a coach with the right availability?",
    content:
      "We understand how important it is to find a coach who fits your schedule. Our top coaches are in high demand, but we’re committed to helping you find the right match. Here’s what you can do:<br/><br/><strong>Join the Waitlist:</strong> If your preferred coach isn’t available, you can <strong><a href='https://survey.zigpoll.com/2tVLrZ1upJBnDCLQ3/2wUgT342Yyt8GCi7d' target='_blank'>join their waitlist</a></strong>. We’ll notify you as soon as a spot opens up that fits your schedule.<br/><br/><strong>Personal Assistance:</strong> If you’re having trouble finding availability, our team is here to help. Please <strong><a href='https://survey.zigpoll.com/2tVLrZ1upJBnDCLQ3/2wUhqYcrMubg4Wmxw' target='_blank'>fill out this form</a></strong>, and a specialist will reach out to work with you personally to secure a session time that fits your schedule."
  }
]

// DATA
const data = reactive({
  lightBoxVideo: "",
  showCalcomEmbedModal: false,
  calcomEmbedLink: "",
  showReviewModal: false,
  showInfoModal: false,
  activeInfoIndex: 0,
  zigpollModalVisible: false,
  zigpollModalDelayMs: 45000 // 45 seconds
})

// COMPUTED
const defaultCoach = computed(() => coachingStore.getDefaultCoach)
const coaches = computed(() => coachingStore.getCoachesForSelectionPage.filter((e) => e.id !== defaultCoach.value?.id))
const defaultCoachfirstName = computed(() => defaultCoach.value?.fullName?.split(" ")[0])
const descriptionFontSize = computed(() => (isTabletOrLarger.value ? "18px" : "16px"))
const descriptionMaxlength = computed(() =>
  isMassiveOrLarger.value ? 810 : isDesktopOrLarger.value ? 550 : isMobileOrLarger.value ? 160 : 100
)
const reviewData = computed(() => {
  const coachFirstName = defaultCoach.value?.fullName?.split(" ")[0]
  return [
    {
      name: "Christine S.",
      image: imageChristine,
      progress: `Finding direction and <strong>confidence</strong> with ${coachFirstName}`,
      comment: `I stumbled across Jobtest during my Google research while contemplating a career shift. After taking the career test, I signed up for biweekly sessions with ${coachFirstName}. Beyond just providing accountability, ${coachFirstName} guided me to online and local resources I wasn't aware of and gave me the confidence to pursue a career in interior design. ${coachFirstName} is incredibly kind and supportive, celebrating my progress each time we meet. I’ve gained so much direction and stability, and I hope my future job suits me as well as ${coachFirstName}'s does to them!`
    },
    {
      name: "Brenden C.",
      image: imageBrenden,
      progress: `Pursuing a <strong>career change</strong> with ${coachFirstName}.`,
      comment: `I was looking for a career change, and Jobtest really helped me clarify my career interests better than any other test. The offer to speak with ${coachFirstName} was super helpful. ${coachFirstName} was extremely friendly and insightful, guiding me on the next steps I should take. I now feel more confident with a guided plan to achieve my goals.`
    },
    {
      name: "Julie B.",
      image: imageJulie,
      progress: `<strong>Transitioning to a new career</strong> with ${coachFirstName}.`,
      comment: `I had the good fortune of meeting with ${coachFirstName}, who really understands what it's like to make a career transition. ${coachFirstName} asked great questions, offered valuable insights, and provided actionable next steps. As a former teacher like me, ${coachFirstName} brought both optimism and expertise to our sessions. I'm now feeling much more hopeful about my career journey.`
    },
    {
      name: "Tristan B.",
      image: imageTristan,
      progress: `<strong>Moving forward</strong> with ${coachFirstName}.`,
      comment: `I was honestly apprehensive about trying this; it could very easily be a scam. But after taking the assessment, I got paired with ${coachFirstName}, who truly listened to me, and we made a great connection right away. ${coachFirstName} introduced me to many things, including positions and industries I had never even heard of. I’m quite excited to see what the future holds, and I think Jobtest is helping me move in the right direction.`
    },
    {
      name: "Daniel B.",
      image: imageDaniel,
      progress: `Exploring <strong>new career paths</strong> with ${coachFirstName}.`,
      comment: `Today, ${coachFirstName} was incredibly thorough and positive, helping me navigate the results from jobtest.org and offering valuable insights into potential careers. ${coachFirstName}'s dedication made me feel truly valued, and our conversation felt like a discussion between good friends, backed by accurate test data. I’m grateful for the time and extra effort ${coachFirstName} put in to help me explore new perspectives and potential roles.`
    },
    {
      name: "Cory O.",
      image: imageCory,
      progress: `<strong>Rediscovering purpose</strong> with ${coachFirstName}`,
      comment: `Jobtest uses a unique scoring mechanism that opened my eyes to careers I hadn't considered before. I chose the coaching option, and ${coachFirstName} helped me think deeply about careers, applications, resumes, and the job market in a whole new way. Thanks to ${coachFirstName} and Jobtest, I'm now on a journey to find work that inspires me, has a real impact, and aligns with who I am as a person. This has been a powerful experience in my mid-30s, realizing that monetary success alone isn't enough.`
    },
    {
      name: "David F.",
      image: imageDavid,
      progress: `From skepticism to <strong>success</strong> with ${coachFirstName}`,
      comment: `I was skeptical at first, but ${coachFirstName} changed my mind almost immediately after our first conversation. I was just expecting some quick resume tips, but ${coachFirstName} ended up coaching me for a couple of months, meeting every other week. Ultimately, it's what you make of it, but I’d say ${coachFirstName} is one of the best coaches in the game.`
    }
  ]
})

// FUNCTIONS
function openCalcomModal(calcomUsername: string | null | undefined) {
  if (!calcomUsername) return
  data.calcomEmbedLink = UrlService.generateCalComEmbedLink(`${calcomUsername}/intro`)
  data.showCalcomEmbedModal = true
}

const goToSelectPlan = (e: any) => {
  router.push({
    name: GlobalVariables.urls.coaching.children.selectPlan.routeName,
    query: { date: e.detail.data.date }
  })
}

const loadZigpoll = (delayMs: number) => {
  const isMobileView = window.innerWidth <= breakpointLg

  if (isMobileView) {
    reportStore.initMobileZigpollPrepayment(delayMs, GlobalVariables.zigpolls.finishedCoaching).then((modalVisible) => {
      data.zigpollModalVisible = modalVisible
    })
  } else {
    reportStore.initDesktopZigpollPrepayment(delayMs)
  }
}

const showInfoModal = (index: number) => {
  data.activeInfoIndex = index
  data.showInfoModal = true
}

// LIFECYCLE HOOKS
onMounted(async () => {
  loadZigpoll(data.zigpollModalDelayMs)

  // We need to get the default coach again, because it will have changed after the user has booked a session
  await coachingStore.loadDefaultCoach()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_select-coach {
  background-color: $black50;

  .badge {
    padding: 4px 12px;
    color: white;
    font-weight: 500;
    background-image: linear-gradient(to right, #4a9dff, #be4fff);
    @include border-radius-xxl;
    width: fit-content;
    @include text-sm;
  }

  .header {
    max-width: 1000px;
    margin: auto;
    @include flex(column, center, start, $gap: 18px);
    padding: 30px 16px 0px 16px;

    @media screen and (min-width: $breakpointSm) {
      align-items: center;
      text-align: center;
    }

    @media screen and (min-width: $breakpointMd) {
      padding: 30px 24px 0px 24px;
    }

    .title {
      @include display-md;
      font-weight: 700;
      color: $black950;

      @media screen and (min-width: $breakpointSm) {
        @include display-lg;
      }
    }

    .sub {
      @include text-md;
      font-weight: 400;
      color: $black700;
    }
  }

  .section_recommended-coach {
    .container {
      @include flex($direction: column, $justify: center, $align: center, $gap: 20px);
      margin: auto;
      width: 100%;
      padding: 30px 16px 15px 16px;

      @media screen and (min-width: $breakpointMd) {
        padding: 30px 24px;
      }

      @media screen and (min-width: $breakpointLg) {
        max-width: 1280px;
      }

      .wrapper {
        background-color: $white;
        border-radius: 22px;
        padding: 15px;

        @media screen and (min-width: $breakpointMd) {
          padding: 30px;
        }

        .grid-2col {
          @include flex($direction: column, $justify: start, $align: start, $gap: 15px);

          @media screen and (min-width: $breakpointMd) {
            flex-direction: row;
            gap: 32px;
          }

          .left {
            width: 100%;
            align-self: center;
            position: relative;
            @include flex(column, start, center, $gap: 20px);

            @media screen and (min-width: $breakpointMd) {
              position: sticky;
              top: 20px;
              align-self: unset;
              flex: 0 0 300px;
            }

            @media screen and (min-width: $breakpointLg) {
              flex: 0 0 400px;
            }

            .top {
              position: relative;

              .image {
                width: 100%;
                height: auto;
                border-radius: 8px;
              }

              .top-pick {
                position: absolute;
                right: 12px;
                top: 12px;
                text-transform: uppercase;
                @include text-xs;

                @media screen and (min-width: $breakpointSm) {
                  @include text-sm;
                  font-weight: 400;
                }
              }

              .play {
                width: 32px;
                height: 32px;
                position: absolute;
                left: 8px;
                bottom: 8px;
                cursor: pointer;
              }
            }
          }

          .right {
            .text {
              @include text-sm;
              font-weight: 500;
              margin-bottom: 10px;
              color: $black700;

              @media screen and (min-width: $breakpointSm) {
                @include text-md;
              }
            }

            .coach {
              @include text-lg;
              font-weight: 700;
              color: $black950;
              @include flex(row, space-between, center, $gap: 5px);

              @media screen and (min-width: $breakpointSm) {
                @include display-md;
                margin-bottom: 6px;
              }

              @media screen and (min-width: $breakpointMd) {
                @include flex(row, start, center, $gap: 25px);
              }
            }

            .rating {
              color: $bilbao500;

              @media screen and (min-width: $breakpointSm) {
                margin-bottom: 5px;
              }

              img {
                vertical-align: text-bottom;
              }

              .review-cta {
                cursor: pointer;
              }
            }

            .stats {
              @include flex(column, start, start);
              width: fit-content;
              margin-bottom: 5px;

              .stat {
                @include flex(row, start, baseline, $gap: 12px);
                padding: 12px 0;
                width: 100%;

                &:not(:last-child) {
                  border-bottom: solid 1px $zumthor100;
                }
              }
            }

            .button {
              margin: 20px auto 0 auto;
              max-width: 500px;
            }
          }
        }
      }

      .help {
        @include grid($cols: 1fr, $rows: 1fr 1fr 1fr, $align-items: stretch, $gap: 15px);
        width: 100%;
        margin-bottom: 5px;

        @media screen and (min-width: $breakpointMd) {
          @include grid($cols: 1fr 1fr 1fr, $rows: 1fr, $align-items: stretch, $gap: 25px);
        }

        .option {
          @include flex($justify: center, $align: center);
          @include border-radius-xxl;
          background-color: $azureRadiance100;
          padding: 12px 20px;
          @include text-sm;
          font-weight: 400;
          color: $black950;
          text-align: center;
          cursor: pointer;

          @media screen and (min-width: $breakpointMd) {
            @include text-md;
          }

          &:hover {
            background-color: $azureRadiance200;
          }
        }
      }
    }
  }

  .section_coaches-list {
    background-color: $white;

    .container {
      max-width: 1280px;
      margin: auto;
      width: 100%;

      .wrapper {
        padding: 15px 16px 30px 16px;

        @media screen and (min-width: $breakpointMd) {
          padding: 30px 24px;
        }

        .title {
          @include display-sm;
          font-weight: 600;
          text-align: center;
        }

        .subtitle {
          @include text-md;
          font-weight: 400;
          color: $black700;
          max-width: 600px;
          text-align: center;
          margin: 10px auto 20px auto;
        }

        .grid-coaches {
          margin-top: 16px;
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr;

          @media screen and (min-width: $breakpointSm) {
            grid-template-columns: 1fr 1fr;
          }

          @media screen and (min-width: $breakpointMd) {
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
      }
    }

    @media screen and (min-width: $breakpointLg) {
      .container {
        .wrapper {
          .title {
            @include display-md;
          }
          .subtitle {
            margin-bottom: 0px;
          }

          .grid-coaches {
            margin-top: 32px;

            .coach {
              &-role {
                @include text-sm;
              }

              &-check-mark {
                width: 28px;
                height: 28px;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }

  .light-box-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 840px;
    border-radius: 4px;
    overflow: hidden;
  }

  .confirmation-modal {
    @include flex($direction: column, $justify: start, $align: center);
    margin: 45px 25px 5px 25px;
  }

  .zigpoll-modal {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      min-height: 90px;
      max-height: 60vh;
      overflow: auto;
      position: relative;
    }
    &-text {
      font-weight: bold;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
    }
  }

  .review-modal {
    margin: 30px;

    .reviews-carousel {
      width: 100%;

      .review {
        @include flex($direction: column, $justify: start, $align: start, $gap: 5px);
        background-color: $black50;
        @include border-radius-sm;
        padding: 15px;
        width: 100%;
        height: 100%;
        margin: 0 8px;
        text-align: left;

        @media screen and (min-width: $breakpointSm) {
          padding: 22px 25px;
          @include border-radius-xl;
        }

        @media screen and (min-width: $breakpointLg) {
          width: 400px;
        }

        .info {
          @include text-md;
          font-weight: 400;

          > img {
            width: 32px;
            height: 32px;
            @include border-radius-rounded;
            margin-right: 10px;
            object-fit: cover;

            @media screen and (min-width: $breakpointSm) {
              width: 40px;
              height: 40px;
            }
          }
        }

        .progress {
          @include text-xs;
          font-weight: 400;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
          }
        }

        .stars {
          height: 18px;
        }

        .comment {
          @include text-sm;
          font-weight: 500;
          margin-top: 25px;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
          }
        }
      }
    }
  }

  .info-modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 17px);
      margin: 25px;
      @include text-md;

      @media screen and (min-width: $breakpointMd) {
        margin: 35px;
        @include text-lg;
      }

      .caption {
        font-weight: 700;
        @include display-xs;
        text-align: center;

        @media screen and (min-width: $breakpointMd) {
          @include display-sm;
        }
      }
    }

    .bottom {
      @include flex($direction: row, $justify: center, $align: center, $gap: 0px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/borders.scss";
@import "@/styles/variables.scss";

.i_select-coach {
  .i_base-modal {
    .outer-wrapper {
      @include border-radius-xl;
      margin: auto;
    }
  }

  .carousel__next,
  .carousel__prev {
    margin: 0 -25px;
  }
}
</style>
