import { GlobalVariables } from "@/helpers/globalVariables"

// Services
import ApiService from "./apiService"

// Types
import UserStatus from "@/shared/enums/UserStatus"

export type PurchaseData = {
  userFlow: "report" | "coaching"
  purchaseValue: number
  purchaseCurrency: string
  sessionId: string | null
  paymentIntent: string | null
  purchaseItems: {
    item_id: string | number
    item_name: string
    price: number
    quantity: number
  }[]
}

export default class GtmService {
  // When set to true, events will be sent to the data layer, even in dev and staging environments.
  // Keep this set to false, unless you are testing GTM events.
  private static fireEventsInDev = false

  // STATIC METHODS
  static async addToCartEvent(tier: UserStatus) {
    await this.pushEventToDataLayer("addToCart", { userFlow: this.getUserFlowByTier(tier) })
  }

  /**
   * Sends a begin checkout event to the data layer.
   */
  static async beginCheckoutEvent(tier: UserStatus, sessionId: string) {
    try {
      const response = await ApiService.getCheckoutSession(sessionId)

      const session = response?.session

      if (!session) {
        throw new Error("Checkout session not found")
      }

      const purchaseData: PurchaseData = {
        userFlow: this.getUserFlowByTier(tier),
        purchaseValue: session.purchaseValue,
        purchaseCurrency: session.purchaseCurrency || "USD",
        sessionId: session.id || sessionId,
        paymentIntent: session.paymentIntent || "",
        purchaseItems:
          session?.items.map((item: any) => ({
            item_id: item.id,
            item_name: item.name,
            price: item.price,
            quantity: item.quantity
          })) || []
      }

      await this.pushEventToDataLayer("beginCheckout", purchaseData)
    } catch (error) {
      console.error("Error in beginCheckoutEvent", error)
    }
  }

  /**
   * Sends a purchase event to the data layer.
   */
  static async purchaseEvent(tier: UserStatus, sessionId: string, fallbackValue: number) {
    try {
      const response = await ApiService.getCheckoutSession(sessionId)

      const session = response?.session

      if (!session) {
        throw new Error("Checkout session not found")
      }

      if (session.status !== "complete") {
        throw new Error("Checkout session not completed")
      }

      const purchaseData: PurchaseData = {
        userFlow: this.getUserFlowByTier(tier),
        purchaseValue: session.purchaseValue || fallbackValue,
        purchaseCurrency: session.purchaseCurrency || "USD",
        sessionId: session.id || sessionId,
        paymentIntent: session.paymentIntent || "",
        purchaseItems:
          session?.items.map((item: any) => ({
            item_id: item.id,
            item_name: item.name,
            price: item.price,
            quantity: item.quantity
          })) || []
      }

      await this.pushEventToDataLayer("purchase", purchaseData)
    } catch (error) {
      console.error("Error in purchaseEvent", error)
    }
  }

  static async quizCompleteEvent() {
    await this.pushEventToDataLayer("quizComplete", { userFlow: this.getUserFlowByPathName() })
  }

  static async quizStartEvent() {
    await this.pushEventToDataLayer("quizStart", { userFlow: this.getUserFlowByPathName() })
  }

  static async userDataEvent(email: string | null) {
    if (!email || typeof email !== "string") return
    await this.pushEventToDataLayer("userData", { userEmail: email.toLowerCase().trim() })
  }

  // PRIVATE STATIC METHODS

  /**
   * Pushes a custom event to the data layer.
   * We await the promise to ensure the event is sent before continuing.
   * If the event is not sent within 2 seconds, we resolve the promise.
   */
  private static async pushEventToDataLayer(event: string, data: any = {}): Promise<void> {
    // If we are not in production, we do not want to send events to the data layer.
    if (!this.fireEventsInDev && import.meta.env.VITE_ENV !== "prod") {
      console.log(`Simulating GTM event: ${event}`, data)
      return
    }

    return new Promise<void>((resolve) => {
      window.dataLayer = window.dataLayer || []

      const eventCallback = (containerId: string) => {
        if (containerId?.startsWith("GTM-")) {
          resolve()
          return
        }
      }

      window.dataLayer.push({ event, ...data, eventCallback })

      // Resolve after 2 seconds if no callback is called with a GTM container ID
      setTimeout(() => {
        resolve()
      }, 3000)
    })
  }

  /**
   * Returns the user flow based on the current page.
   *
   * User flow is either "report" or "coaching".
   *
   * "report" users are targeted for the basic and comprehensive reports. - /job-test, /loading, /welcome
   * "coaching" users are targeted for the premium packages. - /coaching/quiz, /coaching/select-coach
   */
  private static getUserFlowByPathName() {
    return window.location.pathname.includes(GlobalVariables.urls.coaching.path) ? "coaching" : "report"
  }

  /**
   * Returns the user flow based on the tier.
   *
   * User flow is either "report" or "coaching".
   *
   * "report" users are targeted for the basic and comprehensive reports.
   * "coaching" users are targeted for the premium packages.
   */
  private static getUserFlowByTier(tier: UserStatus) {
    if ([UserStatus.BASIC, UserStatus.COMPREHENSIVE].includes(tier)) {
      return "report"
    } else if ([UserStatus.PREMIUM].includes(tier)) {
      return "coaching"
    }
    throw new Error("Tier not recognized")
  }
}
