<template>
  <div class="i_careers">
    <section>
      <div
        class="tag"
        id="overview"
      >
        <div
          class="intro"
          v-if="!!activeJob?.title"
        >
          <iBaseHeader
            class="header"
            :highlight-text="activeJob.title"
            :font-size-highlighted-text="headerSize"
            text=""
            align="start"
          />
          <iCareerActions />
        </div>
        <div
          class="overview"
          v-if="!!activeJob?.overview"
        >
          {{ activeJob.overview }}
        </div>
        <iWhyMatched />
      </div>

      <div
        class="tag"
        id="salary"
      >
        <iMedianSalaries />
      </div>

      <div
        class="tag"
        id="insight"
      >
        <iWhatPeopleSay />
        <iActionCard
          :imageUrl="imageOnlineCoaching"
          :text="`See what ${activeJob.title} positions are available near you.`"
          cta-text="See Openings"
          @cta-clicked="goToJobOpenings"
        />
      </div>
    </section>

    <section
      class="tag"
      id="roadmap"
    >
      <iBaseHeader
        :highlight-text="`Your roadmap to becoming ${jobTitleWithArticle}`"
        :font-size-highlighted-text="headerSize"
        text=""
        align="start"
        :printBreakBefore="true"
      />
      <iCareerRequirements />
      <iActionCard
        :imageUrl="imageInPersonCoaching"
        :text="transitionText"
      />
    </section>

    <section
      class="tag"
      id="skills"
    >
      <iBaseHeader
        highlight-text="Useful Skillset to have"
        :font-size-highlighted-text="headerSize"
        text=""
        align="start"
        :printBreakBefore="true"
      />
      <iCareerSkillset />
      <iActionCard
        :imageUrl="imageSmilingMentee"
        text="88% of people report higher satisfaction after making a <span class='highlighted'>career change.</span>"
      />
    </section>

    <section
      v-if="activeJobMythFact.length > 0"
      class="tag"
      id="myths"
    >
      <iBaseHeader
        :highlight-text="`Misconceptions about ${getPluralJob(activeJob.title)}`"
        :font-size-highlighted-text="headerSize"
        text=""
        align="start"
        :printBreakBefore="true"
      />
      <iMythsAndFacts />
    </section>

    <section
      class="print-hide tag"
      id="need_help"
      v-if="!userStore.isTierPremium"
    >
      <iBaseHeader
        highlight-text="Not sure on what to do next?"
        :font-size-highlighted-text="headerSize"
        text="Talk to our career coach to figure out what exactly you need to do."
        font-size-text="14px"
        font-weight-text="500"
        :color-text="comet600"
        align="start"
      />
      <iActionCard
        class="no-mobile-break"
        caption="Career Coach"
        text="Book a call, <br />dedicated and personalized."
        cta-text="Check Availability"
        @cta-clicked="linkToCoachSelection"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { comet600 } from "@/styles/colors"
import { breakpointSm } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"
import linkToCoachSelection from "@/composables/linkToCoachSelection"
import getPluralJob from "@/composables/getPluralJob"
import { getArticle } from "@/composables/getArticle"

// COMPONENTS
import iBaseHeader from "@/components/base/BaseHeader.vue"
import iActionCard from "@/components/global/ActionCard.vue"
import iCareerActions from "@/components/report/careers/CareersActions.vue"
import iWhyMatched from "@/components/report/careers/WhyMatched.vue"
import iMedianSalaries from "@/components/report/careers/MedianSalaries.vue"
import iWhatPeopleSay from "@/components/report/careers/WhatPeopleSay.vue"
import iCareerRequirements from "@/components/report/careers/CareerRequirements.vue"
import iCareerSkillset from "@/components/report/careers/CareerSkillset.vue"
import iMythsAndFacts from "@/components/report/careers/MythsAndFacts.vue"

//ASSETS
import imageOnlineCoaching from "@/assets/careers/career-action-1.png"
import imageInPersonCoaching from "@/assets/careers/career-action-2.png"
import imageSmilingMentee from "@/assets/careers/career-action-3.png"
import iconSummary from "@/assets/icons/icon-summary.svg"
import iconSummaryBlue from "@/assets/icons/icon-summary-blue.svg"
import iconSalary from "@/assets/icons/icon-coins-hand.svg"
import iconSalaryBlue from "@/assets/icons/icon-coins-hand-blue.svg"
import iconInsight from "@/assets/icons/icon-eye.svg"
import iconInsightBlue from "@/assets/icons/icon-eye-blue.svg"
import iconRoadmap from "@/assets/icons/icon-route.svg"
import iconRoadmapBlue from "@/assets/icons/icon-route-blue.svg"
import iconBulb from "@/assets/icons/icon-lightbulb.svg"
import iconBulbBlue from "@/assets/icons/icon-lightbulb-blue.svg"
import iconAsk from "@/assets/icons/icon-message-question-circle.svg"
import iconAskBlue from "@/assets/icons/icon-message-question-circle-blue.svg"
import iconNeedHelp from "@/assets/icons/icon-info-circle.svg"
import iconNeedHelpBlue from "@/assets/icons/icon-info-circle-blue.svg"

// TYPES
import type { PageAnchors } from "@/types/pageAnchors"

// EMITS
const emit = defineEmits(["load-anchors"])

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const userStore = useUserStore()
const isNotMobile = useIsScreenSize(breakpointSm)

// CONSTANTS
const DEFAULT_MONTHS_TO_TRANSITION = 24

const CONSTANT_PAGE_ANCHORS = {
  overview: {
    text: "Overview",
    icon: iconSummary,
    iconActive: iconSummaryBlue
  },
  salary: {
    text: "Salary",
    icon: iconSalary,
    iconActive: iconSalaryBlue
  },
  insight: {
    text: "Insight",
    icon: iconInsight,
    iconActive: iconInsightBlue
  },
  roadmap: {
    text: "Roadmap",
    icon: iconRoadmap,
    iconActive: iconRoadmapBlue
  },
  skills: {
    text: "Useful Skills",
    icon: iconBulb,
    iconActive: iconBulbBlue
  },
  myths: {
    text: "Common Misconceptions",
    icon: iconAsk,
    iconActive: iconAskBlue
  }
} as PageAnchors

// COMPUTED
const headerSize = computed(() => (isNotMobile.value ? "36px" : "32px"))

const activeJob = computed(() => reportStore.getActiveJob)

const jobTitleWithArticle = computed(() => `${getArticle(activeJob.value.title)} ${activeJob.value.title}`)

const activeJobSearchLink = computed(() => {
  const job = activeJob.value?.title?.replace(/ /g, "+")
  return `https://www.google.com/search?q=${job}&ibp=htl;jobs#htivrt=jobs`
})

const activeJobMythFact = computed(() => {
  return reportStore.getActiveJobMythFact
})

const transitionText = computed(() => {
  return !activeJob.value.isCurrentJob
    ? `Becoming ${jobTitleWithArticle.value} will take ${
        activeJob.value.monthsToTransition || DEFAULT_MONTHS_TO_TRANSITION
      } months based on your answers. You can reduce this <span class='highlighted'>by half or more</span> by working with a coach.`
    : "Working with a career coach typically shortens career transition periods by <span class='highlighted'>50% or more.</span>"
})

// FUNCTIONS
const goToJobOpenings = () => {
  window.open(activeJobSearchLink.value, "_blank")
}

// LIFECYCLE HOOKS
onMounted(() => {
  if (!userStore.isTierPremium)
    CONSTANT_PAGE_ANCHORS.need_help = {
      text: "Need Help?",
      icon: iconNeedHelp,
      iconActive: iconNeedHelpBlue
    }

  emit("load-anchors", CONSTANT_PAGE_ANCHORS)
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_careers {
  max-width: 720px;
  margin: 0 auto 20px auto;
  @include flex($direction: column, $justify: start, $align: stretch, $gap: 40px);

  .tag {
    scroll-margin-top: 90px;
  }

  .intro {
    @include flex($direction: column, $justify: center, $align: start, $gap: 2px);

    @media screen and (min-width: $breakpointLg) {
      @include flex($direction: row, $justify: space-between, $align: end);
    }

    .header {
      width: fit-content;
    }
  }

  .overview {
    margin: 15px 0 40px 0;
    color: $comet600;
    @include text-sm;
    font-weight: 500;

    @media screen and (min-width: $breakpointSm) {
      @include text-md;
    }
  }
}

@media print {
  .i_careers {
    gap: 20px;

    .print-hide {
      display: none;
    }
  }
}
</style>

<style lang="scss">
@import "@/styles/variables.scss";

.i_careers {
  .no-mobile-break {
    margin-top: 32px;

    br {
      display: none;

      @media screen and (min-width: $breakpointSm) {
        display: block;
      }
    }
  }
}
</style>
