<template>
  <div
    v-if="props.visible"
    class="i_modal-message"
    :style="{ 'z-index': props.zIndex }"
  >
    <div class="outer-wrapper">
      <button
        @click="clickOnClose"
        class="close-modal"
      >
        <img
          :src="iconCloseButtonBlack"
          loading="lazy"
          alt="Close"
          class="close-modal-icon"
        />
      </button>

      <div class="message-container">
        <div class="inner-wrapper">
          <div>
            <div class="title">{{ props.title }}</div>
            <div class="message-text">{{ props.text }}</div>
            <div>
              <iBaseButton
                class="cta"
                is-text-uppercase
                letter-spacing="0.25px"
                :icon-url="iconUpArrowBlack"
                :text="props.buttonText"
                @click="clickOnButton"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { zIndexModal } from "@/styles/variables"

import iconCloseButtonBlack from "@/assets/buttons/close-button-black.svg"
import iconUpArrowBlack from "@/assets/icons/icon-up-arrow-black.svg"
import iBaseButton from "@/components/base/BaseButton.vue"

const emit = defineEmits(["clickOnButton", "clickOnClose"])

const props = defineProps({
  buttonText: {
    type: String,
    default: "Button Text"
  },
  text: {
    type: String,
    default: "Text"
  },
  title: {
    type: String,
    default: "Title"
  },
  visible: {
    type: Boolean,
    default: false
  },
  zIndex: {
    type: Number,
    default: zIndexModal
  }
})

function clickOnButton() {
  emit("clickOnButton")
}

function clickOnClose() {
  emit("clickOnClose")
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_modal-message {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: rgba($pickleBluewood950, 0.96);

  .outer-wrapper {
    position: relative;
    margin-top: 15vh;
    width: 95%;
    min-width: 300px;
    max-width: 680px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    border-radius: 16px;
    background-color: #fff;

    .close-modal {
      position: absolute;
      left: auto;
      top: 4px;
      right: 4px;
      bottom: auto;
      z-index: $zIndexModal + $above;
      padding: 4px;
      border-radius: 32px;
      background-color: #e8e8e8;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      transition: background-color 200ms ease;
      cursor: pointer;
    }
    .close-modal:hover {
      background-color: #b3b3b3;
    }
    .close-modal-icon {
      width: 32px;
      height: 32px;
    }

    .message-container {
      display: flex;
      width: 95%;
      max-width: 1498px;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      justify-content: center;

      .inner-wrapper {
        text-align: center;
        position: relative;
        z-index: $zIndexModal;
        padding-top: 3%;
        padding-bottom: 3%;

        .cta {
          margin: auto;
        }

        .title {
          @include font-style("h4");
        }

        .message-text {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpointMd) {
  .i_modal-message {
    .close-modal {
      top: 8px;
      right: 8px;
    }
    .message-text {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>
