import router from "@/router"

import { GlobalVariables } from "@/helpers/globalVariables"

// Types
import type StripePrices from "@/shared/enums/StripePrices"

// DTOs
import type NewUserDto from "@/shared/dtos/NewUserDto"
import type RequestMagicLinkDto from "@/shared/dtos/RequestMagicLinkDto"
import type UpdateUserDto from "@/shared/dtos/UpdateUserDto"
import type UserJobFavoritesDto from "@/shared/dtos/UserJobFavoritesDto"
import type UserJobFeedbackDto from "@/shared/dtos/UserJobFeedbackDto"
import type QuizPostCoachingIn from "@/shared/dtos/in/QuizPostCoachingIn"
import type QuizPostReportAdjustmentIn from "@/shared/dtos/in/QuizPostReportAdjustmentIn"
import type QuizPostReportIn from "@/shared/dtos/in/QuizPostReportIn"
import type QuizPostReportRetakeIn from "@/shared/dtos/in/QuizPostReportRetakeIn"

// Out DTOs
import type JobGetSuggestedOut from "@/shared/dtos/out/JobGetSuggestedOut"
import type StripeGetCheckoutSessionOut from "@/shared/dtos/out/StripeGetCheckoutSessionOut"
import type StripePostCheckoutSessionEmbeddedOut from "@/shared/dtos/out/StripePostCheckoutSessionEmbeddedOut"
import type StripePostCheckoutSessionHostedOut from "@/shared/dtos/out/StripePostCheckoutSessionHostedOut"
import type UserGetMeOut from "@/shared/dtos/out/UserGetMeOut"

export default class ApiService {
  // STATIC VARIABLES

  /**
   * The domain of the API is determined by the current location.
   */
  private static apiDomain =
    location.host === GlobalVariables.domains.app.prod
      ? GlobalVariables.domains.api.prod
      : location.host === GlobalVariables.domains.app.staging
      ? GlobalVariables.domains.api.staging
      : GlobalVariables.domains.api.local

  // ENDPOINTS

  /**
   * Get the status of the checkout session.
   */
  static async getCheckoutSession(sessionId: string): Promise<StripeGetCheckoutSessionOut> {
    const endpoint = `/stripe/checkout-session/${sessionId}`
    return (await ApiService.get(endpoint)) as StripeGetCheckoutSessionOut
  }

  /**
   * Get all active coaches.
   */
  static async getCoaches() {
    const endpoint = "/coach"
    return await ApiService.get(endpoint)
  }

  /**
   * Get users default coach.
   */
  static async getDefaultCoach() {
    const endpoint = "/coach/default"
    return await ApiService.get(endpoint)
  }

  /**
   * Gets the user's core data.
   */
  static async getUserData(): Promise<UserGetMeOut> {
    const endpoint = "/user/me"
    return await ApiService.get(endpoint)
  }

  /**
   * Gets the user report.
   */
  static async getUserReport() {
    const endpoint = "/user/report"
    return await ApiService.get(endpoint)
  }

  /**
   * Gets the user's suggested jobs.
   */
  static async getSuggestedJobs(): Promise<JobGetSuggestedOut> {
    const endpoint = "/job/suggested"
    return (await ApiService.get(endpoint)) as JobGetSuggestedOut
  }

  /**
   * Posts a new embedded checkout session for the user.
   */
  static async postCheckoutSessionEmbedded(stripePrice: StripePrices): Promise<StripePostCheckoutSessionEmbeddedOut> {
    if (!stripePrice) {
      throw new Error("Stripe price is required.")
    }
    const endpoint = `/stripe/checkout-session/embedded/${stripePrice}`
    return (await ApiService.post(endpoint, null)) as StripePostCheckoutSessionEmbeddedOut
  }

  /**
   * Posts a new hosted checkout session for the user.
   */
  static async postCheckoutSessionHosted(stripePrice: StripePrices): Promise<StripePostCheckoutSessionHostedOut> {
    if (!stripePrice) {
      throw new Error("Stripe price is required.")
    }
    const endpoint = `/stripe/checkout-session/hosted/${stripePrice}`
    return (await ApiService.post(endpoint, null)) as StripePostCheckoutSessionHostedOut
  }

  static async postFeedbackExperienceCompleted() {
    const endpoint = `/user/report/complete-feedback`
    return await ApiService.post(endpoint, {})
  }

  /**
   * Posts a new user.
   */
  static async postUser(payload: NewUserDto) {
    const endpoint = "/user"
    return await ApiService.post(endpoint, payload)
  }

  /**
   * Add or remove a job from the user's favorites.
   */
  static async postUserJobFavorite(jobId: number, isFavorite: boolean) {
    const endpoint = `/job/${jobId}/favorite`

    const payload: UserJobFavoritesDto = { isFavorite }
    return await ApiService.post(endpoint, payload)
  }

  /**
   * Upsert the feedback for a job.
   */
  static async postUserJobFeedback(jobId: number, jobFeedback: UserJobFeedbackDto) {
    const endpoint = `/job/${jobId}/feedback`

    return await ApiService.post(endpoint, jobFeedback)
  }

  /**
   * Logs out the user by removing the session cookie.
   */
  static async postLogout() {
    const endpoint = "/auth/logout"
    return await ApiService.post(endpoint, null)
  }

  /**
   * Exchange authKey for a session cookie, to authenticate the user.
   */
  static async postMagicLogin(authKey: string) {
    const endpoint = "/auth/magic"
    const headers = {
      Authorization: `Bearer ${authKey}`
    }
    return await ApiService.post(endpoint, null, headers)
  }

  /**
   * Sends a magic link to the user's email.
   */
  static async postMagicLinkEmail(email: string) {
    const endpoint = "/auth/magic/email"
    const payload: RequestMagicLinkDto = { email }

    return await ApiService.post(endpoint, payload)
  }

  /**
   * Send the user's initial coaching quiz answers to the server.
   */
  static async postQuizCoaching(payload: QuizPostCoachingIn) {
    const endpoint = "/quiz/coaching"
    return await ApiService.post(endpoint, payload)
  }

  /**
   * Send the user's initial quiz answers to the server.
   *
   * TODO: Convert the quiz to use camelCase, and fix the type of the payload to use the QuizPostReportIn.
   */
  static async postQuizReport(payload: QuizPostReportIn) {
    const endpoint = "/quiz/report"
    return await ApiService.post(endpoint, payload)
  }

  /**
   * Duplicates the quiz answers, and updates key variables included in the payload.
   */
  static async postQuizReportAdjustment(payload: QuizPostReportAdjustmentIn) {
    const endpoint = "/quiz/report/adjustment"
    return await ApiService.post(endpoint, payload)
  }

  /**
   * Send the user's retake quiz answers to the server.
   */
  static async postQuizReportRetake(payload: QuizPostReportRetakeIn) {
    const endpoint = "/quiz/report/retake"
    return await ApiService.post(endpoint, payload)
  }

  /**
   * Updates user data matching UpdateUserDto.
   */
  static async putUserData(payload: UpdateUserDto) {
    const endpoint = "/user/me"
    return await ApiService.put(endpoint, payload)
  }

  /**
   * Upgrade the user for free to the next tier, if they are eligible.
   */
  static async putFreeUpgrade() {
    const endpoint = "/user/free-upgrade"
    return await ApiService.put(endpoint, null)
  }

  /**
   * Regenerate new jobs for the user based on their existing data.
   */
  static async putRegenerateJobs(userId: number) {
    const endpoint = `/user/${userId}/regenerate`
    return await ApiService.put(endpoint, null)
  }

  // METHODS

  /**
   * Performs a GET request to the API.
   */
  static async get(path: string, headers: HttpHeader = {}) {
    return await ApiService.performRequest(path, "GET", null, headers)
  }

  /**
   * Performs a POST request to the API.
   */
  static async post(path: string, payload: any, headers: HttpHeader = {}) {
    return await ApiService.performRequest(path, "POST", payload, headers)
  }

  /**
   * Performs a POST request to the API.
   */
  static async put(path: string, payload: any, headers: HttpHeader = {}) {
    return await ApiService.performRequest(path, "PUT", payload, headers)
  }

  // HELPERS

  /**
   * Redirects to the login page if the error is 401 or 403, otherwise redirects to the error page.
   */
  static errorRedirect(error: any) {
    error.message === "401" || error.message === "403" ? ApiService.handleAuthErrors() : ApiService.handleDefaultError()
  }

  /**
   * Verifies the response and throws an error if the response is not ok.
   * Otherwise, returns the response as JSON.
   */
  static async verifyResponse(response: any) {
    if (!response.ok) {
      throw new Error(`${response.status}`)
    }
    return await response.json()
  }

  // PRIVATE HELPERS

  /**
   * Redirects to the login page.
   */
  private static handleAuthErrors() {
    router.push({ name: GlobalVariables.urls.login.routeName })
  }

  /**
   * Redirects to the error page.
   */
  private static handleDefaultError() {
    router.push({ name: GlobalVariables.urls.error.routeName })
  }

  /**
   * Performs a request to the API.
   */
  private static async performRequest(path: string, method: HttpMethod, payload: any = null, headers: HttpHeader = {}) {
    const url = `${ApiService.apiDomain}${path}`

    headers["Accept"] = "application/json"
    headers["Content-Type"] = "application/json"

    const body = payload ? JSON.stringify(payload) : null

    const response = await fetch(url, { method, headers, body, credentials: "include" })

    return await ApiService.verifyResponse(response)
  }
}

type HttpHeader = Record<string, string>
type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH"
