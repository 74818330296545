<template>
  <div
    class="i_match-graph"
    v-if="!!highlightedIndex"
  >
    <div class="match-graph">
      <template v-for="(marker, index) in markers">
        <div
          class="marker"
          :style="{
            background: marker,
            opacity: index === highlightedIndex ? 1 : 0.3
          }"
        >
          <div
            class="vertical-line"
            v-if="index === highlightedIndex"
          />
        </div>
      </template>
    </div>
    <div class="labels">
      <span class="section">NOT A MATCH</span>
      <span class="dot" />
      <span class="section">NEUTRAL</span>
      <span class="dot" />
      <span class="section">MATCH</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useReportStore } from "@/stores/report"
import { computed } from "vue"
import { markers, getMatchScoreColor } from "@/composables/getMatchScoreColor"

const reportStore = useReportStore()

const highlightedIndex = computed(() => {
  const recommendedJobs = reportStore.getRecommendedJobs
  const activeJobIndex = reportStore.getActiveJobIndex

  if (!recommendedJobs) return null
  if (activeJobIndex >= recommendedJobs.length) return null

  const activeJob = recommendedJobs[reportStore.getActiveJobIndex]

  return getMatchScoreColor("index", activeJob.matchScore)
})
</script>

<style scoped lang="scss">
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_match-graph {
  width: 100%;
  height: 100%;
  margin-top: 32px;

  .match-graph {
    margin-top: 8px;
    width: 100%;
    height: 25px;
    display: flex;
    @include flex($direction: row, $justify: center, $align: center, $gap: 2px);

    @media screen and (min-width: $breakpointXxs) {
      gap: 3px;
    }
    @media screen and (min-width: $breakpointXs) {
      gap: 4px;
    }
    @media screen and (min-width: $breakpointSm) {
      gap: 5px;
    }
    @media screen and (min-width: $breakpointLg) {
      gap: 8px;
    }

    .marker {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 1px;
      transition: height 0.3s ease-in-out;
      opacity: 0.3;

      .vertical-line {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 49px;
        background: $black;
        border: 4px solid $white;
        border-radius: 6px;
      }
    }

    // first marker is rounded on the left
    .marker:first-child {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    // last marker is rounded on the right
    .marker:last-child {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  .labels {
    margin-top: 13px;
    width: 100%;
    height: 24px;
    display: flex;
    @include flex($direction: row, $justify: space-around, $align: center, $gap: 8px);

    .section {
      @include text-xs;
      font-weight: 700;
      color: $comet500;
      text-align: center;
    }

    .dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $black100;
    }
  }
}
</style>
