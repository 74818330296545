<template>
  <div class="i_confirm-hero">
    <img
      :src="logo"
      alt="JobTest.org"
      class="logo-img"
    />
    <div class="match-success">
      <img
        :src="iconCheck"
        alt="check"
      />
      <h1 class="caption">Booking Success</h1>
    </div>
    <div
      class="text"
      v-html="text"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useCoachingStore } from "@/stores/coaching"

// ASSETS
import logo from "@/assets/logo/logo-dark.svg"
import iconCheck from "@/assets/icons/icon-check-blue.svg"

// STORES
const coachingStore = useCoachingStore()

// COMPUTED
const text = computed(() => {
  const defaultCoach = coachingStore.getDefaultCoach
  const start = "You have successfully been booked with "
  const end = defaultCoach.fullName
    ? `our expert career coach, <strong>${defaultCoach.fullName}</strong>.`
    : "an expert career coach."

  return start + end
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/mixins.scss";

.i_confirm-hero {
  text-align: center;

  .logo-img {
    width: 104px;

    @media screen and (min-width: $breakpointLg) {
      width: 150px;
    }
  }

  .match-success {
    @include flex($direction: row, $justify: center, $align: center, $gap: 10px);
    color: #348bff;
    margin: 25px 0px 10px 0;

    @media screen and (min-width: $breakpointMd) {
      margin-top: 30px;
    }

    @media screen and (min-width: $breakpointLg) {
      gap: 15px;
    }

    img {
      width: 30px;

      @media screen and (min-width: $breakpointLg) {
        width: 50px;
      }
    }

    .caption {
      @include display-sm;
      margin: 0;

      @media screen and (min-width: $breakpointMd) {
        @include display-md;
      }

      @media screen and (min-width: $breakpointLg) {
        @include display-lg;
      }
    }
  }

  .text {
    @include text-md;
    margin: 0 auto;
    width: 90%;

    @media screen and (min-width: $breakpointMd) {
      @include text-xl;
      width: 70%;
    }

    @media screen and (min-width: $breakpointLg) {
      @include display-xs;
    }
  }
}
</style>
