<template>
  <span v-html="truncatedText" />
</template>

<script setup lang="ts">
import { computed } from "vue"

// Props
const props = defineProps({
  text: {
    type: String,
    default: ""
  },
  minLength: {
    type: Number,
    default: 10
  },
  maxLength: {
    type: Number,
    default: 50
  }
})

const truncatedText = computed(() => {
  if (props.text.length <= props.minLength) {
    return props.text
  }

  if (props.text.length <= props.maxLength) {
    return props.text
  }

  let truncIndex = props.maxLength
  while (props.text[truncIndex] !== " " && truncIndex > props.minLength) {
    truncIndex--
  }

  return props.text.substring(0, truncIndex) + "..."
})
</script>
