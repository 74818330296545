<template>
  <div class="i_base-expandable-paragraph">
    <p :style="{ '--font-size': props.textSize }">{{ processedText }}</p>
    <div
      class="cta"
      v-if="isCollapsible"
      @click="toggleCollapsed"
      :style="{ justifyContent: ctaPosition }"
    >
      <div>{{ ctaText }}</div>
      <img :src="isCollapsed ? iconChevronDown : iconChevronUp" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from "vue"

// ASSETS
import iconChevronUp from "@/assets/icons/icon-chevron-up.svg"
import iconChevronDown from "@/assets/icons/icon-chevron-down.svg"

// Refs
const isCollapsed = ref(true)

// Props
const props = defineProps({
  text: {
    type: String,
    default: ""
  },
  collapsedLength: {
    type: Number,
    default: 120
  },
  maxSearchRange: {
    type: Number,
    default: 6
  },
  textName: {
    type: String,
    default: "more"
  },
  textSize: {
    type: String,
    default: "16px"
  },
  ctaPosition: {
    type: String as PropType<"start" | "center" | "end">,
    default: "start"
  }
})

// Computed
const isCollapsible = computed(() => {
  const minimumCharactersToHide = props.maxSearchRange / 2
  const isOverThreshold = props.text.length - sliceIndex.value > minimumCharactersToHide
  return props.text.length > props.collapsedLength && isOverThreshold
})

const sliceIndex = computed(() => {
  function truncateAtCharacter(targetChar: string): number {
    let indexLeft = props.collapsedLength - 1
    let indexRight = props.collapsedLength
    let charLeft = props.text[indexLeft]
    let charRight = props.text[indexRight]

    while (
      charLeft !== targetChar &&
      charRight !== targetChar &&
      indexLeft > 0 &&
      indexRight < props.text.length &&
      indexRight - indexLeft < props.maxSearchRange
    ) {
      indexLeft--
      indexRight++
      charLeft = props.text[indexLeft]
      charRight = props.text[indexRight]
    }

    if (charLeft === targetChar) {
      return indexLeft
    } else if (charRight === targetChar) {
      return indexRight
    }

    return -1
  }

  const periodIndex = truncateAtCharacter(".")
  if (periodIndex !== -1) {
    return periodIndex
  }

  const spaceIndex = truncateAtCharacter(" ")
  if (spaceIndex !== -1) {
    return spaceIndex
  }

  return props.collapsedLength
})

const processedText = computed(() => {
  if (!isCollapsed.value || !isCollapsible.value) {
    return props.text
  }

  return props.text.slice(0, sliceIndex.value) + "..."
})

const ctaText = computed(() => (isCollapsed.value ? `Read ${props.textName}` : "Read less"))

// Functions
function toggleCollapsed() {
  isCollapsed.value = !isCollapsed.value
}

function collapseText() {
  isCollapsed.value = true
}

function expandText() {
  isCollapsed.value = false
}

// Expose
defineExpose({ collapseText, expandText })
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";

.i_base-expandable-paragraph {
  @include flex($direction: column, $justify: start, $align: start, $wrap: nowrap);

  p {
    margin-top: 0px;
    margin-bottom: 2px;
    font-size: var(--font-size);
  }

  .cta {
    font-weight: 700;
    width: 100%;
    @include text-sm;
    @include flex(row, start, center, $gap: 4px);
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
