<template>
  <div
    class="i_base-pill"
    :class="pillClasses"
    :style="pillStyle"
  >
    <div class="inside">
      <div class="text">{{ props.pillText }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
/** BasePill
 * displays a text with a rounded background
 *
 * @param {Boolean} isBold - Bolds text inside pill.
 * @param {String} minWidth - The min width of the pill.
 * @param {String} pillColor - The color of the pill.
 * @param {String} pillHeight - The height of the pill.
 * @param {String} pillText - The text inside the pill.
 * @param {String} textColor - The color of text inside the pill.
 * @param {String} textSize - The size of text inside the pill.
 */

// IMPORTS

import { black50 } from "@/styles/colors"
import { computed } from "vue"

// PROPS

const props = defineProps({
  isBold: {
    type: Boolean,
    default: false
  },
  minWidth: {
    type: String,
    default: "auto"
  },
  pillColor: {
    type: String,
    default: black50
  },
  pillHeight: {
    type: String,
    default: "24px"
  },
  pillText: {
    type: String,
    required: true
  },
  textColor: {
    type: String,
    default: "black"
  },
  textSize: {
    type: String,
    default: "14px"
  }
})

// COMPUTED

const pillStyle = computed(() => {
  return {
    backgroundColor: props.pillColor,
    color: props.textColor,
    height: props.pillHeight,
    minWidth: props.minWidth,
    fontSize: props.textSize
  }
})

const pillClasses = computed(() => {
  return {
    "c-bold": props.isBold
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_base-pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 100px;
  .c-bold {
    font-weight: bold;
  }

  .inside {
    @include flex($justify: space-between);
    .text {
      text-align: center;
    }
  }
}
</style>
