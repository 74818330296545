export const white = "#ffffff"
export const black = "#000000"

export const brandOpacity100 = "#5bead0"
export const brandOpacity80 = "rgba(91, 234, 208, 0.8)"
export const brandOpacity60 = "rgba(91, 234, 208, 0.6)"
export const brandOpacity40 = "rgba(91, 234, 208, 0.4)"
export const brandOpacity20 = "rgba(91, 234, 208, 0.2)"
export const brandOpacity0 = "rgba(91, 234, 208, 0)"

export const riptide50 = "#f0fdfa"
export const riptide100 = "#ccfbf0"
export const riptide200 = "#99f6e2"
export const riptide300 = "#5bead0"
export const riptide400 = "#2dd4bb"
export const riptide500 = "#14b8a2"
export const riptide600 = "#0d9485"
export const riptide700 = "#0f766b"
export const riptide800 = "#115e57"
export const riptide900 = "#134e49"
export const riptide950 = "#042f2d"

export const sorbus50 = "#fffaec"
export const sorbus100 = "#fff3d3"
export const sorbus200 = "#ffe3a5"
export const sorbus300 = "#ffce6d"
export const sorbus400 = "#ffad32"
export const sorbus500 = "#ff920a"
export const sorbus600 = "#ff7a00"
export const sorbus700 = "#cc5802"
export const sorbus800 = "#a1440b"
export const sorbus900 = "#823a0c"
export const sorbus950 = "#461b04"

export const bilbao50 = "#f2fbf2"
export const bilbao100 = "#e2f6e2"
export const bilbao200 = "#c6ecc6"
export const bilbao300 = "#99dc9b"
export const bilbao400 = "#65c368"
export const bilbao500 = "#40a744"
export const bilbao600 = "#2c7f2f"
export const bilbao700 = "#286d2b"
export const bilbao800 = "#245726"
export const bilbao900 = "#1f4821"
export const bilbao950 = "#0c270e"

export const timberwolf50 = "#f7f6f5"
export const timberwolf100 = "#edebe7"
export const timberwolf200 = "#d9d6cc"
export const timberwolf300 = "#c3beae"
export const timberwolf400 = "#aaa18d"
export const timberwolf500 = "#988c77"
export const timberwolf600 = "#8c7e6a"
export const timberwolf700 = "#756859"
export const timberwolf800 = "#60564c"
export const timberwolf900 = "#4f473f"
export const timberwolf950 = "#292421"

export const azureRadiance50 = "#edfaff"
export const azureRadiance100 = "#d6f2ff"
export const azureRadiance200 = "#b5eaff"
export const azureRadiance300 = "#83dfff"
export const azureRadiance400 = "#48cbff"
export const azureRadiance500 = "#1eacff"
export const azureRadiance600 = "#068eff"
export const azureRadiance700 = "#007aff"
export const azureRadiance800 = "#085dc5"
export const azureRadiance900 = "#0d519b"
export const azureRadiance950 = "#0e315d"

export const comet50 = "#f6f7f9"
export const comet100 = "#ededf1"
export const comet200 = "#d7d9e0"
export const comet300 = "#b3b8c6"
export const comet400 = "#8a92a6"
export const comet500 = "#6b748c"
export const comet600 = "#535a6f"
export const comet700 = "#464b5e"
export const comet800 = "#3d424f"
export const comet900 = "#363944"
export const comet950 = "#24252d"

export const astronaut50 = "#f0f7fe"
export const astronaut100 = "#ddecfc"
export const astronaut200 = "#c3dffa"
export const astronaut300 = "#9accf6"
export const astronaut400 = "#6ab0f0"
export const astronaut500 = "#4892e9"
export const astronaut600 = "#3275de"
export const astronaut700 = "#2a61cb"
export const astronaut800 = "#284fa5"
export const astronaut900 = "#203b70"
export const astronaut950 = "#1b2b50"

export const zumthor50 = "#f2f5fb"
export const zumthor100 = "#e7ecf7"
export const zumthor200 = "#d5dcf0"
export const zumthor300 = "#bbc6e6"
export const zumthor400 = "#9fa9da"
export const zumthor500 = "#868ece"
export const zumthor600 = "#6e71bd"
export const zumthor700 = "#5c5ca6"
export const zumthor800 = "#4d4f86"
export const zumthor900 = "#43466c"
export const zumthor950 = "#27273f"

export const black50 = "#f6f6f6"
export const black100 = "#e7e7e7"
export const black200 = "#d1d1d1"
export const black300 = "#b0b0b0"
export const black400 = "#888888"
export const black500 = "#6d6d6d"
export const black600 = "#5d5d5d"
export const black700 = "#4f4f4f"
export const black800 = "#454545"
export const black900 = "#3d3d3d"
export const black950 = "#000000"

export const pickleBluewood50 = "#f6f7f9"
export const pickleBluewood100 = "#ebeef3"
export const pickleBluewood200 = "#d3d9e4"
export const pickleBluewood300 = "#acb9cd"
export const pickleBluewood400 = "#7f94b1"
export const pickleBluewood500 = "#5f7698"
export const pickleBluewood600 = "#4b5f7e"
export const pickleBluewood700 = "#3d4c67"
export const pickleBluewood800 = "#344054"
export const pickleBluewood900 = "#30394a"
export const pickleBluewood950 = "#202531"

export const bittersweet50 = "#fef3f2"
export const bittersweet100 = "#fee5e2"
export const bittersweet200 = "#ffd0c9"
export const bittersweet300 = "#fdafa4"
export const bittersweet400 = "#fa7a68"
export const bittersweet500 = "#f25741"
export const bittersweet600 = "#df3a23"
export const bittersweet700 = "#bc2d19"
export const bittersweet800 = "#9b2919"
export const bittersweet900 = "#81281b"
export const bittersweet950 = "#461109"

export const purpleHeart50 = "#f4f3ff"
export const purpleHeart100 = "#ebe9fe"
export const purpleHeart200 = "#d9d5ff"
export const purpleHeart300 = "#bbb4fe"
export const purpleHeart400 = "#9a89fc"
export const purpleHeart500 = "#7959f9"
export const purpleHeart600 = "#6737f0"
export const purpleHeart700 = "#5925dc"
export const purpleHeart800 = "#4a1eb9"
export const purpleHeart900 = "#3f1b97"
export const purpleHeart950 = "#250f66"

export const blackPearl950 = "#001825"

export const chartColorMe = "rgb(64, 167, 68, 0.4)"
export const chartColorOther = bittersweet400

export const inputFieldBackground = "#f3f3f3"
export const inputFieldColor = black900
