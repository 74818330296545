<template>
  <div class="i_sliding-logos">
    <iBaseAnimatedSlider
      sliding-direction="left"
      animation-duration-secs="10"
    >
      <div class="logo-row">
        <img
          class="logo"
          v-for="logo in LOGOS[1]"
          :src="logo"
        />
        <img
          class="logo"
          v-for="logo in LOGOS[1]"
          :src="logo"
        />
      </div>
    </iBaseAnimatedSlider>
    <iBaseAnimatedSlider
      sliding-direction="right"
      animation-duration-secs="10"
    >
      <div class="logo-row">
        <img
          class="logo"
          v-for="logo in LOGOS[2]"
          :src="logo"
        />
        <img
          class="logo"
          v-for="logo in LOGOS[2]"
          :src="logo"
        />
      </div>
    </iBaseAnimatedSlider>
  </div>
</template>

<script lang="ts" setup>
import iBaseAnimatedSlider from "@/components/base/BaseAnimatedSlider.vue"

// ASSETS
import imageAngelList from "@/assets/logos/AngelList_logo.webp"
import imageApple from "@/assets/logos/Apple_logo.webp"
import imageCB from "@/assets/logos/CB_logo.webp"
import imageCraigslist from "@/assets/logos/Craigslist_logo.webp"
import imageDribble from "@/assets/logos/Dribble_logo.webp"
import imageFJ from "@/assets/logos/FJ_logo.webp"
import imageGH from "@/assets/logos/GH_logo.webp"
import imageGlassdoor from "@/assets/logos/Glassdoor_logo.webp"
import imageGoogle from "@/assets/logos/Google_logo.webp"
import imageIndeed from "@/assets/logos/Indeed_logo.webp"
import imageJobtestorg from "@/assets/logos/Jobtestorg_logo.webp"
import imageJobvite from "@/assets/logos/Jobvite_logo.webp"
import imageLinkedIn from "@/assets/logos/LinkedIn_logo.webp"
import imageMonster from "@/assets/logos/Monster_logo.webp"
import imageMuse from "@/assets/logos/Muse_logo.webp"
import imageSJ from "@/assets/logos/SJ_logo.webp"
import imageZR from "@/assets/logos/ZR_logo.webp"

// CONSTANTS
const LOGOS = {
  1: [imageAngelList, imageApple, imageCB, imageCraigslist, imageDribble, imageFJ, imageGH, imageGlassdoor],
  2: [imageGoogle, imageIndeed, imageJobtestorg, imageJobvite, imageLinkedIn, imageMonster, imageMuse, imageSJ, imageZR]
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_sliding-logos {
  min-width: 100vw;
  width: max-content;
  margin-top: 25px;

  @media screen and (min-width: $breakpointMd) {
    margin-top: 35px;
  }

  .logo-row {
    @include flex($direction: row, $justify: center, $align: center);

    .logo {
      width: 60px;
      height: 60px;
      margin: 5px;

      @media screen and (min-width: $breakpointSm) {
        width: 100px;
        height: 100px;
        margin: 5px;
      }

      @media screen and (min-width: $breakpointMd) {
        width: 150px;
        height: 150px;
        margin: 10px;
      }
    }
  }
}
</style>
