<template>
  <div class="i_benefits-row">
    <div
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="item">
        <div>
          <img
            :src="item.image"
            alt=""
            class="image"
          />
        </div>

        <div class="details">
          <div class="title">{{ item.title }}</div>
          <div class="description">{{ item.description }}</div>
          <div
            v-if="item.hasRefundPolicy"
            class="refundPolicy"
          >
            Please check our <a @click="$emit('showRefundPolicy')">Refund Policy</a> for more details.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"

import illustrationWePromise1 from "@/assets/welcome/illustration-we-promise-1.svg"
import illustrationWePromise2 from "@/assets/welcome/illustration-we-promise-2.svg"
import illustrationWePromise3 from "@/assets/welcome/illustration-we-promise-3.svg"

// EMITS
defineEmits(["showRefundPolicy"])

// DATA
const items = reactive([
  {
    description: "If we don't deliver what we promise, we'll refund your money.",
    image: illustrationWePromise1,
    hasRefundPolicy: true,
    title: "100% Money-Back Guarantee"
  },
  {
    description: "You will get lifetime access to your report and all of its subsequent updates.",
    image: illustrationWePromise2,
    title: "Lifetime Access to Your Report and More"
  },
  {
    description:
      "When you are ready to take the next step with your career, you will have access to the world’s best career coaches.",
    image: illustrationWePromise3,
    title: "World's Best Career Coaches"
  }
])
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_benefits-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media screen and (min-width: $breakpointMd) {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .item {
    border-radius: 24px;
    border: 1px solid $black100;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: 100%;
    justify-content: start;
    min-height: 360px;
    padding: 48px 24px;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $breakpointMd) {
      gap: 32px;
    }

    .image {
      width: 150px;
      height: auto;

      @media screen and (min-width: $breakpointMd) {
        width: 75px;
      }
    }

    .details {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media screen and (min-width: $breakpointMd) {
        gap: 20px;
      }
    }

    .title {
      @include display-sm;
      font-weight: 700;
      max-width: 400px;

      @media screen and (min-width: $breakpointMd) {
        @include text-xl;
      }
    }

    .description {
      @include text-sm;
      max-width: 300px;
    }

    .refundPolicy {
      @include text-xs;
      max-width: 175px;

      a {
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;
      }
    }
  }
}
</style>
