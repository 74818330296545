import ApiService from "@/services/apiService"
import GtmService from "@/services/gtmService"
import type StripePrices from "@/shared/enums/StripePrices"
import type UserStatus from "@/shared/enums/UserStatus"

/**
 * Get the payment action for the given tier.
 *
 * @param stripePrice The stripe price that the checkout session should be created for.
 * @param tier The upgrade tier. This is used to send the correct GTM event.
 * @param beforeChangeUrl A function that is called before the url is changed. This is useful for cleanup.
 */
export default function getPaymentAction(
  stripePrice: StripePrices,
  tier: UserStatus,
  beforeChangeUrl: () => void = () => {}
) {
  const action = async () => {
    console.log("Creating checkout session")

    const checkoutSession = await ApiService.postCheckoutSessionHosted(stripePrice)

    // TODO: Once the growth team has fully moved towards using begin checkout events, we can remove add to cart.
    // We do not have a cart, so this event is not necessary. Begin checkout is the more accurate event.
    await Promise.all([GtmService.addToCartEvent(tier), GtmService.beginCheckoutEvent(tier, checkoutSession.sessionId)])

    if (!checkoutSession?.checkoutUrl) {
      throw new Error("Failed to create checkout session url")
    }

    beforeChangeUrl()

    // Open the checkout session in the same tab
    window.location.href = checkoutSession.checkoutUrl
  }

  return action
}
