<template>
  <div class="i_navbar-dropdown">
    <div
      class="dropdown-trigger"
      @click="openDropdown"
      ref="dropdownTriggerRef"
    >
      <slot></slot>
    </div>
    <div
      class="dropdown"
      :class="alignClass"
      v-if="isDropdownOpen"
      :style="{ top: `calc(100% + ${props.dropdownMarginTop}px)` }"
      ref="dropdownRef"
      @mouseleave="closeDropdown"
    >
      <div class="content">
        <template
          v-for="item in dropdownItems"
          :key="item.text"
        >
          <div
            v-if="!item.isHidden"
            class="list-item"
            @click="handleItemClick(item)"
          >
            <img
              class="icon"
              v-if="item.icon"
              :src="item.icon"
              :alt="item.text + ' icon'"
            />
            <div
              class="text"
              :style="{ color: item.textColor || black900 }"
            >
              {{ item.text }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, type PropType, computed, onMounted, onBeforeUnmount } from "vue"
import { black900 } from "@/styles/colors"
import type { DropdownItem } from "@/types/dropdownItem"

const isDropdownOpen = ref(false)
const dropdownRef = ref<HTMLElement | null>(null)
const dropdownTriggerRef = ref<HTMLElement | null>(null)

const props = defineProps({
  align: {
    type: String as PropType<"left" | "center" | "right">,
    default: "right"
  },
  dropdownItems: {
    type: Array as PropType<DropdownItem[]>,
    default: () => []
  },
  dropdownMarginTop: {
    type: Number,
    default: 32
  }
})

// COMPUTED

const alignClass = computed(() => `align-${props.align}`)

// FUNCTIONS

const openDropdown = () => {
  isDropdownOpen.value = true
}

const closeDropdown = () => {
  isDropdownOpen.value = false
}

const handleClickOutside = (event: MouseEvent) => {
  if (
    dropdownRef.value &&
    !dropdownRef.value.contains(event.target as Node) &&
    dropdownTriggerRef.value &&
    !dropdownTriggerRef.value.contains(event.target as Node)
  ) {
    isDropdownOpen.value = false
  }
}

const handleEscapeKey = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    isDropdownOpen.value = false
  }
}

const handleItemClick = (dropdownItem: DropdownItem) => {
  dropdownItem.action()
  isDropdownOpen.value = false // Close dropdown after action
}

// LIFECYCLE HOOKS

onMounted(() => {
  document.addEventListener("click", handleClickOutside)
  document.addEventListener("keydown", handleEscapeKey)
})

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside)
  document.removeEventListener("keydown", handleEscapeKey)
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_navbar-dropdown {
  position: relative;

  .dropdown-trigger {
    cursor: pointer;
  }

  .dropdown {
    z-index: $zIndexDropdown;
    min-width: 175px;
    position: absolute;
    width: max-content;
    filter: drop-shadow(0px 4px 45.5px rgba(0, 0, 0, 0.25));

    &::before {
      content: "";
      position: absolute;
      top: -9px;
      border-width: 0 9px 9px 9px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      left: var(--arrow-left, auto);
      right: var(--arrow-right, auto);
      transform: translateX(var(--arrow-translate-x, 0));
    }

    &.align-left {
      left: 0%;
      --arrow-left: 7px;
      --arrow-right: auto;
      --arrow-translate-x: 0;
    }

    &.align-center {
      left: 50%;
      transform: translateX(-50%);
      --arrow-left: 50%;
      --arrow-right: auto;
      --arrow-translate-x: -50%;
    }

    &.align-right {
      right: 0%;
      --arrow-left: auto;
      --arrow-right: 7px;
      --arrow-translate-x: 0;
    }

    .content {
      .list-item {
        width: 100%;
        padding: 12px;
        cursor: pointer;
        @include flex(row, start, center, $gap: 8px);
        transition: background-color 0.2s ease;
        background-color: $white;

        &:hover {
          background-color: $zumthor50;
        }

        .icon {
          width: 24px;
          height: 24px;
        }

        .text {
          padding: 0;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
        }
      }

      .list-item:not(:last-child) {
        border-bottom: 1px solid $zumthor100;
      }

      .list-item:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .list-item:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}
</style>
