import {
  bilbao200,
  bilbao300,
  bilbao400,
  bilbao500,
  bilbao600,
  bilbao700,
  bittersweet600,
  sorbus200,
  sorbus300,
  sorbus500,
  sorbus600,
  sorbus700,
  black300
} from "@/styles/colors"

export const markers = [
  bittersweet600,
  sorbus700,
  sorbus600,
  sorbus500,
  sorbus300,
  sorbus300,
  sorbus200,
  bilbao200,
  bilbao300,
  bilbao400,
  bilbao500,
  bilbao600,
  bilbao700
]

export const getMatchScoreColor = (type: "index" | "color", matchScore: number | null) => {
  if (matchScore === null) return type === "index" ? -1 : black300

  const colorIndex = Math.floor(markers.length - (100 - matchScore) / 5)
  return type === "index" ? colorIndex : markers[colorIndex]
}
