import { GlobalVariables } from "@/helpers/globalVariables"

export const GlobalFunctions = {
  delayInMiliseconds: (miliseconds = 60000) => {
    return new Promise((resolve) => {
      setTimeout(resolve, miliseconds)
    })
  },
  getWebsiteDomain: () => {
    return location.host === GlobalVariables.domains.app.prod
      ? GlobalVariables.domains.website.prod
      : GlobalVariables.domains.website.staging
  },
  getQublQuizIdForCoaching: () => {
    return location.host === GlobalVariables.domains.app.prod
      ? GlobalVariables.qublQuizIds.prod.coaching
      : GlobalVariables.qublQuizIds.staging.coaching
  },
  getQublQuizIdForJobQuiz: () => {
    return location.host === GlobalVariables.domains.app.prod
      ? GlobalVariables.qublQuizIds.prod.jobQuiz
      : GlobalVariables.qublQuizIds.staging.jobQuiz
  },
  getQublQuizIdForJobTest: () => {
    return location.host === GlobalVariables.domains.app.prod
      ? GlobalVariables.qublQuizIds.prod.jobTest
      : GlobalVariables.qublQuizIds.staging.jobTest
  },
  getQublQuizIdForTestRetake: () => {
    return location.host === GlobalVariables.domains.app.prod
      ? GlobalVariables.qublQuizIds.prod.testRetake
      : GlobalVariables.qublQuizIds.staging.testRetake
  },
  getQublQuizId: () => {
    if (location.pathname.includes(GlobalVariables.urls.report.children.testRetake.path)) {
      return GlobalFunctions.getQublQuizIdForTestRetake()
    }

    if (location.pathname.includes(GlobalVariables.urls.jobQuiz.path)) {
      return GlobalFunctions.getQublQuizIdForJobQuiz()
    }

    // Default to jobTest
    return GlobalFunctions.getQublQuizIdForJobTest()
  },
  getQublStyleForJobTest: () => {
    return GlobalVariables.qublQuizStyles.jobTest
  },
  getQublStyleForJobQuiz: () => {
    return GlobalVariables.qublQuizStyles.jobQuiz
  },
  getQublStyleForTestRetake: () => {
    return GlobalVariables.qublQuizStyles.testRetake
  },
  getQublStyle: () => {
    if (location.pathname.includes(GlobalVariables.urls.report.children.testRetake.path)) {
      return GlobalFunctions.getQublStyleForTestRetake()
    }

    if (location.pathname.includes(GlobalVariables.urls.jobQuiz.path)) {
      return GlobalFunctions.getQublStyleForJobQuiz()
    }

    // Default to jobTest
    return GlobalFunctions.getQublStyleForJobTest()
  },
  getUserSourceData: function () {
    let userSource = {} as { [key: string]: string }

    try {
      // Retrieve url parameters from session storage
      GlobalVariables.userSourceKeys.forEach((key) => {
        const value = sessionStorage.getItem(key)
        if (value) userSource[key] = value
      })

      // Add Facebook cookies
      const fbc = GlobalFunctions.retrieveCookie("_fbc")
      const fbp = GlobalFunctions.retrieveCookie("_fbp")
      if (fbc) userSource["fbc"] = fbc
      if (fbp) userSource["fbp"] = fbp

      // Add affiliate code
      const affiliateCode = sessionStorage.getItem("affiliate_code")
      if (affiliateCode) userSource["affiliate_code"] = affiliateCode

      return userSource
    } catch (error) {
      console.error("Error retrieving user source data: ", error)
      return userSource
    }
  },
  numberAsCurrency: (number: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(number)
  },
  numberWithoutDecimals: (number: number) => {
    const numberAsString = typeof number === "number" ? number.toString() : number
    return numberAsString.includes(".") ? numberAsString.split(".")[0] : numberAsString
  },
  redirectToPage: ({ baseUrl, pageUrl, queryUrl = "" }: any) => {
    const finalUrl = `${baseUrl}${pageUrl}${queryUrl}`
    // Simulate an HTTP redirect
    // Note: The difference between href and replace, is that replace() removes the URL of the current document from the document history, meaning that it is not possible to use the "back" button to navigate back to the original document.
    window.location.replace(finalUrl)
  },
  retrieveCookie(cookieName: string) {
    const cookies = document.cookie.split(";")

    for (let i = 0; i < cookies.length; i++) {
      const [name, value] = cookies[i].trim().split("=")
      if (name === cookieName) {
        return value
      }
    }

    return null
  },
  scrollInToElement: (elementSelector: string) => {
    if (!elementSelector) return
    const element = document.querySelector(elementSelector)
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  },
  storeUserSourceData: function (): void {
    const urlQueryString = window.location.search
    const urlParameters = new URLSearchParams(urlQueryString)

    const storableKeys = GlobalVariables.userSourceKeys

    // Store url parameters in session storage if not already stored
    urlParameters.forEach((value, key) => {
      if (storableKeys.includes(key) && !sessionStorage.getItem(key)) {
        sessionStorage.setItem(key, value)
      }
    })

    // affiliate_code is a special case where it is received as a hash, not a query parameter
    const affiliateCode = window.location.hash.split("#")[1]
    if (affiliateCode && !sessionStorage.getItem("affiliate_code")) {
      sessionStorage.setItem("affiliate_code", affiliateCode)
    }
  },
  toTitleCase: (str: string) => {
    if (!str) return ""
    const lowerCase = str.toLowerCase()
    return lowerCase.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())
  }
}
