<template>
  <div class="i_navbar-dropdown">
    <div
      class="backdrop"
      v-if="isDropdownOpen"
    />

    <div
      class="dropdown-trigger"
      @click="toggleDropdown"
      ref="dropdownTriggerRef"
    >
      <img
        class="icon"
        :src="iconMore"
        alt="More Icon"
      />
    </div>
    <div
      class="overlay"
      v-if="isDropdownOpen"
      @click="closeDropdown"
    ></div>
    <div
      class="dropdown"
      v-if="isDropdownOpen"
      ref="dropdownRef"
      @mouseleave="closeDropdown"
    >
      <div class="content">
        <div
          class="section"
          v-if="homeItem && !homeItem.isHidden"
        >
          <div
            class="list-item"
            @click="handleItemClick(homeItem)"
          >
            <img
              class="icon"
              v-if="homeItem.icon"
              :src="homeItem.icon"
              :alt="homeItem.text + ' icon'"
            />
            <div
              class="text"
              :style="{ color: homeItem.textColor || black900 }"
            >
              {{ homeItem.text }}
            </div>
          </div>
        </div>
        <div
          class="section"
          v-if="supportItems.length"
        >
          <div class="header">Support</div>
          <template
            v-for="item in supportItems"
            :key="item.text"
          >
            <div
              v-if="!item.isHidden"
              class="list-item"
              @click="handleItemClick(item)"
            >
              <img
                class="icon"
                v-if="item.icon"
                :src="item.icon"
                :alt="item.text + ' icon'"
              />
              <div
                class="text"
                :style="{ color: item.textColor || black900 }"
              >
                {{ item.text }}
              </div>
            </div>
          </template>
        </div>
        <div
          class="section"
          v-if="profileItems.length"
        >
          <div class="header">{{ userStore.getFullName }}</div>
          <template
            v-for="item in profileItems"
            :key="item.text"
          >
            <div
              v-if="!item.isHidden"
              class="list-item"
              @click="handleItemClick(item)"
            >
              <img
                class="icon"
                v-if="item.icon"
                :src="item.icon"
                :alt="item.text + ' icon'"
              />
              <div
                class="text"
                :style="{ color: item.textColor || black900 }"
              >
                {{ item.text }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, type PropType, onMounted, onBeforeUnmount } from "vue"

// ASSETS
import iconMore from "@/assets/menu/icon-more.svg"

// STYLES
import { black900 } from "@/styles/colors"

// TYPES
import type { DropdownItem } from "@/types/dropdownItem"
import { useUserStore } from "@/stores/user"

// STORES
const userStore = useUserStore()

const isDropdownOpen = ref(false)
const dropdownRef = ref<HTMLElement | null>(null)
const dropdownTriggerRef = ref<HTMLElement | null>(null)

defineProps({
  homeItem: {
    type: Object as PropType<DropdownItem>,
    required: true
  },
  profileItems: {
    type: Array as PropType<DropdownItem[]>,
    default: () => []
  },
  supportItems: {
    type: Array as PropType<DropdownItem[]>,
    default: () => []
  }
})

// FUNCTIONS
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const closeDropdown = () => {
  isDropdownOpen.value = false
}

const handleClickOutside = (event: MouseEvent) => {
  if (
    dropdownRef.value &&
    !dropdownRef.value.contains(event.target as Node) &&
    dropdownTriggerRef.value &&
    !dropdownTriggerRef.value.contains(event.target as Node)
  ) {
    isDropdownOpen.value = false
  }
}

const handleEscapeKey = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    isDropdownOpen.value = false
  }
}

const handleItemClick = (dropdownItem: DropdownItem) => {
  dropdownItem.action()
  isDropdownOpen.value = false // Close dropdown after action
}

// LIFECYCLE HOOKS

onMounted(() => {
  document.addEventListener("click", handleClickOutside)
  document.addEventListener("keydown", handleEscapeKey)
})

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside)
  document.removeEventListener("keydown", handleEscapeKey)
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_navbar-dropdown {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    opacity: 0.5;
    z-index: -1;
  }

  .dropdown-trigger {
    width: 24px;
    height: 24px;
    cursor: pointer;
    .icon {
      width: 100%;
      height: 100%;
    }
  }

  .dropdown {
    z-index: $zIndexDropdown;
    position: absolute;
    top: calc(100%);
    left: 0;
    right: 0;
    filter: drop-shadow(0px 4px 45.5px rgba(0, 0, 0, 0.25));
    background-color: $white;
    border-radius: 0 0 24px 24px;

    .content {
      padding-top: 8px;

      .section {
        margin-bottom: 16px;

        .header {
          @include text-md;
          font-weight: 700;
          padding: 0 24px 8px;
        }

        .list-item {
          width: 100%;
          padding: 12px 24px;
          cursor: pointer;
          @include flex(row, start, center, $gap: 8px);
          transition: background-color 0.2s ease;

          &:hover {
            background-color: $zumthor50;
          }

          .icon {
            width: 24px;
            height: 24px;
          }

          .text {
            padding: 0;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
          }
        }

        .list-item,
        .header {
          border-bottom: 1px solid $zumthor100;
        }

        .list-item:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
