<template>
  <div id="js-qubl"></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive, ref } from "vue"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"

import ApiService from "@/services/apiService"
import QuizService from "@/services/quizService"

import type { QuizData } from "@/types/quizData"
import GtmService from "@/services/gtmService"
import type NewUserDto from "@/shared/dtos/NewUserDto"

// EMITS

const emit = defineEmits(["quizCompleted", "quizSuccess", "quizError"])

// PROPS

const props = defineProps({
  quizId: {
    type: String,
    required: true
  },
  quizStyle: {
    type: String,
    required: true
  }
})

// EXPOSED METHODS

defineExpose({ processQuizCompletionWithNewEmail })

// CONST (UPPERCASE)

const MODAL_EVENT = (event: BeforeUnloadEvent) => {
  event.preventDefault() // Cancel the event as stated by the standard.
  event.returnValue = "" // Chrome requires returnValue to be set.
}

// DATA

const data = reactive({
  quizData: {} as QuizData
})

// FUNCTIONS (QUBL SETUP)

function listenToQublPostback(quizId: string): void {
  document.querySelector("qubl-instance")?.addEventListener("postback", async (qublResponse) => {
    // @ts-ignore (qublResponse is not typed)
    data.quizData = qublResponse?.detail

    processQuizCompletion(data.quizData)

    // Remove quiz data in localstorage to avoid quiz retake blank screen
    localStorage.removeItem(quizId)
  })

  document.querySelector("qubl-instance")?.addEventListener("next", async () => {
    observeHelperText()
  })

  document.querySelector("qubl-instance")?.addEventListener("back", async () => {
    observeHelperText()
  })
}

const observer = ref<MutationObserver | null>(null)

const observeHelperText = () => {
  const qublInstance = document.querySelector("qubl-instance")?.shadowRoot
  const qublQuiz = qublInstance?.querySelector("qubl-quiz")?.shadowRoot

  if (qublQuiz && !observer.value) {
    observer.value = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const questionContainer = qublQuiz?.querySelector(".question-container")
          const helperText = qublQuiz.querySelector("#helper-text:not(#helper-text.moved)")

          if (helperText) {
            if (questionContainer) {
              helperText.classList.add("moved")
              questionContainer.appendChild(helperText)
            }
          }
        }
      }
    })

    observer.value.observe(qublQuiz, { childList: true, subtree: true })
  }
}

const unobserveHelperText = () => {
  if (observer.value) {
    observer.value.disconnect()
  }
}

function addExitModal(): void {
  window.addEventListener("beforeunload", MODAL_EVENT)
}

function removeExitModal(): void {
  window.removeEventListener("beforeunload", MODAL_EVENT)
}

// FUNCTIONS (QUIZ COMPLETION)

async function processQuizCompletion(quizData: QuizData): Promise<void> {
  // Event triggers UI update
  emit("quizCompleted")

  GtmService.quizCompleteEvent()

  // Exit modal no longer needed
  removeExitModal()

  try {
    const { quizUid, instanceUid, answers, results } = quizData

    let userSource = GlobalFunctions.getUserSourceData() || {}

    userSource["quiz_page"] = window.location.pathname

    // Post new user  to the API
    const newUser: NewUserDto = {
      fullName: answers.full_name,
      email: answers.email,
      phoneNumber: answers.phone_number,
      gender: answers.gender,
      zipCode: answers.zip_code,
      userSource
    }

    await ApiService.postUser(newUser)

    // Post user's quiz data to the API
    const storableData = {
      ...QuizService.getNamedResults(results),
      ...QuizService.getShapeUpdatedAnswers(QuizService.getUpdatedAnswers(answers)),
      qubl_quiz_uuid: quizUid,
      qubl_instance_uuid: instanceUid
    }

    await ApiService.postQuizReport(storableData)

    try {
      // Store user in Klaviyo upon successful storage in database
      const klaviyoStorableData = {
        ...QuizService.getNamedResults(results),
        ...QuizService.getUpdatedAnswers(answers), // Answers do not need to be wrapped inside personality_answers as when storing in database
        ...userSource, // Params shouldn't be wrapped inside user_source as when storing in database
        qubl_quiz_uuid: quizUid,
        qubl_instance_uuid: instanceUid,
        original_quiz_data: quizData // Added for debugging purposes
      }

      setUserInKlaviyo(klaviyoStorableData)
    } catch (error) {
      console.error("Error storing user in Klaviyo", error)
    }

    emit("quizSuccess", { full_name: answers.full_name, zip_code: answers.zip_code })
  } catch (error) {
    emit("quizError", error, quizData)
  }
}

function processQuizCompletionWithNewEmail(quizData: QuizData): void {
  data.quizData = quizData
  processQuizCompletion(data.quizData)
}

function setUserInKlaviyo(data: any) {
  try {
    if (location.host != GlobalVariables.domains.app.prod) return
    if (!data.email) return

    // @ts-ignore - Klaviyo is set via script tag
    klaviyo.identify({
      ...data,
      $email: data.email,
      hasCompletedQuiz: true
    })
  } catch (error) {
    console.error("Error setting user in Klaviyo", error)
  }
}

// Lifecycle
onMounted(() => {
  QuizService.loadQublBundle()
  QuizService.loadQublInstance(props.quizId, props.quizStyle)
  GtmService.quizStartEvent()
  GlobalFunctions.storeUserSourceData()
  addExitModal()
  listenToQublPostback(props.quizId)
})

onUnmounted(() => {
  unobserveHelperText()
  removeExitModal()
})
</script>
