<template>
  <div class="i_outdated">
    <iBackground
      :background-image="bgSquiggleGreen"
      :color="white"
    />
    <iNavbarSimple class="navbar" />
    <div class="wrap">
      <div class="card">
        <div class="title">Jobtest Report Outdated</div>

        <div>
          <p>
            Since you last completed your test, we have updated our platform to provide you with our most accurate and
            advanced capabilities.
          </p>

          <p><strong>Please retake the test to receive your updated report.</strong></p>
        </div>

        <div class="cta">
          <iBaseButton
            text="Start Test Retake"
            @clicked="data.showRetakeModal = true"
            v-bind="BUTTON_STYLE.primary"
          />
          <iBaseButton
            text="Skip Test Retake"
            @clicked="data.showSkipModal = true"
            v-bind="BUTTON_STYLE.red"
          />
        </div>
      </div>
      <div class="logout-cta">
        <a
          @click="logout()"
          class="logout-link"
        >
          Logout
        </a>
      </div>
    </div>

    <iBaseModal
      :visible="data.showRetakeModal"
      :fully-customisable="true"
      @click-on-close="data.showRetakeModal = false"
    >
      <div class="modal">
        <div class="top">
          <div class="caption">Confirm Retake</div>
          <div class="info">
            If you retake the test, your current results (profile, top careers, and next steps) will be replaced with
            new information. This is the encouraged option, please confirm that you'd like to continue.
          </div>
        </div>

        <div class="bottom">
          <iBaseButton
            v-bind="MODAL_BUTTON_STYLE.transparent"
            text="Cancel"
            @clicked="data.showRetakeModal = false"
          />
          <iBaseButton
            v-bind="MODAL_BUTTON_STYLE.primary"
            text="Proceed"
            @clicked="clickOnRetake()"
          />
        </div>
      </div>
    </iBaseModal>

    <iBaseModal
      :visible="data.showSkipModal"
      :fully-customisable="true"
      @click-on-close="data.showSkipModal = false"
    >
      <div class="modal">
        <div class="top">
          <div class="caption">Skip Retake</div>
          <div class="info">
            If you skip retaking the test, your report might be missing some information due to outdated or incomplete
            data. It is recommended you cancel this action and choose to retake the test. Do you still wish to skip the
            test retake?
          </div>
        </div>

        <div class="bottom">
          <iBaseButton
            v-bind="MODAL_BUTTON_STYLE.transparent"
            text="Cancel"
            @clicked="data.showSkipModal = false"
          />
          <iBaseButton
            v-bind="MODAL_BUTTON_STYLE.red"
            text="Skip"
            @clicked="skipRetake()"
          />
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"
import { useRouter } from "vue-router"

// Services
import ApiService from "@/services/apiService"

// Components
import iBaseModal from "@/components/base/BaseModal.vue"
import iBaseButton from "@/components/base/BaseButton.vue"
import iBackground from "@/components/global/Background.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"

// Assets
import bgSquiggleGreen from "@/assets/jobTest/bg-squiggle-green.svg"

// Styles
import { GlobalVariables } from "@/helpers/globalVariables"
import { bittersweet600, black, black600, white } from "@/styles/colors"

// CONSTANTS
const BUTTON_STYLE = {
  primary: {
    fontWeight: "500",
    width: "100%",
    textColor: black,
    borderRadius: "8px"
  },
  red: {
    fontWeight: "500",
    width: "100%",
    textColor: white,
    borderRadius: "8px",
    backgroundColor: bittersweet600
  }
}

const SHARED_BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content"
}

const MODAL_BUTTON_STYLE = {
  primary: {
    ...SHARED_BUTTON_STYLE,
    borderRadius: "24px",
    textColor: black
  },
  red: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: bittersweet600,
    borderRadius: "24px",
    textColor: white
  },
  transparent: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: "transparent",
    textColor: black600,
    isHoverable: false
  }
}

// DATA
const data = reactive({
  showRetakeModal: false,
  showSkipModal: false
})

// Router
const router = useRouter()

// FUNCTIONS
function clickOnRetake() {
  router.push({ name: GlobalVariables.urls.report.children.testRetake.routeName })
}

function skipRetake() {
  localStorage.setItem("hasSkippedOutdatedRetake", "true")
  router.push({ name: GlobalVariables.urls.report.children.home.routeName })
}

function logout() {
  ApiService.postLogout()
    .then(() => {
      window.location.href = GlobalVariables.urls.login.path
    })
    .catch((error) => {
      console.error("Error logging out", error)
    })
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_outdated {
  @include flex($justify: center, $align: center);
  min-height: 100vh;

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .wrap {
    width: 90%;
    max-width: 540px;

    .card {
      @include card($shadow: true, $padding: false);

      padding: 24px;

      @media screen and (min-width: $breakpointSm) {
        padding: 48px;
      }

      background-color: rgba(244.37, 244.37, 244.37, 0.6);
      @include border-radius-lg;
      text-align: start;

      .title {
        @include display-xs();
        font-weight: bold;
      }

      .cta {
        @include flex(column, center, start, $gap: 8px);

        @media screen and (min-width: $breakpointSm) {
          @include flex(row-reverse, $gap: 10px);
        }
      }
    }

    .logout-cta {
      margin-top: 48px;
      text-align: center;

      .logout-link {
        @include text-md;
        color: $azureRadiance950;
        text-align: center;
        margin-top: 70px;
        cursor: pointer;
      }
    }
  }

  .modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 20px);
      margin: 25px;

      .caption {
        font-weight: 700;
        @include display-sm;
        text-align: center;
      }

      .info {
        @include text-lg;
        text-align: center;
        font-weight: 500;
        color: $comet600;
      }
    }

    .bottom {
      @include flex($direction: row, $justify: space-between, $align: center, $gap: 0px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
