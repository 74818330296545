<template>
  <div class="i_jobs-feedback-modal">
    <iBaseModal
      :visible="props.showModal"
      fully-customisable
      @click-on-close="closeModal"
    >
      <div class="modal">
        <div class="top">
          <div class="caption">Get New Careers</div>
          <div class="info">
            {{
              feedbackJobs.length
                ? "If you regenerate your result, your current results (profile and next steps) will be replaced with new information. Select the careers to keep and to replace."
                : "If you replace your result, your current results (profile and careers) will be updated with fresh information. You removed all suggested careers, your new report will present a completely new set of career possibilities."
            }}
          </div>

          <template v-for="job in feedbackJobs">
            <div class="job">
              <div class="title">
                {{ job.title }}
                <img
                  v-if="job.isFavorite"
                  :src="iconStarOrange"
                />
              </div>
              <iBaseTab
                class="tabs"
                :bg-color="job.tabBgColor"
                :active-tab="job.activeTab"
                :tabs="TABS"
                @set-active-tab="activeTabs[job.id] = $event"
              />
            </div>
          </template>
        </div>

        <div class="bottom">
          <iBaseButton
            v-bind="BUTTON_STYLE.transparent"
            text="Cancel"
            @clicked="closeModal"
          />
          <div>
            <div class="warning">This action cannot be undone</div>
            <iBaseButton
              v-bind="BUTTON_STYLE.red"
              :text="data.ctaText"
              @clicked="jobsToRemove.length ? (data.showRemoveModal = true) : getNewCareers()"
            />
          </div>
        </div>
      </div>
    </iBaseModal>
    <iBaseModal
      :visible="data.showRemoveModal"
      max-width="475px"
      fully-customisable
      @click-on-close="data.showRemoveModal = false"
    >
      <div class="remove-modal">
        <div class="top">
          <div class="warning">This action cannot be undone</div>
          <div class="caption">
            Provide feedback for better recommendations. You are about to remove the following careers:
          </div>
          <div class="joblist">
            <template v-for="job in jobsToRemove">
              <div class="job">{{ job.title }}</div>
            </template>
          </div>

          <textarea
            class="text-area"
            placeholder="Why are you not interested in the above listed Career(s)?"
            v-model="data.removeReason"
            maxlength="256"
            autofocus
          ></textarea>
        </div>

        <div class="bottom">
          <iBaseButton
            v-bind="BUTTON_STYLE.transparent"
            text="Go Back"
            @clicked="data.showRemoveModal = false"
          />
          <iBaseButton
            v-bind="BUTTON_STYLE.red"
            text="Continue"
            @clicked="closeRemoveModal()"
          />
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue"
import { useReportStore } from "@/stores/report"

import { bilbao200, bittersweet200, bittersweet600, black100, black600, white } from "@/styles/colors"

import iBaseModal from "@/components/base/BaseModal.vue"
import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseTab from "@/components/base/BaseTab.vue"

// ASSETS
import iconStarOrange from "@/assets/icons/icon-star-orange-filled.svg"

// TYPES
import type { Feedback } from "@/shared/types/UserJobFeedback"

// STORES
const reportStore = useReportStore()

// EMITS
const emit = defineEmits(["click-on-close", "proceed"])

// PROPS
const props = defineProps({
  showModal: {
    type: Boolean,
    deafult: false
  }
})

// DATA
const data = reactive({
  ctaText: "Get New Careers",
  showRemoveModal: false,
  removeReason: ""
})
const activeTabs: { [id: number]: Feedback } = reactive({})

// CONSTANTS
const SHARED_BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content"
}

const BUTTON_STYLE = {
  red: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: bittersweet600,
    borderRadius: "24px",
    textColor: white
  },
  transparent: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: "transparent",
    textColor: black600,
    isHoverable: false
  }
}

const FAVORITE = 2
const NEUTRAL = 0
const REMOVE = -2

const TABS: { id: Feedback; name: string }[] = [
  { id: FAVORITE, name: "Keep" },
  { id: NEUTRAL, name: "Unsure" },
  { id: REMOVE, name: "Replace" }
]

// COMPUTED
const recommendedJobs = computed(() => {
  return reportStore.getRecommendedJobs?.filter((job) => job.isVisible && job.jobRank > 0) ?? []
})

const feedbackJobs = computed(() => {
  return recommendedJobs.value.map((job) => ({
    id: job.id,
    title: job.title,
    activeTab: activeTabs[job.id],
    isFavorite: activeTabs[job.id] === FAVORITE,
    tabBgColor: activeTabs[job.id] === FAVORITE ? bilbao200 : activeTabs[job.id] === REMOVE ? bittersweet200 : black100
  }))
})

const jobsToRemove = computed(() => feedbackJobs.value.filter((job) => activeTabs[job.id] === REMOVE))

// FUNCTIONS
const populateActiveJobs = () => {
  recommendedJobs.value.forEach((job) => (activeTabs[job.id] = job.feedback === FAVORITE ? FAVORITE : NEUTRAL))
}

const closeModal = () => {
  populateActiveJobs()
  data.removeReason = ""
  emit("click-on-close")
}

const closeRemoveModal = () => {
  getNewCareers()
  data.showRemoveModal = false
}

const handleFavorite = async (jobId: number, favorite: boolean) => {
  await reportStore.postUserJobFavorite(jobId, favorite)
}

const handleFeedback = async (jobId: number, feedback: Feedback) => {
  await reportStore.postUserJobFeedback(jobId, {
    feedback,
    source: "retake",
    reasonOther: feedback === REMOVE ? data.removeReason : ""
  })
}

const getNewCareers = async () => {
  data.ctaText = "Getting New Careers..."

  await Promise.all(
    feedbackJobs.value.map(async (job) => {
      await handleFeedback(job.id, activeTabs[job.id])
      await handleFavorite(job.id, activeTabs[job.id] === FAVORITE)
    })
  )

  data.ctaText = "Get New Careers"
  closeModal()
  emit("proceed")
}

// LIFECYCLE HOOKS
onMounted(() => {
  populateActiveJobs()
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_jobs-feedback-modal {
  .modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 20px);
      margin: 25px;

      .caption {
        font-weight: 700;
        @include display-sm;
        text-align: center;
      }

      .info {
        @include text-lg;
        text-align: center;
        font-weight: 500;
        color: $comet600;
      }

      .job {
        @include flex($direction: column-reverse, $justify: space-between, $align: center, $gap: 5px);
        width: 100%;

        @media screen and (min-width: $breakpointSm) {
          flex-direction: row;
          gap: 2px;
        }

        .title,
        .tabs {
          width: 100%;

          @media screen and (min-width: $breakpointSm) {
            width: 50%;
          }
        }

        .title {
          > img {
            padding: 2px 5px;
            text-align: center;
            @include border-radius-lg;
            border: 1px solid $sorbus300;
            background-color: $sorbus100;
          }
        }
      }
    }

    .bottom {
      @include flex($direction: row, $justify: space-between, $align: center, $gap: 5px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      div {
        @include flex($direction: column, $gap: 5px);

        @media screen and (min-width: $breakpointSm) {
          flex-direction: row;
          gap: 15px;
        }

        .warning {
          color: $bittersweet600;
          text-transform: uppercase;
          align-self: start;
          font-weight: 700;
          @include text-xs;
          font-size: 10px;
          margin: auto;

          @media screen and (min-width: $breakpointSm) {
            @include text-xs;
          }
        }
      }
    }
  }

  .remove-modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 17px);
      margin: 25px;

      .warning {
        color: $bittersweet600;
        text-transform: uppercase;
        align-self: start;
        font-weight: 700;
        @include text-xs;
      }

      .caption {
        font-weight: 700;
        @include text-lg;
        text-align: center;
      }

      .joblist {
        @include flex($direction: column, $justify: space-between, $align: stretch, $gap: 10px);

        .job {
          @include text-sm;
          @include border-radius-sm;
          text-align: center;
          font-weight: 600;
          padding: 4px 18px;
          background-color: $black100;
        }
      }

      .text-area {
        border: 1px solid $black300;
        @include border-radius-sm;
        width: 100%;
        height: 130px;
        overflow-y: auto;
        padding: 8px 10px;
        background-color: $black50;

        &:focus-visible {
          outline: none;
          border: 1px solid $black400;
        }
      }
    }

    .bottom {
      @include flex($direction: row, $justify: space-between, $align: center, $gap: 0px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
