import { defineStore } from "pinia"

// Composables
import { getMatchScoreColor } from "@/composables/getMatchScoreColor"
import getSalaryStatistics from "@/composables/getSalaryStatistics"
import notifications from "@/composables/notifications"
import { numberAsCurrency } from "@/composables/numberAsCurrency"
import { sentenceCaseWords } from "@/composables/sentenceCaseWords"
import useRandomNumber from "@/composables/useRandomNumber"
import { useUserStore } from "./user"

// Services and Helpers
import type { SalaryStatistics } from "@/composables/getSalaryStatistics"
import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"
import ApiService from "@/services/apiService"

// Types
import UserStatus from "@/shared/enums/UserStatus"
import type RecommendedJob from "@/shared/types/RecommendedJob"
import type Report from "@/shared/types/Report"
import type Score from "@/shared/types/Score"
import type { ExtendedRecommendedJob, Notification } from "@/types"
import type { OnboardingIndexesType, OnboardingTooltipValueType } from "@/types/onboardingTooltips"

// DTOs
import type QuizPostReportAdjustmentIn from "@/shared/dtos/in/QuizPostReportAdjustmentIn"
import type { SuggestedJob } from "@/shared/dtos/out/JobGetSuggestedOut"
import type UserJobFeedbackDto from "@/shared/dtos/UserJobFeedbackDto"

// Styles
import {
  astronaut100,
  astronaut600,
  azureRadiance100,
  azureRadiance800,
  bilbao100,
  bilbao500,
  bilbao600,
  bittersweet100,
  bittersweet500,
  purpleHeart100,
  purpleHeart500,
  purpleHeart700,
  sorbus100,
  sorbus600
} from "@/styles/colors"

// Icons
import iconCareerGoalBalance from "@/assets/icons/icon-career-goal-balance.svg"
import iconCareerGoalExpert from "@/assets/icons/icon-career-goal-expert.svg"
import iconCareerGoalImpact from "@/assets/icons/icon-career-goal-impact.svg"
import iconCareerGoalLeadership from "@/assets/icons/icon-career-goal-leadership.svg"
import iconIdealCareerFeatureBalance from "@/assets/icons/icon-ideal-career-feature-balance.svg"
import iconIdealCareerFeatureCompatibility from "@/assets/icons/icon-ideal-career-feature-compatibility.svg"
import iconIdealCareerFeatureCompensation from "@/assets/icons/icon-ideal-career-feature-compensation.svg"
import iconIdealCareerFeatureCulture from "@/assets/icons/icon-ideal-career-feature-culture.svg"
import iconMissingNeedsAmbition from "@/assets/icons/icon-missing-needs-ambition.svg"
import iconMissingNeedsEducation from "@/assets/icons/icon-missing-needs-education.svg"
import iconMissingNeedsFinancial from "@/assets/icons/icon-missing-needs-financial.svg"
import iconMissingNeedsFriendship from "@/assets/icons/icon-missing-needs-friendship.svg"
import iconMissingNeedsHealth from "@/assets/icons/icon-missing-needs-health.svg"
import iconMissingNeedsLeisure from "@/assets/icons/icon-missing-needs-leisure.svg"
import iconMissingNeedsPassion from "@/assets/icons/icon-missing-needs-passion.svg"
import iconTestTakingPurposeAdvance from "@/assets/icons/icon-test-taking-purpose-advance.svg"
import iconTestTakingPurposeAi from "@/assets/icons/icon-test-taking-purpose-ai.svg"
import iconTestTakingPurposeCareerSwitch from "@/assets/icons/icon-test-taking-purpose-career-switch.svg"
import iconTestTakingPurposeCollege from "@/assets/icons/icon-test-taking-purpose-college.svg"
import iconTestTakingPurposeLost from "@/assets/icons/icon-test-taking-purpose-lost.svg"
import iconTestTakingPurposeReturn from "@/assets/icons/icon-test-taking-purpose-return.svg"

// Extend window object with zigpoll
declare global {
  interface Window {
    Zigpoll: any
  }
}

export const useReportStore = defineStore("report", {
  state: () => ({
    activeJobId: 0,
    activeJobIndex: 0,
    adjustingReport: false,
    featuredJobId: 0,
    fetchingData: false,
    invalidData: false,
    pageVisits: 0,
    regeneratingJobs: false,
    reportData: {} as Report,
    reportZigpollLoaded: false,
    salaryStatistics: {} as SalaryStatistics,
    suggestedJobs: [] as SuggestedJob[],
    notificationSystem: {
      activeIndex: 0,
      notifications: [] as Notification[]
    },
    onboardingIndexes: {
      basic: {
        desktop: [1, 2, 3, 4],
        mobile: [2, 3, 4]
      },
      free: {
        desktop: [1, 2, 3],
        mobile: [2, 3]
      }
    } as OnboardingIndexesType,
    calculatedTooltipIndexes: [] as OnboardingTooltipValueType[],
    activeTooltipIndex: undefined as undefined | number,
    activeTooltipValue: undefined as undefined | OnboardingTooltipValueType
  }),
  getters: {
    isFreeUpgradeEligible: (state) => {
      return state.reportData?.user?.isFreeUpgradeEligible || false
    },
    isReportOutdated: (state) => {
      return state.reportData?.results?.isReportOutdated || false
    },
    getActiveJob: (state) => {
      const activeJob: RecommendedJob = state.reportData?.results?.recommendedJobs?.[state.activeJobIndex] || null
      return activeJob
    },
    getActiveJobCapboiScores: (state) => {
      const activeJob = state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]
      const activeJobCapboiScores: number[] = []
      const capboiLabels = state.reportData?.results?.capboiScores?.map((capboi) => capboi.name) || []

      if (activeJob) {
        capboiLabels.forEach((label) => {
          const matchingScore = activeJob.capboiScores.find((score) => score.name === label)
          if (matchingScore) activeJobCapboiScores.push(matchingScore.score)
        })
      }
      return activeJobCapboiScores
    },
    getActiveJobCharacteristics: (state) => {
      return state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]?.characteristics || null
    },
    getActiveJobIndex: (state) => {
      return state.activeJobIndex
    },
    getActiveJobMythFact: function (state) {
      return state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]?.mythsFacts || null
    },
    getActiveJobQualifications: (state) => {
      return state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]?.qualifications || []
    },
    getActiveJobReviews: (state) => {
      return state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]?.reviews || null
    },
    getActiveJobSalaryPositions: (state) => {
      const activeJob = state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]
      const entry = activeJob?.salaryPositions?.entry
      const mid = activeJob?.salaryPositions?.mid
      const senior = activeJob?.salaryPositions?.senior

      const salaryPositions = {
        entry: entry ? numberAsCurrency(activeJob.salaryPositions.entry) : null,
        mid: mid ? numberAsCurrency(activeJob.salaryPositions.mid) : null,
        senior: senior ? numberAsCurrency(activeJob.salaryPositions.senior) : null
      }
      return salaryPositions
    },
    getActiveJobSalaryIncrease: (state) => {
      const activeJob = state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]
      const current = state.reportData?.results?.incomePotential?.currentSalary
      const potential = activeJob?.salaryPositions?.senior

      if (!potential || !current || potential < current) return null

      const increase = Math.round(((potential - current) / current) * 100)
      return `+${increase}%`
    },
    getActiveJobTopSkills: (state): Score[] => {
      const activeJob = state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]
      return activeJob?.skillScores.sort((a, b) => b.score - a.score).slice(0, 6) as Score[]
    },
    getActiveJobTopSkillsName: function (): string[] {
      const topSkills = this.getActiveJobTopSkills as Score[]
      return topSkills?.map((skill) => skill.name)
    },
    getActiveJobTopSkillsScores: function (): number[] {
      const topSkills = this.getActiveJobTopSkills as Score[]
      return topSkills?.map((skill) => +skill.score)
    },
    getActiveJobTopSkillsUserScores: function (state): number[] {
      const userSkillScores = state.reportData?.results?.skillScores
      const topSkillsNames = this.getActiveJobTopSkillsName as string[]
      return userSkillScores
        ?.filter((userSkill) => topSkillsNames.includes(userSkill.name))
        .map((userSkill) => userSkill.score) // Return the score directly as an array of numbers
    },
    getActiveJobTitle: (state) => {
      return state.reportData?.results?.recommendedJobs?.[state.activeJobIndex]?.title
    },
    getActivelyExploringJob: (state) => {
      return state.reportData?.user?.activelyExploringJob || null
    },
    getAgeGroup: (state) => {
      return state.reportData?.user?.currentAge?.text || null
    },
    getBio: (state) => {
      return state.reportData?.results?.biography || null
    },
    getCapboiLabels: (state) => {
      return state.reportData?.results?.capboiScores?.map((capboi) => capboi.name) || []
    },
    getCapboiRange: (state) => {
      const range = state.reportData?.results?.capboiRange

      // extend the range by 100 on each side
      const min = range?.minimum - 100
      const max = range?.maximum + 100

      return { minimum: min, maximum: max }
    },
    getCapboiScores: (state) => {
      return state.reportData?.results?.capboiScores?.map((capboi) => capboi.score) || []
    },
    getCapboiScoresPeer: (state) => {
      return state.reportData?.results?.capboiScores?.map((capboi) => capboi.peerScore!) || []
    },
    getCapboiTopTwoTypes: (state) => {
      // Create a clone of the capboiScores array, so that we don't mutate the original array
      const capboiScores = JSON.parse(JSON.stringify(state.reportData?.results?.capboiScores)) as Score[]
      return (
        capboiScores
          ?.sort((a, b) => b.score - a.score)
          ?.slice(0, 2)
          ?.map((capboi) => capboi.name) || []
      )
    },
    getCareerGoal: (state) => {
      const careerGoalMap = {
        balance: {
          icon: iconCareerGoalBalance
        },
        expert: {
          icon: iconCareerGoalExpert
        },
        impact: {
          icon: iconCareerGoalImpact
        },
        leadership: {
          icon: iconCareerGoalLeadership
        }
      }

      const goal = state.reportData?.user?.careerGoal
      if (!goal.value || !goal.text) return null

      return {
        backgroundColor: azureRadiance100,
        id: goal.value,
        textColor: azureRadiance800,
        title: goal.text,
        ...careerGoalMap[goal.value as keyof typeof careerGoalMap]
      }
    },
    getCertificateInterest: (state) => {
      return state.reportData?.user?.certificateInterest || null
    },
    getCurrentJob: (state) => {
      return state.reportData?.user?.currentJob || null
    },
    getCurrentSalary: (state) => {
      return state.reportData?.results?.incomePotential?.currentSalary || null
    },
    getDescriptors: (state) => {
      return state.reportData?.results?.personalityDescriptors || []
    },
    getEducationLevel: (state) => {
      return state.reportData?.user?.educationLevel.text || null
    },
    getEthnicity: (state) => {
      return state.reportData?.user?.ethnicity.text || null
    },
    getGender: (state) => {
      return state.reportData?.user?.gender || null
    },
    getIdealCareerFeature: (state) => {
      const careerFeatureMap = {
        balance: {
          icon: iconIdealCareerFeatureBalance
        },
        compatibility: {
          icon: iconIdealCareerFeatureCompatibility
        },
        compensation: {
          icon: iconIdealCareerFeatureCompensation
        },
        culture: {
          icon: iconIdealCareerFeatureCulture
        }
      }

      const feature = state.reportData?.user?.idealCareerFeature
      if (!feature.value) return null

      return {
        backgroundColor: bilbao100,
        id: feature.value,
        textColor: bilbao600,
        title: feature.text,
        ...careerFeatureMap[feature.value as keyof typeof careerFeatureMap]
      }
    },
    getIncomePotential: (state) => {
      return state.reportData?.results?.incomePotential || null
    },
    getJobLevel: (state) => {
      const jobLevelMap = {
        entry: {
          backgroundColor: bittersweet100,
          textColor: bittersweet500,
          text: "Less than 3 Years",
          title: "Entry Level"
        },
        mid: {
          backgroundColor: astronaut100,
          textColor: astronaut600,
          text: "3 to 7 Years",
          title: "Mid Level"
        },
        senior: {
          backgroundColor: bilbao100,
          textColor: bilbao500,
          text: "More than 15 Years",
          title: "Senior Level"
        },
        unknown: {
          backgroundColor: purpleHeart100,
          textColor: purpleHeart500,
          text: "Unknown",
          title: "Seniority Level"
        }
      }

      const yearsExperience = state.reportData?.user?.yearsOfExperience?.value
      if (yearsExperience <= 3) return jobLevelMap.entry
      if (yearsExperience <= 7) return jobLevelMap.mid
      if (yearsExperience > 15) return jobLevelMap.senior
      return jobLevelMap.unknown
    },
    getJobMotivation: (state) => {
      const jobMotivation = state.reportData?.user?.jobMotivation
      if (!jobMotivation?.value || !jobMotivation?.text) return null

      return jobMotivation
    },
    getLocation: (state) => {
      const city = state.reportData?.user?.location?.city
      const countryState = state.reportData?.user?.location?.state

      if (!city || !countryState) return null

      const citySentenceCase = sentenceCaseWords(city)

      if (city && countryState) return `${citySentenceCase}, ${countryState}`

      return citySentenceCase || countryState
    },
    getMissingNeeds: (state) => {
      const missingNeedsMap = {
        ambition: {
          icon: iconMissingNeedsAmbition
        },
        education: {
          icon: iconMissingNeedsEducation
        },
        financial: {
          icon: iconMissingNeedsFinancial
        },
        friendship: {
          icon: iconMissingNeedsFriendship
        },
        health: {
          icon: iconMissingNeedsHealth
        },
        leisure: {
          icon: iconMissingNeedsLeisure
        },
        passion: {
          icon: iconMissingNeedsPassion
        }
      }

      const need = state.reportData?.user?.missingNeeds
      if (!need.value || !need.text) return null

      return {
        backgroundColor: purpleHeart100,
        id: need.value,
        textColor: purpleHeart700,
        title: need.text,
        ...missingNeedsMap[need.value as keyof typeof missingNeedsMap]
      }
    },
    getMoreSchoolInterest: (state) => {
      return state.reportData?.user?.moreSchoolInterest || null
    },
    getPeerGroupTitle: (state) => {
      return state.reportData?.user?.currentAge?.text || null
    },
    getPreferredJobIndustries: (state) => {
      return state.reportData?.user?.industries || []
    },
    getPreferredJobFunctions: (state) => {
      return state.reportData?.user?.jobFunctions || []
    },
    getQuizPage: (state) => {
      return state.reportData?.user?.quizPage || null
    },
    getRecommendedJobs: function (state) {
      const jobs = state.reportData?.results?.recommendedJobs || null

      if (!jobs) return null

      const getMatchScore = (job: any) => {
        const randomAdjustment = useRandomNumber(job.id, -1, 1, 0)
        return job.jobRank ? Math.floor(95.5 - (job.jobRank - 1) * 1.5 + randomAdjustment.value) : null
      }

      const getBadge = (job: any, matchScore: number | null) => {
        if (job.isActivelyExploringJob) return "Exploring"
        if (job.isCurrentJob) return "Current"
        if (job.isSampleJob) return "Sample"
        return `${matchScore}%` as string
      }

      const getColor = (matchScore: number | null) => {
        return getMatchScoreColor("color", matchScore).toString()
      }

      const getVisibility = (job: RecommendedJob) => {
        if (useUserStore().isTierFree) {
          if (!this.hasCurrentJob && !this.hasActivelyExploringJob) {
            return job.isSampleJob
          } else {
            return job.isCurrentJob || job.isActivelyExploringJob
          }
        } else {
          return !job.isSampleJob && !job.isLocked
        }
      }

      const updatedJobs = jobs?.map((job) => {
        const matchScore = getMatchScore(job)
        return {
          ...job,
          badge: getBadge(job, matchScore),
          color: getColor(matchScore),
          matchScore,
          isVisible: getVisibility(job)
        }
      }) as ExtendedRecommendedJob[]

      return updatedJobs || []
    },
    getJobDescription: (state) => {
      return state.reportData?.results?.jobDescription || null
    },
    getSchoolMajor: (state) => {
      return state.reportData?.user?.schoolMajor || null
    },
    getSkills: (state) => {
      return state.reportData?.results?.skillScores || null
    },
    getSkillsLabels: (state) => {
      return state.reportData?.results?.skillScores?.map((skill) => skill.name) || null
    },
    getSkillsScores: (state) => {
      return state.reportData?.results?.skillScores?.map((skill) => skill.score) || null
    },
    getSkillsScoresPeer: (state) => {
      return state.reportData?.results?.skillScores?.map((skill) => skill.peerScore) || null
    },
    getTargetSalary: (state) => {
      return state.reportData?.user?.targetSalary || 60000
    },
    getTestTakingPurpose: (state) => {
      const testTakingPurposeMap = {
        advance: {
          icon: iconTestTakingPurposeAdvance
        },
        ai: {
          icon: iconTestTakingPurposeAi
        },
        careerSwitch: {
          icon: iconTestTakingPurposeCareerSwitch
        },
        collegeMajor: {
          icon: iconTestTakingPurposeCollege
        },
        lost: {
          icon: iconTestTakingPurposeLost
        },
        return: {
          icon: iconTestTakingPurposeReturn
        }
      }
      const purpose = state.reportData?.user?.testTakingPurpose
      if (!purpose.value || purpose.value === "other") return null

      return {
        backgroundColor: sorbus100,
        id: purpose.value,
        textColor: sorbus600,
        title: purpose.text,
        ...testTakingPurposeMap[purpose.value as keyof typeof testTakingPurposeMap]
      }
    },
    getTopStrengths: (state): Score[] => {
      const skills = state.reportData?.results?.skillScores
      if (!skills) return []
      return [...skills].sort((a, b) => b.score - a.score).slice(0, 6)
    },
    getTopStrengthsLabels: function () {
      const topStrengths = this.getTopStrengths as Score[]
      return topStrengths?.map((skill) => skill.name)
    },
    getTopStrengthsScore: function () {
      const topStrengths = this.getTopStrengths as Score[]
      return topStrengths?.map((skill) => +skill.score)
    },
    getTopStrengthsPeerScore: function () {
      const topStrengths = this.getTopStrengths as Score[]

      return topStrengths?.map((skill) => +skill.peerScore!)
    },
    getTopTwoStrengths: function () {
      const topStrengths = this.getTopStrengths as Score[]
      return topStrengths.length > 0 ? [topStrengths[0]?.name, topStrengths[1]?.name] : []
    },
    getTopWeaknesses: (state): Score[] => {
      const skills = state.reportData?.results?.skillScores
      if (!skills) return []
      return [...skills].sort((a, b) => a.score - b.score).slice(0, 6)
    },
    getTopWeaknessesLabels: function () {
      const topWeaknesses = this.getTopWeaknesses as Score[]
      return topWeaknesses?.map((skill) => skill.name)
    },
    getTopWeaknessesScore: function () {
      const topWeaknesses = this.getTopWeaknesses as Score[]
      return topWeaknesses?.map((skill) => +skill.score)
    },
    getTopWeaknessesPeerScore: function () {
      const topWeaknesses = this.getTopWeaknesses as Score[]
      return topWeaknesses?.map((skill) => +skill.peerScore!)
    },
    getTopTwoWeaknesses: function () {
      const topWeaknesses = this.getTopWeaknesses as Score[]
      return topWeaknesses.length > 0 ? [topWeaknesses[0]?.name, topWeaknesses[1]?.name] : []
    },
    getTrainingInterest: (state) => {
      return state.reportData?.user?.trainingInterest || null
    },
    getTrainingProgramInterest: (state) => {
      return state.reportData?.user?.trainingProgramInterest || null
    },
    getUserId: (state) => {
      return state.reportData?.user?.id || null
    },
    getReportZigpollId: function () {
      const userStore = useUserStore()
      if (userStore.isTierFinished) return GlobalVariables.zigpolls.finishedReport
      if (this.isFreeUpgradeEligible) return GlobalVariables.zigpolls.freeUpgradeEligible
      if (userStore.isTierFree) return GlobalVariables.zigpolls.freeReport
      if (userStore.isTierAnyPaid) return GlobalVariables.zigpolls.paidReport
      else return null
    },
    hasRecommendedJobId: (state) => {
      return (jobId: number) => {
        const recommendedJobs = state.reportData?.results?.recommendedJobs
        return !!recommendedJobs && Array.isArray(recommendedJobs) && !!recommendedJobs.find((job) => job.id === jobId)
      }
    },
    hasActivelyExploringJob: (state) => {
      return state.reportData?.results?.recommendedJobs?.some((job) => job.isActivelyExploringJob) || false
    },
    hasCurrentJob: (state) => {
      return state.reportData?.results?.recommendedJobs?.some((job) => job.isCurrentJob) || false
    },
    hasCoachingInterest: (state) => {
      return state.reportData?.user?.careerCoach == "yes"
    },
    hasPremiumSalary: (state) => {
      const currentSalary = Number(state.reportData?.results?.incomePotential?.currentSalary)
      return currentSalary >= 90000
    },
    hasMediumSalary: (state) => {
      const currentSalary = Number(state.reportData?.results?.incomePotential?.currentSalary)
      return currentSalary === 40000
    },
    hasRetakeQuizAttempts: (state) => {
      return state.reportData?.user?.quizRetakesLeft > 0
    }
  },
  actions: {
    async loadReport() {
      this.fetchingData = true

      try {
        const response = await ApiService.getUserReport()

        this.reportData = response

        function isInvalidData(reportData: Report) {
          return (
            Object.keys(reportData).length === 0 ||
            !reportData?.results ||
            !reportData?.user ||
            (!!reportData?.results && Object.keys(reportData?.results)?.length === 0) ||
            (!!reportData?.user && Object.keys(reportData?.user)?.length === 0)
          )
        }

        this.invalidData = isInvalidData(this.reportData)
        this.salaryStatistics = getSalaryStatistics(this.reportData?.results?.incomePotential?.currentSalary)

        this.filterAndSortRecommendedJobs()
        this.setFeaturedJobId()
        this.setActiveJob(this.featuredJobId)
      } catch (error) {
        ApiService.errorRedirect(error)
      } finally {
        this.fetchingData = false
      }
    },

    async loadSuggestedJobs() {
      try {
        const response = await ApiService.getSuggestedJobs()
        this.suggestedJobs = response?.jobs?.length > 0 ? response.jobs : []
      } catch (error) {
        ApiService.errorRedirect(error)
      }
    },

    incrementPageVisits() {
      this.pageVisits++
    },

    loadZigpoll() {
      window.Zigpoll = { accountId: GlobalVariables.zigpollId }
      let script = document.createElement("script")
      script.type = "text/javascript"
      script.charset = "utf-8"
      script.src = "//cdn.zigpoll.com/static/js/main.js"
      script.async = true
      document.head.appendChild(script)
      return true
    },

    renderZigpoll(pollId: string | null) {
      if (!pollId) return
      window.Zigpoll.pollId = pollId
      window.Zigpoll.user = {
        id: this.getUserId,
        metadata: {
          email: useUserStore().getEmail,
          name: useUserStore().getFullName
        }
      }
      window.Zigpoll.onload = () => window.Zigpoll.open()
      this.reportZigpollLoaded = true
    },

    deactivateZigpollReload() {
      window.Zigpoll.reload = () => {}
    },

    async initMobileZigpollPrepayment(delayMs = 30000, zigpollId?: string): Promise<boolean> {
      const pollId = zigpollId || this.getReportZigpollId
      const scrollDelta = 50
      this.loadZigpoll()
      this.renderZigpoll(pollId)
      console.log("Zigpoll:", "Mobile")

      // Delay survey being shown to user to give time to read the page
      await GlobalFunctions.delayInMiliseconds(delayMs)

      return new Promise((resolve) => {
        const handleScroll = () => {
          const delta = getScrollDelta()
          if (delta < -scrollDelta) {
            window.removeEventListener("scroll", handleScroll)
            resolve(true)
          }
        }

        const getScrollDelta = (() => {
          const delay = 50
          let lastPosition = window.scrollY
          let delta = 0
          let timer: ReturnType<typeof setTimeout>

          return () => {
            const newPosition = window.scrollY
            delta = newPosition - lastPosition
            lastPosition = newPosition

            clearTimeout(timer)
            timer = setTimeout(() => {
              delta = 0
            }, delay)

            return delta
          }
        })()

        window.addEventListener("scroll", handleScroll)
      })
    },

    async initDesktopZigpollPrepayment(delayMs = 30000) {
      // Delay survey being shown to user to give time to read the page
      await GlobalFunctions.delayInMiliseconds(delayMs)
      this.loadZigpoll()
      window.dispatchEvent(new Event("resize")) // needed to fix zigpoll iframe rendering
      console.log("Zigpoll:", "Desktop")
    },

    /**
     * Remove jobs that have a feedback of less than 0 (not including locked jobs)
     *
     * Then sort the jobs by the following order:
     * 1. Favorite Job (ties are broken by job rank)
     * 2. Jobs with a job rank greater than 0 (ties are broken by job rank)
     * 3. Actively Exploring Job
     * 4. Current Job
     * 5. Sample Job
     */
    async filterAndSortRecommendedJobs() {
      const recommendedJobs = this.reportData?.results?.recommendedJobs
      if (!recommendedJobs) return

      const mutableJobs = JSON.parse(JSON.stringify(recommendedJobs)) as RecommendedJob[]

      const filteredJobs = mutableJobs?.filter((job) => job.feedback >= 0 || job.isLocked)

      const sortedJobs = filteredJobs?.sort((a, b) => {
        // Sample job should always be at the bottom
        if (a.isSampleJob && !b.isSampleJob) return 1
        if (!a.isSampleJob && b.isSampleJob) return -1

        // Current job should be second to last
        if (a.isCurrentJob && !b.isCurrentJob) return 1
        if (!a.isCurrentJob && b.isCurrentJob) return -1

        // Actively exploring job should be third to last
        if (a.isActivelyExploringJob && !b.isActivelyExploringJob) return 1
        if (!a.isActivelyExploringJob && b.isActivelyExploringJob) return -1

        // Non-favorite jobs should be sorted by job rank
        if (!a.isFavorite && !b.isFavorite) return a.jobRank - b.jobRank

        // Favorite jobs should be at the top
        // If both jobs are favorite, sort by job rank
        if (a.isFavorite && !b.isFavorite) return -1
        if (!a.isFavorite && b.isFavorite) return 1
        if (a.isFavorite && b.isFavorite) {
          if (a.jobRank && b.jobRank) return a.jobRank - b.jobRank
        }

        return 0
      })

      this.reportData.results.recommendedJobs = sortedJobs
    },

    /**
     * This function will add or remove a job from the user's favorites.
     *
     * @param {number} jobId - The ID of the job to favorite or unfavorite.
     * @param {boolean} isFavorite - Whether to add (true) or remove (false) the job from the user's favorites.
     */
    async postUserJobFavorite(jobId: number, isFavorite: boolean) {
      try {
        await ApiService.postUserJobFavorite(jobId, isFavorite)

        const recommendedJobs = this.reportData?.results?.recommendedJobs
        if (!recommendedJobs) return

        const job = recommendedJobs.find((job) => job.id === jobId)
        if (!job) return

        job.isFavorite = isFavorite
        this.filterAndSortRecommendedJobs()
        this.setFeaturedJobId()
        this.setActiveJob(this.activeJobId)
      } catch (error) {
        console.error("Error updating job favorite.")
      }
    },

    /**
     * This function will upsert user's feedback for a given job.
     *
     * Each combination of userId, jobId, and source must be unique. Up to 3 feedbacks can be provided per job (one per source).
     *
     * userId is set via the session cookie.
     *
     * @param {number} jobId - The ID of the job to provide feedback for.
     * @param {Object} userJobFeedback - An object containing the feedback details.
     * @param {string} userJobFeedback.feedback - (Required) The feedback to provide.
     * @param {string} userJobFeedback.source - (Required) The source of the feedback. For example, "report" references feedback provided in the report.
     * @param {string} [userJobFeedback.reason] - (Optional) The reason for the feedback. We can add variables here (e.g., "highSalary", "undesiredIndustry").
     * @param {string} [userJobFeedback.reasonOther] - (Optional) The open-ended reason for the feedback.
     */
    async postUserJobFeedback(jobId: number, userJobFeedback: UserJobFeedbackDto) {
      try {
        await ApiService.postUserJobFeedback(jobId, userJobFeedback)

        const recommendedJobs = this.reportData?.results?.recommendedJobs
        if (!recommendedJobs) return

        const job = recommendedJobs.find((job) => job.id === jobId)
        if (!job) return

        job.feedback = userJobFeedback.feedback
        this.filterAndSortRecommendedJobs()
        this.setFeaturedJobId()
        this.setActiveJob(this.activeJobId)
        this.notifyIfFewJobs()
      } catch (error) {
        console.error("Error submitting job feedback.")
      }
    },

    async adjustQuizAnswers(payload: QuizPostReportAdjustmentIn) {
      this.adjustingReport = true

      try {
        await ApiService.postQuizReportAdjustment(payload)
      } catch (error) {
        ApiService.errorRedirect(error)
        throw new Error("Error adjusting quiz answers")
      } finally {
        this.adjustingReport = false
      }
    },

    async regenerateJobs() {
      this.regeneratingJobs = true

      try {
        const userId = this.getUserId
        if (!userId) throw new Error("This user has no Id")

        await ApiService.putRegenerateJobs(userId)
      } catch (error) {
        ApiService.errorRedirect(error)
        throw new Error(`Error regenerating new jobs: ${error}`)
      } finally {
        this.regeneratingJobs = false
      }
    },

    /**
     * This function will determine which jobId should be featured.
     */
    setFeaturedJobId() {
      const recommendedJobs = this.getRecommendedJobs
      if (!recommendedJobs) return 0

      let featuredJob = null

      if (useUserStore().isTierAnyPaid) {
        featuredJob = recommendedJobs.find((job) => !job.isLocked && job.jobRank > 0)
      }

      if (!featuredJob) {
        featuredJob = recommendedJobs.find((job) => job.isVisible)
      }

      if (featuredJob) {
        this.featuredJobId = featuredJob.id
      } else {
        this.featuredJobId = 0
      }

      return this.featuredJobId
    },

    /**
     * This function will set the activeJobId and activeJobIndex based on the jobId provided.
     *
     * If the jobId is not found in the recommendedJobs array, the featuredJobId will be set as the activeJobId.
     */
    setActiveJob(jobId: number) {
      const recommendedJobs = this.getRecommendedJobs
      if (!recommendedJobs) return 0 // Handle in the router

      let jobIndex = recommendedJobs.findIndex((job) => job.id === jobId)

      if (jobIndex !== -1) {
        this.activeJobId = jobId
        this.activeJobIndex = jobIndex
        return this.activeJobId
      }

      jobIndex = recommendedJobs.findIndex((job) => job.id === this.featuredJobId)
      this.activeJobId = this.featuredJobId
      this.activeJobIndex = jobIndex
      return this.activeJobId
    },

    notificationIndexIncrement() {
      this.notificationSystem.activeIndex =
        this.notificationSystem.activeIndex === this.notificationSystem.notifications.length - 1
          ? 0
          : this.notificationSystem.activeIndex + 1
    },

    notificationIndexDecrement() {
      this.notificationSystem.activeIndex =
        this.notificationSystem.activeIndex === 0
          ? this.notificationSystem.notifications.length - 1
          : this.notificationSystem.activeIndex - 1
    },

    notificationDismiss() {
      this.notificationSystem.notifications.splice(this.notificationSystem.activeIndex, 1)

      if (
        this.notificationSystem.activeIndex === this.notificationSystem.notifications.length &&
        this.notificationSystem.activeIndex > 0
      ) {
        this.notificationSystem.activeIndex--
      }
    },

    notificationAdd(notification: Notification) {
      this.notificationSystem.notifications = this.notificationSystem.notifications.filter(
        (n) => n.id !== notification.id
      )
      this.notificationSystem.notifications.unshift(notification)
    },

    notifyIfFewJobs() {
      if (!useUserStore().isTierAnyPaid) return

      // Check if the `noJobRecommendations` notification has already been added
      const noJobRecommendationsNotification = this.notificationSystem.notifications.find(
        (notification) => notification.id === "noJobRecommendations"
      )

      if (noJobRecommendationsNotification) return

      const MIN_JOB_RECOMMENDATIONS = 3
      const jobs = this.getRecommendedJobs?.filter((job) => job.jobRank >= 1 && !job.isLocked) // If jobRank is not 1 or higher, it is not a recommended job
      if (jobs && jobs.length < MIN_JOB_RECOMMENDATIONS) this.notificationAdd(notifications.fewJobRecommendations)
    },

    notifyIfNoJobs() {
      if (!useUserStore().isTierAnyPaid) return

      const jobs = this.getRecommendedJobs?.filter((job) => job.jobRank >= 1 && !job.isLocked) // If jobRank is not 1 or higher, it is not a recommended job
      if (jobs && jobs.length === 0) {
        // Check if the `fewJobRecommendations` notification has already been added, and if so, remove it
        const fewJobRecommendationsNotificationIndex = this.notificationSystem.notifications.findIndex(
          (notification) => notification.id === "fewJobRecommendations"
        )

        if (fewJobRecommendationsNotificationIndex !== -1) {
          this.notificationSystem.notifications.splice(fewJobRecommendationsNotificationIndex, 1)
        }

        this.notificationAdd(notifications.noJobRecommendations)
      }
    },

    notifyIfSkippedQuestions() {
      if (useUserStore().isTierAnyPaid && this.reportData?.user?.personalitySkipped) {
        this.notificationAdd(notifications.skippedQuestions)
      }
    },

    notifyIfReportOutdated() {
      if (this.isReportOutdated) this.notificationAdd(notifications.reportOutdated)
    },

    setTooltipData(isLargeScreen: boolean) {
      const tier = useUserStore().isTierBasicOrHigher ? UserStatus.BASIC : UserStatus.FREE
      const deviceWidth = isLargeScreen ? "desktop" : "mobile"

      this.calculatedTooltipIndexes = this.onboardingIndexes[tier] ? this.onboardingIndexes[tier][deviceWidth] : []
      this.activeTooltipIndex = 0
      this.activeTooltipValue = this.calculatedTooltipIndexes[this.activeTooltipIndex]
    },

    closeTooltip() {
      this.activeTooltipIndex = undefined
      this.activeTooltipValue = undefined
    },

    nextTooltipIndex() {
      if (this.activeTooltipIndex != undefined && this.activeTooltipIndex < this.calculatedTooltipIndexes.length - 1) {
        this.activeTooltipIndex++
        this.activeTooltipValue = this.calculatedTooltipIndexes[this.activeTooltipIndex]
      } else this.closeTooltip()
    },

    previousTooltipIndex() {
      if (this.activeTooltipIndex != undefined && this.activeTooltipIndex > 0) {
        this.activeTooltipIndex--
        this.activeTooltipValue = this.calculatedTooltipIndexes[this.activeTooltipIndex]
      }
    }
  }
})
