<template>
  <div class="i_base-stars">
    <img
      v-for="icon in icons"
      :src="icon"
      loading="lazy"
      alt=""
      class="icon-star"
    />
  </div>
</template>

<script setup lang="ts">
import iIconStarFull from "@/assets/icons/icon-star-full.svg"
import iIconStarHalf from "@/assets/icons/icon-star-half.svg"
import iIconStarEmpty from "@/assets/icons/icon-star-empty.svg"
import { computed } from "vue"

const props = defineProps({
  rating: {
    type: Number,
    default: 5
  }
})

const icons = computed(() => {
  const icons = []

  // Show full, half, or empty stars depending on the rating
  for (let i = 1; i <= 5; i++) {
    if (props.rating >= i) {
      icons.push(iIconStarFull)
    } else if (props.rating >= i - 0.5) {
      icons.push(iIconStarHalf)
    } else {
      icons.push(iIconStarEmpty)
    }
  }

  return icons
})
</script>
