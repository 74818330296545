<template>
  <div class="i_slider-testimonials-mobile">
    <div class="testimonial-grid">
      <div
        v-for="(testimonial, index) in TESTIMONIALS"
        :key="index"
        class="testimonial"
      >
        <img
          :src="testimonial"
          alt=""
          class="gif"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// DATA

const TESTIMONIALS = [
  "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Ashley_Subtitled_Reduced_Preview.gif",
  "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Traci_Subtitled_Reduced_Preview.gif",
  "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Eve_Subtitled_Reduced_Preview.gif",
  "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Charlene_Subtitled_Reduced_Preview.gif"
]
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_slider-testimonials-mobile {
  padding: 0 12px 24px 12px;
  width: 100%;
  @include flex();

  .testimonial-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .testimonial {
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid $black100;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;

    .gif {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 9/16;
    }

    .icon-play {
      height: 40px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
    }
  }
}
</style>
