<template>
  <RouterView />
</template>

<script setup lang="ts">
import { useCoachingStore } from "@/stores/coaching"
import { useUserStore } from "@/stores/user"
import { onMounted } from "vue"
import { RouterView } from "vue-router"

const userStore = useUserStore()
const coachingStore = useCoachingStore()

onMounted(async () => {
  if (userStore.isTierFinishedOrHigher) {
    const promises = [coachingStore.loadCoaches(), coachingStore.loadDefaultCoach()]
    await Promise.all(promises)
  }
})
</script>
