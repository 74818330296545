<template>
  <div class="i_plan-options">
    <div class="plans">
      <div class="intro">
        <iBaseHeader
          highlight-text="How frequently do you want to meet?"
          :font-size-highlighted-text="headerSize"
          align="start"
        />
        <div
          class="text1"
          :style="{ color: azureRadiance600 }"
          @click="data.showFrequencyModal = true"
        >
          What frequency is right for you?
          <img :src="iconPlus" />
        </div>
      </div>

      <template v-for="coachingPlan in coachingPlans">
        <div
          class="plan"
          :class="{ active: props.chosenPlan === coachingPlan.id }"
          @click="$emit('planSelected', coachingPlan.id)"
          v-if="coachingPlan.isVisible"
        >
          <div class="caption">
            ${{ coachingPlan.price }}
            <span
              class="per"
              v-if="coachingPlan.isSubscription"
            >
              /mo
            </span>

            <span
              v-if="coachingPlan.discounted"
              class="discounted"
            >
              ${{ coachingPlan.discounted }}
            </span>
          </div>
          <div class="details">
            <div class="caption">{{ coachingPlan.title }}</div>
            <ul>
              <li
                class="text2"
                v-for="detail in coachingPlan.details"
                :innerHTML="detail"
              />
            </ul>
          </div>
        </div>
      </template>

      <div class="tip">
        <div class="caption">Tips 💡</div>
        <div class="text2"><strong>75%</strong> of other {{ currentJob }} chose weekly sessions.</div>
      </div>
    </div>

    <iBaseModal
      :visible="data.showFrequencyModal"
      :fully-customisable="true"
      :enable-bg-close="true"
      @click-on-close="data.showFrequencyModal = false"
    >
      <div class="modal">
        <div class="heading">Frequency</div>
        <div>
          <p>
            Regular sessions keep you on track, address challenges promptly, and help you achieve your goals faster.
            Please note that our coaches can only work with a limited number of clients at a time. If a coach's
            availability is full, you will be waitlisted until a spot opens up.
          </p>
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue"

import { useReportStore } from "@/stores/report"

import { azureRadiance600 } from "@/styles/colors"
import { breakpointSm } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"
import getPluralJob from "@/composables/getPluralJob"

import iBaseHeader from "@/components/base/BaseHeader.vue"
import iBaseModal from "@/components/base/BaseModal.vue"

// ASSETS
import iconPlus from "@/assets/icons/icon-rounded-blue-plus.svg"

//TYPES
import type StripePrices from "@/shared/enums/StripePrices"
import type { CoachingPlans } from "@/types"

// EMITS
defineEmits(["planSelected"])

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const isNotMobile = useIsScreenSize(breakpointSm)
const currentJob = getPluralJob(reportStore.getCurrentJob)

const props = defineProps({
  coachingPlans: {
    type: Object as () => CoachingPlans,
    required: true
  },
  chosenPlan: {
    type: String as () => StripePrices,
    required: true
  }
})

// DATA
const data = reactive({
  showFrequencyModal: false
})

// COMPUTED
const headerSize = computed(() => (isNotMobile.value ? "36px" : "32px"))
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_plan-options {
  .plans {
    @include flex(column, start, stretch, $gap: 18px);

    .text2 {
      @include text-sm;
      font-weight: 400;
      color: $black700;

      @media screen and (min-width: $breakpointSm) {
        @include text-md;
      }
    }

    .caption {
      @include text-md;
      font-weight: 600;
      color: $black950;

      @media screen and (min-width: $breakpointSm) {
        @include text-xl;
      }
    }

    .intro {
      .text1 {
        @include text-sm;
        font-weight: 500;
        margin-bottom: 10px;
        color: $black700;
        cursor: pointer;

        @media screen and (min-width: $breakpointSm) {
          @include text-md;
          margin-bottom: 10px;
        }
      }

      img {
        vertical-align: text-bottom;
        margin-left: 6px;
      }
    }

    .plan {
      @include flex(column, start, start, $gap: 15px);
      padding: 18px 22px;
      @include border-radius-md;
      border: 1px solid $black100;
      cursor: pointer;

      &:hover,
      &.active {
        border: 1px solid $azureRadiance600;
        background-color: $azureRadiance50;
      }

      > .caption {
        font-weight: 700;

        .per,
        .discounted {
          color: $black500;
          @include text-xs;
          font-weight: 500;
        }

        .per {
          margin-left: -4px;
        }

        .discounted {
          text-decoration: line-through;
          margin-left: 10px;
        }
      }

      ul {
        margin: 10px 0 0 0;
        padding-left: 28px;
      }
    }

    .tip {
      @include flex(column, start, start, $gap: 15px);
      padding: 18px 22px;
      @include border-radius-md;
      background-color: $black50;
    }
  }

  .modal {
    @include flex($direction: column, $justify: start, $align: center);
    margin: 25px;
    text-align: center;

    .heading {
      font-weight: 700;
      @include display-sm;
      text-align: center;
    }
  }
}
</style>
