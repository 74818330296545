<template>
  <div
    class="i_global-notification"
    :class="activeNotification?.type"
    v-if="activeNotification"
  >
    <div class="wrapper">
      <div class="controls">
        <div
          class="left-chevron"
          @click="reportStore.notificationIndexDecrement()"
        >
          <img :src="iconChevronLeft" />
        </div>

        <div>{{ activeIndex }}/{{ totalNotifications }}</div>

        <div
          class="right-chevron"
          @click="reportStore.notificationIndexIncrement()"
        >
          <img :src="iconChevronRight" />
        </div>
      </div>

      <div class="content">
        <div class="message">
          <span v-html="activeNotification?.message"></span>
          <span v-if="!showAction && activeNotification?.action">{{ ` ${activeNotification?.action?.label}!` }}</span>
        </div>

        <div class="ctas">
          <div
            v-if="showAction"
            class="action"
            @click="activeNotification?.action?.task()"
          >
            {{ activeNotification?.action?.label }}
          </div>

          <div
            class="dismiss"
            @click="reportStore.notificationDismiss()"
          >
            Dismiss
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iconChevronLeftAlert from "@/assets/icons/icon-chevron-left-alert.svg"
import iconChevronLeftError from "@/assets/icons/icon-chevron-left-error.svg"
import iconChevronLeftInfo from "@/assets/icons/icon-chevron-left-info.svg"
import iconChevronLeftSuccess from "@/assets/icons/icon-chevron-left-success.svg"
import iconChevronRightAlert from "@/assets/icons/icon-chevron-right-alert.svg"
import iconChevronRightError from "@/assets/icons/icon-chevron-right-error.svg"
import iconChevronRightInfo from "@/assets/icons/icon-chevron-right-info.svg"
import iconChevronRightSuccess from "@/assets/icons/icon-chevron-right-success.svg"
import { useRoute } from "vue-router"

const activeRoute = useRoute()

// Stores
const reportStore = useReportStore()

// Consts

const notificationTypeIcons = {
  alert: {
    chevronLeft: iconChevronLeftAlert,
    chevronRight: iconChevronRightAlert
  },
  error: {
    chevronLeft: iconChevronLeftError,
    chevronRight: iconChevronRightError
  },
  info: {
    chevronLeft: iconChevronLeftInfo,
    chevronRight: iconChevronRightInfo
  },
  success: {
    chevronLeft: iconChevronLeftSuccess,
    chevronRight: iconChevronRightSuccess
  }
}

// Computed

const activeIndex = computed(() => {
  return reportStore.notificationSystem.activeIndex + 1
})

const activeNotification = computed(() => {
  return notifications.value.find((_notification, index) => {
    return index === reportStore.notificationSystem.activeIndex
  })
})

const showAction = computed(() => {
  return activeNotification.value?.action && activeRoute.name !== activeNotification.value?.actionRouteName
})

const iconChevronLeft = computed(() => {
  return notificationTypeIcons[activeNotification.value?.type || "info"].chevronLeft
})

const iconChevronRight = computed(() => {
  return notificationTypeIcons[activeNotification.value?.type || "info"].chevronRight
})

const notifications = computed(() => {
  return reportStore.notificationSystem.notifications
})

const totalNotifications = computed(() => {
  return notifications.value.length
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/mixins.scss";

.i_global-notification {
  @include text-xs;
  letter-spacing: 0.5px;
  min-height: 40px;
  width: 100%;

  @media screen and (min-width: $breakpointMd) {
    @include text-sm;
  }

  .wrapper {
    margin: auto;
    max-width: $breakpointXl;
    padding: 15px 12px;
    @include flex($direction: column, $justify: start, $align: center, $gap: 5px);

    @media screen and (min-width: $breakpointSm) {
      flex-direction: row;
      gap: 24px;
      padding: 8px 20px 8px 16px;
    }

    @media screen and (min-width: $breakpointLg) {
      padding-left: 20px;
      padding-right: 24px;
    }

    .controls {
      display: flex;
      gap: 4px;
      height: 24px;
      justify-content: space-between;
      min-width: 80px;
      max-width: 90px;
      place-items: center;

      .left-chevron,
      .right-chevron {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 24px;
      }

      img {
        width: 6px;
      }
    }

    .content {
      @include flex($direction: column, $justify: start, $align: center, $gap: 10px);
      text-align: center;
      width: 100%;

      @media screen and (min-width: $breakpointSm) {
        @include flex($justify: space-between, $gap: 24px);
      }

      .ctas {
        @include flex($justify: center, $align: center, $gap: 24px);

        @media screen and (min-width: $breakpointSm) {
          @include flex($direction: column, $justify: center, $align: stretch, $gap: 10px);
        }

        @media screen and (min-width: $breakpointLg) {
          @include flex($justify: center, $gap: 24px);
        }

        .action,
        .dismiss {
          border-radius: 32px;
          cursor: pointer;
          justify-content: center;
          padding: 3px 12px;
          text-wrap: nowrap;

          &:hover {
            transition: all 0.3s ease;
          }
        }

        .action {
          background-color: white;
        }
      }
    }
  }
}

.i_global-notification.alert {
  background-color: $sorbus600;
  color: $sorbus100;
  .action {
    color: $sorbus600;
  }
  .dismiss {
    background-color: $sorbus700;
    &:hover {
      background-color: $sorbus800;
    }
  }
}

.i_global-notification.error {
  background-color: $bittersweet600;
  color: $bittersweet100;
  .action {
    color: $bittersweet600;
  }
  .dismiss {
    background-color: $bittersweet700;
    &:hover {
      background-color: $bittersweet800;
    }
  }
}

.i_global-notification.info {
  background-color: $azureRadiance600;
  color: $azureRadiance100;
  .action {
    color: $azureRadiance600;
  }
  .dismiss {
    background-color: $azureRadiance700;
    &:hover {
      background-color: $azureRadiance800;
    }
  }
}

.i_global-notification.success {
  background-color: $riptide600;
  color: $riptide100;
  .action {
    color: $riptide600;
  }
  .dismiss {
    background-color: $riptide700;
    &:hover {
      background-color: $riptide800;
    }
  }
}

// hide from print
@media print {
  .i_global-notification {
    display: none;
  }
}
</style>
