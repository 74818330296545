<template>
  <div
    v-if="props.visible"
    class="i_base-modal"
    :style="{
      'z-index': props.zIndex
    }"
  >
    <div
      class="outer-wrapper"
      :style="{
        'min-width': props.minWidth,
        'max-width': props.maxWidth
      }"
    >
      <button
        v-if="props.showCloseIcon"
        @click="clickOnClose"
        class="close-modal"
      >
        <img
          :src="iconCloseButtonBlack"
          loading="lazy"
          alt="Close"
          class="close-modal-icon"
        />
      </button>

      <div :class="{ 'message-container': !props.fullyCustomisable }">
        <div :class="{ 'inner-wrapper': !props.fullyCustomisable }">
          <div :class="{ content: !props.fullyCustomisable }">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div
      class="shade"
      @click="props.enableBgClose ? clickOnClose() : null"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { zIndexModal } from "@/styles/variables"

import iconCloseButtonBlack from "@/assets/buttons/close-button-black.svg"

const emit = defineEmits(["clickOnClose"])

// Props

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  minWidth: {
    type: String,
    default: "220px"
  },
  maxWidth: {
    type: String,
    default: "680px"
  },
  zIndex: {
    type: Number,
    default: zIndexModal
  },
  fullyCustomisable: {
    type: Boolean,
    default: false
  },
  showCloseIcon: {
    type: Boolean,
    default: true
  },
  enableBgClose: {
    type: Boolean,
    default: false
  }
})

// Functions

function clickOnClose() {
  emit("clickOnClose")
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_base-modal {
  position: fixed;
  overflow-y: auto;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: rgba($pickleBluewood950, 0.96);
  height: 100lvh;

  .shade {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: $zIndexBackground;
  }

  .outer-wrapper {
    position: relative;
    margin-top: 15vh;
    width: 90%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    border-radius: 16px;
    background-color: #fff;

    .close-modal {
      position: absolute;
      left: auto;
      top: 10px;
      right: 10px;
      bottom: auto;
      z-index: $zIndexModal + $above;
      padding: 4px;
      border-radius: 32px;
      background-color: #e8e8e8;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      transition: background-color 200ms ease;
      cursor: pointer;
    }
    .close-modal:hover {
      background-color: #b3b3b3;
    }
    .close-modal-icon {
      width: 20px;
      height: 20px;
    }

    .message-container {
      display: flex;
      width: 95%;
      max-width: 1498px;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      justify-content: center;

      .inner-wrapper {
        text-align: center;
        position: relative;
        z-index: $zIndexModal;
        padding-top: 3%;
        padding-bottom: 3%;

        .content {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpointMd) {
  .i_base-modal {
    .close-modal {
      top: 8px;
      right: 8px;
    }
    .content {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>
