<template>
  <div
    class="i_payment-table"
    :class="$props.version"
  >
    <div class="grid">
      <div
        v-for="(tier, index) in PAYMENT_TABLE"
        :key="index"
        :id="`card-${index}`"
      >
        <iPaymentCard
          v-bind="tier"
          :is-button-disabled="isPaymentButtonDisabled"
          :version="props.version"
          @clicked="disablePaymentButtons"
          @payment-action-error="enablePaymentButtons"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user"
import { onMounted, ref } from "vue"

// COMPOSABLES
import getPaymentAction from "@/composables/getPaymentAction"
import linkToCoachSelection from "@/composables/linkToCoachSelection"
import useRandomNumber from "@/composables/useRandomNumber"

// COMPONENTS
import iPaymentCard from "@/components/pricing/PaymentCard.vue"

// ASSETS
import iconGreenCheckmark from "@/assets/icons/icon-green-checkmark.svg"
import iconRedXmark from "@/assets/icons/icon-red-xmark.svg"
import imageReportBasic from "@/assets/images/image-report-basic.png"
import imageReportPremium from "@/assets/images/image-report-new-premium.png"
import imageReportComprehensive from "@/assets/images/image-report-premium.png"
import imageReportBasicV2 from "@/assets/pricing/image-report-basic-v2.webp"
import imageReportComprehensiveV2 from "@/assets/pricing/image-report-comprehensive-v2.webp"
import imageReportPremiumV2 from "@/assets/pricing/image-report-premium-v2.webp"

// TYPES
import StripePrices from "@/shared/enums/StripePrices"
import UserStatus from "@/shared/enums/UserStatus"

// STYLES
import { black, black400 } from "@/styles/colors"

// TYPES
type TierProps = {
  benefits: { iconUrl: string; title: string; titleColor: string }[]
  buttonText: string
  currentPriceWithCents?: string
  highlightedText?: string
  imageUrl?: string
  isButtonDisabled?: boolean
  isButtonHidden?: boolean
  isHighlighted?: boolean
  numberOfPurchases?: number
  paymentAction: () => void
  reportType?: UserStatus
  subtitle?: string
  title?: string
}

// PROPS
const props = defineProps({
  version: {
    type: String,
    default: "v1",
    validator: (value: string) => {
      return value === "v1" || value === "v2"
    }
  }
})

// STORES
const userStore = useUserStore()

// COMPOSABLES
const basicReportPurchases = useRandomNumber(userStore.getFullName || "Full Name", 80, 130)
const comprehensiveReportPurchases = useRandomNumber(userStore.getFullName || "Full Name", 200, 310)
const premiumReportPurchases = useRandomNumber(userStore.getFullName || "Full Name", 90, 170)

// FUNCTIONS

const enablePaymentButtons = () => {
  isPaymentButtonDisabled.value = false
}

const disablePaymentButtons = () => {
  isPaymentButtonDisabled.value = true
}

// CONSTANTS
const BUTTON_TEXT_V1 = "GET MY RESULTS"
const BUTTON_TEXT_V2 = "Get My Results"

const BENEFITS = [
  { title: "Your 5 Top Career Paths" },
  { title: "Your Career Personality" },
  { title: "Detailed Career Analysis" },
  { title: "Exclusive Industry Insights" },
  { title: "1:1 Coaching Session (60min)" }
]

const ICONS_URL = {
  included: iconGreenCheckmark,
  excluded: iconRedXmark
}

const isVersion1 = props.version === "v1"

const getPaymentActionBasic = getPaymentAction(StripePrices.BASIC_LOW, UserStatus.BASIC, enablePaymentButtons)

const getPaymentActionComprehensive = getPaymentAction(
  StripePrices.COMPREHENSIVE_LOW,
  UserStatus.COMPREHENSIVE,
  enablePaymentButtons
)

const PAYMENT_TABLE: TierProps[] = [
  {
    benefits: [
      {
        ...BENEFITS[0],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[1],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[2],
        titleColor: black400,
        iconUrl: ICONS_URL.excluded
      },
      {
        ...BENEFITS[3],
        titleColor: black400,
        iconUrl: ICONS_URL.excluded
      },
      {
        ...BENEFITS[4],
        titleColor: black400,
        iconUrl: ICONS_URL.excluded
      }
    ],
    buttonText: isVersion1 ? BUTTON_TEXT_V1 : BUTTON_TEXT_V2,
    currentPriceWithCents: "24.90",
    imageUrl: isVersion1 ? imageReportBasic : imageReportBasicV2,
    isButtonHidden: false,
    isHighlighted: false,
    numberOfPurchases: basicReportPurchases.value,
    paymentAction: getPaymentActionBasic,
    reportType: UserStatus.BASIC,
    subtitle: "Simple Report for Light Career Exploration",
    title: "Basic Report"
  },
  {
    benefits: [
      {
        title: "Your 7 Top Career Paths",
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[1],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[2],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[3],
        titleColor: black400,
        iconUrl: ICONS_URL.excluded
      },
      {
        ...BENEFITS[4],
        titleColor: black400,
        iconUrl: ICONS_URL.excluded
      }
    ],
    buttonText: isVersion1 ? BUTTON_TEXT_V1 : BUTTON_TEXT_V2,
    currentPriceWithCents: "34.90",
    highlightedText: "Most popular",
    imageUrl: isVersion1 ? imageReportComprehensive : imageReportComprehensiveV2,
    isButtonHidden: false,
    isHighlighted: true,
    numberOfPurchases: comprehensiveReportPurchases.value,
    paymentAction: getPaymentActionComprehensive,
    reportType: UserStatus.COMPREHENSIVE,
    subtitle: "In-Depth Analysis for Serious Jobseekers",
    title: "Comprehensive Report"
  },
  {
    benefits: [
      {
        title: "Your 10 Top Career Paths",
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[1],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[2],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[3],
        titleColor: black,
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[4],
        titleColor: black,
        iconUrl: ICONS_URL.included
      }
    ],
    buttonText: isVersion1 ? BUTTON_TEXT_V1 : BUTTON_TEXT_V2,
    currentPriceWithCents: "99.90",
    imageUrl: isVersion1 ? imageReportPremium : imageReportPremiumV2,
    isButtonDisabled: false,
    isHighlighted: false,
    numberOfPurchases: premiumReportPurchases.value,
    paymentAction: linkToCoachSelection,
    reportType: UserStatus.PREMIUM,
    subtitle: "Supercharge Your Career with Expert Guidance",
    title: "Premium Report"
  }
]

// REFS
const isPaymentButtonDisabled = ref(false)

// LIFECYCLE
onMounted(() => {
  enablePaymentButtons()
})
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.i_payment-table {
  width: fit-content;
  margin: auto;
  margin-top: 30px;

  &.v1 {
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
  }

  &.v2 {
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 4px;
      grid-row-gap: 16px;
    }
  }

  #card-0 {
    grid-area: 2 / 1 / 3 / 2;
  }
  #card-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  #card-2 {
    grid-area: 3 / 1 / 4 / 2;
  }
}

@media screen and (min-width: $breakpointLg) {
  .i_payment-table {
    &.v2 {
      .grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
      }

      #card-0 {
        grid-area: 1 / 1 / 2 / 2;
      }
      #card-1 {
        grid-area: 1 / 2 / 2 / 3;
      }
      #card-2 {
        grid-area: 1 / 3 / 2 / 4;
      }
    }
  }
}

@media screen and (min-width: $breakpointXl) {
  .i_payment-table {
    &.v1,
    &.v2 {
      .grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
      }
    }

    #card-0 {
      grid-area: 1 / 1 / 2 / 2;
    }
    #card-1 {
      grid-area: 1 / 2 / 2 / 3;
    }
    #card-2 {
      grid-area: 1 / 3 / 2 / 4;
    }
  }
}
</style>
