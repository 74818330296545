<template>
  <div id="js-qubl"></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive, ref } from "vue"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"

// Services
import ApiService from "@/services/apiService"
import GtmService from "@/services/gtmService"
import QuizService from "@/services/quizService"

// Types
import QuizPostCoachingIn from "@/shared/dtos/in/QuizPostCoachingIn"
import type { QuizData } from "@/types/quizData"
import type NewUserDto from "@/shared/dtos/NewUserDto"

// EMITS
const emit = defineEmits(["quizCompleted", "quizSuccess", "quizError"])

// PROPS
const props = defineProps({
  quizId: {
    type: String,
    required: true
  }
})

// EXPOSED METHODS
defineExpose({ processQuizCompletionWithNewEmail })

// CONST (UPPERCASE)
const MODAL_EVENT = (event: BeforeUnloadEvent) => {
  event.preventDefault() // Cancel the event as stated by the standard.
  event.returnValue = "" // Chrome requires returnValue to be set.
}

// DATA
const data = reactive({
  quizData: {} as QuizData
})

// FUNCTIONS (QUBL SETUP)
function listenToQublPostback(quizId: string): void {
  document.querySelector("qubl-instance")?.addEventListener("postback", async (qublResponse) => {
    // @ts-ignore (qublResponse is not typed)
    data.quizData = qublResponse?.detail

    processQuizCompletion(data.quizData)

    // Remove quiz data in localstorage to avoid quiz retake blank screen
    localStorage.removeItem(quizId)
  })

  document.querySelector("qubl-instance")?.addEventListener("next", async () => {
    observeHelperText()
  })

  document.querySelector("qubl-instance")?.addEventListener("back", async () => {
    observeHelperText()
  })
}

const observer = ref<MutationObserver | null>(null)

const observeHelperText = () => {
  const qublInstance = document.querySelector("qubl-instance")?.shadowRoot
  const qublQuiz = qublInstance?.querySelector("qubl-quiz")?.shadowRoot

  if (qublQuiz && !observer.value) {
    observer.value = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const questionContainer = qublQuiz?.querySelector(".question-container")
          const helperText = qublQuiz.querySelector("#helper-text:not(#helper-text.moved)")

          if (helperText) {
            if (questionContainer) {
              helperText.classList.add("moved")
              questionContainer.appendChild(helperText)
            }
          }
        }
      }
    })

    observer.value.observe(qublQuiz, { childList: true, subtree: true })
  }
}

const unobserveHelperText = () => {
  if (observer.value) {
    observer.value.disconnect()
  }
}

function addExitModal(): void {
  window.addEventListener("beforeunload", MODAL_EVENT)
}

function removeExitModal(): void {
  window.removeEventListener("beforeunload", MODAL_EVENT)
}

// FUNCTIONS (QUIZ COMPLETION)
async function processQuizCompletion(quizData: QuizData): Promise<void> {
  emit("quizCompleted")

  GtmService.quizCompleteEvent()

  removeExitModal()

  try {
    const { quizUid, instanceUid, answers } = quizData

    // Store user in database
    let userSource = GlobalFunctions.getUserSourceData() || {}
    userSource["quiz_page"] = window.location.pathname

    const newUser: NewUserDto = {
      fullName: answers.fullName,
      email: answers.email,
      phoneNumber: answers.phoneNumber,
      gender: answers.gender,
      zipCode: answers.zipCode || null,
      userSource
    }

    try {
      await ApiService.postUser(newUser)
    } catch (error) {
      console.error("Error storing user in database", error)
      throw error
    }

    // Store quiz answers in database
    const coachingQuizAnswers = new QuizPostCoachingIn()

    type CoachingQuizAnswersDtoKeys = keyof QuizPostCoachingIn
    ;(Object.keys(coachingQuizAnswers) as Array<CoachingQuizAnswersDtoKeys>).forEach((key) => {
      if (answers[key] !== undefined) {
        ;(coachingQuizAnswers[key] as any) = answers[key]
      }
    })

    coachingQuizAnswers.qublInstanceUuid = instanceUid
    coachingQuizAnswers.qublQuizUuid = quizUid

    coachingQuizAnswers.industrySelection = QuizService.getIndustryValues(answers.industrySelection)

    try {
      await ApiService.postQuizCoaching(coachingQuizAnswers)
    } catch (error) {
      console.error("Error storing coaching quiz answers in database", error)
      throw error
    }

    try {
      // Store user in Klaviyo upon successful storage in database
      const klaviyoStorableData = {
        ...userSource,
        email: answers.email,
        first_name: answers.fullName,
        phone_number: answers.phoneNumber,
        qubl_quiz_uuid: quizUid,
        qubl_instance_uuid: instanceUid
      }

      setUserInKlaviyo(klaviyoStorableData)
    } catch (error) {
      console.error("Error storing user in Klaviyo", error)
    }

    emit("quizSuccess")
  } catch (error) {
    emit("quizError", error, quizData)
  }
}

function processQuizCompletionWithNewEmail(quizData: QuizData): void {
  data.quizData = quizData
  processQuizCompletion(data.quizData)
}

function setUserInKlaviyo(data: any) {
  try {
    if (location.host != GlobalVariables.domains.app.prod) return
    if (!data.email) return

    // @ts-ignore - Klaviyo is set via script tag
    klaviyo.identify({
      ...data,
      $email: data.email,
      hasCompletedQuiz: true
    })
  } catch (error) {
    console.error("Error setting user in Klaviyo", error)
  }
}

// Lifecycle
onMounted(() => {
  QuizService.loadQublBundle()
  QuizService.loadQublInstance(props.quizId, "")
  GtmService.quizStartEvent()
  GlobalFunctions.storeUserSourceData()
  addExitModal()
  listenToQublPostback(props.quizId)
})

onUnmounted(() => {
  removeExitModal()
  unobserveHelperText()
})
</script>
