<template>
  <a
    class="i_base-button"
    :class="{
      disabled: props.isDisabled,
      hoverable: props.isHoverable,
      uppercase: props.isTextUppercase,
      extraSmall: props.size === 'extraSmall',
      small: props.size === 'small',
      medium: props.size === 'medium',
      large: props.size === 'large',
      pulse: !!props.pulseColor
    }"
    :style="buttonStyle"
    @click="clicked"
  >
    <span
      v-if="props.preIcon"
      class="pre-icon"
    >
      <img
        :src="props.preIcon"
        loading="lazy"
        alt=""
      />
    </span>

    <span
      class="text"
      :style="textStyle"
    >
      {{ props.text }}
    </span>

    <span
      v-if="props.iconUrl"
      class="icon"
    >
      <img
        :src="props.iconUrl"
        loading="lazy"
        alt=""
      />
    </span>
  </a>
</template>

<script setup lang="ts">
import { black, brandOpacity100 } from "@/styles/colors"
import { computed, type ComputedRef } from "vue"

const emit = defineEmits(["clicked"])

const props = defineProps({
  backgroundColor: {
    type: String,
    default: brandOpacity100
  },
  borderColor: {
    type: String,
    default: ""
  },
  borderRadius: {
    type: String
  },
  fontWeight: {
    type: String,
    default: "700"
  },
  iconUrl: {
    type: String
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  isHoverable: {
    type: Boolean,
    default: true
  },
  isTextUppercase: {
    type: Boolean,
    default: false
  },
  letterSpacing: {
    type: String,
    default: "normal"
  },
  lineHeight: {
    type: String,
    default: "normal"
  },
  padding: {
    type: String,
    required: false
  },
  preIcon: {
    type: String
  },
  pulseColor: {
    type: String,
    default: ""
  },
  size: {
    type: String,
    default: "medium",
    required: false,
    validator(value: string | undefined) {
      if (!value) return true
      return ["extraSmall", "small", "medium", "large"].includes(value)
    }
  },
  text: {
    type: String,
    required: true
  },
  textColor: {
    type: String,
    default: black
  },
  version: {
    type: String,
    default: "solid",
    validator(value: string | undefined) {
      if (!value) return true
      return ["solid", "outline"].includes(value)
    }
  },
  width: {
    type: String,
    default: "fit-content"
  }
})

// COMPUTED PROPERTIES

const buttonStyle = computed(() => {
  let baseStyle = {
    padding: props.padding,
    width: props.width,
    "--pulse-color": props.pulseColor
  }

  if (props.version === "solid") {
    return {
      ...baseStyle,
      backgroundColor: props.backgroundColor,
      borderRadius: props.borderRadius || "36px",
      "--pulse-radius": props.borderRadius || "36px"
    }
  } else if (props.version === "outline") {
    return {
      ...baseStyle,
      backgroundColor: props.backgroundColor,
      border: `1px solid ${props.borderColor || props.textColor}`,
      borderRadius: props.borderRadius || "8px",
      "--pulse-radius": props.borderRadius || "8px"
    }
  }
  return baseStyle
})

const textStyle: ComputedRef = computed(() => {
  return {
    color: props.textColor,
    letterSpacing: props.letterSpacing,
    lineHeight: props.lineHeight,
    fontWeight: props.fontWeight
  }
})

// FUNCTIONS

function clicked() {
  if (!props.isDisabled) emit("clicked")
}
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_base-button {
  cursor: pointer;
  position: relative;
  text-align: center;
  text-decoration: none;
  top: 0px;
  @include flex($direction: row, $justify: center, $align: center, $gap: 4px);

  &.disabled {
    opacity: $buttonDisabledOpacity !important;
    filter: none !important;
    &:hover {
      cursor: default !important;
      box-shadow: unset !important;
      top: unset !important;
    }
  }

  &.hoverable {
    @include transition();
    @include hoverShadow();
    @include hoverBrightness();
    @include hoverTop();
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.extraSmall {
    min-height: 28px;
    padding: 2px 8px;

    .text {
      font-size: 13px;
    }
  }

  &.small {
    min-height: 36px;
    padding: 4px 12px;

    .text {
      @include text-sm;
    }
  }

  &.medium {
    min-height: 48px;
    padding: 8px 24px;

    .text {
      font-size: 15px;
    }
  }

  &.large {
    min-height: 72px;
    padding: 20px 32px;

    .text {
      @include text-md;
    }
  }

  .pre-icon {
    margin-right: 10px;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px var(--pulse-color);
    }
    50% {
      box-shadow: 0 0 0 8px var(--pulse-color);
    }
    100% {
      box-shadow: 0 0 0 0px var(--pulse-color);
    }
  }

  &.pulse:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: var(--pulse-radius);
    z-index: 1;
    animation: pulse 3s forwards infinite;
    animation-timing-function: linear;
  }
}
</style>
