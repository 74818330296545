<template>
  <div class="i_confirmation">
    <iBackground
      :background-image="bgGradient"
      imagePosition="center center"
    />

    <div class="content">
      <section>
        <iConfirmHero />
        <iMatchedCoach v-if="coachingStore.hasDefaultCoach" />
      </section>

      <section>
        <iWhatsNext />
      </section>

      <section>
        <iConfirmFooter />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue"
import { useCoachingStore } from "@/stores/coaching"

// SERVICES
import GtmService from "@/services/gtmService"

// COMPONENTS
import iBackground from "@/components/global/Background.vue"
import iConfirmHero from "@/components/coaching/confirmation/ConfirmHero.vue"
import iMatchedCoach from "@/components/coaching/confirmation/MatchedCoach.vue"
import iWhatsNext from "@/components/coaching/confirmation/WhatsNext.vue"
import iConfirmFooter from "@/components/coaching/confirmation/ConfirmFooter.vue"

// ASSETS
import bgGradient from "@/assets/confirm/bg-gradient-multi.png"
import UserStatus from "@/shared/enums/UserStatus"

// STORES
const coachingStore = useCoachingStore()

// FUNCTIONS
async function reportOrderToGoogleTagManager() {
  const urlParams = new URLSearchParams(window.location.search)
  const sessionId = urlParams.get("session_id")
  const valueAsString = urlParams.get("val")
  const value = valueAsString ? parseFloat(valueAsString) : 0
  const tier = (urlParams.get("tier") || UserStatus.PREMIUM) as UserStatus

  if (!sessionId) return

  try {
    await GtmService.purchaseEvent(tier, sessionId, value)
  } catch (error) {
    console.error("Error reporting coaching purchase to Google Tag Manager", error)
  }
}

//  LIFECYCLE HOOKS
onMounted(async () => {
  await reportOrderToGoogleTagManager()
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

$contentWidth: 960px;
.i_confirmation {
  .content {
    @include grid($cols: 1fr, $rows: auto, $gap: 0px);

    > section {
      padding: 40px 0px;

      > div {
        max-width: $contentWidth;
        margin-right: 15px;
        margin-left: 15px;

        @media screen and (min-width: $breakpointSm) {
          margin-right: 40px;
          margin-left: 40px;
        }

        @media screen and (min-width: $breakpointLg) {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
