<template>
  <div class="i_page-anchors">
    <template v-for="anchor in Object.keys(props.anchors)">
      <div
        class="anchor"
        :class="{ active: props.activeAnchor === anchor }"
        @click="$emit('set-active', anchor)"
      >
        <img :src="showIcon(anchor)" /> {{ props.anchors[anchor].text }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
// TYPES
import type { PageAnchors } from "@/types/pageAnchors"

// EMITS
defineEmits(["set-active"])

const props = defineProps({
  anchors: {
    type: Object as () => PageAnchors,
    required: true
  },
  activeAnchor: {
    type: String,
    required: true
  }
})

// FUNCTIONS
const showIcon = (anchor: string) => {
  return props.activeAnchor === anchor ? props.anchors[anchor].iconActive : props.anchors[anchor].icon
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_page-anchors {
  .anchor {
    color: $black950;
    @include text-md;
    font-weight: 400;
    padding: 12px 0 12px 10px;
    border-bottom: 1px solid $zumthor100;
    cursor: pointer;

    &.active {
      color: $azureRadiance700;
      font-weight: 600;
    }

    &:not(.active):hover {
      background-color: $azureRadiance50;
      border-bottom: 1px solid $azureRadiance200;
    }

    &:has(+ .anchor:not(.active):hover) {
      border-bottom: 1px solid $azureRadiance200;
    }

    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      vertical-align: text-bottom;
    }
  }
}
</style>
