import { createApp } from "vue"
import { createPinia } from "pinia"
import Vue3Lottie from "vue3-lottie"
import App from "./App.vue"
import router from "./router"
import * as Sentry from "@sentry/vue"

import "@/styles/main.scss"
import "@/styles/loading.scss"
import "vue3-lottie/dist/style.css"
import "vue3-carousel/dist/carousel.css"
import { useReportStore } from "./stores/report"
import { useUserStore } from "./stores/user"
import { GlobalVariables } from "./helpers/globalVariables"

async function init() {
  const isMagicAuthPage = window.location.pathname.includes(
    GlobalVariables.urls.auth.path + GlobalVariables.urls.auth.children.magic.path
  )
  const loading = document.getElementById("loading")

  if (loading) {
    loading.style.display = ""
  }

  const app = createApp(App)
  const pinia = createPinia()

  if (["dev", "stag", "prod"].includes(import.meta.env.VITE_ENV as string)) {
    Sentry.init({
      app,
      environment: import.meta.env.VITE_ENV as string,
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      tracesSampleRate: 0.2,
      denyUrls: [
        // This will exclude errors from QUBL
        /quiz\.builders/
      ]
    })
  } else {
    console.log("Sentry is disabled in current environment: " + import.meta.env.VITE_ENV)
  }

  app.use(pinia)

  if (!isMagicAuthPage) {
    const userStore = useUserStore()
    await userStore.loadUserData()

    if (userStore.isReportUser) {
      const reportStore = useReportStore()
      await reportStore.loadReport()
    }
  }

  app.use(router)

  // @ts-ignore
  app.use(Vue3Lottie)

  app.mount("#app")

  // Remove the loading screen for all pages except the magic link verification page
  // On this page, it is removed after the user is authenticated
  if (!isMagicAuthPage) {
    document.getElementById("loading")?.remove()
  }
}

init()
