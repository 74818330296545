<template>
  <div
    class="i_base-radio-select"
    :class="{ selected: props.isSelected }"
    @click="selectRadio"
  >
    <div
      class="radio"
      :class="{ selected: props.isSelected }"
    >
      <img
        v-if="props.isSelected"
        :src="iconCheckmarkWhite"
      />
    </div>
    <label>{{ props.label }}</label>
  </div>
</template>

<script setup lang="ts">
import iconCheckmarkWhite from "@/assets/icons/icon-checkmark-white.svg"

// Props

const props = defineProps({
  isSelected: {
    type: Boolean,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  value: {
    type: String,
    required: true
  }
})

// Emits

const emit = defineEmits(["select"])

// Functions

function selectRadio() {
  emit("select", props.value)
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";

.i_base-radio-select {
  align-items: center;
  border-radius: 32px;
  border: 1px solid $black300;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;

  &.selected {
    background-color: $azureRadiance100;
    border: 1px solid $azureRadiance300;
  }

  .radio {
    align-items: center;
    border-radius: 32px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;

    &.selected {
      background-color: $azureRadiance500;
      border: 1px solid $azureRadiance500;
    }

    img {
      height: auto;
      width: 60%;
    }
  }

  label {
    font-weight: normal;
    margin: 0px;
    cursor: pointer;
  }
}
</style>
