import type { QuizData } from "@/types/quizData"
enum QuizStatuses {
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR"
}

type ErrorMessages = {
  400: string
  401: string
  403: string
  500: string
  DEFAULT: string
}

type ObjectWithStringKeys = { [key: string]: any }

export default class QuizService {
  // STATIC VARIABLES
  static ERROR_MESSAGES: ErrorMessages = {
    400: `<p>The quiz answers are invalid. Please contact us at <strong><a href="mailto:support@jobtest.org">support@jobtest.org</a></strong> with the same email address you used when completing your test to assist you.</p>`,
    401: `<p>Invalid credentials. Please contact us at <strong><a href="mailto:support@jobtest.org">support@jobtest.org</a></strong> with the same email address you used when completing your test to assist you.</p>`,
    403: `<p>This email has been already used to take the test. Since only one attempt is allowed per email, please use a different email to get your results.</p>`,
    500: `<p>We have encountered an internal error procesing your test. Please contact us at <strong><a href="mailto:support@jobtest.org">support@jobtest.org</a></strong> with the same email address you used when completing your test to assist you.</p>`,
    DEFAULT: `<p>There was an error on our end. Please contact us at <strong><a href="mailto:support@jobtest.org">support@jobtest.org</a></strong> with the same email address you used when completing your test to assist you.</p>`
  }

  static QUIZ_STATUSES = QuizStatuses

  static INDUSTRIES_MAP: ObjectWithStringKeys = {
    "Agriculture / Forestry / Fishing": 1,
    "Mining and Quarrying": 2,
    Manufacturing: 3,
    "Construction and Engineering": 4,
    "Wholesale and Retail Trade": 5,
    "Transportation and Logistics": 6,
    "Information and Communication Technology": 7,
    "Finance and Insurance": 8,
    "Real Estate and Property Management": 9,
    "Professional and Technical Services": 10,
    "Education and Training": 11,
    "Healthcare and Social Assistance": 12,
    "Arts / Entertainment / Recreation": 13,
    "Hospitality and Tourism": 14,
    "Government and Public Administration": 15,
    "Non-profit and Community Services": 16,
    "Legal Services": 17,
    "Media and Communication": 18,
    "Personal Services": 19,
    "Sales and Marketing": 20,
    "Science and Research": 21,
    "Sports and Fitness": 22,
    Telecommunications: 23,
    "Aviation and Aerospace": 24,
    "Startup and Innovation": 25
  }

  static JOB_FUNCTIONS_MAP: ObjectWithStringKeys = {
    "Administrative and Office Support": 1,
    "Business Strategy and Management": 2,
    "Customer Service and Support": 3,
    "Design and Creative": 4,
    "Education and Training": 5,
    "Engineering and Technology": 6,
    "Finance and Accounting": 7,
    Healthcare: 8,
    "Hospitality and Tourism": 9,
    "Human Resources": 10,
    Legal: 11,
    "Manufacturing and Production": 12,
    "Marketing / Advertising / Public Relations": 13,
    "Real Estate and Construction": 14,
    "Research and Development": 15,
    Sales: 16,
    "Supply Chain / Logistics": 17,
    Other: 18
  }

  // STATIC METHODS
  /**
   * Get the RIASEC results that are generated by QUBL
   */
  static getNamedResults(results: QuizData["results"]): ObjectWithStringKeys {
    if (!Array.isArray(results)) return {}

    const namedResults: ObjectWithStringKeys = {}
    const acceptedResults = ["realistic", "investigative", "artistic", "social", "enterprising", "conventional"]

    results.forEach((result) => {
      const resultName = result.metadata?.resultName
      if (!resultName) return
      if (acceptedResults.includes(resultName)) {
        const value = Number(result.points) || 0
        namedResults[resultName] = value
      }
    })

    return namedResults
  }

  /**
   * Prepares the quiz answers received from Qubl for submission
   */
  static getUpdatedAnswers(answers: QuizData["answers"] | undefined) {
    if (!answers) return

    // Deep clone of answers to have a pure function
    const clonedAnswers = JSON.parse(JSON.stringify(answers))

    try {
      // Step 1: Remove trailing \r
      const sanitizedAnswers = QuizService.sanitizeAnswers(clonedAnswers)
      // Step 2: Some answers need to be updated to a different type
      const typeUpdatedAnswers = QuizService.typeUpdateAnswers(sanitizedAnswers)
      // Step 3: Some answers need to be reduced to a single value, based on quiz logic
      const reducedAnswers = QuizService.reduceAnswers(typeUpdatedAnswers)
      // Step 4: Check if personality section was skipped, and clean up quiz answers related to it
      const personalitySectionHandledAnswers = QuizService.handlePersonalitySectionSkip(reducedAnswers)
      // Step 5: If answers missing, adding default values avoids submission errors
      const completedAnswers = QuizService.completeAnswers(personalitySectionHandledAnswers)
      // Step 6: Remove answers that should not be stored
      const finalAnswers = QuizService.removeAnswers(completedAnswers)

      return finalAnswers
    } catch (error) {
      console.error("Error updating answers", error)
      throw error
    }
  }

  /**
   *
   */
  static getShapeUpdatedAnswers(answers: QuizData["answers"] | undefined) {
    if (!answers) return

    // Some answers need to be encapsulated inside object
    // Listed in the order they appear in the quiz
    const personalityKeys = [
      "big_picture_focus",
      "morning_person",
      "self_reliance",
      "prefer_facts",
      "clear_success_metrics",
      "situation_adaptation",
      "organized_planner",
      "enjoys_philosophical_questions",
      "dislike_socializing",
      "detail_focus",
      "positive_impact",
      "people_motivations_interest",
      "enjoys_extensive_research",
      "competitive_environment",
      "frustrated_by_emotions",
      "great_attention_to_detail",
      "alone_time_preference",
      "passion_for_helping",
      "practical_over_inventive",
      "persuasive_skills",
      "logical_over_empathetic",
      "dislike_schedule",
      "influence_others",
      "prefer_social_activities",
      "great_imagination",
      "interested_in_machines",
      "nonconformist",
      "large_organization_preference",
      "like_theory_and_philosophy",
      "helping_others_achieve",
      "dislikes_competition",
      "detail_oriented",
      "research_analytical_value",
      "talkative_and_outgoing",
      "enjoys_taking_apart",
      "logic_first",
      "enjoys_deep_research",
      "prefer_planning",
      "requires_creativity",
      "high_risk_high_reward",
      "other_feeling_important",
      "consider_other_first",
      "group_leader",
      "prefers_working_alone",
      "creative_expression"
    ]

    try {
      // Created a deep clone of answers to have a pure function
      const shapedAnswers = JSON.parse(JSON.stringify(answers))

      shapedAnswers.personality_answers = {}

      const answersAsArray = Object.entries(shapedAnswers)
      answersAsArray.map((answer) => {
        if (personalityKeys.includes(answer[0])) {
          shapedAnswers.personality_answers[answer[0]] = answer[1]
          delete shapedAnswers[answer[0]]
        }
      })

      return shapedAnswers
    } catch (error) {
      console.error("Error shaping answers", error)
      throw error
    }
  }

  /**
   * Loads the Qubl instance bundle
   */
  static loadQublBundle(): void {
    const script = "https://quiz.builders/instance-bundle"
    const target = document.createElement("script")
    const head = document.head
    target.setAttribute("src", script)
    target.setAttribute("async", "false")
    head.insertBefore(target, head.firstElementChild)
  }

  /**
   * Loads the Qubl instance
   */
  static loadQublInstance(quizId: string, quizStyle: string): void {
    const qublContainer = document.querySelector("#js-qubl") as HTMLElement
    const quizHtml = `<qubl-instance quiz-id="${quizId}" id="qubl-instance" css="${quizStyle}"></qubl-instance>`
    qublContainer.innerHTML = quizHtml
  }

  // PRIVATE STATIC METHODS

  /**
   * Adds default values to missing answers to avoid submission errors
   */
  private static completeAnswers(answers: QuizData["answers"] | undefined) {
    if (!answers) return

    // Listed in the order they appear in the quiz
    const defaultValues = {
      first_time_test: "",
      test_taking_purpose: "",
      test_taking_purpose_other: "",
      student_status: "",
      school_name: "",
      future_college_when: "",
      ideal_career_feature: "",
      personality_change: "",
      personality_change_feeling: "",
      job_motivation: "",
      career_goal: "",
      risk_tolerance: "",
      capboi_short: "",
      big_picture_focus: 0,
      morning_person: 0,
      self_reliance: 0,
      prefer_facts: 0,
      clear_success_metrics: 0,
      situation_adaptation: 0,
      organized_planner: 0,
      enjoys_philosophical_questions: 0,
      dislike_socializing: 0,
      detail_focus: 0,
      positive_impact: 0,
      people_motivations_interest: 0,
      enjoys_extensive_research: 0,
      competitive_environment: 0,
      frustrated_by_emotions: 0,
      great_attention_to_detail: 0,
      alone_time_preference: 0,
      passion_for_helping: 0,
      practical_over_inventive: 0,
      persuasive_skills: 0,
      logical_over_empathetic: 0,
      dislike_schedule: 0,
      influence_others: 0,
      prefer_social_activities: 0,
      great_imagination: 0,
      interested_in_machines: 0,
      nonconformist: 0,
      large_organization_preference: 0,
      like_theory_and_philosophy: 0,
      helping_others_achieve: 0,
      dislikes_competition: 0,
      detail_oriented: 0,
      research_analytical_value: 0,
      talkative_and_outgoing: 0,
      enjoys_taking_apart: 0,
      logic_first: 0,
      enjoys_deep_research: 0,
      prefer_planning: 0,
      requires_creativity: 0,
      high_risk_high_reward: 0,
      other_feeling_important: 0,
      consider_other_first: 0,
      group_leader: 0,
      prefers_working_alone: 0,
      creative_expression: 0,
      image_selection1: 0,
      image_selection2: 0,
      image_selection3: 0,
      image_selection4: 0,
      image_selection5: 0,
      current_hobby: "",
      current_hobby_other: "",
      future_hobby: "",
      future_hobby_other: "",
      future_hobby_language: "",
      future_hobby_language_other: "",
      future_hobby_music: "",
      future_hobby_music_other: "",
      future_hobby_sport: "",
      future_hobby_sport_other: "",
      phobia: [],
      attention_to_detail_score: 0,
      presentation_skills_score: 0,
      leadership_confidence_score: 0,
      communication_skills_score: 0,
      organizational_skills_score: 0,
      strategic_thinking_score: 0,
      decision_making_score: 0,
      problem_solving_score: 0,
      time_management_score: 0,
      team_management_score: 0,
      financial_management_score: 0,
      marketing_knowledge_score: 0,
      sales_skills_score: 0,
      negotiation_skills_score: 0,
      customer_service_skills: 0,
      data_analysis_score: 0,
      project_management_score: 0,
      interpersonal_skills_score: 0,
      change_adaptation_score: 0,
      creative_thinker_score: 0,
      innovation_skills_score: 0,
      technical_skills_score: 0,
      computer_proficiency_score: 0,
      statistical_analysis_score: 0,
      research_skills_score: 0,
      ethnicity: "",
      employment_status: "",
      employment_history: "",
      unemployment_length: "",
      unemployment_benefit_info: "",
      temporary_job_interest: "",
      employment_industry: [],
      current_job: "",
      current_job_other: "",
      years_of_experience: 0,
      job_decision_reason: "",
      job_decision_reason_other: "",
      company_size: 0,
      weekly_hours_worked: 0,
      work_life_balance_score: 0,
      job_security_score: 0,
      career_potential_score: 0,
      job_satisfaction_score: 0,
      current_salary: 0,
      job_lacking_feature: "",
      job_secret: "",
      ugc_candidate: "",
      considered_job: "",
      considered_industry: [],
      considered_role: [],
      home_influence: "",
      feeling_towards_home: "",
      home_feeling_reason: "",
      parent_job_influence: "",
      parent_job: "",
      fullfilling_job_idea: "",
      desired_industry: [],
      admired_job: "",
      desired_function: [],
      undesired_industry: [],
      undesired_function: [],
      current_age: 0,
      education_level: 0,
      school_major: "",
      student_loan: "",
      actively_exploring_job: "",
      actively_exploring_job_other: "",
      more_school_interest: "",
      certificate_interest: "",
      training_program_interest: "",
      training_interest: "",
      career_coach: "",
      career_coach_service: "",
      five_year: "",
      missing_needs: "",
      burnout_experience: "",
      burnout_reason: "",
      stress_tolerance: "",
      preferred_team_size: "",
      autonomy_preference: 0,
      preferred_leadership_style: "",
      preferred_feedback_style: "",
      preferred_work_schedule: "",
      relocation_preference: "",
      work_from_home: "",
      open_to_travel: "",
      startup_interest: "",
      social_impact_importance: 0,
      consideration_user_input: ""
    }

    try {
      const completedAnswers = {
        ...defaultValues,
        ...answers
      }

      return completedAnswers
    } catch (error) {
      console.error("Error completing answers", error)
      throw error
    }
  }

  /**
   * Gets the industry id based on the string value
   */
  private static getIndustryValue(answer: string): number | null {
    if (!answer) return null
    return QuizService.INDUSTRIES_MAP[answer as keyof typeof this.INDUSTRIES_MAP] || null
  }

  /**
   * Gets the industry ids based on the string values
   */
  static getIndustryValues(answer: string | null = null): number[] {
    if (!answer) return []

    const answersArray = answer.split(",") || []
    const industryValues = answersArray.map((answer) => QuizService.getIndustryValue(answer))

    // Filter out null values
    return industryValues.filter((value): value is number => value !== null)
  }

  /**
   * Gets the job function id based on the string value
   */
  private static getJobFunctionValue(answer: string) {
    if (!answer) return null
    return QuizService.JOB_FUNCTIONS_MAP[answer as keyof typeof this.JOB_FUNCTIONS_MAP] || null
  }

  /**
   * Gets the job function ids based on the string values
   */
  static getJobFunctionsValues(answer: string | null = null): number[] {
    if (!answer) return []

    const answersArray = answer.split(",") || []
    const jobFunctionsValues = answersArray.map((answer) => QuizService.getJobFunctionValue(answer))

    return jobFunctionsValues.filter((value): value is number => value !== null)
  }

  /**
   * Gets the phobia ids based on the string values
   */
  private static getPhobiasValues(answer: string | null = null): number[] {
    if (!answer) return []

    const answersArray = answer.split(",") || []
    const phobiasMap = {
      "Arachnophobia (Fear of Spiders)": 1,
      "Acrophobia (Fear of Heights)": 2,
      "Aerophobia (Fear of Flying)": 3,
      "Agoraphobia (Fear of Open or Crowded Spaces)": 4,
      "Cynophobia (Fear of Dogs)": 5,
      "Ophidiophobia (Fear of Snakes)": 6,
      "Astraphobia (Fear of Thunder and Lightning)": 7,
      "Claustrophobia (Fear of Enclosed Spaces)": 8,
      "Trypanophobia (Fear of Needles)": 9,
      "Mysophobia (Fear of Germs and Contamination)": 10,

      // Quiz v2.0 incudes emojis
      "🕷 Arachnophobia (Fear of Spiders)": 1,
      "🗼 Acrophobia (Fear of Heights)": 2,
      "✈️ Aerophobia (Fear of Flying)": 3,
      "😵 Agoraphobia (Fear of Open or Crowded Spaces)": 4,
      "🐶 Cynophobia (Fear of Dogs)": 5,
      "🐍 Ophidiophobia (Fear of Snakes)": 6,
      "⚡️ Astraphobia (Fear of Thunder and Lightning)": 7,
      "🚪 Claustrophobia (Fear of Enclosed Spaces)": 8,
      "💉 Trypanophobia (Fear of Needles)": 9,
      "🦠️ Mysophobia (Fear of Germs and Contamination)": 10
    }

    return answersArray.map((answer) => {
      return phobiasMap[answer as keyof typeof phobiasMap] || 0
    })
  }

  private static handlePersonalitySectionSkip(answers: QuizData["answers"] | undefined) {
    // Function explanation
    // Check if personality section was skipped, and clean up quiz answers related to it

    if (!answers) return

    try {
      answers.personality_skipped = false
      if (answers.capboi_section_check && answers.capboi_section_check?.toLowerCase() === "skipped") {
        if (answers.capboi_section_final && answers.capboi_section_final?.toLowerCase() === "skipped") {
          answers.personality_skipped = true
        }
      }

      // Remove quiz answers that should not be stored
      delete answers.capboi_section_check
      delete answers.capboi_section_final

      return answers
    } catch (error) {
      console.error("Error handling personality section skip", error)
      throw error
    }
  }

  private static reduceAnswers(answers: QuizData["answers"] | undefined) {
    // Function explanation
    // Some answers need to be reduced to a single value, based on quiz logic

    if (!answers) return

    try {
      // Make a deep clone of answers to have a pure function
      const reducedAnswers = JSON.parse(JSON.stringify(answers))

      // Merge desired_industry and employment_industry if industry_inclusion is yes
      if (answers.industry_inclusion && answers.industry_inclusion?.toLowerCase() === "yes") {
        const desiredIndustry = reducedAnswers.desired_industry || []
        const employmentIndustry = reducedAnswers.employment_industry || []
        reducedAnswers.desired_industry = [...new Set([...desiredIndustry, ...employmentIndustry])]
      }

      // Merge desired_industry and considered_industry if considered_industry_inclusion is not "no"
      if (answers.considered_industry_inclusion && answers.considered_industry_inclusion?.toLowerCase() !== "no") {
        const desiredIndustry = reducedAnswers.desired_industry || []
        const consideredIndustry = reducedAnswers.considered_industry || []
        reducedAnswers.desired_industry = [...new Set([...desiredIndustry, ...consideredIndustry])]
      }

      // Merge undesired_industry and undesired_industry2
      if (Array.isArray(answers.undesired_industry2) && answers.undesired_industry2?.length > 0) {
        const undesiredIndustry = reducedAnswers.undesired_industry || []
        const undesiredIndustry2 = reducedAnswers.undesired_industry2 || []
        reducedAnswers.undesired_industry = [...new Set([...undesiredIndustry, ...undesiredIndustry2])]
      }

      // Merge desired_function and considered_role if considered_role_inclusion is not "no"
      if (answers.considered_role_inclusion && answers.considered_role_inclusion?.toLowerCase() !== "no") {
        const desiredFunction = reducedAnswers.desired_function || []
        const consideredRole = reducedAnswers.considered_role || []
        reducedAnswers.desired_function = [...new Set([...desiredFunction, ...consideredRole])]
      }

      // Remove quiz answers that should not be stored
      delete reducedAnswers.industry_inclusion
      delete reducedAnswers.considered_industry_inclusion
      delete reducedAnswers.considered_role_inclusion
      delete reducedAnswers.undesired_industry2

      return reducedAnswers
    } catch (error) {
      console.error("Error reducing answers", error)
      throw error
    }
  }

  private static removeAnswers(answers: QuizData["answers"] | undefined) {
    if (!answers) return

    // full_name, email, phone_number, gender, zip_code are not needed in the final data since they are stored in the user profile
    const answersToRemove = ["full_name", "email", "phone_number", "gender", "zip_code"]

    try {
      answersToRemove.forEach((answer) => {
        delete answers[answer]
      })

      return answers
    } catch (error) {
      console.error("Error removing answers", error)
      throw error
    }
  }

  private static sanitizeAnswers(answers: QuizData["answers"] | undefined) {
    if (!answers) return

    try {
      const answersAsArray = Object.entries(answers)
      answersAsArray.map((answer) => {
        answer[1] = answer[1].replace(/\r$/, "")
      })

      return Object.fromEntries(answersAsArray)
    } catch (error) {
      console.error("Error sanitizing answers", error)
      throw error
    }
  }

  private static typeUpdateAnswers(answers: QuizData["answers"] | undefined) {
    if (!answers) return

    // Keys whose value must be phohia-updated
    const phobiaUpdateKeys = ["phobia"]

    // Keys whose value must be industry-updated (multiple value)
    const industryUpdateKeys = [
      "desired_industry",
      "undesired_industry",
      "undesired_industry2",
      "employment_industry",
      "considered_industry"
    ]

    // Keys whose value must be function-updated (multiple value)
    const jobFunctionUpdateKeys = ["undesired_function", "desired_function", "considered_role"]

    // Keys whose value must be integer-updated
    const integerUpdateKeys = [
      "big_picture_focus",
      "morning_person",
      "self_reliance",
      "prefer_facts",
      "clear_success_metrics",
      "situation_adaptation",
      "organized_planner",
      "enjoys_philosophical_questions",
      "dislike_socializing",
      "detail_focus",
      "positive_impact",
      "people_motivations_interest",
      "enjoys_extensive_research",
      "competitive_environment",
      "frustrated_by_emotions",
      "great_attention_to_detail",
      "alone_time_preference",
      "passion_for_helping",
      "practical_over_inventive",
      "persuasive_skills",
      "logical_over_empathetic",
      "dislike_schedule",
      "influence_others",
      "prefer_social_activities",
      "great_imagination",
      "interested_in_machines",
      "nonconformist",
      "large_organization_preference",
      "like_theory_and_philosophy",
      "helping_others_achieve",
      "dislikes_competition",
      "detail_oriented",
      "research_analytical_value",
      "talkative_and_outgoing",
      "enjoys_taking_apart",
      "logic_first",
      "enjoys_deep_research",
      "prefer_planning",
      "requires_creativity",
      "high_risk_high_reward",
      "other_feeling_important",
      "consider_other_first",
      "group_leader",
      "prefers_working_alone",
      "creative_expression",
      "image_selection1",
      "image_selection2",
      "image_selection3",
      "image_selection4",
      "image_selection5",
      "attention_to_detail_score",
      "presentation_skills_score",
      "leadership_confidence_score",
      "communication_skills_score",
      "organizational_skills_score",
      "strategic_thinking_score",
      "decision_making_score",
      "problem_solving_score",
      "time_management_score",
      "team_management_score",
      "financial_management_score",
      "marketing_knowledge_score",
      "sales_skills_score",
      "negotiation_skills_score",
      "customer_service_skills",
      "data_analysis_score",
      "project_management_score",
      "interpersonal_skills_score",
      "change_adaptation_score",
      "creative_thinker_score",
      "innovation_skills_score",
      "technical_skills_score",
      "computer_proficiency_score",
      "statistical_analysis_score",
      "research_skills_score",
      "years_of_experience",
      "company_size",
      "weekly_hours_worked",
      "work_life_balance_score",
      "job_security_score",
      "career_potential_score",
      "job_satisfaction_score",
      "current_salary",
      "current_age",
      "education_level",
      "autonomy_preference",
      "social_impact_importance",
      "target_salary"
    ]

    try {
      const answersAsArray = Object.entries(answers)
      answersAsArray.map((answer) => {
        // Keys whose value must be integer-updated
        if (integerUpdateKeys.includes(answer[0])) {
          answer[1] = +answer[1]
        }
        // Keys whose value must be phohia-updated
        if (phobiaUpdateKeys.includes(answer[0])) {
          answer[1] = QuizService.getPhobiasValues(answer[1])
        }
        // Keys whose value must be industry-updated (multiple value)
        if (industryUpdateKeys.includes(answer[0])) {
          answer[1] = QuizService.getIndustryValues(answer[1])
        }
        // Keys whose value must be function-updated (multiple value)
        if (jobFunctionUpdateKeys.includes(answer[0])) {
          answer[1] = QuizService.getJobFunctionsValues(answer[1])
        }
        // target_salary can be undefined
        if (answer[0] === "target_salary" && answer[1] === 0) {
          answer[1] = undefined
        }
      })

      return Object.fromEntries(answersAsArray)
    } catch (error) {
      console.error("Error updating answers", error)
      throw error
    }
  }
}
