<template>
  <div class="i_refund-policy-in-modal">
    <div class="caption">Refund Policy</div>
    <div>
      <p>
        <strong>Jobtest.org</strong> empowers people and helps them make informed career decisions. When you take our
        proprietary test, we'll provide you with a report identifying your best career paths based on your skills,
        interests, and opportunities. If your report fails to identify any career paths based on the answers provided to
        the test, we will provide a full refund of the cost of the test.
      </p>
      <p>
        If you are unsatisfied with the results of your test for any other reason, please let us know. We will
        incorporate your feedback into the testing for other users. At our discretion, we may offer a full refund or
        promo code to retake the test. All returns must be made within five (5) days of the purchase date.
      </p>
      <p>
        Please email us at
        <strong>
          <a
            href="mailto:support@jobtest.org"
            target="_blank"
            >support@jobtest.org</a
          >
        </strong>
        with the subject line: <i>Refund Request</i>.
      </p>
      <p>
        In your email, please include the email you used during the quiz and the form of payment (e.g. credit card) you
        used to purchase the test.
      </p>
      <p>Please allow up to 14 days to process your refund request.</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";

.i_refund-policy-in-modal {
  @include flex($direction: column, $justify: start, $align: center);
  margin: 25px;

  .caption {
    font-weight: 700;
    @include display-sm;
    text-align: center;
  }
}
</style>
