<template>
  <div class="i_action-card">
    <div>
      <img
        v-if="props.imageUrl"
        :src="props.imageUrl"
      />
      <!-- For IOS we need to add playsinline attribute -->
      <video
        v-if="!props.imageUrl"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="props.videoUrl"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="wrap">
      <div
        class="texts"
        :style="{ '--lg-width': props.ctaText ? 'fit-content' : '75%' }"
      >
        <div
          class="subtitle"
          v-if="props.caption"
        >
          {{ props.caption }}
        </div>

        <div
          class="title"
          v-if="props.text"
          v-html="props.text"
        ></div>
      </div>

      <div
        class="cta"
        v-if="props.ctaText || isDesktop"
      >
        <iBaseButton
          v-if="props.ctaText"
          :background-color="azureRadiance700"
          border-radius="8px"
          font-weight="500"
          size="small"
          :text="props.ctaText"
          text-color="white"
          width="100%"
          @click="$emit('ctaClicked')"
        />
      </div>
    </div>

    <div class="overlay"></div>
  </div>
</template>

<script setup lang="ts">
import { azureRadiance700 } from "@/styles/colors"
import { breakpointMd } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"

// COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)

// EMITS
defineEmits(["ctaClicked"])

const props = defineProps({
  text: {
    type: String,
    default: ""
  },
  caption: {
    type: String,
    default: ""
  },
  ctaText: {
    type: String,
    default: ""
  },
  imageUrl: {
    type: String,
    default: ""
  },
  videoUrl: {
    type: String,
    default: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/jobtest-career-coach-video-1.mp4"
  }
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_action-card {
  @include border-radius-lg;
  align-items: flex-end;
  display: flex;
  height: 374px;
  overflow: hidden;
  position: relative;
  color: white;
  text-align: left;

  @media screen and (min-width: $breakpointMd) {
    height: 320px;
  }

  .overlay {
    background: linear-gradient(180deg, rgba(24, 34, 48, 0) 0%, rgba(13, 18, 25, 0.5) 48.44%, rgba(0, 0, 0, 0.6) 100%);
    height: 50%;
    width: 100%;
  }

  .wrap {
    overflow: hidden;
    position: absolute;

    width: 100%;
    padding: 32px 24px;
    @include flex($direction: column, $justify: center, $align: start, $gap: 8px);

    @media screen and (min-width: $breakpointMd) {
      padding: 30px 32px;
      @include flex($direction: row, $justify: space-between, $align: end, $gap: 0px);
    }

    .cta {
      width: 100%;
      margin-top: 6px;

      @media screen and (min-width: $breakpointSm) {
        width: 180px;
      }
    }

    .texts {
      width: 100%;

      @media screen and (min-width: $breakpointMd) {
        width: unset;
      }

      @media screen and (min-width: $breakpointLg) {
        width: var(--lg-width);
      }

      .subtitle {
        @include text-caption;
        font-weight: 700;
        margin-bottom: 6px;
      }

      .title {
        font-size: 24px;
        line-height: 31px;
        font-weight: 500;
      }
    }
  }

  video,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/borders.scss";

.i_action-card {
  .highlighted {
    background-color: $azureRadiance500;
    padding: 0 6px;
    color: $white;
    @include border-radius-sm;
    white-space: nowrap;
  }
}
</style>
