<template>
  <div
    class="i_onboarding-tooltip"
    :class="{ left: $props.positionLeft }"
    v-if="reportStore.activeTooltipValue === props.value"
  >
    <img
      class="image"
      :src="tooltipContent[props.value].image"
    />
    <div class="bottom">
      <img
        class="close"
        :src="IconClose"
        @click.stop="reportStore.closeTooltip()"
      />
      <div class="details">
        <div class="caption">{{ tooltipContent[props.value].caption }}</div>
        <div class="text">{{ tooltipContent[props.value].text }}</div>
      </div>
      <div class="nav">
        <div class="count">
          {{ (reportStore.activeTooltipIndex || 0) + 1 }} of {{ reportStore.calculatedTooltipIndexes.length }}
        </div>
        <div class="buttons">
          <iBaseButton
            v-if="reportStore.activeTooltipIndex !== 0"
            v-bind="BUTTON_STYLE.previous"
            text="Previous"
            @click.stop="action(false)"
          />
          <iBaseButton
            v-bind="BUTTON_STYLE.next"
            :text="ctaText"
            @click.stop="action(true)"
          />
        </div>
      </div>
    </div>
    <img
      class="tippy"
      :src="IconTippy"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, reactive } from "vue"
import { useRouter } from "vue-router"
import { useUserStore } from "@/stores/user"
import { useReportStore } from "@/stores/report"
import { azureRadiance800, black, white } from "@/styles/colors"

import { GlobalVariables } from "@/helpers/globalVariables"

import iBaseButton from "@/components/base/BaseButton.vue"

// ASSETS
import Image1 from "@/assets/images/onboarding-1.svg"
import Image2 from "@/assets/images/onboarding-2.svg"
import Image3 from "@/assets/images/onboarding-3.svg"
import Image4 from "@/assets/images/onboarding-4.svg"
import IconClose from "@/assets/icons/icon-close-whiite.svg"
import IconTippy from "@/assets/icons/icon-tippy.svg"

// TYPES
import type { OnboardingTooltipValueType, OnboardingTooltipContentType } from "@/types/onboardingTooltips"

// STORES AND ROUTERS
const reportStore = useReportStore()
const userStore = useUserStore()
const router = useRouter()

// PROPS
const props = defineProps({
  value: {
    type: Number as () => OnboardingTooltipValueType,
    default: 1
  },
  positionLeft: {
    type: Boolean,
    default: false
  }
})

// CONSTANTS
const REPORT_ROUTES = GlobalVariables.urls.report.children
const SHARED_BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content",
  borderRadius: "5px",
  padding: "0px 20px"
}
const BUTTON_STYLE = {
  previous: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: azureRadiance800,
    textColor: white,
    version: "outline"
  },
  next: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: white,
    textColor: black
  }
}

// DATA
const tooltipContent: OnboardingTooltipContentType = reactive({
  1: {
    caption: "Navigate between sections",
    text: "Easily navigate to different sections of your report using our side navigation.",
    image: Image1,
    previousAction: () => {
      router.push({ name: REPORT_ROUTES.home.routeName })
    }
  },
  2: {
    caption: "Explore different careers",
    text: userStore.isTierFree
      ? "Here is a sample job that follows the format of our paid recommendations"
      : "We’ve pulled the top recommendations for you based of your answers in the quiz.",
    image: Image2
  },
  3: {
    caption: "Save into PDF and Print",
    text: "Prefer a paper copy? Save this into a PDF or print the entire page.",
    image: Image3,
    nextAction: () => {
      router.push({ name: REPORT_ROUTES.careers.routeName, params: { jobId: reportStore.getActiveJobIndex } })
    }
  },
  4: {
    caption: "Like, Remove, or Replace",
    text: "You can use these buttons to like, remove, or replace recommendations from your dashboard.",
    image: Image4
  }
})

// COMPUTED
const ctaText = computed(() =>
  reportStore.calculatedTooltipIndexes.length - 1 === reportStore.activeTooltipIndex ? "Finish" : "Next"
)

// FUNCTIONS
const scrollToOnboardingTooltip = () => {
  nextTick(() => {
    const onboardingTooltip = document.querySelector(".i_onboarding-tooltip")

    const offset = 206
    const element = onboardingTooltip

    if (element) {
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    }
  })
}

const action = (isNext: boolean) => {
  isNext ? reportStore.nextTooltipIndex() : reportStore.previousTooltipIndex()
  if (reportStore.activeTooltipValue)
    tooltipContent[reportStore.activeTooltipValue][isNext ? "nextAction" : "previousAction"]?.()
  scrollToOnboardingTooltip()
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_onboarding-tooltip {
  position: absolute;
  top: 0;
  z-index: 500;
  transform: translate(0%, -100%);
  width: 300px;
  color: $white;

  @media screen and (min-width: $breakpointMd) {
    transform: translate(20%, -100%);
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 500;
    box-shadow: 0px -3px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .bottom {
    position: relative;
    background-color: $azureRadiance800;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);

    .close {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }

    .details {
      border-bottom: 1px solid $azureRadiance700;
      padding: 12px 18px 12px 22px;
      text-align: start;
      @include flex(column, start, start, $gap: 4px);

      .caption {
        font-weight: 700;
        @include text-md;
      }

      .text {
        font-weight: 400;
        @include text-sm;
      }
    }

    .nav {
      @include flex(row, space-between, center, $gap: 10px);
      padding: 12px 18px 12px 22px;

      .buttons {
        @include flex(row, center, center, $gap: 6px);
      }
    }
  }

  .tippy {
    vertical-align: top;
    transform: translate(100%, -1px);
  }

  &.left {
    @media screen and (min-width: $breakpointMd) {
      transform: translate(-60%, -100%);
    }

    .tippy {
      float: right;
      transform: translate(-100%, -1px) scaleX(-1);
    }
  }
}
</style>
