<template>
  <div class="i_coaching-home">
    <div class="navbar">
      <iNavbarSimple />
    </div>

    <div class="content">
      <div
        class="header"
        v-if="!!defaultCoach?.fullName"
      >
        <div class="title">{{ defaultCoach?.fullName }} is your dedicated coach</div>
      </div>

      <div class="main">
        <div class="left">
          <img
            class="coach-image"
            :src="defaultCoach?.profilePhotoUrl || ''"
          />
        </div>
        <div class="right">
          <div class="coach-summary">
            <div class="coach">{{ defaultCoach?.fullName || "Your Coach" }}</div>
            <div class="text1">Career Coach</div>
            <div class="text1 rating">
              <img :src="iconStars" />
              {{ defaultCoach?.rating }} stars
            </div>
            <iBaseExpandableParagraph
              :text="defaultCoach?.biography || ''"
              :text-size="descriptionFontSize"
              text-name="full biography"
              :collapsed-length="descriptionMaxlength"
            />
            <iBaseButton
              class="cta"
              v-bind="BUTTON_STYLE"
              :text="reportAccessText"
              @clicked="clickOnReportAccess"
            />
            <div
              class="contact"
              v-if="defaultCoach?.email"
            >
              If you have any question regarding your subscription or want to change your coach, please contact us at:
              <a href="mailto:support@jobtest.org"><strong>support@jobtest.org</strong></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useRouter } from "vue-router"

import { useCoachingStore } from "@/stores/coaching"
import { useUserStore } from "@/stores/user"
import { breakpointLg, breakpointSm } from "@/styles/variables"
import { azureRadiance700, white } from "@/styles/colors"

import { GlobalVariables } from "@/helpers/globalVariables"
import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseExpandableParagraph from "@/components/base/BaseExpandableParagraph.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"

import iconStars from "@/assets/icons/icon-five-slashed-stars.svg"

// STORES AND ROUTERS
const coachingStore = useCoachingStore()
const userStore = useUserStore()
const router = useRouter()

// COMPOSABLES
const isMobileOrLarger = useIsScreenSize(breakpointSm)
const isDesktopOrLarger = useIsScreenSize(breakpointLg)

// CONSTANTS
const BUTTON_STYLE = {
  backgroundColor: azureRadiance700,
  borderRadius: "12px",
  fontWeight: "500",
  textColor: white,
  width: "fit-content",
  padding: "8px 40px"
}

// COMPUTED
const defaultCoach = computed(() => coachingStore.getDefaultCoach)
const descriptionFontSize = computed(() => (isMobileOrLarger.value ? "16px" : "14px"))
const descriptionMaxlength = computed(() => (isDesktopOrLarger.value ? 610 : isMobileOrLarger.value ? 480 : 380))
const reportAccessText = computed(() => (userStore.isReportUser ? "Access My Report" : "Take a Career Test"))

// FUNCTIONS
function clickOnReportAccess() {
  const routeName = userStore.isReportUser
    ? GlobalVariables.urls.report.children.home.routeName
    : GlobalVariables.urls.report.children.testRetake.routeName
  router.push({ name: routeName })
}
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

$navbarHeight: 120px;

.i_coaching-home {
  .content {
    padding: 24px;

    .header {
      margin: 0 auto;
      max-width: 480px;

      @media screen and (min-width: $breakpointMd) {
        text-align: center;
        margin-top: 20px;
        max-width: unset;
      }

      .title {
        @include display-md;
        font-weight: 700;
        margin-bottom: 18px;
        color: $black950;

        @media screen and (min-width: $breakpointSm) {
          @include display-lg;
        }
      }
    }

    .main {
      width: 100%;
      box-sizing: border-box;
      margin-top: 40px;

      @include flex(column, start, start, $gap: 20px);

      @media screen and (min-width: $breakpointMd) {
        margin-top: 80px;
        @include flex(row, center, start, $gap: 40px);
        justify-content: center;
        align-items: center;
      }

      .left {
        width: 100%;
        max-width: 480px;
        align-self: center;
        position: relative;

        .coach-image {
          width: 100%;
          height: 100%;
          object-position: top;
          object-fit: cover;
          @include border-radius-md;
        }
      }

      .right {
        width: 100%;
        @include flex(column, center, stretch, $gap: 48px);

        max-width: 480px;
        align-self: center;

        @media screen and (min-width: $breakpointMd) {
          align-self: unset;
          max-width: 600px;
        }

        .coach {
          @include text-lg;
          font-weight: 700;
          color: $black950;

          @media screen and (min-width: $breakpointSm) {
            @include display-md;
            margin-bottom: 6px;
          }
        }

        .text1 {
          @include text-sm;
          font-weight: 500;
          margin-bottom: 10px;
          color: $black700;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
            margin-bottom: 10px;
          }
        }

        .rating {
          color: $black950;

          @media screen and (min-width: $breakpointSm) {
            margin-bottom: 25px;
          }
        }

        .cta {
          margin-top: 25px;
        }

        .contact {
          width: max-content;
          max-width: 100%;
          margin-top: 48px;
          background-color: $astronaut50;
          color: $black950;
          padding: 15px;
          @include text-xs;
          font-weight: 400;
          @include border-radius-md;

          a {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
