<template>
  <div class="i_coach-summary">
    <div class="coach">{{ defaultCoach?.fullName || "Your Coach" }}</div>
    <div class="text1">Career Coach</div>
    <div class="text1 rating">
      <img :src="iconStars" />
      {{ defaultCoach?.rating }} stars
    </div>
    <iBaseExpandableParagraph
      :text="defaultCoach?.biography || ''"
      :text-size="descriptionFontSize"
      text-name="full biography"
      :collapsed-length="descriptionMaxlength"
    />
    <div class="perks">
      <div class="caption">{{ defaultCoach?.fullName || "Your coach" }} will help:</div>
      <div class="list">
        <div
          class="item"
          v-for="item in LIST_ITEMS"
        >
          <img :src="iconTick" /> {{ item }}
        </div>
      </div>
    </div>
    <div class="calendar">
      <div class="pulse-circle"></div>
      <div>
        {{ defaultCoach?.fullName || "Your coach" }} has <strong>{{ slotsLeft }} slots</strong> left for new clients.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { useUserStore } from "@/stores/user"
import { useCoachingStore } from "@/stores/coaching"
import { breakpointLg, breakpointSm } from "@/styles/variables"

import useRandomNumber from "@/composables/useRandomNumber"
import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseExpandableParagraph from "@/components/base/BaseExpandableParagraph.vue"

// ASSETS
import iconTick from "@/assets/icons/icon-rounded-blue-tick.svg"
import iconStars from "@/assets/icons/icon-five-slashed-stars.svg"

// STORES AND COMPOSABLES
const userStore = useUserStore()
const coachingStore = useCoachingStore()
const slotsLeft = useRandomNumber(userStore.getFullName || "Full Name", 3, 5)
const isMobileOrLarger = useIsScreenSize(breakpointSm)
const isDesktopOrLarger = useIsScreenSize(breakpointLg)

// CONSTANTS
const LIST_ITEMS = [
  "Identify and pursue dream career opportunities",
  "Provide step-by-step action planning",
  "Optimize your resume & LinkedIn profile",
  "Ensure you get and pass the interview",
  "Land the dream job at your dream company",
  "Succeed and grow in your dream role"
]

// COMPUTED
const defaultCoach = computed(() => coachingStore.getDefaultCoach)
const descriptionFontSize = computed(() => (isMobileOrLarger.value ? "16px" : "14px"))
const descriptionMaxlength = computed(() => (isDesktopOrLarger.value ? 610 : isMobileOrLarger.value ? 480 : 380))
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_coach-summary {
  img {
    vertical-align: text-bottom;
  }

  .text1 {
    @include text-sm;
    font-weight: 500;
    margin-bottom: 10px;
    color: $black700;

    @media screen and (min-width: $breakpointSm) {
      @include text-md;
      margin-bottom: 10px;
    }
  }

  .item {
    @include text-sm;
    font-weight: 400;
    color: $black700;

    @media screen and (min-width: $breakpointSm) {
      @include text-md;
    }
  }

  .coach {
    @include text-lg;
    font-weight: 700;
    color: $black950;

    @media screen and (min-width: $breakpointSm) {
      @include display-md;
      margin-bottom: 6px;
    }
  }

  .rating {
    color: $black950;

    @media screen and (min-width: $breakpointSm) {
      margin-bottom: 25px;
    }
  }

  .perks {
    margin: 20px 0;

    .caption {
      @include text-md;
      font-weight: 600;
      color: $black950;

      @media screen and (min-width: $breakpointSm) {
        @include text-xl;
      }
    }

    .list {
      margin-top: 6px;

      .item {
        @include flex(row, start, start, $gap: 12px);
        padding: 6px 0;
        color: $black900;

        &:not(:last-child) {
          border-bottom: 1px solid $black100;
        }
      }
    }
  }

  .calendar {
    @include flex(row, start, center, $gap: 15px);
    background-color: $astronaut50;
    color: $black950;
    padding: 15px;
    @include text-xs;
    font-weight: 400;
    @include border-radius-md;
  }
}
</style>
