<template>
  <div
    class="i_coach-profile-card"
    :class="{ outlined: props.outlined }"
  >
    <div class="top">
      <div class="image">
        <div
          v-if="props.badgeText"
          class="badge"
        >
          {{ props.badgeText }}
        </div>
        <img
          class="photo"
          :src="props.profilePhotoUrl"
        />
        <template v-if="props.introVideoUrl">
          <img
            class="play"
            :src="iconPlay"
            @click="$emit('playVideo')"
          />
        </template>
      </div>

      <div class="details">
        <div>
          <div class="name">{{ props.fullName }}</div>
          <div class="role">Expert Career Coach</div>
          <div class="rating">
            <img :src="iconStars" />
            {{ props.rating }} stars
          </div>
        </div>

        <img
          class="check-mark"
          :src="iconCheckPurple"
        />
      </div>

      <iBaseExpandableParagraph
        :text="props.biography || ''"
        :text-size="descriptionFontSize"
        text-name="full biography"
        :collapsed-length="descriptionMaxlength"
      />
    </div>

    <iBaseButton
      class="button"
      v-bind="data.buttonCoaches"
      size="medium"
      text="Check Availability"
      @clicked="$emit('clickedCta')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue"
import { azureRadiance600, white } from "@/styles/colors"
import { breakpointLg, breakpointMd, breakpointSm, breakpointXl } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseExpandableParagraph from "@/components/base/BaseExpandableParagraph.vue"

// ASSETS
import iconPlay from "@/assets/icons/icon-play-v2-white.svg"
import iconCheckPurple from "@/assets/icons/icon-rounded-blue-tick.svg"
import iconStars from "@/assets/icons/icon-five-slashed-stars.svg"

// COMPOSABLES
const isMobileOrLarger = useIsScreenSize(breakpointSm)
const isTabletOrLarger = useIsScreenSize(breakpointMd)
const isDesktopOrLarger = useIsScreenSize(breakpointLg)
const isMassiveOrLarger = useIsScreenSize(breakpointXl)

// EMITS
defineEmits(["playVideo", "clickedCta"])

// PROPS
const props = defineProps({
  profilePhotoUrl: {
    type: String,
    required: true
  },
  fullName: {
    type: String,
    required: true
  },
  biography: {
    type: String,
    required: true
  },
  introVideoUrl: {
    type: String,
    default: ""
  },
  outlined: {
    type: Boolean,
    default: false
  },
  rating: {
    type: Number,
    required: true
  },
  badgeText: {
    type: String,
    default: ""
  }
})

// DATA
const data = reactive({
  buttonCoaches: {
    backgroundColor: white,
    borderColor: azureRadiance600,
    borderRadius: "12px",
    fontWeight: "500",
    textColor: azureRadiance600,
    width: "100%",
    version: "outline"
  }
})

// COMPUTED
const descriptionFontSize = computed(() => (isTabletOrLarger.value ? "18px" : "16px"))
const descriptionMaxlength = computed(() =>
  isMassiveOrLarger.value ? 110 : isDesktopOrLarger.value ? 80 : isMobileOrLarger.value ? 60 : 100
)
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_coach-profile-card {
  @include flex(column, space-between, start, $gap: 16px);
  padding: 12px;
  background-color: white;
  border-radius: 16px;

  &.outlined {
    border: 3px solid $zumthor100;
  }

  .top {
    @include flex(column, start, start, $gap: 16px);

    .image {
      width: 100%;
      height: auto;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      .badge {
        padding: 4px 12px;
        color: white;
        font-weight: 500;
        background-image: linear-gradient(to right, #4a9dff, #be4fff);
        @include border-radius-xxl;
        width: fit-content;
        @include text-sm;
        position: absolute;
        right: 12px;
        top: 12px;
        text-transform: uppercase;
      }
    }

    .photo {
      width: 100%;
      height: auto;
    }

    .play {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 8px;
      bottom: 8px;
      cursor: pointer;
    }

    .check-mark {
      width: 24px;
      height: 24px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
    }

    .name {
      font-weight: 600;
      @include text-md;
    }

    .role {
      @include text-xs;
      color: $black600;
      margin-bottom: 8px;
    }

    .rating {
      @include text-xs;
      font-weight: 400;
      margin-top: 6px;
      color: $bilbao500;

      img {
        vertical-align: text-top;
        height: 12px;
      }
    }
  }
}
</style>
