<template>
  <div class="i_career-actions">
    <iDownloadPdf />
    <div
      class="badge"
      :style="{ backgroundColor: activeJob?.color }"
    >
      {{ badgeText }}
    </div>
    <div
      class="job-actions"
      v-if="showJobFeedback && activeJob"
    >
      <iFavoriteJob
        :job-id="activeJob.id"
        :job-feedback="activeJob.feedback"
      />
      <iRemoveJob
        :job-id="activeJob.id"
        :job-feedback="activeJob.feedback"
        :job-title="activeJob.title"
      />
      <iNewCareers />
      <iOnboardingTooltip
        class="tooltip"
        :position-left="isTablet ? false : true"
        :value="4"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { breakpointMd } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iDownloadPdf from "@/components/global/DownloadPdf.vue"
import iFavoriteJob from "@/components/report/careers/FavoriteJob.vue"
import iRemoveJob from "@/components/report/careers/RemoveJob.vue"
import iNewCareers from "@/components/report/careers/NewCareers.vue"
import iOnboardingTooltip from "@/components/report/OnboardingTooltip.vue"

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const isTablet = useIsScreenSize(breakpointMd)

// COMPUTED
const activeJob = computed(() => {
  const recommendedJobs = reportStore.getRecommendedJobs
  return recommendedJobs ? recommendedJobs[reportStore.getActiveJobIndex] : null
})

const showJobFeedback = computed(() => {
  return (
    !!activeJob.value?.id &&
    !!activeJob.value?.title &&
    !activeJob.value?.isCurrentJob &&
    !activeJob.value?.isActivelyExploringJob &&
    !activeJob.value?.isSampleJob
  )
})

const badgeText = computed(() => {
  return showJobFeedback.value ? `You are a ${activeJob.value?.badge} match` : `${activeJob.value?.badge} Career`
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_career-actions {
  @include flex(row, $justify: center, $align: center, $gap: 10px);
  margin-bottom: 10px;
  flex: 0 0 max-content;

  .badge {
    padding: 2px 0px;
    @include border-radius-lg;
    white-space: nowrap;
    padding: 2px 10px;
    color: $white;
    @include text-sm;
    font-weight: 500;
  }

  .job-actions {
    position: relative;
    @include flex(row, $justify: center, $align: center, $gap: 10px);
  }
}
</style>

<style lang="scss">
@import "@/styles/variables.scss";

.i_career-actions .job-actions {
  & > .i_onboarding-tooltip {
    transform: translate(24%, -100%);

    &.left {
      transform: translate(-27%, -100%);

      .tippy {
        float: right;
        transform: translate(-190%, -1px) scaleX(-1);
      }
    }
  }
}
</style>
