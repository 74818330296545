<template>
  <div class="i_income-vs-satisfaction-graph">
    <div class="wrapper">
      <div class="tags">
        <iBasePillTag
          isBold
          class="current-career-tag"
          :circleColor="bittersweet400"
          tagColor="transparent"
          :tagText="currentCareerTitle"
        />

        <iBasePillTag
          isBold
          class="ideal-career-tag"
          :circleColor="bilbao500"
          tagColor="transparent"
          :tagText="idealCareerTitle"
        />
      </div>

      <div class="graph">
        <div class="axis-1-title">Satisfaction</div>
        <div class="axis-2-title">Income</div>
        <div class="current-salary">{{ reportStore.salaryStatistics.currentSalary }}</div>
        <div class="ideal-salary">{{ reportStore.salaryStatistics.potentialSalary }}</div>

        <img
          :src="imageIncomeGraph"
          loading="lazy"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { bilbao500, bittersweet400 } from "@/styles/colors"

import iBasePillTag from "@/components/base/BasePillTag.vue"

// ASSETS
import imageIncomeGraph from "@/assets/welcome/income-vs-satisfaction.svg"

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()

// COMPUTED
const currentCareerTitle = computed<string>(() => {
  return `${userStore.getFullName} as ${reportStore.getCurrentJob || "Current Career"}`
})
const idealCareerTitle = computed<string>(() => {
  return `${userStore.getFullName} as Ideal Career`
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_income-vs-satisfaction-graph {
  .wrapper {
    padding: 15px;
    @include border-radius-md;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px -10px 20px rgba(0, 0, 0, 0.04);

    @media screen and (min-width: $breakpointMd) {
      margin: unset;
      padding: 20px;
    }

    .tags {
      @include flex($direction: column, $align: center, $gap: 5px);

      @media screen and (min-width: $breakpointMd) {
        @include flex($direction: row, $gap: 15px);
      }
    }

    .graph {
      position: relative;
      font-weight: 700;
      margin: auto;
      margin-top: 15px;
      width: fit-content;

      @media screen and (min-width: $breakpointMd) {
        @include text-md;
      }

      .axis-1-title {
        @include text-xs;
        position: absolute;
        left: 6%;
        bottom: 84%;

        @media screen and (min-width: $breakpointMd) {
          @include text-sm;
          bottom: 87%;
        }
      }

      .axis-2-title {
        @include text-xs;
        position: absolute;
        right: 7%;
        bottom: 5%;

        @media screen and (min-width: $breakpointMd) {
          @include text-sm;
          right: 5%;
        }
      }

      .current-salary {
        @include text-xs;
        position: absolute;
        left: 8%;
        color: $bittersweet400;
        bottom: 17%;

        @media screen and (min-width: $breakpointMd) {
          @include text-xl;
        }
      }

      .ideal-salary {
        @include text-xs;
        position: absolute;
        right: 12%;
        bottom: 80%;
        color: $bilbao500;

        @media screen and (min-width: $breakpointMd) {
          @include text-xl;
        }
      }

      img {
        width: 300px;
        margin: auto;

        @media screen and (min-width: $breakpointMd) {
          width: 450px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.i_income-vs-satisfaction-graph {
  .i_base-pill-tag {
    .text {
      white-space: unset;
      text-align: center;
    }
  }
}
</style>
