<template>
  <div class="i_matched-coach">
    <div class="left-wrap">
      <img
        :src="defaultCoach?.profilePhotoUrl || ''"
        alt="achieve-goals-img"
      />
    </div>
    <div class="right-wrap">
      <span class="why"
        >Your coach <br /><span class="coach"> {{ defaultCoach?.fullName }}</span></span
      >

      <div class="reason">
        <img
          :src="iconPerson"
          alt="achieve-goals-img"
        />
        Deep Experience
      </div>
      <div class="reason">
        <img
          :src="iconThumbsup"
          alt="achieve-goals-img"
        />
        High Satisfaction Rate
      </div>
      <div class="reason">
        <img
          :src="iconSmilie"
          alt="achieve-goals-img"
        />
        {{ peopleHelpedText }} reported high satisfaction with {{ defaultCoach?.fullName }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { useCoachingStore } from "@/stores/coaching"

import useRandomNumber from "@/composables/useRandomNumber"
import getPluralJob from "@/composables/getPluralJob"

// ASSETS
import iconPerson from "@/assets/icons/icon-person-star-blue.svg"
import iconThumbsup from "@/assets/icons/icon-thumbsup-purple.svg"
import iconSmilie from "@/assets/icons/icon-smilie-green.svg"

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()
const coachingStore = useCoachingStore()

// COMPUTED
const defaultCoach = computed(() => coachingStore.getDefaultCoach)

const peopleHelpedText = computed(() => {
  const helpedPeople = useRandomNumber(userStore.getFullName || "Full Name", 500, 2500).value.toLocaleString()
  const currentJob = getPluralJob(reportStore?.getCurrentJob || "")
  return `${helpedPeople} other ${currentJob}`
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/mixins.scss";

.i_matched-coach {
  @include flex(column, space-between, stretch, $gap: 0);
  @include border-radius-lg;
  width: fit-content;
  background-color: $white;
  color: $black;
  padding: 30px;
  margin-top: 30px;

  @media screen and (min-width: $breakpointMd) {
    flex-direction: row;
  }

  @media screen and (min-width: $breakpointLg) {
    width: 100%;
  }

  .left-wrap {
    width: 100%;

    @media screen and (min-width: $breakpointMd) {
      max-width: 400px;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: top;
      object-fit: cover;
      @include border-radius-md;

      @media screen and (min-width: $breakpointMd) {
        max-height: 400px;
      }
    }
  }

  .right-wrap {
    @include flex(column, initial, initial, $gap: 15px);
    text-align: left;
    height: 100%;
    padding: 30px 0;
    margin: auto;

    @media screen and (min-width: $breakpointMd) {
      max-width: 500px;
      padding: 25px 10px 25px 45px;
    }

    .why {
      @include text-lg;
      font-weight: 600;

      @media screen and (min-width: $breakpointSm) {
        @include text-xl;
      }

      .coach {
        @include display-xs;

        @media screen and (min-width: $breakpointMd) {
          @include display-lg;
          font-weight: 700;
        }
      }
    }

    .reason {
      @include text-md;
      font-weight: 400;

      @media screen and (min-width: $breakpointMd) {
        @include text-lg;
      }

      img {
        width: 18px;

        @media screen and (min-width: $breakpointMd) {
          width: 26px;
        }
      }
    }
  }
}
</style>
