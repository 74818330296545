<template>
  <div class="i_card-review">
    <div class="wrap">
      <img
        :alt="reviewer"
        :src="iconReview"
        class="icon-review"
      />

      <div class="title">{{ props.title }}</div>

      <div class="score-wrap">
        <img
          :src="iconScore"
          alt="Score"
          class="icon-score"
        />

        <span class="ago">{{ props.ago }}</span>
      </div>

      <div class="review">
        {{ props.review }}
      </div>

      <div class="reviewer">{{ props.reviewer }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import iconReview from "@/assets/icons/icon-review.svg"
import iconScore50 from "@/assets/icons/icon-score-50.svg"
import iconScore40 from "@/assets/icons/icon-score-40.svg"

const props = defineProps({
  ago: {
    type: String,
    required: true
  },
  review: {
    type: String,
    required: true
  },
  reviewer: {
    type: String,
    required: true
  },
  score: {
    type: Number,
    required: true,
    validator: (value: number) => value >= 4 && value <= 5
  },
  title: {
    type: String,
    required: true
  }
})

const iconScore = computed(() => {
  switch (props.score) {
    case 5:
      return iconScore50
    case 4:
      return iconScore40
    default:
      return iconScore50
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_card-review {
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 300px;

  @media screen and (min-width: $breakpointLg) {
    width: 390px;
  }

  .wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .icon-review {
    width: 34px;
  }

  .title {
    @include text-md;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-weight: 500;
    height: 48px;
    overflow: hidden;

    @media screen and (min-width: $breakpointLg) {
      @include text-lg;
      height: 54px;
    }
  }

  .score-wrap {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .icon-score {
    height: 18px;

    @media screen and (min-width: $breakpointLg) {
      height: 20px;
    }
  }

  .ago {
    @include text-xs;
    color: $black500;
    font-size: 11px;
    font-weight: lighter;

    @media screen and (min-width: $breakpointLg) {
      font-size: 12px;
    }
  }

  .review,
  .reviewer {
    @include text-xs;
    font-weight: 400;

    @media screen and (min-width: $breakpointLg) {
      @include text-sm;
    }
  }

  .review {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    height: 52px;
    overflow: hidden;

    @media screen and (min-width: $breakpointLg) {
      height: 60px;
    }
  }
}
</style>
