<template>
  <div class="i_personality-analysis">
    <section>
      <iBaseHeader
        highlight-text=""
        :text="personalityType"
        align="start"
        :style="{ marginTop: '25px' }"
      />
      <div class="summary">
        {{ personalityDescription }}
      </div>

      <div
        v-if="data.chartDataAvailable"
        class="chart-card"
      >
        <iRadarChart
          class="chart"
          v-bind="data.capboiChart"
        />

        <div class="chart-tags">
          <div>
            <iBasePillTag
              isBold
              :circle-color="data.capboiChart.colorMe"
              :tag-text="data.capboiChart.fullName"
              :tag-color="black"
              :text-style="white"
            />
          </div>
          <div>
            <iBasePillTag
              isBold
              :circle-color="data.capboiChart.colorOther"
              :tag-text="data.capboiChart.peerGroupTitle"
              :tag-color="black"
              :text-style="white"
            />
          </div>
        </div>
      </div>

      <iBaseHeader
        highlight-text=""
        :text="`${highestCapboiScores.join(' and ')}`"
        align="start"
      />
      <div class="summary">
        {{ capboiAssessment }}
      </div>
    </section>

    <section>
      <div class="biggest">
        <div :style="{ backgroundColor: bilbao50 }">
          <img
            :src="IconStrength"
            class="icon"
          />
          <div class="title">Biggest Strengths</div>
          <div class="info">{{ highestStrengthsScores.join(" & ") }}</div>
        </div>

        <div :style="{ backgroundColor: bittersweet50 }">
          <img
            :src="IconWeakness"
            class="icon"
          />
          <div class="title">Biggest Weaknesses</div>
          <div class="info">{{ highestWeaknessesScores.join(" & ") }}</div>
        </div>
      </div>

      <div class="sub">
        <img
          :src="IconStrength"
          class="icon"
        />
        <iBaseHeader
          highlight-text=""
          text="Personality Strengths"
          align="start"
        />
      </div>
      <div class="summary">
        {{ strengthsAssessment }}
      </div>

      <div
        class="sub"
        :style="{ marginTop: '25px' }"
      >
        <img
          :src="IconWeakness"
          class="icon"
        />
        <iBaseHeader
          highlight-text=""
          text="Personality Weaknesses"
          align="start"
        />
      </div>
      <div class="summary">
        {{ weaknessesAssessment }}
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import {
  bilbao50,
  bilbao500,
  bittersweet50,
  black,
  chartColorMe,
  chartColorOther,
  white,
  pickleBluewood500,
  pickleBluewood600
} from "@/styles/colors"

import iBasePillTag from "@/components/base/BasePillTag.vue"
import iBaseHeader from "@/components/base/BaseHeader.vue"
import iRadarChart from "@/components/global/RadarChart.vue"

// ASSETS
import IconStrength from "@/assets/icons/icon-green-bolt.svg"
import IconWeakness from "@/assets/icons/icon-red-link.svg"

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()

// TYPES
interface ChartModel {
  canvasId: string
  chartId: string
  colorMe: string
  colorOther: string
  fullName: string
  gridColor: string
  labels: (string | string[])[]
  labelColor: string
  labelHighlightColor: string
  maxDiameter: string
  peerGroupTitle: string
  range: { minimum: number; maximum: number }
  scoresMe: number[]
  scoresOther: number[]
  steps: number
}

// CONSTANTS
const ASSESSMENT_DEFAULT_TEXT = "Your assessment is coming soon..."

// DATA
const data = reactive({
  chartDataAvailable: false,
  capboiChart: {} as ChartModel
})

// COMPUTED
const personalityType = computed(() => `The ${reportStore.reportData?.results?.personalityType}`)
const personalityDescription = computed(() => reportStore.reportData?.results?.personalityDescription)

const capboiAssessment = computed(() => reportStore.reportData?.results?.capboiText ?? ASSESSMENT_DEFAULT_TEXT)
const strengthsAssessment = computed(() => {
  return reportStore.reportData?.results?.skillTexts?.strengths ?? ASSESSMENT_DEFAULT_TEXT
})
const weaknessesAssessment = computed(() => {
  return reportStore.reportData?.results?.skillTexts?.weaknesses ?? ASSESSMENT_DEFAULT_TEXT
})

const highestCapboiScores = computed(() => reportStore.getCapboiTopTwoTypes)
const highestStrengthsScores = computed(() => reportStore.getTopTwoStrengths)
const highestWeaknessesScores = computed(() => reportStore.getTopTwoWeaknesses)

// FUNCTIONS
function setChartsData() {
  data.capboiChart = {
    colorMe: chartColorMe,
    colorOther: chartColorOther,
    fullName: userStore.getFullName || "You",
    maxDiameter: "350px",
    peerGroupTitle: `Peers, ${reportStore.getPeerGroupTitle || "similar age"}`,
    steps: 4,
    gridColor: pickleBluewood600,
    labelColor: pickleBluewood500,
    labelHighlightColor: bilbao500,
    canvasId: "capboiCanvas",
    chartId: "capboiChart",
    labels: reportStore.getCapboiLabels,
    range: reportStore.getCapboiRange,
    scoresMe: reportStore.getCapboiScores,
    scoresOther: reportStore.getCapboiScoresPeer
  } as ChartModel

  data.chartDataAvailable = true
}

// LIFECYCLE HOOKS
onMounted(() => {
  setChartsData()
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";
@import "@/styles/typography.scss";

.i_personality-analysis {
  section {
    .chart-card {
      margin: 35px auto;
      @include border-radius-lg;
      background-color: $black;
      padding: 0 20px 20px 20px;

      .chart-tags {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;

        div {
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      .chart {
        display: block;
      }
    }

    .biggest {
      margin: 35px auto;
      @include flex($direction: column, $justify: center, $align: center, $gap: 20px);

      @media screen and (min-width: $breakpointSm) {
        @include flex($direction: row, $justify: center, $align: center, $gap: 20px);
      }

      > div {
        @include grid($cols: 1fr, $rows: auto auto 1fr, $align-items: center, $justify-items: center, $gap: 10px);
        width: 100%;
        padding: 45px 20px;
        align-self: stretch;
        @include border-radius-lg;
        text-align: center;

        .icon {
          width: 44px;
          height: 44px;
          align-self: end;
          padding: 5px;
        }

        .title {
          @include text-xs;
          font-weight: 700;
          align-self: start;
          text-transform: uppercase;
        }

        .info {
          @include text-xl;
          font-weight: 600;
          align-self: start;
        }
      }
    }

    .sub {
      @include flex(row, center, start, $gap: 10px);

      .icon {
        width: 22px;
        height: 22px;
        align-self: center;
      }
    }

    .summary {
      margin-top: 10px;
      @include text-sm;
      font-weight: 500;
      color: $comet600;
    }
  }
}
</style>
