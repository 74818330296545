<template>
  <div class="i_remove-job">
    <a
      class="remove-job"
      @click="data.showConfirmModal = true"
    >
      <img :src="iconTrashRed" />
    </a>

    <iBaseModal
      :visible="data.showConfirmModal"
      max-width="445px"
      :fully-customisable="true"
      @click-on-close="closeConfirmModal"
    >
      <div class="confirmation-modal">
        <div class="top">
          <div class="warning">This action cannot be undone</div>
          <div class="caption">You are about to remove this Career?</div>
          <div class="job">{{ jobTitle }}</div>

          <textarea
            class="text-area"
            placeholder="Why are you not interested in this Career?"
            v-model="data.removeReason"
            maxlength="256"
            autofocus
          ></textarea>
        </div>

        <div class="bottom">
          <iBaseButton
            v-bind="BUTTON_STYLE.transparent"
            text="Don't Remove It"
            @clicked="closeConfirmModal"
          />
          <iBaseButton
            v-bind="BUTTON_STYLE.red"
            text="Yes, Remove"
            @clicked="dislikeJob"
          />
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"
import { useReportStore } from "@/stores/report"
import { useRouter } from "vue-router"

import { bittersweet600, black600, white } from "@/styles/colors"

import iBaseModal from "@/components/base/BaseModal.vue"
import iBaseButton from "@/components/base/BaseButton.vue"

import iconTrashRed from "@/assets/icons/icon-trash-red.svg"

import type { Feedback } from "@/shared/types/UserJobFeedback"

// ROUTER
const router = useRouter()

// STORES
const reportStore = useReportStore()

// PROPS
const props = defineProps({
  jobId: {
    type: Number,
    required: true
  },
  jobFeedback: {
    type: Number,
    required: true
  },
  jobTitle: {
    type: String,
    required: true
  }
})

const data = reactive({
  showConfirmModal: false,
  removeReason: ""
})

// CONSTANTS
const SHARED_BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content"
}

const BUTTON_STYLE = {
  red: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: bittersweet600,
    borderRadius: "24px",
    textColor: white
  },
  transparent: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: "transparent",
    textColor: black600,
    isHoverable: false
  }
}

// FUNCTIONS
const closeConfirmModal = () => {
  data.showConfirmModal = false
  data.removeReason = ""
}

const dislikeJob = () => {
  const feedback = props.jobFeedback < 0 ? 0 : -2

  handleFeedback(feedback)
  unfavoriteJob()
  closeConfirmModal()
}

const unfavoriteJob = () => {
  reportStore.postUserJobFavorite(props.jobId, false)
}

const handleFeedback = (feedback: Feedback) => {
  reportStore
    .postUserJobFeedback(props.jobId, {
      feedback,
      source: "report",
      reasonOther: data.removeReason
    })
    .then(() => {
      router.push({ name: router.currentRoute.value.name!, params: { jobId: reportStore.activeJobId } })
    })
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_remove-job {
  .remove-job {
    max-width: fit-content;
    cursor: pointer;

    img {
      align-self: start;
      width: 18px;
    }
  }

  .confirmation-modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 20px);
      margin: 25px;

      .warning {
        color: $bittersweet600;
        text-transform: uppercase;
        align-self: start;
        font-weight: 700;
        @include text-xs;
      }

      .caption {
        font-weight: 700;
        @include display-sm;
        text-align: center;
      }

      .job {
        @include text-lg;
        @include border-radius-sm;
        text-align: center;
        font-weight: 600;
        padding: 4px 12px;
        background-color: $black100;
      }

      .text-area {
        border: 1px solid $black300;
        @include border-radius-sm;
        width: 100%;
        height: 130px;
        overflow-y: auto;
        padding: 8px 10px;
        background-color: $black50;

        &:focus-visible {
          outline: none;
          border: 1px solid $black400;
        }
      }
    }

    .bottom {
      @include flex($direction: row, $justify: space-between, $align: center, $gap: 0px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
