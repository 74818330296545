<template>
  <div class="i_whats-next">
    <div class="header">What's Next?</div>
    <div class="steps">
      <template v-for="step in nextSteps">
        <div class="step">
          <div class="head">
            <img
              :src="step.icon"
              class="icon"
            />
            <div class="title">{{ step.title }}</div>
          </div>
          <div
            class="description"
            v-html="step.description"
          ></div>
        </div>
      </template>
    </div>
    <div class="support-text">If you have any questions or concerns, please reach out to us at:</div>
    <div class="support">support@jobtest.org</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { useUserStore } from "@/stores/user"
import { useCoachingStore } from "@/stores/coaching"

// ASSETS
import iconMail from "@/assets/icons/icon-mail-blue.svg"
import iconDoc from "@/assets/icons/icon-doc-purple.svg"
import iconPerson from "@/assets/icons/icon-person-check-green.svg"

// STORES
const userStore = useUserStore()
const coachingStore = useCoachingStore()

// COMPUTED
const defaultCoach = computed(() => coachingStore.getDefaultCoach)
const nextSteps = computed(() => {
  const coachName = defaultCoach.value?.fullName ? defaultCoach.value?.fullName?.split(" ")[0] : "your coach"
  const coach = defaultCoach.value?.fullName ? `Coach ${coachName}` : "Your coach"
  const email = userStore.getEmail

  return [
    {
      title: "Step 1",
      description: `${coach} will follow up with you via ${
        email ? `your email <strong>(${email})</strong>` : "email"
      } in the next 24 hours.`,
      icon: iconMail
    },
    {
      title: "Step 2",
      description: `Please share any professional assets you already have (resume, portfolio, etc.) with ${coachName} before your first session.`,
      icon: iconDoc
    },
    {
      title: "Step 3",
      description: "Kickoff your journey towards career realignment with your coach! 100% satisfaction guarantee.",
      icon: iconPerson
    }
  ]
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_whats-next {
  color: $black;

  .header {
    @include display-md;
    font-weight: 600;
    line-height: 40px;
    text-align: center;

    @media screen and (min-width: $breakpointMd) {
      font-size: 40px;
      font-weight: 700;
    }
  }

  .steps {
    @include grid($cols: 1fr, $align-items: center, $justify-items: center, $gap: 25px);
    width: 100%;
    margin-top: 30px;
    grid-auto-rows: 1fr;

    @media screen and (min-width: $breakpointMd) {
      margin-top: 35px;
      grid-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    @media screen and (min-width: $breakpointLg) {
      grid-gap: 25px;
    }

    .step {
      @include grid($cols: 1fr, $rows: auto 1fr, $align-items: center, $justify-items: left, $gap: 14px);
      position: relative;
      width: 100%;
      background-color: $white;
      padding: 34px;
      align-self: stretch;
      @include border-radius-lg;

      @media screen and (min-width: $breakpointMd) {
        padding: 25px 20px;
      }

      .head {
        @include flex(row, start, stretch, $gap: 5px);
        align-self: start;

        @media screen and (min-width: $breakpointMd) {
          flex-direction: column;
        }

        .icon {
          width: 35px;
          height: 35px;
        }

        .title {
          @include text-xl;
          font-weight: 700;
          align-self: end;
        }
      }

      .description {
        @include text-md;
        align-self: flex-start;
        overflow-wrap: anywhere;
      }
    }
  }

  .support-text {
    margin-top: 50px;
    text-align: center;
    @include text-sm;
    font-weight: 500;

    @media screen and (min-width: $breakpointSm) {
      @include text-md;
    }

    @media screen and (min-width: $breakpointMd) {
      @include text-lg;
      margin-top: 65px;
    }
  }

  .support {
    margin-top: 5px;
    text-align: center;
    @include display-xs;
    font-weight: 700;
    background-color: #569efa;
    background-image: linear-gradient(45deg, #569efa 0%, #798bf7 40%, #a074f4 70%);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    @media screen and (min-width: $breakpointMd) {
      @include display-md;
    }
  }
}
</style>
