<template>
  <div v-if="data.loadTest">
    <div class="i_test-retake">
      <iNavbarSimple />

      <div v-show="isQuizStarted">
        <div class="container-qubl">
          <iQublQuizRetake
            ref="qublQuiz"
            :quiz-id="GlobalFunctions.getQublQuizId()"
            :quiz-style="GlobalFunctions.getQublStyle()"
            @quiz-completed="onQuizCompleted"
            @quiz-success="onQuizSuccess"
            @quiz-error="onQuizError"
          />
        </div>
      </div>

      <div v-if="isQuizCompleted">
        <div class="container-loader">
          <iLoaderSimple :loading-animation-duration-ms="data.loadingAnimationDurationMs">
            <template v-slot:title>Test retake complete!</template>
            <template v-slot:subtitle>
              We are processing your test answers and generating your updated report. This process may take a few
              minutes, please do not refresh this page...
            </template>
          </iLoaderSimple>
        </div>
      </div>

      <div v-if="isQuizError">
        <div class="container-error-general">
          <iBaseErrorCard
            buttonText="Back to Package"
            :error-message-one-HTML="data.quizErrorMessageHTML"
            @clicked-logo="goToReport()"
            @clicked-button="goToReport()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"
import QuizService from "@/services/quizService"

import iBaseErrorCard from "@/components/base/BaseErrorCard.vue"
import iLoaderSimple from "@/components/global/LoaderSimple.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"

import iQublQuizRetake from "@/views/jobTest/sections/QublQuizRetake.vue"

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()

// REFS
const qublQuiz = ref(null)

// DATA
const data = reactive({
  loadTest: false,
  loadingAnimationDurationMs: 4000,
  quizStatus: QuizService.QUIZ_STATUSES.STARTED,
  quizErrorMessageHTML: QuizService.ERROR_MESSAGES["DEFAULT"]
})

// COMPUTED
const isQuizStarted = computed(() => {
  return data.quizStatus === QuizService.QUIZ_STATUSES.STARTED
})

const isQuizCompleted = computed(() => {
  return data.quizStatus === QuizService.QUIZ_STATUSES.COMPLETED
})

const isQuizError = computed(() => {
  return data.quizStatus === QuizService.QUIZ_STATUSES.ERROR
})

// FUNCTIONS
function goToReport() {
  // Do not use native Vue router push method because it won't trigger a page reload
  const protocol = window.location.protocol
  const baseUrl = `${protocol}//${window.location.host}`
  const pageUrl = GlobalVariables.urls.report.path
  GlobalFunctions.redirectToPage({ baseUrl, pageUrl, queryUrl: "" })
}

function initNavigationValidation() {
  userStore.isCoachingOnlyUser || // User is a coaching-only user => allow quiz retake
  reportStore.isReportOutdated || // User has outdated report => allow quiz retake regardless of retake attempts left
  reportStore.hasRetakeQuizAttempts // User has retake attempts left => allow quiz retake
    ? (data.loadTest = true)
    : goToReport()
}

function onQuizCompleted() {
  data.quizStatus = QuizService.QUIZ_STATUSES.COMPLETED
}

function onQuizSuccess() {
  goToReport()
}

function onQuizError(error: { message: string }) {
  if (error.message === "400") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[400]
  else if (error.message === "401") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[401]
  else if (error.message === "403") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[403]
  else if (error.message === "500") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[500]
  else data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES["DEFAULT"]

  data.quizStatus = QuizService.QUIZ_STATUSES.ERROR
}

// LIFECYCLE HOOKS
onMounted(() => {
  initNavigationValidation()
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_test-retake {
  .container-qubl,
  .container-loader,
  .container-error-email,
  .container-error-general {
    height: calc(100vh - 90px);
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .container-qubl {
    margin-top: 10px;
    max-width: $sectionMaxWidth;
  }

  .container-loader {
    margin-top: -60px;
    max-width: $sectionMaxWidth;
  }

  .container-error-general {
    @include flex($justify: center, $align: center);
    max-width: 450px;
    margin-top: -30px;
  }
}
</style>
