export const breakpointXxs = 320
export const breakpointXs = 425
export const breakpointSm = 480
export const breakpointMd = 768
export const breakpointLg = 1024
export const breakpointXl = 1281
export const breakpointXxl = 1440

export const zIndexBackground = -100
export const zIndexBase = 0
export const zIndexText = 100
export const zIndexButton = 200
export const zIndexFooter = 400
export const zIndexHeader = 500
export const zIndexNavbar = 600
export const zIndexDropdown = 700
export const zIndexOverlay = 800
export const zIndexModal = 900

export const zIndexAbove = 40 // EXAMPLE: zIndexBase + zIndexAbove SHOULD BE LESS THAN zIndexText + zIndexBelow
export const zIndexBelow = -40
