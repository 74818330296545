<template>
  <div class="i_pricing-table">
    <template v-if="hasTitle">
      <slot name="title"></slot>
    </template>

    <div class="grid">
      <div
        v-for="(tier, index) in sortedPaymentTiers"
        :key="index"
      >
        <iPricingTier
          v-bind="tier"
          :is-payment-button-disabled="isPaymentButtonDisabled"
          @clicked="disablePaymentButtons"
          @payment-action-error="enablePaymentButtons"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user"
import { computed, onMounted, ref, useSlots } from "vue"

// COMPONENTS
import iPricingTier from "@/components/pricing/PricingTier.vue"

// COMPOSABLES
import getPaymentAction from "@/composables/getPaymentAction"
import linkToCoachSelection from "@/composables/linkToCoachSelection"
import useIsScreenSize from "@/composables/useIsScreenSize"
import useRandomNumber from "@/composables/useRandomNumber"

// TYPES
import StripePrices from "@/shared/enums/StripePrices"
import UserStatus from "@/shared/enums/UserStatus"

// ASSETS
import iconGreenCheckmark from "@/assets/icons/icon-green-checkmark.svg"
import iconRedXmark from "@/assets/icons/icon-red-xmark.svg"
import imagePremiumTier from "@/assets/images/image-premium-tier.png"
import imageReportTier1 from "@/assets/images/image-report-tier1-squared.png"
import imageReportTier2 from "@/assets/images/image-report-tier2-squared.png"

// STYLES
import { astronaut600 } from "@/styles/colors"
import { breakpointXl } from "@/styles/variables"

// STORES
const userStore = useUserStore()

// COMPOSABLES
const isScreenSizeXlOrLarger = useIsScreenSize(breakpointXl)

const comprehensiveReportPurchases = useRandomNumber(userStore.getFullName || "string", 200, 310)
const premiumReportPurchases = useRandomNumber(userStore.getFullName || "string", 10, 50)

// TYPES
type TierProps = {
  benefits: { iconUrl: string; title: string; html?: string }[]
  paymentAction: () => void
  buttonColorBg: string
  buttonColorText: string
  buttonText: string
  currentPriceWithCents: string
  imageUrl: string
  isRecommended: boolean
  numberOfPurchases?: number
  packageText: string
  subtitle: string
  title: string
  packageTier: UserStatus
  isButtonDisabled?: boolean
  discountPercentageValue?: number
  creditAppliedValue?: number
  previousPriceWithCents?: string
}

// SLOTS
const slots = useSlots()
const hasTitle = computed(() => slots.title)

// CONSTANTS
const ICONS_URL = {
  included: iconGreenCheckmark,
  excluded: iconRedXmark
}

const BUTTON = {
  paymentAction: () => {},
  buttonColorBg: "white",
  buttonColorText: astronaut600,
  buttonText: "Check Availability"
}

const BENEFITS = [
  { title: "Your recommended career paths" },
  { title: "Your career personality" },
  { title: "Access to exclusive online community" },
  { title: "Detailed Analysis" },
  { title: "60-minutes session with expert coach" }
]

// REFS
const isPaymentButtonDisabled = ref(false)

// COMPUTED (PRICING TABLE)
const basicData = computed(() => {
  return {
    imageUrl: imageReportTier1,
    subtitle: "Career Assessment Results",
    packageText: "(Limited, Self-Guided)",
    benefits: [
      {
        ...BENEFITS[0],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[1],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[2],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[3],
        iconUrl: ICONS_URL.excluded
      },
      {
        ...BENEFITS[4],
        iconUrl: ICONS_URL.excluded
      }
    ],
    ...BUTTON,
    ...basicDataDynamic.value,
    isRecommended: false,
    title: "Basic",
    packageTier: UserStatus.BASIC
  } as TierProps
})

const basicDataDynamic = computed(() => {
  const basic = {
    finished: {
      buttonText: "Get My Results",
      paymentAction: getPaymentAction(StripePrices.BASIC_LOW, UserStatus.BASIC, enablePaymentButtons),
      currentPriceWithCents: "24.90"
    },
    free: {
      buttonText: "Upgrade Now",
      paymentAction: getPaymentAction(StripePrices.BASIC_HIGH, UserStatus.BASIC, enablePaymentButtons),
      currentPriceWithCents: "29.90"
    },
    basic: {
      buttonText: "Current Report",
      paymentAction: () => {},
      currentPriceWithCents: "",
      isButtonDisabled: true
    }
  }

  return basic[userStore.getUserStatus as keyof typeof basic]
})

const comprehensiveData = computed(() => {
  return {
    imageUrl: imageReportTier2,
    subtitle: "Career Assessment Results",
    packageText: "(Full, Self-Guided)",
    benefits: [
      {
        ...BENEFITS[0],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[1],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[2],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[3],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[4],
        iconUrl: ICONS_URL.excluded
      }
    ],
    ...BUTTON,
    ...comprehensiveDataDynamic.value,
    numberOfPurchases: comprehensiveReportPurchases.value,
    title: "Comprehensive",
    packageTier: UserStatus.COMPREHENSIVE
  } as TierProps
})

const comprehensiveDataDynamic = computed(() => {
  const comprehensive = {
    finished: {
      buttonText: "Get My Results",
      paymentAction: getPaymentAction(StripePrices.COMPREHENSIVE_LOW, UserStatus.COMPREHENSIVE, enablePaymentButtons),
      currentPriceWithCents: "34.90",
      isRecommended: false
    },
    free: {
      buttonText: "Upgrade Now",
      paymentAction: getPaymentAction(StripePrices.COMPREHENSIVE_LOW, UserStatus.COMPREHENSIVE, enablePaymentButtons),
      currentPriceWithCents: "34.90",
      isRecommended: true
    },
    basic: {
      buttonText: "Upgrade Now",
      paymentAction: getPaymentAction(
        StripePrices.COMPREHENSIVE_UPGRADE,
        UserStatus.COMPREHENSIVE,
        enablePaymentButtons
      ),
      currentPriceWithCents: "4.90",
      isRecommended: true,
      discountPercentageValue: 51,
      previousPriceWithCents: "10.00"
    },
    comprehensive: {
      buttonText: "Current Report",
      paymentAction: () => {},
      currentPriceWithCents: "",
      isRecommended: false,
      isButtonDisabled: true
    }
  }

  return comprehensive[userStore.getUserStatus as keyof typeof comprehensive]
})

const premiumData = computed(() => {
  return {
    imageUrl: imagePremiumTier,
    subtitle: "1:1 Career Coaching",
    packageText: "(Includes Assessment Results)",
    benefits: [
      {
        ...BENEFITS[0],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[1],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[2],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[3],
        iconUrl: ICONS_URL.included
      },
      {
        ...BENEFITS[4],
        iconUrl: ICONS_URL.included
      }
    ],
    ...BUTTON,
    buttonText: "Upgrade Now",
    currentPriceWithCents: "99.90",
    ...premiumDataDynamic.value,
    numberOfPurchases: premiumReportPurchases.value,
    title: "Premium",
    packageTier: UserStatus.PREMIUM
  } as TierProps
})

const premiumDataDynamic = computed(() => {
  const premium = {
    finished: {
      buttonText: "Check Availability",
      paymentAction: linkToCoachSelection,
      isRecommended: true
    },
    free: {
      paymentAction: linkToCoachSelection,
      isRecommended: false
    },
    basic: {
      paymentAction: linkToCoachSelection,
      isRecommended: false,
      discountPercentageValue: 18,
      previousPriceWithCents: "120.00"
    },
    comprehensive: {
      paymentAction: linkToCoachSelection,
      isRecommended: true,
      discountPercentageValue: 10,
      previousPriceWithCents: "110.00"
    }
  }

  return premium[userStore.getUserStatus as keyof typeof premium]
})

const computedPaymentTiers = computed(() => {
  return userStore.isTierComprehensive
    ? [{ ...comprehensiveData.value }, { ...premiumData.value }]
    : [{ ...basicData.value }, { ...comprehensiveData.value }, { ...premiumData.value }]
})

const sortedPaymentTiers = computed(() => {
  if (isScreenSizeXlOrLarger.value) return computedPaymentTiers.value // no sorting for xl and larger

  // Clone the array to avoid mutating the original objects in `data`
  const tiers = [...computedPaymentTiers.value]

  // Sort the array based on `isRecommended` property and Current Report tier
  // The element with `isRecommended` true will be moved to the beginning
  // The element that matches the user's current report tier will be moved to the end
  return tiers.sort((a, b) => {
    if (a.isRecommended && !b.isRecommended) return -1
    if (b.isRecommended && !a.isRecommended) return 1
    if (a.packageTier === userStore.getUserStatus) return 1
    if (b.packageTier === userStore.getUserStatus) return -1
    return 0
  })
})

// FUNCTIONS
const disablePaymentButtons = () => {
  isPaymentButtonDisabled.value = true
}

const enablePaymentButtons = () => {
  isPaymentButtonDisabled.value = false
}

// LIFECYCLE

onMounted(() => {
  enablePaymentButtons()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/borders.scss";

.i_pricing-table {
  position: relative;

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    justify-items: center;

    > div {
      width: -webkit-fill-available;
      width: -moz-available;
      width: fill-available;
    }
  }
}

@media screen and (min-width: $breakpointLg) {
  .i_pricing-table {
    .grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }
  }
}

@media screen and (min-width: $breakpointXl) {
  .i_pricing-table {
    .grid {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
    }
  }
}

::v-deep .benefit-highlight {
  border-bottom: 2px solid $riptide300;
  font-weight: bold;
}
</style>
