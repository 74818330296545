<template>
  <div class="i_career-navigator">
    <div class="careers-list">
      <template
        v-for="career in careers"
        :key="career.id"
      >
        <iCareerCard
          :career="career"
          @click="handleCareerClick(career.id)"
        />
      </template>

      <div
        class="unlock"
        v-if="!userStore.isTierPremium"
      >
        <div class="divider" />
        <iUnlockCareers
          :is-compact="true"
          @upgrade-clicked="emit('open-modal-payment')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { useRouter } from "vue-router"

import { GlobalVariables } from "@/helpers/globalVariables"

import iCareerCard from "@/components/report/CareerCard.vue"
import iUnlockCareers from "@/components/report/UnlockCareers.vue"

// ROUTER
const router = useRouter()

// STORE
const reportStore = useReportStore()
const userStore = useUserStore()

// EMITS
const emit = defineEmits(["open-modal-payment", "career-clicked"])

// CONSTANTS
const REPORT_ROUTES = GlobalVariables.urls.report.children

// COMPUTED

const careers = computed(() => reportStore.getRecommendedJobs)

//FUNCTIONS

const handleCareerClick = (jobId: number) => {
  router.push({
    name: REPORT_ROUTES.careers.routeName,
    params: {
      jobId
    }
  })

  emit("career-clicked")
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";

.i_career-navigator {
  width: 100%;

  .careers-list {
    @include flex(column, flex-start, flex-start, $gap: 8px);
    width: 100%;

    @media screen and (min-width: $breakpointLg) {
      padding: 0 2px;
    }
  }

  .unlock {
    width: 100%;
    .divider {
      width: 100%;
      height: 1px;
      background-color: $zumthor100;
      margin: 12px 0 38px 0;
    }
  }
}
</style>
