import { defineStore } from "pinia"

// Services
import ApiService from "@/services/apiService"
import GtmService from "@/services/gtmService"

// Types
import UserStatus from "@/shared/enums/UserStatus"
import type UserGetMeOut from "@/shared/dtos/out/UserGetMeOut"

export const useUserStore = defineStore("user", {
  state: () => ({
    userData: {} as UserGetMeOut
  }),
  getters: {
    getEmail: (state) => {
      return state.userData?.email || null
    },
    getFullName: (state) => {
      return state.userData?.fullName || null
    },
    getPhoneNumber: (state) => {
      return state.userData?.phoneNumber || null
    },
    getQuizPage: (state) => {
      return state.userData?.quizPage || null
    },
    getUserData: (state) => {
      return state.userData
    },
    getUserId: (state) => {
      return state.userData?.id || null
    },
    getUserStatus: (state) => {
      return state.userData?.status || null
    },
    isCoachingUser: (state) => {
      return state.userData?.isCoachingUser || false
    },
    isReportUser: (state) => {
      return state.userData?.isReportUser || false
    },
    isCoachingOnlyUser: (state) => {
      return state.userData?.isCoachingUser && !state.userData?.isReportUser
    },
    isFeedbackExperienceCompleted: (state) => {
      return state.userData?.isFeedbackExperienceCompleted || false
    },
    isTierFinished: (state) => {
      return state.userData?.status === UserStatus.FINISHED
    },
    isTierFinishedOrHigher: (state) => {
      return !!state.userData?.status ?? false
    },
    isTierFree: (state) => {
      return state.userData?.status === UserStatus.FREE
    },
    isTierFreeOrHigher: (state) => {
      return state.userData?.status !== UserStatus.FINISHED
    },
    isTierAnyPaid: (state) => {
      return state.userData?.status
        ? [UserStatus.FREEMIUM, UserStatus.BASIC, UserStatus.COMPREHENSIVE, UserStatus.PREMIUM].includes(
            state.userData.status
          )
        : false
    },
    isTierBasic: (state) => {
      return state.userData?.status === UserStatus.BASIC
    },
    isTierBasicOrHigher: (state) => {
      return state.userData?.status
        ? [UserStatus.BASIC, UserStatus.COMPREHENSIVE, UserStatus.PREMIUM].includes(state.userData.status)
        : false
    },
    isTierComprehensive: (state) => {
      return state.userData?.status === UserStatus.COMPREHENSIVE
    },
    isTierComprehensiveOrHigher: (state) => {
      return state.userData?.status
        ? [UserStatus.COMPREHENSIVE, UserStatus.PREMIUM].includes(state.userData.status)
        : false
    },
    isTierPremium: (state) => {
      return state.userData?.status === UserStatus.PREMIUM
    }
  },
  actions: {
    async loadUserData() {
      try {
        const data = await ApiService.getUserData()
        this.userData = data || {}

        // Track user data in GTM for enhanced conversions
        if (this.getEmail) {
          GtmService.userDataEvent(this.getEmail)
        }
      } catch (error) {
        console.error("Error loading user's data", error)
      }
    }
  }
})
