interface SalaryRangeNumbers {
  bottom: number
  middle: number
  high: number
  top: number
}

interface SalaryComparisons {
  currentPotentialPercent: number
  potentialPeerPercent: number
  currentPotential: string
  potentialPeer: string
}

interface SalaryRanges {
  bottom: string
  middle: string
  high: string
  top: string
}

export interface SalaryStatistics {
  currentSalary: string
  potentialSalary: string
  comparisons: SalaryComparisons
  salaryRanges: SalaryRanges
  salaryRangesAsNumbers: SalaryRangeNumbers
}

// Calculate the salary ranges based on the current salary
export default function getSalaryStatistics(currentSalary: number): SalaryStatistics {
  // Get the salary range numbers
  const range: SalaryRangeNumbers = getSalaryRange(currentSalary)

  // Calculate the salaries for peer, potential and high potential
  const peer = Math.round(range.middle + (range.high - range.middle) * (2 / 3))
  const potential = Math.round(range.middle + (range.high - range.middle) * (1 / 2))
  const potentialHigh = Math.round(range.high + (range.top - range.high) * (1 / 2))

  // Calculate the salary comparisons
  const currentPotentialPercent = Math.round(((currentSalary - potential) / potential) * 100)
  const potentialPeerPercent = Math.round(((potentialHigh - peer) / peer) * 100)

  // Convert the salary comparisons to strings
  const salaryComparisons: SalaryComparisons = {
    currentPotentialPercent,
    potentialPeerPercent,
    currentPotential: salaryComparisonToString(currentPotentialPercent),
    potentialPeer: salaryComparisonToString(potentialPeerPercent)
  }

  // Prepare the final result object
  return {
    currentSalary: getSalaryDisplay(currentSalary),
    potentialSalary: `${salaryToString(range.middle)} - ${salaryToString(range.high)}`,
    comparisons: salaryComparisons,
    salaryRanges: {
      bottom: salaryToString(range.bottom),
      middle: salaryToString(range.middle),
      high: salaryToString(range.high),
      top: salaryToString(range.top)
    },
    salaryRangesAsNumbers: {
      bottom: range.bottom,
      middle: range.middle,
      high: range.high,
      top: range.top
    }
  }
}

// MAPS
const getSalaryRange = (currentSalary: number): any => {
  // i.e. 110K
  let bottomMultiplier = 0.67 // 73K
  let middleMultiplier = 1.2 // 132K
  let highMultiplier = 1.5 // 165K
  let topMultiplier = 2.5 // 275K

  if (currentSalary < 25000) {
    // i.e. 15K
    bottomMultiplier = 0.67 // 10K
    middleMultiplier = 2.87 // 43K
    highMultiplier = 6.33 // 95K
    topMultiplier = 8 // 120K
  } else if (currentSalary < 40000) {
    // i.e. 25K
    bottomMultiplier = 0.67 // 17K
    middleMultiplier = 1.84 // 46K
    highMultiplier = 4.12 // 103K
    topMultiplier = 5.2 // 130K
  } else if (currentSalary < 60000) {
    // i.e. 40K
    bottomMultiplier = 0.67 // 27K
    middleMultiplier = 1.3 // 52K
    highMultiplier = 2.65 // 106K
    topMultiplier = 3.38 // 135K
  } else if (currentSalary < 90000) {
    // i.e. 60K
    bottomMultiplier = 0.67 // 40K
    middleMultiplier = 1.28 // 77K
    highMultiplier = 1.8 // 108K
    topMultiplier = 2.5 // 150K
  } else if (currentSalary < 110000) {
    // i.e. 90K
    bottomMultiplier = 0.67 // 60K
    middleMultiplier = 1.2 // 108K
    highMultiplier = 1.5 // 135K
    topMultiplier = 2.5 // 220K
  }

  const bottom = Math.round((currentSalary * bottomMultiplier) / 1000) * 1000
  const middle = Math.round((currentSalary * middleMultiplier) / 1000) * 1000
  const high = Math.round((currentSalary * highMultiplier) / 1000) * 1000
  const top = Math.round((currentSalary * topMultiplier) / 1000) * 1000

  return { bottom, middle, high, top }
}

const getSalaryDisplay = (salary: number): string => {
  if (salary < 20000) return "below $20K"
  if (salary < 30000) return "$20K - $30K"
  if (salary < 50000) return "$30K - $50K"
  if (salary < 75000) return "$50K - $75K"
  if (salary < 100000) return "$75K - $100K"
  if (salary < 120000) return "$100K - $120K"
  return "$120K+"
}

// HELPER FUNCTIONS

// Function to convert the salary difference to a string
function salaryComparisonToString(comparison: number): string {
  if (comparison > 0) return `${comparison}% higher`
  else if (comparison < 0) return `${-1 * comparison}% lower`
  else return "equal"
}

// Function to convert a salary number to a string
function salaryToString(salary: number): string {
  return `$${salary / 1000}K`
}
