<template>
  <div class="i_select-plan-countdown-nav">
    <div class="inner">
      <img
        class="logo"
        :src="logo"
      />
      <div class="right">
        <div class="countdown">
          <div class="text">Time Remaining:</div>
          <div class="time">{{ formattedTime }}</div>
        </div>
        <div
          class="question"
          @click="data.showInfoModal = true"
        >
          ?
        </div>
      </div>
    </div>

    <iBaseModal
      :visible="data.showInfoModal"
      :fully-customisable="true"
      :show-close-icon="false"
      max-width="600px"
    >
      <div class="modal">
        <div class="top">
          <div class="caption">⏰ What's This?</div>
          <div>
            The clock is ticking - this timer shows how long we'll hold this spot for you. If time runs out, other users
            will have a chance to select this slot with {{ defaultCoachFirstName }}.
          </div>
        </div>
        <div class="bottom">
          <iBaseButton
            v-bind="BUTTON_STYLE"
            text="Got It!"
            @clicked="data.showInfoModal = false"
          />
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, reactive, onBeforeUnmount } from "vue"
import { useRoute } from "vue-router"
import { useCoachingStore } from "@/stores/coaching"
import { azureRadiance600, white } from "@/styles/colors"

import iBaseModal from "@/components/base/BaseModal.vue"
import iBaseButton from "@/components/base/BaseButton.vue"

// ASSETS
import logo from "@/assets/logo/logo-dark.svg"

// STORES AND ROUTES
const coachingStore = useCoachingStore()
const activeRoute = useRoute()
const dateTimeObject = new Date(activeRoute.query["date"]?.toString() || "")

// CONSTANTS
const TIMER_DURATION_MIN = 15

const BOOKED_DATE = new Date(dateTimeObject).toLocaleDateString("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric"
})

const BOOKED_TIME = new Date(dateTimeObject).toLocaleTimeString("en-US", {
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short"
})

const BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content",
  backgroundColor: azureRadiance600,
  borderRadius: "12px",
  textColor: white
}

// REFS
const timeRemaining = ref(TIMER_DURATION_MIN * 60)
const data = reactive({
  showInfoModal: false,
  interval: undefined as undefined | ReturnType<typeof setInterval>
})

// COMPUTED
const formattedTime = computed(() => {
  const minutes = Math.floor((timeRemaining.value / 60) % 60)
  const seconds = Math.floor(timeRemaining.value % 60)

  return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
})
const defaultCoachFullName = computed(() => coachingStore.getDefaultCoach?.fullName)
const defaultCoachFirstName = computed(() => defaultCoachFullName.value?.split(" ")[0])

// FUNCTIONS
function startTimer() {
  data.interval = setInterval(() => {
    if (timeRemaining.value > 0) timeRemaining.value--

    if (timeRemaining.value >= 0) {
      localStorage.setItem("coaching-countdown", timeRemaining.value.toString())
    }

    if (timeRemaining.value <= 0) {
      clearInterval(data.interval)
    }
  }, 1000)
}

const setStartTime = async () => {
  const timer = localStorage.getItem("coaching-countdown")
  const previousBookingDetails = localStorage.getItem("select-plan-booking-details") || ``
  const newBookingDetails = `${defaultCoachFullName.value}+${BOOKED_DATE}+${BOOKED_TIME}`

  if (timer == null || previousBookingDetails != newBookingDetails) {
    localStorage.setItem("coaching-countdown", timeRemaining.value.toString())
    localStorage.setItem("select-plan-booking-details", newBookingDetails)
  }

  timeRemaining.value = parseInt(localStorage.getItem("coaching-countdown") || timeRemaining.value.toString())
}

// LIFECYCLE HOOKS
onMounted(async () => {
  await setStartTime()
  startTimer()
})

onBeforeUnmount(() => {
  clearInterval(data.interval)
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_select-plan-countdown-nav {
  width: 100%;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(83, 83, 83, 0.07);
  padding: 0 20px;

  .inner {
    @include flex(row, space-between, center, $gap: 0px);
    max-width: 1152px;
    margin: 0 auto;
    padding: 18px 0;

    @media screen and (min-width: $breakpointSm) {
      padding: 15px 0;
    }

    .logo {
      display: none;

      @media screen and (min-width: $breakpointXxs) {
        display: block;
        width: 125px;
      }
    }

    .right {
      @include flex(row, $align: center, $gap: 8px);
      width: unset;

      .countdown {
        @include flex($direction: column, $align: start);

        @media screen and (min-width: $breakpointXs) {
          @include flex($direction: row, $gap: 8px);
        }

        .time {
          @include display-xs;
          font-weight: 700;
        }

        .text {
          @include text-sm;
          font-weight: 500;
          width: max-content;
        }
      }

      .question {
        padding: 0px 6px;
        @include border-radius-rounded;
        border: 3px solid $comet400;
        color: $comet400;
        font-weight: 700;
        @include text-sm;
        cursor: pointer;
      }
    }
  }

  .modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 17px);
      margin: 25px;
      @include text-lg;

      .caption {
        font-weight: 700;
        @include display-sm;
        text-align: center;
      }
    }

    .bottom {
      @include flex($direction: row, $justify: center, $align: center, $gap: 0px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
