import { onMounted, onBeforeUnmount, ref, type Ref } from "vue"

function useIsScreenSize(minSize: number): Ref<boolean> {
  const isScreenSize = ref(false)

  function handleResize() {
    isScreenSize.value = window.innerWidth >= minSize
  }

  onMounted(() => {
    handleResize()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize)
    }
  })

  onBeforeUnmount(() => {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", handleResize)
    }
  })

  return isScreenSize
}

export default useIsScreenSize
