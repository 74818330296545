<template>
  <div class="i_career-factors">
    <div
      v-for="(item, index) in items"
      :key="index"
    >
      <template v-if="!!item.title && !!item.description">
        <div
          class="item"
          :style="{ backgroundColor: item.backgroundColor, color: item.color }"
        >
          <div class="title">{{ item.title }}</div>
          <div class="description">{{ item.description }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { white } from "@/styles/colors"

// STORES
const reportStore = useReportStore()

const items = computed(() => {
  return [
    {
      backgroundColor: "#2B802E",
      color: white,
      description: reportStore.reportData?.results?.welcomeCapboiTexts?.personality,
      title: "Personality"
    },
    {
      backgroundColor: "#235725",
      color: white,
      description: reportStore.reportData?.results?.welcomeCapboiTexts?.workStyle,
      title: "Work Style"
    },
    {
      backgroundColor: "#0B280E",
      color: white,
      description: reportStore.reportData?.results?.welcomeCapboiTexts?.environment,
      title: "Environment"
    }
  ]
})
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_career-factors {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .item {
    align-items: center;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    min-height: 320px;
    padding: 24px;
    text-align: center;

    @media screen and (min-width: $breakpointLg) {
      display: grid;
      gap: 48px;
      grid-template-columns: 1fr 1fr;
      min-height: 236px;
      padding: 36px 96px;
      text-align: left;
    }

    @media screen and (min-width: $breakpointXl) {
      gap: 64px;
      padding: 36px 160px;
    }

    .title {
      @include display-sm;
      font-weight: 700;

      @media screen and (min-width: $breakpointMd) {
        @include display-lg;
      }

      @media screen and (min-width: $breakpointXl) {
        @include display-xl;
      }
    }

    .description {
      @include text-md;
      max-width: 400px;
    }
  }
}
</style>
