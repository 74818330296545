<template>
  <div class="i_favorite-job">
    <a
      class="favorite-job"
      @click="likeJob"
    >
      <img :src="icon" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iconStarOutlined from "@/assets/icons/icon-star-black-outline.svg"
import iconStarOrange from "@/assets/icons/icon-star-orange-filled.svg"

import type { Feedback } from "@/shared/types/UserJobFeedback"

// STORES
const reportStore = useReportStore()

// PROPS
const props = defineProps({
  jobId: {
    type: Number,
    required: true
  },
  jobFeedback: {
    type: Number,
    required: true
  }
})

// COMPUTED
const icon = computed(() => (props.jobFeedback == 2 ? iconStarOrange : iconStarOutlined))

// FUNCTIONS
const likeJob = () => {
  const feedback = props.jobFeedback > 0 ? 0 : 2
  const favorite = feedback === 2

  handleFeedback(feedback)
  handleFavorite(favorite)
}

const handleFavorite = (favorite: boolean) => {
  reportStore.postUserJobFavorite(props.jobId, favorite)
}

const handleFeedback = (feedback: Feedback) => {
  reportStore.postUserJobFeedback(props.jobId, {
    feedback,
    source: "report"
  })
}
</script>

<style scoped lang="scss">
.i_favorite-job {
  .favorite-job {
    max-width: fit-content;
    cursor: pointer;

    img {
      align-self: start;
      width: 19px;
    }
  }
}
</style>
