<template>
  <div class="i_loading">
    <iBackground />

    <iNavbarSimple />

    <template v-if="data.userValidated">
      <div class="container-success">
        <iQuizCompletion
          :user-name="data.userName"
          :user-zip-code="data.userZipCode"
          @animation-completed="onAnimationCompleted"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from "vue"
import { useRoute } from "vue-router"

import { GlobalVariables } from "@/helpers/globalVariables"
import { GlobalFunctions } from "@/helpers/globalFunctions"

import iBackground from "@/components/global/Background.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"
import iQuizCompletion from "@/views/loading/sections/QuizCompletion.vue"

// ROUTE

const activeRoute = useRoute()

// DATA

const data = reactive({
  userName: "",
  userZipCode: "",
  userValidated: false
})

// FUNCTIONS

function setPageData() {
  const userName = activeRoute.query[GlobalVariables.urlQueryKeys.fullName] || ""
  const userZipCode = activeRoute.query[GlobalVariables.urlQueryKeys.zipCode] || ""

  data.userName = userName as string
  data.userZipCode = userZipCode as string
  data.userValidated = true
}

function onAnimationCompleted() {
  // Do not use native Vue router push method because it affects the way conversions are marked in Convert
  const protocol = window.location.protocol
  const baseUrl = `${protocol}//${window.location.host}`

  let pageUrl = GlobalVariables.urls.welcome.path

  GlobalFunctions.redirectToPage({ baseUrl, pageUrl, queryUrl: "" })
}

// LIFECYCLE HOOKS

onMounted(() => {
  setPageData()
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_loading {
  .container-success {
    height: calc(100vh - 90px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    max-width: $sectionMaxWidth;
    width: 90%;
  }
}
</style>
