import router from "@/router"
import scrollToTop from "@/composables/scrollToTop"
import { GlobalVariables } from "@/helpers/globalVariables"

// FUNCTION
export default function linkToCoachSelection() {
  const routeName = GlobalVariables.urls.coaching.children.selectCoach.routeName
  router.push({ name: routeName })
  scrollToTop()
}
