<template>
  <div
    class="i_why-matched"
    v-if="!!activeJob?.matchScore"
  >
    <template v-if="!!activeJob?.recommendation">
      <iBaseHeader
        highlight-text=""
        :text="`Why am I a ${activeJob.matchScore}% match with ${activeJob.title}?`"
        align="start"
      />
      <div class="text">{{ activeJob.recommendation }}</div>
    </template>
    <iMatchGraph />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iBaseHeader from "@/components/base/BaseHeader.vue"
import iMatchGraph from "@/components/report/careers/MatchGraph.vue"

// STORES
const reportStore = useReportStore()

// COMPUTED
const activeJob = computed(() => {
  const recommendedJobs = reportStore.getRecommendedJobs
  return recommendedJobs ? recommendedJobs[reportStore.getActiveJobIndex] : null
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/mixins.scss";

.i_why-matched {
  margin-bottom: 45px;

  .text {
    margin: 15px 0 25px 0;
    color: $comet600;
    @include text-sm;
    font-weight: 500;

    @media screen and (min-width: $breakpointSm) {
      @include text-md;
    }
  }
}
</style>
