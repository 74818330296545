<template>
  <div
    class="i_feedback-info"
    :style="{ justifyContent }"
  >
    <div class="caption">Enhance Your Career Matches</div>
    <div class="info">
      Help us tailor our recommendations to your needs. Your feedback is key to ensuring you receive the most accurate
      and personalized career matches.
    </div>

    <div class="rate">
      <div class="meter">
        <template v-for="color in COLORS">
          <div
            class="bar"
            :style="{ '--color': color }"
          ></div>
        </template>
      </div>
      <div class="text">Please rate your interest level for each career from <strong>1 to 5</strong></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

// STYLES
import { bilbao400, bilbao500, bittersweet600, sorbus400, sorbus600 } from "@/styles/colors"

// CONSTANTS
const COLORS = [bittersweet600, sorbus600, sorbus400, bilbao400, bilbao500]

// PROPS
const props = defineProps({
  contentArea: {
    type: Number,
    required: true
  }
})

// COMPUTED
const justifyContent = computed(() => {
  return props.contentArea > 150000 ? "center" : "start"
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_feedback-info {
  @include flex($direction: column, $gap: 22px);
  margin: auto;
  text-align: center;

  .caption {
    font-weight: 700;
    @include display-xs;
  }

  .info {
    @include text-md;
    font-weight: 500;
    color: $black600;
  }

  .rate {
    @include border-radius-md;
    background-color: $pickleBluewood50;
    padding: 20px;
    margin-top: 12px;
    max-width: 325px;

    .meter {
      @include grid($cols: 1fr 1fr 1fr 1fr 1fr, $rows: 1fr, $gap: 4px);

      .bar {
        border-radius: 2px;
        height: 9px;
        background-color: var(--color);
      }
    }

    .text {
      @include text-sm;
      font-weight: 500;
      color: $comet700;
      margin-top: 12px;
      padding: 0 10px;
    }
  }
}
</style>
