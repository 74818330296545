<template>
  <div class="i_report-navbar">
    <div class="navbar">
      <div class="wrap">
        <div class="logo">
          <img
            :src="logo"
            loading="lazy"
            alt="Jobtest Logo"
            @click="redirectToHome"
          />
        </div>

        <div
          class="menu-desktop"
          v-if="isDesktop"
        >
          <template v-if="!homeItem.isHidden">
            <iNavbarItem
              @click="homeItem.action"
              :text="homeItem.text"
            >
              <template #icon>
                <img
                  class="icon"
                  :src="homeItem.icon"
                />
              </template>
            </iNavbarItem>

            <div class="divider" />
          </template>

          <iNavbarDropdown
            :dropdownMarginTop="dropdownMarginTop"
            :dropdownItems="supportDropdownItems"
            align="center"
          >
            <iNavbarItem
              :isDropdownItem="true"
              text="Support"
            >
              <template #icon>
                <img
                  class="icon"
                  :src="iconSupport"
                  alt="Menu"
                />
              </template>
            </iNavbarItem>
          </iNavbarDropdown>

          <div class="divider" />

          <iNavbarDropdown
            :dropdownItems="profileDropdownItems"
            :dropdownMarginTop="dropdownMarginTop"
            align="right"
          >
            <iNavbarItem
              :isDropdownItem="true"
              :text="userStore.getFullName || 'Your Details'"
            >
              <template #icon>
                <span class="user-initials icon">{{ userInitials }}</span>
              </template>
            </iNavbarItem>
          </iNavbarDropdown>
        </div>
        <div v-else>
          <iNavbarDropdownMobile
            :supportItems="supportDropdownItems"
            :profileItems="profileDropdownItems"
            :homeItem="homeItem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useIsScreenSize from "@/composables/useIsScreenSize"
import { GlobalVariables } from "@/helpers/globalVariables"
import { useUserStore } from "@/stores/user"
import { computed } from "vue"
import { useRouter } from "vue-router"

// Services
import ApiService from "@/services/apiService"

// Components
import iNavbarDropdown from "@/components/report/navbar/NavbarDropdown.vue"
import iNavbarDropdownMobile from "@/components/report/navbar/NavbarDropdownMobile.vue"
import iNavbarItem from "@/components/report/navbar/NavbarItem.vue"

// Assets
import logo from "@/assets/logo/logo-dark.svg"
import iconFeatureRequest from "@/assets/menu/icon-feature-request.svg"
import iconGetNewCareers from "@/assets/menu/icon-get-new-careers.svg"
import iconHome from "@/assets/menu/icon-home.svg"
import iconLogout from "@/assets/menu/icon-logout.svg"
import iconProfile from "@/assets/menu/icon-profile.svg"
import iconReportABug from "@/assets/menu/icon-report-a-bug.svg"
import iconSupport from "@/assets/menu/icon-support.svg"

// Styles
import { bittersweet600 } from "@/styles/colors"
import { breakpointMd } from "@/styles/variables"

// Types
import type { DropdownItem } from "@/types/dropdownItem"

// Stores
const userStore = useUserStore()
const router = useRouter()

// Composables
const isDesktop = useIsScreenSize(breakpointMd)

// Constants

const dropdownMarginTop = 24

const profileDropdownItems: DropdownItem[] = [
  {
    action: () => {
      router.push({ name: GlobalVariables.urls.report.children.profile.routeName })
    },
    icon: iconProfile,
    text: "Profile"
  },
  {
    action: () =>
      ApiService.postLogout()
        .then(() => {
          window.location.href = GlobalVariables.urls.login.path
        })
        .catch((error) => {
          console.error("Error logging out", error)
        }),
    icon: iconLogout,
    text: "Logout",
    textColor: bittersweet600
  }
]

// Computed

const homeItem = computed<DropdownItem>(() => {
  return {
    action: () => {
      router.push({ name: GlobalVariables.urls.report.children.home.routeName })
    },
    icon: iconHome,
    text: "Home",
    isHidden: router.currentRoute.value.name === GlobalVariables.urls.report.children.home.routeName
  }
})

const supportDropdownItems = computed<DropdownItem[]>(() => {
  return [
    {
      action: () => {
        router.push({ name: GlobalVariables.urls.report.children.newCareers.routeName })
      },
      icon: iconGetNewCareers,
      text: "Get New Careers",
      isHidden: !userStore.isTierAnyPaid
    },
    {
      action: () => {
        window.open("https://survey.zigpoll.com/2tVLrZ1upJBnDCLQ3/2uNSktDWV6KUvui1R")
      },
      icon: iconFeatureRequest,
      text: "Feature Request"
    },
    {
      action: () => {
        window.open("https://survey.zigpoll.com/2tVLrZ1upJBnDCLQ3/2ue38doMHvnsYd8Ga")
      },
      icon: iconReportABug,
      text: "Report a Bug"
    }
  ]
})

const userInitials = computed(() => {
  const fullName = userStore.getFullName?.trim()
  if (!fullName) return ""

  const fullNameArray = fullName.split(" ")
  const arrLength = fullNameArray.length

  const firstInitial = fullNameArray[0][0]
  const lastInitial = arrLength > 1 ? fullNameArray[arrLength - 1][0] : ""

  return `${firstInitial}${lastInitial}`
})

// FUNCTIONS
const redirectToHome = () => {
  router.push({ name: GlobalVariables.urls.report.children.home.routeName })
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/colors.scss";

$navbarHeight: 80px;

.i_report-navbar {
  z-index: $zIndexNavbar;
  width: 100%;
  height: $navbarHeight;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(83, 83, 83, 0.07);

  position: sticky;
  top: 0;

  .wrap {
    background-color: $white;

    width: 100%;
    height: $navbarHeight;
    padding: 24px;

    @include flex($direction: row, $justify: space-between, $align: center, $gap: 8px);

    .logo {
      cursor: pointer;
      padding: 4px;

      img {
        width: 121px;
        height: 28.8px;
      }
    }

    .menu-desktop {
      @include flex($direction: row, $justify: flex-end, $align: center, $gap: 12px);

      .divider {
        height: 24px;
        width: 1px;
        background-color: $zumthor100;
      }

      .user-initials {
        @include flex(row, center, center);
        @include text-style($size: 10px, $weight: 700, $line-height: 16px, $color: $pickleBluewood900);
        background-color: $brandOpacity100;
        border-radius: 100%;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media print {
  .i_report-navbar {
    display: none;
  }
}
</style>
