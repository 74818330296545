<template>
  <div class="i_slider-testimonials">
    <div
      v-for="(testimonial, index) in testimonialsSlider"
      :key="index"
    >
      <div
        @click="handlePlayVideo(testimonial.videoUrl)"
        class="testimonial"
      >
        <img
          :src="testimonial.gifUrl"
          class="gif"
          loading="eager"
        />

        <img
          :src="iconPlay"
          alt=""
          class="icon-play"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// ASSETS
import iconPlay from "@/assets/icons/icon-play-v2-white.svg"

// EMITS
const emit = defineEmits(["clicked"])

// DATA
const testimonials = [
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Ashley_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Ashley_Subtitled_Reduced.mp4"
  },
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Cassidy_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Cassidy_Subtitled_Reduced.mp4"
  },
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Charlene_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Charlene_Subtitled_Reduced.mp4"
  },
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Erin_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Erin_Subtitled_Reduced.mp4"
  },
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Eve_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Eve_Subtitled_Reduced.mp4"
  },
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Kai_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Kai_Subtitled_Reduced.mp4"
  },
  {
    gifUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Traci_Subtitled_Gif.webp",
    videoUrl:
      "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/ugc-testimonials/welcome+page+new+videos+(08.08.2024)/Testimonial_Traci_Subtitled_Reduced.mp4"
  }
]

// Computed
const testimonialsSlider = [...testimonials, ...testimonials]

// METHODS
const handlePlayVideo = (videoUrl: string) => {
  emit("clicked", videoUrl)
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/variables.scss";

.i_slider-testimonials {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  width: fit-content;

  .testimonial {
    cursor: pointer;
    border-radius: 24px;
    border: 1px solid $black100;
    height: 284px;
    overflow: hidden;
    position: relative;
    width: 195px;

    @media screen and (min-width: $breakpointMd) {
      height: 374px;
      width: 256px;
    }

    .gif {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .icon-play {
      height: 40px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
    }
  }
}
</style>
