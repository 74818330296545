<template>
  <div
    class="i_myths-and-facts"
    v-if="activeJobMythFact.length > 0"
  >
    <div class="cards">
      <template v-for="mythFact in activeJobMythFact">
        <div class="card">
          <div class="myth">
            <div class="title">Common Belief</div>
            <div class="content">{{ mythFact.myth }}</div>
          </div>
          <div class="fact">
            <div class="title">Truth</div>
            <div class="content">{{ mythFact.fact }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

// STORES
const reportStore = useReportStore()

// COMPUTED
const activeJobMythFact = computed(() => {
  return reportStore.getActiveJobMythFact
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_myths-and-facts {
  margin-top: 24px;

  .cards {
    @include flex(column, $justify: center, $align: start, $gap: 20px);

    @media screen and (min-width: $breakpointSm) {
      gap: 30px;
    }

    .card {
      @include flex(column, $justify: center, $align: start, $gap: 25px);
      @include border-radius-lg;
      border: 1px solid $zumthor100;
      padding: 20px;
      width: 100%;

      @media screen and (min-width: $breakpointSm) {
        padding: 25px 40px;
        gap: 30px;
      }

      .myth,
      .fact {
        @include flex(column, $justify: center, $align: start, $gap: 8px);

        @media screen and (min-width: $breakpointSm) {
          gap: 12px;
        }

        .title {
          @include text-sm;
          font-weight: 700;
        }
      }

      .myth {
        color: $pickleBluewood900;

        .content {
          font-size: 24px;
          line-height: 31px;
          font-weight: 700;
        }
      }

      .fact {
        .title {
          color: $bilbao600;
        }

        .content {
          color: $pickleBluewood600;
          @include text-md;
          font-weight: 400;

          @media screen and (min-width: $breakpointSm) {
            @include text-lg;
          }
        }
      }
    }
  }
}
</style>
