<template>
  <div :class="`i_base-header ${$props.printBreakBefore ? 'print-page-break' : ''}`">
    <h2
      class="text highlight"
      :style="highLightedTextStyle"
      v-if="props.highlightText"
    >
      {{ props.highlightText }}
    </h2>
    <h3
      class="text"
      :style="textStyle"
      v-if="props.text"
    >
      {{ props.text }}
    </h3>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { pickleBluewood900 } from "@/styles/colors"

const props = defineProps({
  align: {
    type: String,
    default: "left"
  },
  fontSizeHighlightedText: {
    type: String,
    default: "36px"
  },
  fontSizeText: {
    type: String,
    default: "20px"
  },
  fontWeightText: {
    type: String,
    default: "700"
  },
  colorText: {
    type: String,
    default: pickleBluewood900
  },
  highlightText: {
    type: String,
    default: null
  },
  lineHeight: {
    type: String,
    default: null
  },
  text: {
    type: String,
    default: ""
  },
  printBreakBefore: {
    type: Boolean,
    default: false
  }
})

const textStyle = computed((): Record<string, string> => {
  return {
    textAlign: props.align,
    fontSize: props.fontSizeText,
    lineHeight: props.lineHeight,
    fontWeight: props.fontWeightText,
    color: props.colorText
  }
})

const highLightedTextStyle = computed((): Record<string, string> => {
  return {
    textAlign: props.align,
    fontSize: props.fontSizeHighlightedText,
    lineHeight: props.lineHeight
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";

.i_base-header {
  @include flex(column, center, center);
  margin: 0;
  width: 100%;

  .text {
    width: 100%;
    margin: 0;

    font-weight: 700;
    color: $pickleBluewood900;

    &.highlight {
      @include display-sm;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }
}

@media print {
  .i_base-header {
    &.print-page-break {
      margin-top: 5px;
      page-break-before: always;
      -webkit-page-break-before: always;
    }
  }
}
</style>
