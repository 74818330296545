<template>
  <div class="i_payment-options">
    <iSectionTitle class="payment-table-title">
      Your <span class="highlight">personalized career report</span> is ready!
    </iSectionTitle>

    <iTextBlock>
      Since {{ testTakingPurposeText }}, you will find the most success with our <strong>Comprehensive Report.</strong>
    </iTextBlock>

    <iBaseSpacer
      mobile="medium"
      tablet="small"
      desktop="small"
    />

    <iPaymentTable />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iBaseSpacer from "@/components/base/BaseSpacer.vue"
import iPaymentTable from "@/components/pricing/PaymentTable.vue"
import iSectionTitle from "@/components/welcome/SectionTitle.vue"
import iTextBlock from "@/components/welcome/TextBlock.vue"

// STORES
const reportStore = useReportStore()

// CONSTANTS
const TEST_PURPOSE_MAP = {
  collegeMajor: "you are looking to choose your university/college major",
  careerSwitch: "you are actively looking to switch your career path",
  advance: "you are looking to advance your career",
  ai: "you are looking for AI-proof careers",
  lost: "you are feeling lost",
  return: "you are returning to the workforce",
  other: "you belong in the high potential group"
}

// COMPUTED
const testTakingPurposeText = computed(() => {
  const testTakingPurpose = (reportStore.getTestTakingPurpose?.id || "other") as keyof typeof TEST_PURPOSE_MAP
  return TEST_PURPOSE_MAP[testTakingPurpose]
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_payment-options {
  .i_text-block {
    @include text-md;
    font-weight: 400;
    margin-top: 12px;

    @media screen and (min-width: $breakpointLg) {
      @include text-xl;
    }
  }
}
</style>
