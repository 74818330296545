<template>
  <div
    class="i_base-info-tag"
    :style="tagStyle"
  >
    <div class="inside">
      <div class="name">{{ tagName }}:</div>
      <div class="value">{{ tagValue }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
// IMPORTS
import { black50 } from "@/styles/colors"
import { computed } from "vue"

// PROPS
const props = defineProps({
  tagColor: {
    type: String,
    default: black50
  },
  tagHeight: {
    type: String,
    default: "24px"
  },
  tagName: {
    type: String,
    required: true
  },
  tagValue: {
    type: String,
    required: true
  }
})

// COMPUTED
const tagStyle = computed(() => {
  return {
    backgroundColor: props.tagColor,
    height: props.tagHeight
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_base-info-tag {
  display: inline-flex;
  align-items: center;
  background-color: red;
  padding: 0 8px;
  border-radius: 4px;

  .inside {
    @include flex($justify: space-between);
    @include font-style(small);

    .name {
      font-weight: bold;
    }
    .value {
      text-align: right;
      margin-left: 12px;
    }
  }
}
</style>
