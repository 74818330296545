<template>
  <div class="i_test-adjustment">
    <div class="section-grid">
      <section class="vertical-grid">
        <div class="text-align-center">
          <div class="heading">Fine-Tune Your Results</div>
          <div>
            The categories below reflect your quiz responses.<br />Adjust them to enhance the accuracy of our
            recommendations.
          </div>
        </div>
      </section>

      <section class="vertical-grid">
        <div class="card">
          <div class="target-salary">
            <div class="heading">Target Salary</div>

            <div>
              You answered <strong>{{ originalTargetSalary }}</strong> as your target salary. Please slide the control
              bar below to select the range that matches your career expectations.
            </div>

            <iTargetSalarySlider
              :initial-value="reportStore.getTargetSalary"
              @update-value="handleUpdateTargetSalary"
              :min="MIN_SALARY"
              :max="MAX_SALARY"
            />

            <div>
              Less than <strong>{{ data.jobsLeft }} careers</strong> fit your profile. Slide the control bar to refine
              your options.
            </div>
          </div>
        </div>
      </section>

      <section class="vertical-grid">
        <div class="text-align-center">
          <div class="heading">Choose Your Desired Industries</div>
          <div>
            You must select at least 5 industries. Careers in any unselected industries will not be included in your
            recommendations.
          </div>
        </div>

        <div
          v-for="category in industryCategories"
          :key="category.title"
        >
          <div class="card">
            <div class="grid-2col">
              <div class="grid-2col-left">
                <div class="heading">{{ category.title }}</div>
                <div>{{ category.description }}</div>
              </div>

              <div>
                <div
                  v-for="industry in category.industries"
                  :key="industry.id"
                >
                  <div>
                    <iBaseCheckbox
                      :is-checked="industry.isDesired"
                      :label="industry.name"
                      @toggle="industry.isDesired = $event"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="vertical-grid">
        <div class="text-align-center">
          <div class="heading">Choose Your Desired Job Functions</div>
          <div>
            You must select at least 5 job functions. Careers in any unselected functions will not be included in your
            recommendations.
          </div>
        </div>

        <div class="card">
          <div class="grid-functions">
            <div
              v-for="jobFunction in data.mutablePreferences.jobFunctions"
              :key="jobFunction.id"
            >
              <iJobFunctionCheckbox
                :id="jobFunction.id"
                :is-checked="jobFunction.isDesired"
                :label="jobFunction.name"
                @toggle="jobFunction.isDesired = $event"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="vertical-grid">
        <div class="heading text-align-center">Define Your Growth Opportunities</div>

        <div class="card">
          <div class="grid-2col">
            <div class="grid-2col-left">
              <div class="heading">
                Would you consider pursuing an additional college degree in order to enhance your career prospects?
              </div>
              <div>
                On average, each college degree can lead to a 20% salary increment. Compounded over 25 years, this could
                result in a wealth difference of $748,997.
              </div>
            </div>

            <div>
              <iBaseRadioSelect
                :is-selected="data.mutablePreferences.moreSchoolInterest === 'yes'"
                label="Yes"
                value="yes"
                @select="data.mutablePreferences.moreSchoolInterest = $event"
              />

              <iBaseRadioSelect
                :is-selected="data.mutablePreferences.moreSchoolInterest === 'no'"
                label="No"
                value="no"
                @select="data.mutablePreferences.moreSchoolInterest = $event"
              />
            </div>
          </div>
        </div>

        <div class="card">
          <div class="grid-2col">
            <div class="grid-2col-left">
              <div class="heading">
                Would you be open to getting an online professional certificate(s) to advance your career?
              </div>
              <div>
                Some of the popular professional certificates include Project Management Professional (PMP), Certified
                Information Systems Security Professional (CISSP), and TESOL (Teaching English to Speakers of Other
                Languages).
              </div>
            </div>

            <div>
              <iBaseRadioSelect
                :is-selected="data.mutablePreferences.certificateInterest === 'yes'"
                label="Yes"
                value="yes"
                @select="data.mutablePreferences.certificateInterest = $event"
              />

              <iBaseRadioSelect
                :is-selected="data.mutablePreferences.certificateInterest === 'no'"
                label="No"
                value="no"
                @select="data.mutablePreferences.certificateInterest = $event"
              />
            </div>
          </div>
        </div>

        <div class="card">
          <div class="grid-2col">
            <div class="grid-2col-left">
              <div class="heading">Would you be open to enrolling in a training program to advance your career?</div>
              <div>
                Some popular training programs include programming, cybersecurity, AI, and design, amongst others.
              </div>
            </div>

            <div>
              <iBaseRadioSelect
                :is-selected="data.mutablePreferences.trainingProgramInterest === 'yes'"
                label="Yes"
                value="yes"
                @select="data.mutablePreferences.trainingProgramInterest = $event"
              />

              <iBaseRadioSelect
                :is-selected="data.mutablePreferences.trainingProgramInterest === 'no'"
                label="No"
                value="no"
                @select="data.mutablePreferences.trainingProgramInterest = $event"
              />
            </div>
          </div>
        </div>

        <div class="card">
          <div class="grid-2col">
            <div class="grid-2col-left">
              <div class="heading">
                Would you consider on-site training for your desired career, and what's the maximum duration you could
                commit?
              </div>
              <div>
                Most careers require a certain level of on-site training. Choosing No will only consider jobs that you
                readily have transferrable skillsets of.
              </div>
            </div>

            <div>
              <div
                v-for="interest in data.trainingProgramInterest"
                :key="interest.value"
              >
                <iBaseRadioSelect
                  :is-selected="data.mutablePreferences.trainingInterest === interest.value"
                  :label="interest.text"
                  :value="interest.value"
                  @select="data.mutablePreferences.trainingInterest = $event"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="grid-2col">
            <div class="grid-2col-left">
              <div class="heading">Tell us how we can do it better this time</div>
              <div>
                The more specific you are, the better our algorithm can match you with satisfying jobs. If you mention,
                "I have poor attention to detail," we can avoid recommending jobs that require this skill.
              </div>
            </div>

            <div>
              <textarea
                class="text-area"
                placeholder="The more specific, the better the results."
                v-model="data.mutablePreferences.quizFeedback"
                :maxlength="data.maxFeedbackLength"
              ></textarea>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      class="sticky-submit"
      :class="{ fadeIn: formHasChanges }"
    >
      <div
        class="info"
        v-if="isDesktop || !formIsValid"
      >
        <template v-if="isDesktop && formIsValid">
          <span class="success">Awesome!</span>
          Feel free to continue to make changes. Once you're done, click the button to regenerate.
        </template>

        <template v-else="!formIsValid">
          <span class="error">Review!</span>
          Please review your selections. You must select at least 5 industries and 5 job functions.
        </template>
      </div>

      <div
        class="cta"
        v-if="isDesktop || formIsValid"
      >
        <iBaseButton
          border-radius="12px"
          text="Regenerate My Results"
          :text-color="white"
          :background-color="formIsValid ? astronaut600 : comet600"
          :is-disabled="!formIsValid"
          :width="`100%`"
          version="outline"
          @click="openConfirmationModal"
        />
      </div>
    </div>

    <iJobsFeedbackModal
      :showModal="data.openConfirmationModal"
      @click-on-close="data.openConfirmationModal = false"
      @proceed="regenerateResults"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue"
import { useRouter } from "vue-router"
import { useReportStore } from "@/stores/report"
import useIsScreenSize from "@/composables/useIsScreenSize"

import _ from "lodash"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseCheckbox from "@/components/base/BaseCheckbox.vue"
import iBaseRadioSelect from "@/components/base/BaseRadioSelect.vue"
import iJobsFeedbackModal from "@/components/report/JobsFeedbackModal.vue"
import iJobFunctionCheckbox from "@/components/report/testAdjustment/JobFunctionCheckbox.vue"
import iTargetSalarySlider from "@/components/jobTest/TargetSalarySlider.vue"

import { GlobalVariables } from "@/helpers/globalVariables"
import { astronaut600, comet600, white } from "@/styles/colors"
import { breakpointMd } from "@/styles/variables"
import type QuizPostReportAdjustmentIn from "@/shared/dtos/in/QuizPostReportAdjustmentIn"

// Stores and constants

const reportStore = useReportStore()
const router = useRouter()

const isDesktop = useIsScreenSize(breakpointMd)

// Constants

const MIN_SALARY = 20000
const MAX_SALARY = 125000
const MIN_JOBS = 87
const MAX_JOBS = 1382

const MAX_FEEDBACK_LENGTH = 512

const INDUSTRY_CATEGORIES = {
  1: "Business, Finance, and Professional Services",
  2: "Innovation, Science, and Research",
  3: "Land and Natural Resources",
  4: "Media, Arts, and Lifestyle",
  5: "Production, Construction, and Logistics",
  6: "Public Services, Health, and Education"
}

const TRAINING_PROGRAM_INTERESTS = [
  {
    value: "no",
    text: "No, I don't want any on-site training"
  },
  {
    value: "short_term",
    text: "Yes, Short-Term (less than 3 months)"
  },
  {
    value: "moderate_term",
    text: "Yes, Moderate-Term (3 to 6 months)"
  },
  {
    value: "long_term",
    text: "Yes, Long-Term (more than 6 months)"
  }
]

// Types

type preferences = {
  certificateInterest: booleanInterest
  jobFunctions: jobFunction[]
  jobIndustries: jobIndustry[]
  moreSchoolInterest: booleanInterest
  quizFeedback: string
  targetSalary: number
  trainingInterest: trainingInterest
  trainingProgramInterest: booleanInterest
}

type booleanInterest = "yes" | "no"
type trainingInterest = "short_term" | "moderate_term" | "long_term" | "no"

type jobIndustry = {
  id: number
  name: string
  category: string
  isDesired: boolean
}

type jobFunction = {
  id: number
  name: string
  isDesired: boolean
}

// Data

const data = reactive({
  originalPreferences: {} as preferences,
  mutablePreferences: {} as preferences,
  maxFeedbackLength: MAX_FEEDBACK_LENGTH,
  trainingProgramInterest: TRAINING_PROGRAM_INTERESTS,
  openConfirmationModal: false,
  jobsLeft: MAX_JOBS
})

// Computed

const formHasChanges = computed(() => {
  return !_.isEqual(data.originalPreferences, data.mutablePreferences)
})

const formIsValid = computed(() => {
  return (
    data.mutablePreferences.jobIndustries?.filter((industry) => industry.isDesired).length >= 5 &&
    data.mutablePreferences.jobFunctions?.filter((jobFunction) => jobFunction.isDesired).length >= 5 &&
    data.mutablePreferences.quizFeedback.length <= data.maxFeedbackLength
  )
})

const industryCategories = computed(() => {
  return [
    jobIndustry1.value,
    jobIndustry2.value,
    jobIndustry3.value,
    jobIndustry4.value,
    jobIndustry5.value,
    jobIndustry6.value
  ]
})

const jobIndustry1 = computed(() => {
  return {
    title: "Business, Finance, and Professional Services",
    description:
      "This sector boasts a range of white-collar jobs in dynamic environments, offering professional growth and attractive salaries. However, it can be competitive, demanding long hours or specialized education.",
    industries: data.mutablePreferences?.jobIndustries?.filter(
      (industry) => industry.category === INDUSTRY_CATEGORIES[1]
    )
  }
})

const jobIndustry2 = computed(() => {
  return {
    title: "Innovation, Science, and Research",
    description:
      "This sector encompasses the pursuit of knowledge, discovery, and cutting-edge advancements. It offers opportunities for those with a curious mind and a passion for exploration. However, it can be highly specialized, and success often requires rigorous dedication and continuous learning.",
    industries: data.mutablePreferences?.jobIndustries?.filter(
      (industry) => industry.category === INDUSTRY_CATEGORIES[2]
    )
  }
})

const jobIndustry3 = computed(() => {
  return {
    title: "Land and Natural Resources",
    description:
      "Involving roles in agriculture and mining, this sector offers a rewarding connection to nature for outdoor enthusiasts. However, it comes with physical demands and varying job security due to factors like weather and market prices.",
    industries: data.mutablePreferences?.jobIndustries?.filter(
      (industry) => industry.category === INDUSTRY_CATEGORIES[3]
    )
  }
})

const jobIndustry4 = computed(() => {
  return {
    title: "Media, Arts, and Lifestyle",
    description:
      "This vibrant sector provides avenues for creative expression and aligning work with passions. However, it can be competitive, with some roles having irregular hours or project-based engagements.",
    industries: data.mutablePreferences?.jobIndustries?.filter(
      (industry) => industry.category === INDUSTRY_CATEGORIES[4]
    )
  }
})

const jobIndustry5 = computed(() => {
  return {
    title: "Production, Construction, and Logistics",
    description:
      "Encompassing manufacturing to aerospace, jobs here provide a tangible sense of achievement and a broad role spectrum. Yet, they may have irregular hours, physical demands, and sensitivity to economic fluctuations.",
    industries: data.mutablePreferences?.jobIndustries?.filter(
      (industry) => industry.category === INDUSTRY_CATEGORIES[5]
    )
  }
})

const jobIndustry6 = computed(() => {
  return {
    title: "Public Services, Health, and Education",
    description:
      "Roles here, from teaching to healthcare, offer a sense of societal contribution and job stability. But some positions might be emotionally taxing or have a slower pace of change.",
    industries: data.mutablePreferences?.jobIndustries?.filter(
      (industry) => industry.category === INDUSTRY_CATEGORIES[6]
    )
  }
})

const originalTargetSalary = computed(() => {
  return `$${data.originalPreferences.targetSalary?.toLocaleString()}`
})

// Functions

function calculateJobsLeft(x: number, x1 = MIN_SALARY, y1 = MAX_JOBS, x2 = MAX_SALARY, y2 = MIN_JOBS) {
  // Ensure the salary is within the bounds
  if (x <= x1) return y1
  if (x >= x2) return y2

  // Linear interpolation formula
  const y = y1 + ((y2 - y1) * (x - x1)) / (x2 - x1)

  return Math.round(y) // Return a rounded value if needed
}

function goToReport() {
  router.push({ name: GlobalVariables.urls.report.routeName })
}

function handleUpdateTargetSalary(salary: number) {
  data.mutablePreferences.targetSalary = +salary
  data.jobsLeft = calculateJobsLeft(+salary)
}

function loadPageData() {
  data.originalPreferences = {
    certificateInterest: reportStore.getCertificateInterest as booleanInterest,
    jobFunctions: reportStore.getPreferredJobFunctions as jobFunction[],
    jobIndustries: reportStore.getPreferredJobIndustries as jobIndustry[],
    moreSchoolInterest: reportStore.getMoreSchoolInterest as booleanInterest,
    quizFeedback: "" as string,
    targetSalary: reportStore.getTargetSalary,
    trainingInterest: reportStore.getTrainingInterest?.value as trainingInterest,
    trainingProgramInterest: reportStore.getTrainingProgramInterest as booleanInterest
  }

  data.mutablePreferences = _.cloneDeep(data.originalPreferences)

  data.jobsLeft = calculateJobsLeft(data.mutablePreferences.targetSalary)
}

function openConfirmationModal() {
  if (!formIsValid.value) return
  data.openConfirmationModal = true
}

async function regenerateResults() {
  if (!formIsValid.value) return

  const payload: QuizPostReportAdjustmentIn = {
    certificateInterest: data.mutablePreferences.certificateInterest,
    desiredFunction: data.mutablePreferences.jobFunctions
      .filter((jobFunction) => jobFunction.isDesired)
      .map((jobFunction) => jobFunction.id),
    desiredIndustry: data.mutablePreferences.jobIndustries
      .filter((industry) => industry.isDesired)
      .map((industry) => industry.id),
    moreSchoolInterest: data.mutablePreferences.moreSchoolInterest,
    quizFeedback: data.mutablePreferences.quizFeedback,
    targetSalary: data.mutablePreferences.targetSalary,
    trainingInterest: data.mutablePreferences.trainingInterest,
    trainingProgramInterest: data.mutablePreferences.trainingProgramInterest
  }

  try {
    await reportStore.adjustQuizAnswers(payload)

    // Need to reload report for fresh data
    await reportStore.loadReport()
    router.push({ name: GlobalVariables.urls.report.routeName })
  } catch (error) {
    console.error("Error adjusting quiz answers")
  }
}

// Lifecycle hooks

onMounted(() => {
  reportStore.hasRetakeQuizAttempts ? loadPageData() : goToReport()
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";

.i_test-adjustment {
  .card {
    @include card();
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
  }

  .grid-2col {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;

    &-left {
      height: 100%;

      .heading {
        margin-bottom: 16px;
      }
    }

    @media screen and (min-width: $breakpointLg) {
      grid-template-columns: 1.3fr 1fr;
      padding: 5px;
    }
  }

  .grid-functions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @media screen and (min-width: $breakpointSm) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .heading {
    @include font-style("h5");
  }

  .sticky-submit {
    z-index: $zIndexFooter;
    align-items: center;
    background-color: white;
    bottom: 89px;
    border-top: 1px solid $zumthor100;
    display: none;
    justify-content: center;
    left: 0px;
    padding: 8px 16px;
    position: sticky;
    width: calc(100% + 48px);
    margin-left: -24px;

    @media screen and (min-width: $breakpointMd) {
      width: calc(100% + 96px);
      margin-left: -48px;
    }

    @media screen and (min-width: $breakpointLg) {
      width: 100%;
      margin-left: 0;
    }

    &.fadeIn {
      display: flex;
      animation: fadeIn 1s ease 0s 1 normal forwards;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .success,
    .error {
      font-weight: bold;
      display: inline-block;
      margin-right: 4px;
    }

    .success {
      color: $astronaut600;
    }

    .error {
      color: $bittersweet500;
    }

    .cta {
      width: 100%;
    }

    .info {
      display: block;
    }

    @media screen and (min-width: $breakpointSm) {
      .cta {
        margin-left: 12px;
        min-width: 150px;
        max-width: 215px;
      }
    }

    @media screen and (min-width: $breakpointLg) {
      bottom: 0px;
      justify-content: center;

      .cta {
        margin-left: 32px;
      }
    }
  }

  .section-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px;
    margin-bottom: 35px;
  }

  .text-area {
    border: 1px solid $black300;
    border-radius: 8px;
    width: 100%;
    height: 140px;
    overflow-y: auto;
    padding: 6px 12px;
    background-color: $black50;

    &:focus-visible {
      outline: none;
      border: 1px solid $azureRadiance400;
    }
  }

  .vertical-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;

    .text-align-center {
      text-align: center;
    }
  }

  .target-salary {
    @include flex($direction: column, $gap: 16px);
    max-width: 480px;
    margin: auto;
    padding: 8px;
    text-align: center;
  }
}
</style>
