<template>
  <div
    class="i_feedback-skip-warning"
    :style="{ justifyContent }"
  >
    <div class="caption">Hold On!</div>
    <div class="info">
      Don't miss out on your most accurate career matches. Skipping this step could mean losing out on tailored
      suggestions. Help us help you find the perfect fit by sharing your feedback.
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

// PROPS
const props = defineProps({
  contentArea: {
    type: Number,
    required: true
  }
})

// COMPUTED
const justifyContent = computed(() => {
  return props.contentArea > 100000 ? "center" : "start"
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_feedback-skip-warning {
  @include flex($direction: column, $gap: 22px);
  margin: auto;
  text-align: center;
  max-width: 420px;

  .caption {
    font-weight: 700;
    @include display-xs;
  }

  .info {
    @include text-md;
    font-weight: 500;
    color: $black600;
  }
}
</style>
