<template>
  <div class="i_please-wait">
    <div
      class="content"
      v-if="data.activeLoader != null"
    >
      <img
        class="logo"
        :src="logo"
        loading="lazy"
      />

      <template v-if="!data.startRedirect">
        <div class="main-header">Generating your career report...</div>

        <div class="loading-section">
          <div
            class="loading"
            v-for="(loader, index) in LOADING_DATA"
          >
            <div
              class="text"
              :style="{ color: loader.color }"
            >
              <div>{{ loader.caption }}</div>
              <div>
                {{ index === data.activeLoader ? `${data.loaderPercent}%` : data.activeLoader > index ? "100%" : "0%" }}
              </div>
            </div>
            <iBaseLoadingBar
              :fillColor="data.activeLoader < index ? black100 : loader.color"
              barWidth="100%"
              barHeight="10px"
              :infinite="false"
              :isActive="index === data.activeLoader"
              :loadingAnimationDurationMs="LOADING_ANIMATION_DURATION_MS"
            />
          </div>
        </div>

        <div
          class="review-section"
          v-if="data.activeLoader < 2 && LOADING_DATA[data.activeLoader].review"
        >
          <div class="header">Trusted by over 700,000 users</div>
          <div class="review">
            <div class="info">
              <img :src="LOADING_DATA[data.activeLoader].review?.image" />
              <div class="right">
                <div class="top">
                  <div class="name">
                    <strong>{{ LOADING_DATA[data.activeLoader].review?.name }}</strong>
                    <span
                      class="tier"
                      :style="{
                        '--bg-color': LOADING_DATA[data.activeLoader].color,
                        color: data.activeLoader === 0 ? white : black950
                      }"
                      >{{ LOADING_DATA[data.activeLoader].review?.tier }}</span
                    >
                  </div>
                  <div class="time">{{ LOADING_DATA[data.activeLoader].review?.time }}</div>
                </div>
                <div class="location">
                  <img :src="iconLocation" />{{ LOADING_DATA[data.activeLoader].review?.location }}
                </div>
              </div>
            </div>
            <img
              class="stars"
              :src="iconStars"
            />
            <div class="progress">{{ LOADING_DATA[data.activeLoader].review?.progress }}</div>
            <div class="comment">{{ LOADING_DATA[data.activeLoader].review?.comment }}</div>
          </div>
        </div>

        <div
          class="gathering"
          v-if="data.activeLoader === 2"
        >
          <div>
            We're analyzing <strong>thousands of databases in real-time</strong> to deliver the most accurate and
            comprehensive data on your ideal career matches.
          </div>
          <iSlidingLogos />
        </div>

        <div
          class="spinner-section"
          v-if="data.activeLoader === 3"
        >
          <div class="header">Adding finishing touches...</div>
          <div
            class="spinner-block"
            :class="{ finished: data.loaderPercent >= 20 }"
          >
            <img :src="data.loaderPercent < 20 ? iconSpinner : iconCheckMark" />Finalizing Career Personality Analysis
          </div>
          <div
            class="spinner-block"
            :class="{ finished: data.loaderPercent >= 40 }"
          >
            <img :src="data.loaderPercent < 40 ? iconSpinner : iconCheckMark" />Refining Career Match Accuracy
          </div>
          <div
            class="spinner-block"
            :class="{ finished: data.loaderPercent >= 60 }"
          >
            <img :src="data.loaderPercent < 60 ? iconSpinner : iconCheckMark" />Customizing Career Recommendations
          </div>
          <div
            class="spinner-block"
            :class="{ finished: data.loaderPercent >= 80 }"
          >
            <img :src="data.loaderPercent < 80 ? iconSpinner : iconCheckMark" />Preparing Personalized Step-by-Step
            Guidance
          </div>
          <div
            class="spinner-block"
            :class="{ finished: data.loaderPercent >= 99 }"
          >
            <img :src="data.loaderPercent < 99 ? iconSpinner : iconCheckMark" />Optimizing Report Layout for Clarity
          </div>
        </div>
      </template>
      <template v-else>
        <div class="redirect-section">
          <div class="main-header">Your career report is ready!</div>
          <div>
            <iBaseButton
              v-bind="buttonStyle"
              @clicked="goToWelcome"
            />
            <div class="teeny">You will be redirected in: {{ data.redirectDelay }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue"
import {
  astronaut600,
  azureRadiance500,
  azureRadiance600,
  bilbao500,
  black100,
  black950,
  brandOpacity100,
  white
} from "@/styles/colors"
import { breakpointMd } from "@/styles/variables"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"
import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseLoadingBar from "@/components/base/BaseLoadingBar.vue"
import iSlidingLogos from "@/components/loading/SlidingLogos.vue"

// ASSETS
import logo from "@/assets/logo/logo-dark.svg"
import imageAriel from "@/assets/images/ariel.png"
import imageDaniel from "@/assets/images/daniel.png"
import iconStars from "@/assets/icons/icon-five-stars.svg"
import iconCheckMark from "@/assets/icons/icon-green-checkmark.svg"
import iconSpinner from "@/assets/images/spinner.gif"
import iconLocation from "@/assets/icons/icon-profile-location-black.svg"

// COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)

// CONSTANTS
const LOADING_DATA = [
  {
    caption: "Analyzing your career personality",
    color: astronaut600,
    review: {
      name: "Daniel S.",
      image: imageDaniel,
      tier: "Comprehensive",
      location: "Nashville, TN",
      time: "3 days ago",
      progress: "Tests personality, value and passion, not just skills and experience",
      comment:
        "One of the more valuable career ’aptitude’ tests I’ve ever taken. Others simply assessed what I would be good at and suggested careers based on potential talents and experience. While jobtest.org takes those things into account, they focus more on your psychological profile, values and passions to give you suggestions based on all of those. I really resonated with their assessments and suggestions."
    }
  },
  {
    caption: "Identifying your ideal careers",
    color: azureRadiance500,
    review: {
      name: "Ariel C.",
      image: imageAriel,
      tier: "Premium",
      location: "Hoboken, NJ",
      time: "Just now",
      progress: "Amazing!!! 10/10",
      comment:
        "I’ve been working with Julie for a few weeks now and found her through the Jobtest best match survey. Since starting with her, I’ve had a clear direction on what to do during the search process. I have also had tremendous luck on everything due to my optimized LinkedIn, resume, and cover-letters. Interview prep has given me so much confidence too. Within the first 2 weeks, I got a job offer and interviews/opportunities are still streaming in. I am so thankful for this experience and feel so much better about this whole process thanks to Julie & Jobtest."
    }
  },
  {
    caption: "Collecting data on your ideal careers",
    color: brandOpacity100
  },
  {
    caption: "Finalizing your career report",
    color: bilbao500
  }
]
const LOADING_ANIMATION_DURATION_MS = 15000

// DATA
const data = reactive({
  activeLoader: undefined as number | undefined,
  loaderPercent: 0,
  startRedirect: false,
  redirectDelay: 3
})

//COMPUTED
const buttonStyle = computed(() => {
  return {
    backgroundColor: azureRadiance600,
    borderRadius: "12px",
    fontWeight: "500",
    size: "small",
    text: "Check Now",
    textColor: white,
    width: isDesktop.value ? "fit-content" : "100%",
    padding: isDesktop.value ? "12px 120px" : "12px 70px"
  }
})

// FUNCTIONS
function goToWelcome() {
  // Do not use native Vue router push method because it affects the way conversions are marked in Convert
  const protocol = window.location.protocol
  const baseUrl = `${protocol}//${window.location.host}`

  let pageUrl = GlobalVariables.urls.welcome.path

  GlobalFunctions.redirectToPage({ baseUrl, pageUrl, queryUrl: "" })
}

const startLoader = async () => {
  for (let i = 0; i < LOADING_DATA.length; i++) {
    data.activeLoader = i
    data.loaderPercent = 0

    // Load 100% for each loading bar
    while (data.loaderPercent < 100) {
      await GlobalFunctions.delayInMiliseconds(LOADING_ANIMATION_DURATION_MS / 100)
      data.loaderPercent++
    }
  }

  // Show redirect screen and start countdown to redirect to welcome
  data.startRedirect = true
  while (data.redirectDelay > 0) {
    await GlobalFunctions.delayInMiliseconds(1000)
    data.redirectDelay--
  }
  goToWelcome()
}

// LIFECYCLE HOOKS
onMounted(() => {
  startLoader()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_please-wait {
  background-color: $white;
  min-height: 100vh;
  @include flex(row, center, center);

  @media screen and (min-width: $breakpointMd) {
    background-color: rgb(0, 0, 0, 0.6);
  }

  .content {
    max-width: 750px;
    width: 100%;
    background-color: white;
    padding: 30px;
    @include flex(column, center, stretch, $gap: 45px);

    @media screen and (min-width: $breakpointMd) {
      @include border-radius-xl;
      margin: 50px 0;
    }

    .logo {
      width: 150px;
      height: 40px;
      margin: auto;
    }

    .main-header {
      @include display-xs;
      font-weight: 700;
      text-align: center;

      @media screen and (min-width: $breakpointSm) {
        @include display-sm;
      }
    }

    .loading-section {
      @include flex(column, center, stretch, $gap: 20px);

      .loading {
        @include flex(column, center, stretch, $gap: 12px);

        .text {
          @include flex(row, space-between, center, $gap: 5px);
          @include text-sm;
          font-weight: 600;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
          }
        }
      }
    }

    .review-section {
      @include flex(column, center, center, $gap: 15px);

      .header {
        @include text-lg;
        font-weight: 700;
      }

      .review {
        @include flex($direction: column, $justify: start, $align: start, $gap: 5px);
        background-color: $black50;
        @include border-radius-sm;
        padding: 15px;
        width: 100%;

        @media screen and (min-width: $breakpointSm) {
          padding: 22px 25px;
          @include border-radius-xl;
        }

        .info {
          @include flex(column, start, stretch);
          @include text-md;
          font-weight: 400;
          width: 100%;

          @media screen and (min-width: $breakpointXxs) {
            flex-direction: row;
            align-items: center;
          }

          @media screen and (min-width: $breakpointSm) {
            @include text-xl;
          }

          > img {
            @include border-radius-rounded;
            margin-right: 10px;
            object-fit: cover;
            width: 48px;
            height: 48px;
          }

          .right {
            width: 100%;

            .top {
              @include flex(row, space-between, start, $gap: 0px);

              .name {
                @include flex(column, start, start, $gap: 2px);

                @media screen and (min-width: $breakpointSm) {
                  @include flex(row, center, center, $gap: 10px);
                }

                .tier {
                  background-color: var(--bg-color);
                  @include border-radius-lg;
                  @include text-xs;
                  padding: 2px 6px;

                  @media screen and (min-width: $breakpointSm) {
                    @include text-sm;
                    padding: 3px 8px;
                  }
                }
              }

              .time {
                @include text-sm;
                color: $black500;
              }
            }

            .location {
              @include text-md;
              margin-top: 3px;

              @media screen and (min-width: $breakpointSm) {
                margin-top: 0px;
              }

              > img {
                width: 18px;
                height: 18px;
                vertical-align: text-top;
                margin-right: 4px;
              }
            }
          }
        }

        .progress {
          @include text-sm;
          margin-top: 10px;
          font-weight: 700;

          @media screen and (min-width: $breakpointSm) {
            @include text-lg;
            margin-top: 25px;
          }
        }

        .stars {
          height: 18px;
          margin-top: 5px;

          @media screen and (min-width: $breakpointSm) {
            height: 24px;
          }
        }

        .comment {
          @include text-sm;
          font-weight: 500;

          @media screen and (min-width: $breakpointSm) {
            @include text-lg;
          }
        }
      }
    }

    .gathering {
      text-align: center;
      overflow-x: clip;

      .header {
        max-width: 650px;
      }

      img {
        width: 100%;
        max-height: 220px;
        object-fit: cover;
        margin: 0 auto;

        @media screen and (min-width: $breakpointSm) {
          max-height: 370px;
        }
      }
    }

    .spinner-section {
      @include flex(column, center, stretch, $gap: 15px);

      .header {
        @include text-md;
        font-weight: 700;
        text-align: center;
        margin-bottom: 12px;
      }

      .spinner-block {
        @include flex(row, start, center, $gap: 15px);
        @include border-radius-md;
        background-color: $black50;
        padding: 4px 8px;
        @include text-sm;

        @media screen and (min-width: $breakpointSm) {
          padding: 8px 14px;
          @include text-md;
        }

        &.finished {
          background-color: $bilbao100;
          color: $bilbao500;
        }

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .redirect-section {
      @include flex(column, start, center, $gap: 150px);

      .teeny {
        @include text-xs;
        margin-top: 8px;
        font-weight: 300;
        color: #088bf5;
        text-align: center;
      }
    }
  }
}
</style>
