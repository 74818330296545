<template>
  <div class="i_slider-reviews">
    <iCardReview
      v-for="(item, index) in reviewsSet"
      :key="index"
      v-bind="item"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import iCardReview from "@/components/global/CardReview.vue"

// Props
const props = defineProps({
  set: {
    type: Number,
    default: 1
  }
})

// Constants
const reviewsSet1 = [
  {
    ago: "Just now",
    review:
      "This company had a detailed test that helps you determine what type of career may fit your personality. It does appear that after taking their test, the results were pretty insightful and really did seem to fit my personality type. I would recommend them for anyone who feels “stuck” and wants to find a job that makes them more excited to go to work and feel like they have purpose, can make a difference and have a fulfilling career.",
    reviewer: "Megan",
    score: 5,
    title: "This company really can help with finding purpose in your job"
  },
  {
    ago: "12 hours ago",
    review:
      "Jobtest.org is a wonderful service for beginning a great career. Not only do I now have a better idea as to who I am and what I would like to do, but Jobtest.org has given me some areas to shoot for in future careers. In addition to the test, they also offer great paid services that are really, truly useful in gaining a competitive advantage and bringing your job search into the 21st century. I met with a job coach to enhance my career search now that I'm graduating college, and I feel like I can approach finding a career in a much more strategic and intelligent way.",
    reviewer: "Kallista",
    score: 5,
    title: "Jobtest.org is for Intelligent Job Search"
  },
  {
    ago: "1 day ago",
    review:
      "Test took about 20 minutes. I had taken this test before a couple years ago but never bought a report. I regret doing that. But now that I bought the comprehensive report have a clear career goal.",
    reviewer: "Joshua",
    score: 5,
    title: "Should’ve done this years ago."
  },
  {
    ago: "2 days ago",
    review:
      "The test results were accurate. The system-generated corresponding jobs was OK. The most valuable part, for me, was working with Candace Barr. We had excellent conversation. It was kind, focused and concise. She was able to take the personality results, understand my story and validate where I am at and where I want to go. Once we synchronized, Candace then made specific recommendations for me to focus on and outlined next steps we can work on together should I chose to pay for extended career coaching with her. She is very astute and can grasp people very well. Thank you, Candace!",
    reviewer: "Scott",
    score: 5,
    title: "The test results were accurate"
  },
  {
    ago: "3 days ago",
    review:
      "I was feeling discouraged on finding a career that I am passionate about, but the aptitude test help me narrow down my passions and skill set. Not only that, but it helped me understand my strengths and weaknesses on how I can work on them to improve my performance.",
    reviewer: "Tucker Grounds",
    score: 5,
    title: "Fast and accurate results."
  },
  {
    ago: "7 days ago",
    review:
      "I think paying for a report was worth it! Having to take a test on my career path, means wanting to know what the results say for guidance. I found myself thinking that this one time payment can be worth the abundance of payment I can get afterwards if it connects me with the right job suggestions. I am glad to say, I think this will be the case :).",
    reviewer: "Charles C.",
    score: 5,
    title: "My Review :) - Pretty Good"
  }
]

const reviewsSet2 = [
  {
    ago: "1 week ago",
    review:
      "The test was very thorough and went through a variety of areas I feel are important to finding a job that matches my interests, personality, and goals. One aspect I really appreciated was the test asked me about fields I am not interested in so my results only included fields that would be relevant to my future, not fields that I have no desire to work in.",
    reviewer: "Ryan Watters",
    score: 5,
    title: "All Encompassing Aptitude Test"
  },
  {
    ago: "2 weeks ago",
    review:
      "I have used multiple career services over the past 10 years. I think this assessment test and the subsequent hour of career coaching was more helpful than any other I have used, even the much more expensive services. Michelle was absolutely wonderful in her demeanor and guidance. She's a real gem. She gave me both strategic and tactical guidance that I can implement right away. Many thanks.",
    reviewer: "Michael Mielke",
    score: 5,
    title: "Far better than most online career services"
  },
  {
    ago: "3 weeks ago",
    review:
      "Better than therapy if you feel lost on career journey and what's this 9-5 all for?! I did the test and met with Jen for an hour to discuss the results and how I could best act on the insights. Best investment I’ve made in my career. Jen’s ability to structure the conversation, provide perspective, dig into what’s needed to help you feel fulfilled, challenged and compensated for efforts is a gift. Thank you Jen!",
    reviewer: "Luann Donnelly",
    score: 5,
    title: "Career journey simplified with concrete action steps!"
  },
  {
    ago: "4 weeks ago",
    review:
      "I was looking to make a career transition so I took the test and scheduled a coaching session. Candace Barr helped me narrow down which career options would suit my personality traits. She gave me good resources to research different jobs I found interesting. And a roadmap on pursuing different career.",
    reviewer: "Phillip G",
    score: 5,
    title: "Changing Careers"
  },
  {
    ago: "1 month ago",
    review:
      "I recommend using Jobtest to anyone needing help and guidance in their careers. After taking the career test I got access to so many career resources to help guide me down a clearer path for my future. I recommend talking with one of their career coaches as well. I was fortunate enough to talk with Corey Bott about my career and he offered so much guidance and resources to me to help me decide which career path I wanted to take in my life.",
    reviewer: "Grant Glasson",
    score: 5,
    title: "Jobtest gave me clarity and direction!"
  },
  {
    ago: "2 months ago",
    review:
      "This is a great resource I stumbled across in my Google research. I've been contemplating a career shift for some time and I took the Job Test career test. I saw that there were options to review my results with a career coach and I ultimately signed up for biweekly sessions with Jen. In addition to accountability, Jen guided me to online and local resources that I wasn't aware of. Beyond that, she is so kind and supportive and has given me the confidence to pursue a career in interior design. She celebrates my progress each time we meet, which isn't something I'm naturally inclined to do. I feel like I've gained a lot of direction and stability and I hope my future job is as suited to me as Jen's is to her!",
    reviewer: "Christine",
    score: 5,
    title: "The Support I Needed"
  }
]

// Computed
const reviewsSet = computed(() => {
  return props.set === 1
    ? [...reviewsSet1, ...reviewsSet1, ...reviewsSet1]
    : [...reviewsSet2, ...reviewsSet2, ...reviewsSet2]
})
</script>

<style scoped lang="scss">
.i_slider-reviews {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  width: fit-content;
}
</style>
