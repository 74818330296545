<template>
  <div
    class="i_campaign-countdown"
    :class="{
      fixed: props.position === 'fixed',
      sticky: props.position === 'sticky'
    }"
  >
    <div class="main">
      <img
        class="logo"
        :src="logo"
        loading="lazy"
        v-if="isScreenSizeMd || !timerDisplay"
      />
      <div
        class="right"
        v-if="isScreenSizeXxs && timerDisplay"
      >
        <div class="countdown">
          <div class="text">Discount is available for:</div>
          <div class="time">{{ formattedTime }}</div>
        </div>

        <iBaseButton
          v-bind="buttonStyle"
          @click="$emit('ctaClicked')"
          text="Check Availability"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watchEffect, reactive } from "vue"
import { astronaut600, azureRadiance100, white } from "@/styles/colors"
import { breakpointMd, breakpointXxs } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"

// ASSETS
import logo from "@/assets/logo/logo-dark.svg"

// STORES AND COMPOSABLES
const isScreenSizeXxs = useIsScreenSize(breakpointXxs)
const isScreenSizeMd = useIsScreenSize(breakpointMd)

// EXPOSED
defineExpose({
  makeActive: () => {
    startTimer()
    data.isTimerActive = true
    clearTimeout(data.timerActiveTimeout)
  }
})

// PROPS
const props = defineProps({
  position: {
    type: String,
    default: "sticky",
    validator: (value: string) => {
      return ["fixed", "sticky"].includes(value)
    }
  },
  timerDelayMs: {
    type: Number,
    default: 0
  },
  timerDurationMin: {
    type: Number,
    default: 15
  }
})

// REFS
const timeRemaining = ref(props.timerDurationMin * 60)

// EMITS
defineEmits(["ctaClicked"])

// DATA
const data = reactive({
  isTimerActive: false,
  timerActiveTimeout: undefined as undefined | ReturnType<typeof setTimeout>
})

// COMPUTED
const formattedTime = computed(() => {
  const minutes = Math.floor((timeRemaining.value / 60) % 60)
  const seconds = Math.floor(timeRemaining.value % 60)

  return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
})

const timerDisplay = computed(() => {
  return timeRemaining.value > 0 && data.isTimerActive
})

const buttonStyle = computed(() => {
  return {
    backgroundColor: astronaut600,
    borderRadius: "8px",
    fontWeight: "600",
    size: isScreenSizeMd.value ? "medium" : "small",
    textColor: white,
    width: isScreenSizeMd.value ? "100%" : "fit-content",
    pulseColor: azureRadiance100
  }
})

// FUNCTIONS
function startDelayTimer(delayMs: number) {
  data.timerActiveTimeout = setTimeout(() => {
    data.isTimerActive = true
    startTimer()
  }, delayMs)
}

function startTimer() {
  const intervalId = setInterval(() => {
    timeRemaining.value--

    if (timeRemaining.value >= 0) {
      localStorage.setItem("campaign-countdown", timeRemaining.value.toString())
    }

    if (timeRemaining.value === 0) {
      clearInterval(intervalId)
    }
  }, 1000)
}

// WATCHERS
watchEffect(() => {
  const timer = localStorage.getItem("campaign-countdown")

  if (timer !== null) {
    timeRemaining.value = parseInt(timer)
  } else {
    localStorage.setItem("campaign-countdown", timeRemaining.value.toString())
  }
})

// LIFECYCLE HOOKS
onMounted(() => {
  startDelayTimer(props.timerDelayMs)
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_campaign-countdown {
  background-color: $white;
  box-shadow: 0px 10px 20px rgba(83, 83, 83, 0.2);
  width: 100%;
  z-index: $zIndexOverlay;

  &.fixed {
    position: fixed;
  }
  &.sticky {
    position: sticky;
  }

  .main {
    @include flex($direction: row, $align: center, $justify: space-around);
    gap: 16px;
    top: 0;
    padding: 13px 12px;

    .logo {
      width: 175px;
      height: 50px;
    }

    .right {
      @include flex($direction: row, $justify: space-between, $gap: 10px);
      width: 100%;

      @media screen and (min-width: $breakpointMd) {
        @include flex($direction: row, $gap: 20px);
        width: unset;
      }

      .countdown {
        @include flex($direction: column, $align: start);

        @media screen and (min-width: $breakpointXs) {
          @include flex($direction: row, $gap: 8px);
        }

        .time {
          @include display-xs;
          font-weight: 700;
        }

        .text {
          @include text-sm;
          font-weight: 500;
          width: max-content;
        }
      }
    }
  }
}
</style>
