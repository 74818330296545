<template>
  <div class="i_benchmark">
    <div>
      <div class="title">
        We benchmarked you against 3 million users in our database and are
        <span class="highlighted">{{ confidence }}% confident</span> in our recommendation.
      </div>

      <iIncomeVsSatisfactionGraph />
    </div>

    <div>
      <div class="title">
        You are currently making less than your peer group. You have the potential to earn a salary that is
        <span class="highlighted">{{ potentialPeerComparison }} than your peers</span>, with the right career path.
      </div>

      <iSalaryStatisticsGraph />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import useRandomNumber from "@/composables/useRandomNumber"

import iIncomeVsSatisfactionGraph from "@/components/welcome/IncomeVsSatisfactionGraph.vue"
import iSalaryStatisticsGraph from "@/components/welcome/SalaryStatisticsGraph.vue"

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()

// DATA
const confidence = useRandomNumber(userStore.getFullName || "Full Name", 89, 98)

// COMPUTED
const potentialPeerComparison = computed(() => reportStore.salaryStatistics?.comparisons?.potentialPeer)
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_benchmark {
  @include flex($direction: column, $gap: 80px);
  color: $black950;

  @media screen and (min-width: $breakpointMd) {
    gap: 100px;
  }

  > div {
    @include flex($direction: column, $gap: 42px);

    .title {
      text-align: center;
      @include display-xs;
      font-weight: 600;

      @media screen and (min-width: $breakpointMd) {
        font-size: 32px;
        line-height: 40px;
      }

      .highlighted {
        color: $bilbao500;
      }
    }
  }
}
</style>
