<template>
  <div id="js-qubl"></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive, ref } from "vue"

import ApiService from "@/services/apiService"
import QuizService from "@/services/quizService"

import type { QuizData } from "@/types/quizData"

// EMITS

const emit = defineEmits(["quizCompleted", "quizSuccess", "quizError"])

// PROPS

const props = defineProps({
  quizId: {
    type: String,
    required: true
  },
  quizStyle: {
    type: String,
    required: true
  }
})

// DATA

const data = reactive({
  quizData: {} as QuizData
})

// HELPER TEXT
const observer = ref<MutationObserver | null>(null)

const observeHelperText = () => {
  const qublInstance = document.querySelector("qubl-instance")?.shadowRoot
  const qublQuiz = qublInstance?.querySelector("qubl-quiz")?.shadowRoot

  if (qublQuiz && !observer.value) {
    observer.value = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const questionContainer = qublQuiz?.querySelector(".question-container")
          const helperText = qublQuiz.querySelector("#helper-text:not(#helper-text.moved)")

          if (helperText) {
            if (questionContainer) {
              helperText.classList.add("moved")
              questionContainer.appendChild(helperText)
            }
          }
        }
      }
    })

    observer.value.observe(qublQuiz, { childList: true, subtree: true })
  }
}

const unobserveHelperText = () => {
  if (observer.value) {
    observer.value.disconnect()
  }
}

// FUNCTIONS (QUBL SETUP)

function listenToQublPostback(quizId: string): void {
  document.querySelector("qubl-instance")?.addEventListener("postback", async (qublResponse) => {
    // @ts-ignore (qublResponse is not typed)
    data.quizData = qublResponse?.detail
    processQuizCompletion(data.quizData)

    // Remove quiz data in localstorage to avoid quiz retake blank screen
    localStorage.removeItem(quizId)
  })

  document.querySelector("qubl-instance")?.addEventListener("next", async () => {
    observeHelperText()
  })

  document.querySelector("qubl-instance")?.addEventListener("back", async () => {
    observeHelperText()
  })
}

// FUNCTIONS (QUIZ COMPLETION)

async function processQuizCompletion(quizData: QuizData): Promise<void> {
  // Event triggers UI update
  emit("quizCompleted")

  try {
    const { quizUid, instanceUid, answers, results } = quizData

    // Store user in Database
    const storableData = {
      ...QuizService.getNamedResults(results),
      ...QuizService.getShapeUpdatedAnswers(QuizService.getUpdatedAnswers(answers)),
      qubl_quiz_uuid: quizUid,
      qubl_instance_uuid: instanceUid
    }

    await ApiService.postQuizReportRetake(storableData)
    emit("quizSuccess")
  } catch (error) {
    emit("quizError", error)
  }
}

// Lifecycle
onMounted(() => {
  QuizService.loadQublBundle()
  QuizService.loadQublInstance(props.quizId, props.quizStyle)
  listenToQublPostback(props.quizId)
})

onUnmounted(() => {
  unobserveHelperText()
})
</script>
