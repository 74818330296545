import { GlobalVariables } from "@/helpers/globalVariables"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized } from "vue-router"

import Error from "@/views/Error.vue"
import Login from "@/views/Login.vue"
import ThankYou from "@/views/ThankYou.vue"

import Magic from "@/views/auth/Magic.vue"

import JobTest from "@/views/jobTest/JobTest.vue"
import CoachingQuiz from "@/views/jobTest/QuizCoaching.vue"

import Loading from "@/views/loading/Loading.vue"
import PleaseWait from "@/views/loading/PleaseWait.vue"
import Processing from "@/views/loading/Processing.vue"

import Begin from "@/views/preview/Begin.vue"
import Discover from "@/views/preview/Discover.vue"
import GetStarted from "@/views/preview/GetStarted.vue"
import Hello from "@/views/preview/Hello.vue"
import Start from "@/views/preview/Start.vue"
import Welcome from "@/views/preview/Welcome.vue"

import Report from "@/views/report/Report.vue"
import SectionFeedback from "@/views/report/sections/Feedback.vue"
import SectionCareers from "@/views/report/sections/Careers.vue"
import SectionNewCareers from "@/views/report/sections/GetNewCareers.vue"
import SectionHome from "@/views/report/sections/Home.vue"
import SectionOutdated from "@/views/report/sections/Outdated.vue"
import SectionProfile from "@/views/report/sections/Profile.vue"
import SectionCheckout from "@/views/report/sections/ReportCheckout.vue"
import SectionTestAdjustment from "@/views/report/sections/TestAdjustment.vue"
import SectionTestRetake from "@/views/report/sections/TestRetake.vue"

import Coaching from "@/views/coaching/Coaching.vue"
import CoachingHome from "@/views/coaching/sections/CoachingHome.vue"
import CoachingLoading from "@/views/coaching/sections/CoachingLoading.vue"
import Confirmation from "@/views/coaching/sections/Confirmation.vue"
import SelectCoach from "@/views/coaching/sections/SelectCoach.vue"
import SelectPlan from "@/views/coaching/sections/SelectPlan.vue"

import CampaignV1 from "@/views/lifecycle/sections/CampaignV1.vue"
import CampaignV2 from "@/views/lifecycle/sections/CampaignV2.vue"
import CampaignV3 from "@/views/lifecycle/sections/CampaignV3.vue"
import LifeCycle from "@/views/lifecycle/LifeCycle.vue"

declare module "vue-router" {
  interface RouteMeta {
    onUnauthenticatedUser: Function | null // Executed when the user is not authenticated
    onFinishedUser: Function | null // Executed when the user is authenticated and has finished status
    onFullUser: Function | null // Executed when the user is authenticated and has full status
    title: string
  }
}

// Constants
const COACHING_TITLE = "Career Coaching - JobTest.org"
const SELECT_COACH_TITLE = "We Found Your Perfect Match! - JobTest.org"
const COACHING_LOADING_TITLE = "Finding you the perfect coach... - JobTest.org"
const DEFAULT_TITLE = "Career Test - JobTest.org"
const ERROR_TITLE = "Error - JobTest.org"
const LIFECYCLE_TITLE = "A Video For You - JobTest.org"
const LOADING_TITLE = "Loading Test Results - JobTest.org"
const LOGIN_TITLE = "Login - JobTest.org"
const PREVIEW_TITLE = "Career Test Preview - JobTest.org"
const QUIZ_TITLE = "Take the Job Test - JobTest.org"
const REPORT_TITLE = "Career Test Report - JobTest.org"
const THANK_YOU_TITLE = "Thank You - JobTest.org"

// Variable used to reduce code verbosity
const AUTH_SECTIONS = GlobalVariables.urls.auth.children
const COACHING_SECTIONS = GlobalVariables.urls.coaching.children
const REPORT_SECTIONS = GlobalVariables.urls.report.children
const LIFECYCLE_SECTIONS = GlobalVariables.urls.lifecycle.children
const SECTIONS = GlobalVariables.urls

// Default redirections
const REDIRECT_COACHING_HOME = COACHING_SECTIONS.home.routeName
const REDIRECT_COACHING_QUIZ = COACHING_SECTIONS.quiz.routeName
const REDIRECT_COACHING_SELECT_COACH = COACHING_SECTIONS.selectCoach.routeName
const REDIRECT_LOGIN_PAGE = SECTIONS.login.routeName
const REDIRECT_REPORT_PAGE = REPORT_SECTIONS.home.routeName
const REDIRECT_UNAUTHENTICATED = SECTIONS.login.routeName
const REDIRECT_WELCOME_PAGE = SECTIONS.welcome.routeName

// Definition of Vue Routes
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: SECTIONS.root.path,
      name: SECTIONS.root.routeName,
      component: Login, // Required as we are using custom redirection
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: LOGIN_TITLE
      }
    },
    // TODO: Move login into auth section ?
    {
      path: SECTIONS.login.path,
      name: SECTIONS.login.routeName,
      component: Login,
      meta: {
        onUnauthenticatedUser: null,
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: LOGIN_TITLE
      }
    },
    {
      path: SECTIONS.auth.path,
      children: [
        {
          path: SECTIONS.auth.path + AUTH_SECTIONS.magic.path,
          name: AUTH_SECTIONS.magic.routeName,
          component: Magic,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            title: DEFAULT_TITLE
          }
        }
      ]
    },
    {
      path: SECTIONS.error.path,
      name: SECTIONS.error.routeName,
      component: Error,
      meta: {
        onUnauthenticatedUser: null,
        onFinishedUser: null,
        onFullUser: null,
        title: ERROR_TITLE
      }
    },
    {
      path: SECTIONS.thankYou.path,
      name: SECTIONS.thankYou.routeName,
      component: ThankYou,
      meta: {
        onUnauthenticatedUser: null,
        onFinishedUser: null,
        onFullUser: null,
        title: THANK_YOU_TITLE
      }
    },
    // ***
    // Quiz Routes
    // ***
    {
      path: SECTIONS.jobTest.path,
      name: SECTIONS.jobTest.routeName,
      component: JobTest,
      meta: {
        onUnauthenticatedUser: null,
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: QUIZ_TITLE
      }
    },
    {
      path: SECTIONS.jobQuiz.path,
      name: SECTIONS.jobQuiz.routeName,
      component: JobTest,
      meta: {
        onUnauthenticatedUser: null,
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: QUIZ_TITLE
      }
    },
    // ***
    // Loading Animation (Pre-Paywall) Routes
    // ***
    {
      path: SECTIONS.loading.path,
      name: SECTIONS.loading.routeName,
      component: Loading,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: LOADING_TITLE
      }
    },
    {
      path: SECTIONS.pleaseWait.path,
      name: SECTIONS.pleaseWait.routeName,
      component: PleaseWait,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: LOADING_TITLE
      }
    },
    {
      path: SECTIONS.processing.path,
      name: SECTIONS.processing.routeName,
      component: Processing,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: LOADING_TITLE
      }
    },
    // ***
    // Preview (Paywall) Routes
    // ***
    {
      path: SECTIONS.welcome.path,
      name: SECTIONS.welcome.routeName,
      component: Welcome,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: PREVIEW_TITLE
      }
    },
    {
      path: SECTIONS.hello.path,
      name: SECTIONS.hello.routeName,
      component: Hello,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: PREVIEW_TITLE
      }
    },
    {
      path: SECTIONS.begin.path,
      name: SECTIONS.begin.routeName,
      component: Begin,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: PREVIEW_TITLE
      }
    },
    {
      path: SECTIONS.discover.path,
      name: SECTIONS.discover.routeName,
      component: Discover,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: PREVIEW_TITLE
      }
    },
    {
      path: SECTIONS.start.path,
      name: SECTIONS.start.routeName,
      component: Start,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: PREVIEW_TITLE
      }
    },
    {
      path: SECTIONS.getStarted.path,
      name: SECTIONS.getStarted.routeName,
      component: GetStarted,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserReportAccess,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: PREVIEW_TITLE
      }
    },
    // ***
    // Coaching Routes
    // ***
    {
      path: SECTIONS.coaching.path,
      name: SECTIONS.coaching.routeName,
      component: Coaching, // Required as we are using custom redirection
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_COACHING_HOME }),
        title: COACHING_TITLE
      },
      children: [
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.home.path,
          name: COACHING_SECTIONS.home.routeName,
          component: CoachingHome,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: handleFullUserCoachingAccess,
            title: QUIZ_TITLE
          }
        },
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.quiz.path,
          name: COACHING_SECTIONS.quiz.routeName,
          component: CoachingQuiz,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: () => router.push({ name: REDIRECT_COACHING_HOME }),
            title: COACHING_TITLE
          }
        },
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.loading.path,
          name: COACHING_SECTIONS.loading.routeName,
          component: CoachingLoading,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserCoachingAccess,
            onFullUser: () => router.push({ name: REDIRECT_COACHING_HOME }),
            title: COACHING_LOADING_TITLE
          }
        },
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.selectCoach.path,
          name: COACHING_SECTIONS.selectCoach.routeName,
          component: SelectCoach,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: COACHING_SECTIONS.selectCoach.routeTitle,
            title: SELECT_COACH_TITLE
          }
        },
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.selectTime.path,
          redirect: SECTIONS.coaching.path + COACHING_SECTIONS.selectCoach.path
        },
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.selectPlan.path,
          name: COACHING_SECTIONS.selectPlan.routeName,
          component: SelectPlan,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: COACHING_SECTIONS.selectPlan.routeTitle,
            title: COACHING_TITLE
          }
        },
        {
          path: SECTIONS.coaching.path + COACHING_SECTIONS.confirmation.path,
          name: COACHING_SECTIONS.confirmation.routeName,
          component: Confirmation,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: COACHING_SECTIONS.confirmation.routeTitle,
            title: COACHING_TITLE
          }
        }
      ]
    },
    // ***
    // Report Routes
    // ***
    {
      path: SECTIONS.report.path,
      name: SECTIONS.report.routeName,
      component: Report,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: REPORT_TITLE
      },
      children: [
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.home.path,
          name: REPORT_SECTIONS.home.routeName,
          component: SectionHome,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: handleFullUserReportAccess,
            sectionTitle: null,
            title: REPORT_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.welcome.path,
          redirect: SECTIONS.report.path + REPORT_SECTIONS.home.path
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.profile.path,
          name: REPORT_SECTIONS.profile.routeName,
          component: SectionProfile,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: handleFullUserReportAccess,
            sectionTitle: REPORT_SECTIONS.profile.routeTitle,
            title: REPORT_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.careers.path + "/:jobId",
          name: REPORT_SECTIONS.careers.routeName,
          component: SectionCareers,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: handleFullUserReportAccess,
            sectionTitle: REPORT_SECTIONS.careers.routeTitle,
            title: REPORT_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.newCareers.path,
          name: REPORT_SECTIONS.newCareers.routeName,
          component: SectionNewCareers,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: (to: any, next: any) => {
              useUserStore().isTierFree
                ? router.push({ name: REDIRECT_REPORT_PAGE })
                : handleFullUserReportAccess(to, next)
            },
            sectionTitle: null,
            title: REPORT_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.selectCoach.path,
          redirect: SECTIONS.coaching.path + COACHING_SECTIONS.selectCoach.path
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.testRetake.path,
          name: REPORT_SECTIONS.testRetake.routeName,
          component: SectionTestRetake,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: (to: any, next: any) => {
              useReportStore().isReportOutdated || useUserStore().isCoachingOnlyUser
                ? next()
                : useUserStore().isTierFree
                ? router.push({ name: REDIRECT_REPORT_PAGE })
                : handleFullUserReportAccess(to, next)
            },
            isFullScreen: true, // When set, only the component will be rendered
            sectionTitle: null,
            title: QUIZ_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.testAdjustment.path,
          name: REPORT_SECTIONS.testAdjustment.routeName,
          component: SectionTestAdjustment,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: (to: any, next: any) => {
              useUserStore().isTierFree
                ? router.push({ name: REDIRECT_REPORT_PAGE })
                : handleFullUserReportAccess(to, next)
            },
            sectionTitle: null,
            title: QUIZ_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.checkout.path,
          name: REPORT_SECTIONS.checkout.routeName,
          component: SectionCheckout,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserReportAccess,
            onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
            isFullScreen: true, // When set, only the component will be rendered
            sectionTitle: null,
            title: REPORT_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.outdated.path,
          name: REPORT_SECTIONS.outdated.routeName,
          component: SectionOutdated,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: (_to: any, next: any) => {
              useReportStore().isReportOutdated && !localStorage.getItem("hasSkippedOutdatedRetake")
                ? next()
                : router.push({ name: REDIRECT_UNAUTHENTICATED })
            },
            isFullScreen: true, // When set, only the component will be rendered
            sectionTitle: null,
            title: REPORT_TITLE
          }
        },
        {
          path: SECTIONS.report.path + REPORT_SECTIONS.feedback.path,
          name: REPORT_SECTIONS.feedback.routeName,
          component: SectionFeedback,
          meta: {
            onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
            onFinishedUser: handleFinishedUserRedirect,
            onFullUser: (_to: any, next: any) => {
              useUserStore().isFeedbackExperienceCompleted ? router.push({ name: REDIRECT_REPORT_PAGE }) : next()
            },
            isFullScreen: true, // When set, only the component will be rendered
            sectionTitle: REPORT_SECTIONS.feedback.routeTitle,
            title: REPORT_TITLE
          }
        }
      ]
    },
    // ***
    // Lifecycle Video Routes
    // ***
    {
      path: SECTIONS.lifecycle.path,
      name: SECTIONS.lifecycle.routeName,
      component: LifeCycle,
      meta: {
        onUnauthenticatedUser: () => router.push({ name: REDIRECT_UNAUTHENTICATED }),
        onFinishedUser: handleFinishedUserRedirect,
        onFullUser: () => router.push({ name: REDIRECT_REPORT_PAGE }),
        title: LIFECYCLE_TITLE
      },
      children: [
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutz60.path,
          name: LIFECYCLE_SECTIONS.lutz60.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutz100.path,
          name: LIFECYCLE_SECTIONS.lutz100.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutzrcs1.path,
          name: LIFECYCLE_SECTIONS.lutzrcs1.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutzrcs2.path,
          name: LIFECYCLE_SECTIONS.lutzrcs2.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutzrcs3.path,
          name: LIFECYCLE_SECTIONS.lutzrcs3.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutzrcs1p.path,
          name: LIFECYCLE_SECTIONS.lutzrcs1p.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutzrcs2p.path,
          name: LIFECYCLE_SECTIONS.lutzrcs2p.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutzrcs3p.path,
          name: LIFECYCLE_SECTIONS.lutzrcs3p.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutz60b.path,
          name: LIFECYCLE_SECTIONS.lutz60b.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutz100b.path,
          name: LIFECYCLE_SECTIONS.lutz100b.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.lutz100c.path,
          name: LIFECYCLE_SECTIONS.lutz100c.routeName,
          component: CampaignV3,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlo60.path,
          name: LIFECYCLE_SECTIONS.marlo60.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlo100.path,
          name: LIFECYCLE_SECTIONS.marlo100.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlorcs1.path,
          name: LIFECYCLE_SECTIONS.marlorcs1.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlorcs2.path,
          name: LIFECYCLE_SECTIONS.marlorcs2.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlorcs3.path,
          name: LIFECYCLE_SECTIONS.marlorcs3.routeName,
          component: CampaignV1,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlorcs1p.path,
          name: LIFECYCLE_SECTIONS.marlorcs1p.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlorcs2p.path,
          name: LIFECYCLE_SECTIONS.marlorcs2p.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        },
        {
          path: SECTIONS.lifecycle.path + LIFECYCLE_SECTIONS.marlorcs3p.path,
          name: LIFECYCLE_SECTIONS.marlorcs3p.routeName,
          component: CampaignV2,
          meta: {
            onUnauthenticatedUser: null,
            onFinishedUser: null,
            onFullUser: null,
            sectionTitle: null,
            title: LIFECYCLE_TITLE
          }
        }
      ]
    },
    {
      // Catch all route
      path: "/:pathMatch(.*)*",
      redirect: REDIRECT_LOGIN_PAGE
    }
  ]
})

// ***
// Router Guards
// ***

router.beforeEach((to, _from, next) => {
  // Set the document title
  document.title = to.meta?.title ?? DEFAULT_TITLE

  const userStore = useUserStore()
  !userStore.isReportUser && !userStore.isCoachingUser ? onUnauthenticatedUser(to, next) : onAuthenticatedUser(to, next)
})

// ***
// Helper functions
// ***

async function onAuthenticatedUser(to: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()
  !!userStore.isTierFinished ? onFinishedUser(to, next) : onFullUser(to, next)

  // Handle Zigpoll
  handleZigpoll(to)
}

function onUnauthenticatedUser(to: RouteLocationNormalized, next: NavigationGuardNext) {
  return to.meta?.onUnauthenticatedUser === null ? next() : to.meta?.onUnauthenticatedUser()
}

function onFinishedUser(to: RouteLocationNormalized, next: NavigationGuardNext) {
  return to.meta?.onFinishedUser === null ? next() : to.meta?.onFinishedUser(to, next)
}

function onFullUser(to: RouteLocationNormalized, next: NavigationGuardNext) {
  // Routes with ':jobId' param are handled differently
  if ("jobId" in to.params) return handleJobIdParam(to, next)

  // Execute default route processing if the route does not have ':jobId'
  if (to.meta?.onFullUser === null) {
    return next()
  }

  return to.meta?.onFullUser(to, next)
}

function handleFinishedUserRedirect() {
  const userStore = useUserStore()

  if (userStore.isReportUser) router.push({ name: REDIRECT_WELCOME_PAGE })
  else if (userStore.isCoachingUser) router.push({ name: REDIRECT_COACHING_SELECT_COACH })
  else router.push({ name: REDIRECT_COACHING_QUIZ })
}

/**
 * Only allow full users who have completed the report quiz to access.
 *
 * If the report is outdated and they haven't skipped reportOutdated retake yet, redirect to the outdated page.
 */
function handleFullUserReportAccess(_to: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()
  const reportStore = useReportStore()

  if (userStore.isReportUser) {
    // Redirect to the outdated page if the report is outdated
    if (reportStore.isReportOutdated && !localStorage.getItem("hasSkippedOutdatedRetake")) {
      return router.push({ name: REPORT_SECTIONS.outdated.routeName })
    }

    // Redirect to the feedback page if the user has not completed the feedback
    if (!userStore.isFeedbackExperienceCompleted) {
      return router.push({ name: REPORT_SECTIONS.feedback.routeName })
    }

    // Otherwise, allow access to the report
    return next()
  } else if (userStore.isCoachingUser) {
    return router.push({ name: REDIRECT_COACHING_HOME })
  } else {
    return router.push({ name: REDIRECT_UNAUTHENTICATED })
  }
}

function handleFinishedUserReportAccess(_to: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()

  if (userStore.isReportUser) {
    return next()
  } else if (userStore.isCoachingUser) {
    return router.push({ name: REDIRECT_COACHING_SELECT_COACH })
  } else {
    return router.push({ name: REDIRECT_UNAUTHENTICATED })
  }
}

/**
 * Only allow full users who have completed the coaching quiz to access
 */
function handleFullUserCoachingAccess(_to: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()

  if (userStore.isCoachingUser) {
    return next()
  } else if (userStore.isReportUser) {
    return router.push({ name: REDIRECT_REPORT_PAGE })
  } else {
    return router.push({ name: REDIRECT_UNAUTHENTICATED })
  }
}

function handleFinishedUserCoachingAccess(_to: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()

  if (userStore.isCoachingUser) return next()
  else if (userStore.isReportUser) return router.push({ name: REDIRECT_WELCOME_PAGE })
  else return router.push({ name: REDIRECT_UNAUTHENTICATED })
}

/**
 * Handle the 'jobId' parameter in the URL
 */
function handleJobIdParam(to: RouteLocationNormalized, next: NavigationGuardNext) {
  // Ensure the 'jobId' parameter is a number
  const idToParse = typeof to.params.jobId === "string" ? to.params.jobId : to.params.jobId?.[0]
  const jobIdParam = parseInt(idToParse, 10) // Ensure safe access to params

  const reportStore = useReportStore()

  // Check if the jobIdParam exists in the recommended jobs
  const hasRecommendedJobId = reportStore.hasRecommendedJobId(jobIdParam)

  // Make sure the jobIdParam is valid, otherwise use the default jobId
  const jobId =
    typeof jobIdParam !== "number" || !hasRecommendedJobId
      ? reportStore.featuredJobId
      : useReportStore().setActiveJob(jobIdParam)

  if (!jobId || isNaN(jobId) || jobId === 0) {
    reportStore.notifyIfNoJobs()
    return next({ name: REPORT_SECTIONS.newCareers.routeName })
  }

  // Do not change the route if the correct jobId is already set
  if (jobId === jobIdParam) return next()

  // Redirect to the correct route with the jobId
  if (typeof to.name === "string") {
    return next({
      name: to.name,
      params: { jobId: jobId.toString() }
    })
  }
}

function handleZigpoll(to: RouteLocationNormalized) {
  const reportStore = useReportStore()

  if (sessionStorage.getItem("free-upgrade-report-zigpoll-loaded") || reportStore.reportZigpollLoaded) return

  if (String(to.name).startsWith("report")) {
    reportStore.incrementPageVisits()
  }
  if (reportStore.pageVisits === 8) {
    reportStore.loadZigpoll()
    reportStore.renderZigpoll(reportStore.getReportZigpollId)
    if (reportStore.isFreeUpgradeEligible) sessionStorage.setItem("free-upgrade-report-zigpoll-loaded", "true")
  }
}

export default router
