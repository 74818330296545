export function capitalizeWords(text: string) {
  // Split the string into an array of words
  const words = text.toLowerCase().split(" ")

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))

  // Join the words back into a single string
  const result = capitalizedWords.join(" ")

  return result
}
