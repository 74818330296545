<template>
  <div class="i_campaign-review">
    <div>
      <div class="caption">Hear from others who experienced it</div>
      <div
        v-if="props.version === 2"
        class="info"
      >
        Our coaches are changing people's lives, literally. Join the transformation.
      </div>
    </div>
    <div
      class="reviews"
      v-if="props.version === 1"
    >
      <div class="review">
        <div class="info"><img :src="imageChirita" /><strong>Chirita</strong> (40, Recruiter)</div>
        <div class="salary">Increased salary from $40K <strong>to $120K</strong></div>
        <img
          class="stars"
          :src="iconStars"
        />
        <div class="comment">
          I met {{ props.coach }} almost 5 years ago, and she has been my mentor, coach, and a friend. She changed my
          professional life and is the reason I can spend more time with my family.
        </div>
      </div>
      <div class="review">
        <div class="info"><img :src="imageYoonmi" /><strong>Yoon Mi</strong> (29, Pilates Instructor)</div>
        <div class="salary">Increased salary from $70K <strong>to $110K</strong></div>
        <img
          class="stars"
          :src="iconStars"
        />
        <div class="comment">
          I was struggling to market myself because I had dropped out of college. Working with a dedicated coach to
          highlight my strengths and mask my weaknesses helped me reach my full potential.
        </div>
      </div>
    </div>
    <div
      class="rotating-reviews"
      v-if="props.version === 2"
    >
      <iBaseAnimatedSlider
        sliding-direction="right"
        animation-duration-secs="210"
      >
        <iSliderReviews :set="1" />
      </iBaseAnimatedSlider>

      <iBaseAnimatedSlider
        sliding-direction="right"
        animation-duration-secs="140"
      >
        <iSliderReviews :set="2" />
      </iBaseAnimatedSlider>
    </div>
    <div class="cta">
      <iBaseButton
        v-bind="buttonStyle"
        :text="props.version === 2 ? `Check ${props.coach}'s Recommendation` : 'Check Availability'"
        @click="$emit('ctaClicked')"
      />
      <div class="slots">
        <div class="pulse-circle"></div>
        There are only <strong>{{ openSlots }} slots</strong> remaining
      </div>
    </div>
    <div class="money-back">
      <div class="header"><img :src="iconSheild" />100% Money-Back Guarantee</div>
      <div class="info">
        If you are unhappy with {{ props.version === 2 ? "our coaching session" : "my session" }},<br />{{
          props.version === 2 ? "we" : "I"
        }}
        will refund your money. No question asked.
      </div>
      <div class="copyright">Copyright ® 2024 Jobtest.org.<br />All Rights Reserved</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { breakpointMd } from "@/styles/variables"
import { astronaut600, azureRadiance100, white } from "@/styles/colors"

import useIsScreenSize from "@/composables/useIsScreenSize"
import useRandomNumber from "@/composables/useRandomNumber"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseAnimatedSlider from "@/components/base/BaseAnimatedSlider.vue"
import iSliderReviews from "@/components/global/SliderReviews.vue"

import imageChirita from "@/assets/lifecycle/chirita.png"
import imageYoonmi from "@/assets/lifecycle/yoonmi.png"
import iconStars from "@/assets/icons/icon-five-stars.svg"
import iconSheild from "@/assets/icons/icon-shield-tick.svg"
import { useUserStore } from "@/stores/user"

// STORES
const userStore = useUserStore()

// EMITS
defineEmits(["ctaClicked"])

const props = defineProps({
  coach: {
    type: String,
    required: true
  },
  version: {
    type: Number,
    default: 1
  }
})

//COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)
const openSlots = useRandomNumber(userStore.getFullName || "Full Name", 3, 5)

// COMPUTED
const buttonStyle = computed(() => {
  return {
    backgroundColor: astronaut600,
    borderRadius: "8px",
    fontWeight: "600",
    size: isDesktop.value ? "medium" : "small",
    textColor: white,
    width: "100%",
    pulseColor: azureRadiance100
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_campaign-review {
  @include flex($direction: column, $gap: 20px);
  margin-top: 40px;

  @media screen and (min-width: $breakpointSm) {
    gap: 40px;
  }

  .caption {
    @include display-sm;
    font-weight: 700;

    @media screen and (min-width: $breakpointSm) {
      text-align: center;
    }

    @media screen and (min-width: $breakpointMd) {
      @include display-lg;
    }
  }

  .info {
    @include text-sm;
    font-weight: 500;
    color: $black500;
    margin-top: 5px;

    @media screen and (min-width: $breakpointSm) {
      @include text-lg;
      margin-top: 10px;
      text-align: center;
    }
  }

  .reviews {
    @include flex($direction: column, $gap: 15px);

    @media screen and (min-width: $breakpointMd) {
      @include flex($direction: row, $align: stretch, $gap: 20px);
    }

    .review {
      @include flex($direction: column, $justify: start, $align: start, $gap: 5px);
      background-color: $black50;
      @include border-radius-sm;
      padding: 15px;
      width: 100%;

      @media screen and (min-width: $breakpointSm) {
        padding: 22px 25px;
        @include border-radius-xl;
      }

      .info {
        @include text-md;
        font-weight: 400;

        @media screen and (min-width: $breakpointSm) {
          @include text-xl;
        }

        > img {
          width: 32px;
          height: 32px;
          @include border-radius-rounded;
          margin-right: 10px;

          @media screen and (min-width: $breakpointSm) {
            width: 48px;
            height: 48px;
          }
        }
      }

      .salary {
        @include text-xs;
        font-weight: 400;

        @media screen and (min-width: $breakpointSm) {
          @include text-md;
        }
      }

      .stars {
        height: 18px;

        @media screen and (min-width: $breakpointSm) {
          height: 24px;
        }
      }

      .comment {
        @include text-sm;
        font-weight: 500;
        margin-top: 25px;

        @media screen and (min-width: $breakpointSm) {
          @include text-lg;
        }
      }
    }
  }

  .rotating-reviews {
    width: 100vw;
  }

  .cta {
    width: 100%;
    max-width: 500px;
    margin: auto;

    .slots {
      @include flex(row, $gap: 6px);
      width: 85%;
      text-align: center;
      margin: 15px auto 0 auto;

      @media screen and (min-width: $breakpointSm) {
        width: 70%;
      }

      .pulse-circle {
        width: 10px;
        height: 10px;
        @include border-radius-rounded;
        background-color: $sorbus500;
        position: relative;

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
          }
          50% {
            box-shadow: 0 0 0 5px rgba(255, 146, 10, 0.2);
          }
          100% {
            box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
          }
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          @include border-radius-rounded;
          z-index: 1;
          animation: pulse 2s forwards infinite;
          animation-timing-function: linear;
        }
      }
    }
  }

  .money-back {
    @include flex($direction: column, $gap: 24px);
    background-color: $azureRadiance100;
    padding: 15px;
    text-align: center;
    width: calc(100% + 20px);

    @media screen and (min-width: $breakpointMd) {
      padding: 35px 25px;
      @include border-radius-lg;
      width: 100%;
    }

    .header {
      @include flex($direction: column, $gap: 8px);
      @include text-lg;
      font-weight: 700;

      @media screen and (min-width: $breakpointMd) {
        gap: 12px;
        @include display-sm;
      }
    }

    .info {
      @include text-sm;
      font-weight: 500;

      @media screen and (min-width: $breakpointMd) {
        @include text-lg;
      }
    }

    .copyright {
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      color: $black500;

      @media screen and (min-width: $breakpointMd) {
        @include text-xs;
        font-weight: 400;
      }
    }
  }
}
</style>
