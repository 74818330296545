<template>
  <div
    class="i_announcement-bar"
    :class="props.version"
  >
    <img
      class="logo"
      loading="lazy"
      alt="Jobtest Logo"
      :src="logo"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import logoWhite from "@/assets/logo/logo-white.svg"
import logoDark from "@/assets/logo/logo-dark.svg"

// Props
const props = defineProps({
  version: {
    type: String,
    default: "dark",
    validator: (value: string) => value === "light" || value === "dark"
  }
})

// Computed
const logo = computed(() => {
  return props.version === "light" ? logoDark : logoWhite
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_announcement-bar {
  @include flex($direction: row, $justify: center, $align: center);
  height: 50px;
  z-index: $zIndexHeader;

  &.light {
    background-color: $white;
  }
  &.dark {
    background-color: $black;
  }

  .logo {
    height: 28px;
  }

  @media screen and (min-width: $breakpointMd) {
    height: 60px;
  }
}
</style>
