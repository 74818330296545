<template>
  <div class="i_professional-bio">
    <iBaseHeader
      highlight-text="Your Professional Bio"
      :font-size-highlighted-text="headerSize"
      text=""
      align="start"
    />
    <div
      class="professional-bio-column"
      v-if="bioSummary"
    >
      {{ bioSummary }}
    </div>
    <div
      class="descriptors"
      v-if="descriptors.length >= 1"
    >
      <div class="caption">{{ descriptorCaption }}</div>
      <div class="bubble-row">
        <template v-for="descriptor in descriptors">
          <div class="descriptor">{{ descriptor }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { breakpointSm } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseHeader from "@/components/base/BaseHeader.vue"

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const userStore = useUserStore()
const isNotMobile = useIsScreenSize(breakpointSm)

// COMPUTED
const headerSize = computed(() => (isNotMobile.value ? "36px" : "32px"))

const descriptorCaption = computed(() =>
  userStore.getFullName ? `${userStore.getFullName}'s Descriptors` : "Your Descriptors"
)

const bioSummary = computed(() => reportStore.getBio ?? "Your professional bio is coming Soon...")

const descriptors = computed(() => reportStore.getDescriptors)
</script>

<style lang="scss" scoped>
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";
@import "@/styles/mixins.scss";

.i_professional-bio {
  @include grid($cols: 1fr, $align-items: center, $gap: 20px);
  margin-top: 45px;

  @media screen and (min-width: $breakpointMd) {
    padding: 0;
  }

  .professional-bio-column {
    @include text-sm;
    font-weight: 500;
    text-align: left;
    color: $comet600;
  }

  .descriptors {
    @include grid($cols: 1fr, $rows: auto, $gap: 8px);
    background-color: $white;
    border: 1px solid $zumthor100;
    @include border-radius-lg;
    padding: 32px;
    max-width: 720px;

    .caption {
      @include text-xs;
      font-weight: 600;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 8px;
      z-index: 2;
    }

    .bubble-row {
      @include flex(row, $justify: start, $align: center, $gap: 12px);
      margin-top: 5px;
      flex-flow: wrap;

      .descriptor {
        padding: 8px 16px;
        @include border-radius-lg;
        @include text-sm;
        font-weight: 500;
        background-color: $pickleBluewood100;
        color: $pickleBluewood900;
      }
    }
  }
}
</style>
