<template>
  <div class="i_background">
    <div
      :class="`container ${backgroundImage ? 'image-background' : ''}`"
      :style="backgroundStyle"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { black50 } from "@/styles/colors"

const props = defineProps({
  color: {
    type: String,
    default: black50
  },
  backgroundImage: {
    type: String,
    default: undefined
  },
  imagePosition: {
    type: String,
    default: "bottom right"
  }
})

// CONSTANTS
const backgroundStyle = {
  backgroundColor: props.color,
  "--bg-image": `url(${props.backgroundImage})`,
  "--bg-pos": props.imagePosition
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/variables.scss";

.i_background {
  z-index: $zIndexBackground;
  position: fixed;
  width: 100vw;
  height: 100vh;

  .container {
    width: 100%;
    height: 100%;
  }

  .image-background {
    width: 100%;
    height: 100%;
    background-image: var(--bg-image);
    background-position: var(--bg-pos);
    background-size: cover;
  }
}
</style>
