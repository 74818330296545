<template>
  <div class="i_career-navigator-mobile">
    <div
      class="backdrop"
      v-if="!isMinimized"
      @click="toggleMinimized"
    />

    <div
      class="wrap"
      :class="{ lite: isMinimized }"
    >
      <iOnboardingTooltip :value="2" />
      <div
        v-if="activeCareer && isMinimized"
        @click.stop="toggleMinimized"
      >
        <iCareerCard
          :career="activeCareer"
          show-chevron
        />
      </div>

      <div
        class="expanded"
        v-if="!isMinimized"
      >
        <img
          class="icon-close"
          :src="iconClose"
          @click="toggleMinimized"
        />

        <div class="header">
          <h3 class="title">Your top careers</h3>
        </div>
        <iCareerNavigator
          @open-modal-payment="emit('open-modal-payment')"
          @career-clicked="toggleMinimized"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"

import { useReportStore } from "@/stores/report"

import iCareerCard from "@/components/report/CareerCard.vue"
import iCareerNavigator from "@/components/report/CareerNavigator.vue"
import iOnboardingTooltip from "@/components/report/OnboardingTooltip.vue"

import iconClose from "@/assets/icons/icon-close.svg"

// STORE
const reportStore = useReportStore()

// EMITS
const emit = defineEmits(["open-modal-payment"])

// REFS
const isMinimized = ref(true)

// COMPUTED
const careers = computed(() => reportStore.getRecommendedJobs)
const activeCareer = computed(() => careers.value?.find((career) => career.id === reportStore.activeJobId))

// FUNCTIONS
const toggleMinimized = () => {
  isMinimized.value = !isMinimized.value
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";

.i_career-navigator-mobile {
  width: 100%;
  z-index: $zIndexNavbar;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .wrap {
    width: 100%;
    padding-top: 24px;
    background-color: $white;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    &.lite {
      border-top: 1px solid $zumthor100;
      background-color: $white;
      padding: 12px;
    }

    .expanded {
      padding: 0px 24px 24px 24px;

      height: 70vh;
      overflow-y: auto;
      overscroll-behavior: contain;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;

      .icon-close {
        width: 24px;
        height: 24px;
        cursor: pointer;

        position: absolute;
        top: 16px;
        right: 16px;
      }

      .header {
        margin-bottom: 10px;

        .title {
          @include text-md;
          font-weight: 700;
          color: $pickleBluewood900;
          margin: 0;
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    opacity: 0.5;
    z-index: -1;
  }
}
</style>
