<template>
  <div class="i_welcome">
    <iBackground :color="white" />

    <template v-if="reportStore.invalidData">
      <iUnavailableCard
        text="Please try again later. If the issue persists please contact us at support@jobtest.org"
        title="Report unavailable"
      />
    </template>

    <template v-else>
      <iBaseModal
        visible
        class="zigpoll-modal"
        :class="{ visible: data.zigpollModalVisible }"
        @clickOnClose="data.zigpollModalVisible = false"
      >
        <div class="zigpoll-modal-wrap">
          <div class="zigpoll-modal-text">You can close this modal now!</div>
          <div id="zigpoll-embed-target"></div>
        </div>
      </iBaseModal>

      <iBaseModal
        :visible="data.showRefundPolicyModal"
        @click-on-close="data.showRefundPolicyModal = false"
        fully-customisable
      >
        <iRefundPolicyInModal />
      </iBaseModal>

      <div class="dashboard">
        <iAnnouncementBar />

        <iReportCountdown
          :timerDurationMin="data.timerDurationMin"
          @scrollToPaymentTable="scrollToPaymentTable"
        />

        <div class="content">
          <section>
            <iCareerRoadmap @scrollToPaymentTable="scrollToPaymentTable" />
          </section>

          <section>
            <iBenchmark />
          </section>

          <section>
            <iElevateCareer />
          </section>

          <section>
            <iCustomerReviews />
          </section>

          <section>
            <iReportComparison />
          </section>

          <section>
            <iPaymentOptions />
          </section>

          <section>
            <iMoneyBackGuarantee @show-refund-policy="data.showRefundPolicyModal = true" />
          </section>
        </div>

        <iFooter />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from "vue"
import { useReportStore } from "@/stores/report"

// STYLES
import { breakpointLg } from "@/styles/variables"
import { white } from "@/styles/colors"

// COMPONENTS
import iBackground from "@/components/global/Background.vue"
import iUnavailableCard from "@/components/global/UnavailableCard.vue"
import iPaymentOptions from "@/components/pricing/PaymentOptions.vue"
import iBaseModal from "@/components/base/BaseModal.vue"
import iAnnouncementBar from "@/components/welcome/AnnouncementBar.vue"
import iCareerRoadmap from "@/components/welcome/CareerRoadmap.vue"
import iCustomerReviews from "@/components/welcome/CustomerReviews.vue"
import iReportComparison from "@/components/welcome/ReportComparison.vue"
import iMoneyBackGuarantee from "@/components/welcome/MoneyBackGuarantee.vue"
import iElevateCareer from "@/components/welcome/ElevateCareer.vue"
import iBenchmark from "@/components/welcome//Benchmark.vue"
import iFooter from "@/components/welcome/Footer.vue"
import iRefundPolicyInModal from "@/components/welcome/RefundPolicyInModal.vue"
import iReportCountdown from "@/components/welcome/ReportCountdown.vue"

// STORES
const reportStore = useReportStore()

// DATA
const data = reactive({
  showRefundPolicyModal: false,
  timerDurationMin: 15,
  zigpollModalVisible: false,
  zigpollModalDelayMs: 45000 // 45 seconds
})

// FUNCTIONS
function loadZigpoll(delayMs: number) {
  const isMobileView = window.innerWidth <= breakpointLg

  if (isMobileView) {
    reportStore.initMobileZigpollPrepayment(delayMs).then((modalVisible) => {
      data.zigpollModalVisible = modalVisible
    })
  } else {
    reportStore.initDesktopZigpollPrepayment(delayMs)
  }
}

function scrollToPaymentTable() {
  const paymentSection = document.querySelector(".payment-table-title")
  const reportCountdown = document.querySelector(".i_report-countdown-v3")
  if (!paymentSection) return

  let offset = 16
  if (reportCountdown) offset = reportCountdown.getBoundingClientRect().height + offset

  // Calculate the target scroll position and scroll to
  const targetScrollPosition = paymentSection.getBoundingClientRect().top + window.scrollY - offset
  window.scrollTo({ top: targetScrollPosition, behavior: "smooth" })
}

// LIFECYCLE HOOKS
onMounted(() => {
  loadZigpoll(data.zigpollModalDelayMs)
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

$minContentWidth: 960px;
$maxContentWidth: 1200px;
.i_welcome {
  .zigpoll-modal {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      min-height: 90px;
      max-height: 60vh;
      overflow: auto;
      position: relative;
    }
    &-text {
      font-weight: bold;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
    }
  }

  .dashboard {
    .content {
      @include flex($direction: column, $justify: start, $align: center, $wrap: nowrap, $gap: 0px);
      margin: auto;

      > section {
        color: $black950;
        padding: 45px 0px;
        width: 100%;

        > div,
        .section-div {
          max-width: $minContentWidth;
          margin: 0 15px;

          @media screen and (min-width: $breakpointSm) {
            margin: 0 40px;
          }

          @media screen and (min-width: $breakpointLg) {
            margin: 0 auto;
          }

          @media screen and (min-width: $breakpointXl) {
            max-width: $maxContentWidth;
          }
        }
      }
    }
  }
}
</style>
