<template>
  <div class="i_select-plan-footer">
    <div class="inner">
      <div class="summary">
        <div class="pulse-circle"></div>
        <span>
          {{ defaultCoachFirstName }} has only <strong>{{ slotsLeft }} slots</strong> remaining.
        </span>
      </div>
      <div class="cta">
        <div class="price">
          <span
            v-if="!!props.coachingPlans[props.chosenPlan].discounted"
            class="slashed"
          >
            ${{ props.coachingPlans[props.chosenPlan].discounted }}<strong v-if="isSubscription">/mo</strong>
          </span>
          ${{ props.coachingPlans[props.chosenPlan].price }}<strong v-if="isSubscription">/mo</strong>
        </div>
        <iBaseButton
          v-bind="buttonStyle"
          :isDisabled="isPaymentButtonDisabled"
          text="Reserve Your Spot"
          @clicked="firePaymentAction"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"

import { useCoachingStore } from "@/stores/coaching"
import { useUserStore } from "@/stores/user"

import useIsScreenSize from "@/composables/useIsScreenSize"
import useRandomNumber from "@/composables/useRandomNumber"

// COMPONENTS
import iBaseButton from "@/components/base/BaseButton.vue"

// TYPES
import StripePrices from "@/shared/enums/StripePrices"
import type { CoachingPlans } from "@/types"

// STYLES
import { azureRadiance600, white } from "@/styles/colors"
import { breakpointSm } from "@/styles/variables"
import getPaymentAction from "@/composables/getPaymentAction"

// STORES AND COMPOSABLES
const userStore = useUserStore()
const coachingStore = useCoachingStore()
const isNotMobile = useIsScreenSize(breakpointSm)
const slotsLeft = useRandomNumber(userStore.getFullName || "Full Name", 3, 5)

const isPaymentButtonDisabled = ref(false)

const props = defineProps({
  coachingPlans: {
    type: Object as () => CoachingPlans,
    required: true
  },
  chosenPlan: {
    type: String as () => StripePrices,
    required: true
  }
})

// COMPUTED
const defaultCoachFirstName = computed(() => {
  const defaultCoach = coachingStore.getDefaultCoach
  return defaultCoach.fullName ? defaultCoach.fullName?.split(" ")[0] : "Your coach"
})
const isSubscription = computed(() =>
  [props.coachingPlans[StripePrices.COACHING_BIWEEKLY], props.coachingPlans[StripePrices.COACHING_WEEKLY]].includes(
    props.coachingPlans[props.chosenPlan]
  )
)
const buttonStyle = computed(() => {
  return {
    backgroundColor: azureRadiance600,
    borderRadius: "12px",
    fontWeight: "500",
    textColor: white,
    width: isNotMobile.value ? "max-content" : "100%",
    padding: isNotMobile.value ? "8px 24px" : "8px 6px",
    size: "medium"
  }
})

// FUNCTIONS
const firePaymentAction = async () => {
  disablePaymentButton()

  const coachingPlan = props.coachingPlans[props.chosenPlan]
  const paymentAction = getPaymentAction(coachingPlan.stripePrice, coachingPlan.upgradeTier, enablePaymentButton)
  try {
    await paymentAction()
  } catch (error) {
    isPaymentButtonDisabled.value = false
  }
}

const disablePaymentButton = () => {
  isPaymentButtonDisabled.value = true
}

const enablePaymentButton = () => {
  isPaymentButtonDisabled.value = false
}

// LIFECYCLE
onMounted(() => {
  enablePaymentButton()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_select-plan-footer {
  background-color: $white;
  box-shadow: 0 -5px 10px 0 rgba(83, 83, 83, 0.07), 0 5px 10px 0 rgba(83, 83, 83, 0.07);
  padding: 0 20px;
  position: sticky;
  bottom: 0;
  margin: 0 20px;
  @include border-radius-md;

  @media screen and (min-width: $breakpointMd) {
    width: 100%;
    margin: 0;
    @include border-radius-none;
  }

  .inner {
    @include flex(column-reverse, center, start, $gap: 8px);
    width: 100%;
    max-width: 1152px;
    margin: 0 auto;
    padding-top: 18px;
    padding-bottom: 18px;
    color: $black950;
    font-weight: 500;
    @include text-sm;

    @media screen and (min-width: $breakpointMd) {
      @include flex(row, space-between, center, $gap: 25px);
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .summary {
      @include flex(row, start, center, $gap: 15px);
    }

    .cta {
      @include flex(row, space-between, center, $gap: 30px);
      width: 100%;

      @media screen and (min-width: $breakpointMd) {
        @include flex(row, center, center, $gap: 5px);
        gap: 25px;
        width: unset;
      }

      .price {
        font-weight: 600;
        width: max-content;

        @media screen and (min-width: $breakpointSm) {
          white-space: nowrap;
        }

        .slashed {
          text-decoration: line-through;
          margin-right: 5px;
          color: $black300;
        }
      }
    }
  }
}
</style>
