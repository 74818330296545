<template>
  <div class="i_feedback">
    <div v-if="data.isFeedbackCompleted">
      <iNavbarSimple />
      <iLoaderSimple :loading-animation-duration-ms="LOADING_ANIMATION_DURATION">
        <template v-slot:title>Enhancing Your Career Matches!</template>
        <template v-slot:subtitle>
          Please wait while we personalize your report based on the feedback you provided. It may take 30-60 seconds.
        </template>
      </iLoaderSimple>
    </div>
    <div
      id="career-feedback-experience"
      v-else
    >
      <div class="background"><iNavbarSimple /> <iReportHero /></div>
      <div class="shade" />

      <div class="feedback-modal">
        <div
          class="header"
          v-if="!data.isSkippingFeedback"
        >
          <div class="left">
            <button
              class="nav-button"
              v-if="data.hasStartedFeedback && data.activeJobIndex > 0"
              @click="data.activeJobIndex--"
            >
              <img
                :src="iconBackButtonBlack"
                loading="lazy"
                alt="Close"
                class="icon"
              />
            </button>
          </div>
          <div class="right">
            <iBaseButton
              v-if="data.hasStartedFeedback && data.positiveFeedbackCount >= 5"
              v-bind="BUTTON_STYLE"
              text="Finish"
              width="fit-content"
              @clicked="feedbackCompleted"
            />
            <button
              class="nav-button"
              v-else
              @click="data.isSkippingFeedback = true"
            >
              <img
                :src="iconCloseButtonBlack"
                loading="lazy"
                alt="Close"
                class="icon"
              />
            </button>
          </div>
        </div>
        <div
          class="main"
          ref="mainContent"
        >
          <iFeedbackSkipWarning
            v-if="data.isSkippingFeedback"
            :content-area="mainContentArea"
          />
          <iFeedbackInfo
            v-else-if="!data.hasStartedFeedback"
            @started-feedback="data.hasStartedFeedback = true"
            :content-area="mainContentArea"
          />
          <iCareerSummary
            v-else-if="reportStore.suggestedJobs"
            :job="reportStore.suggestedJobs[data.activeJobIndex]"
            :content-area="mainContentArea"
          />
        </div>

        <div class="footer">
          <iFeedbackSkipFooter
            v-if="data.isSkippingFeedback"
            @back="data.isSkippingFeedback = false"
            @skip="feedbackCompleted"
          />
          <iBaseButton
            v-else-if="!data.hasStartedFeedback"
            class="button"
            v-bind="BUTTON_STYLE"
            width="240px"
            text="Continue"
            @clicked="data.hasStartedFeedback = true"
          />
          <iRater
            @rating-selected="rateJob"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue"

// COMPOSABLES
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { useRouter } from "vue-router"

// HELPERS
import { GlobalVariables } from "@/helpers/globalVariables"
import ApiService from "@/services/apiService"

// COMPONENTS
import iBaseButton from "@/components/base/BaseButton.vue"
import iLoaderSimple from "@/components/global/LoaderSimple.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"
import iCareerSummary from "@/components/report/feedback/CareerSummary.vue"
import iFeedbackInfo from "@/components/report/feedback/FeedbackInfo.vue"
import iFeedbackSkipWarning from "@/components/report/feedback/FeedbackSkipWarning.vue"
import iFeedbackSkipFooter from "@/components/report/feedback/FeedbackSkipFooter.vue"
import iRater from "@/components/report/feedback/Rater.vue"
import iReportHero from "@/components/report/ReportHero.vue"

// ASSETS
import iconCloseButtonBlack from "@/assets/buttons/close-button-black.svg"
import iconBackButtonBlack from "@/assets/buttons/back-button-black.svg"

// TYPES
import type { Feedback } from "@/shared/types/UserJobFeedback"

// STYLES
import { astronaut500, white } from "@/styles/colors"

// CONSTANTS
const BUTTON_STYLE = {
  backgroundColor: astronaut500,
  borderRadius: "24px",
  fontWeight: "600",
  textColor: white,
  size: "small"
}

const LOADING_TIME = 25000 // Wait 25 seconds to give time for the report to be regenerated before redirecting
const LOADING_ANIMATION_DURATION = (LOADING_TIME - 500) / 5 // Complete the animation 5 times. 0.5s buffer for loading the report

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()

// ROUTER
const router = useRouter()

// REFS
const mainContent = ref<HTMLElement | null>(null)
const mainContentArea = ref<number>(0)

// DATA
const data = reactive({
  hasStartedFeedback: false,
  isSkippingFeedback: false,
  isFeedbackCompleted: false,
  activeJobIndex: 0,
  positiveFeedbackCount: 0
})

// METHODS
const calculateMainContentArea = () => {
  if (mainContent.value) {
    mainContentArea.value = mainContent.value.clientWidth * mainContent.value.clientHeight
  } else {
    mainContentArea.value = 0
  }
}

/**
 * Complete the feedback
 *
 * Reload the report and redirect the user
 */
const feedbackCompleted = () => {
  ApiService.postFeedbackExperienceCompleted()

  data.isFeedbackCompleted = true

  setTimeout(async () => {
    await Promise.all([reportStore.loadReport(), userStore.loadUserData()])
    router.push({ name: GlobalVariables.urls.report.children.home.routeName })
  }, LOADING_TIME)
}

/**
 * Send user feedback to the backend
 *
 * If the user has rated all the suggested jobs, the feedback is completed
 * Otherwise, the next job is displayed
 */
const rateJob = (feedback: Feedback) => {
  if (feedback > 0) {
    data.positiveFeedbackCount++
  }

  const jobId = reportStore.suggestedJobs[data.activeJobIndex].id
  reportStore.postUserJobFeedback(jobId, {
    feedback,
    source: "initial"
  })

  data.activeJobIndex++

  if (data.activeJobIndex >= reportStore.suggestedJobs.length) {
    feedbackCompleted()
  } else {
    mainContent.value?.scrollTo({ top: 0, behavior: "smooth" })
  }
}

// LIFECYCLE HOOKS
onMounted(async () => {
  calculateMainContentArea()
  window.addEventListener("resize", calculateMainContentArea)
  await reportStore.loadSuggestedJobs()

  const suggestedJobs = [...reportStore.suggestedJobs]

  // If there are no suggested jobs, handle the error by completing the feedback
  if (!suggestedJobs || suggestedJobs.length === 0) {
    feedbackCompleted()
  }

  // Find the first job that has not been rated (feedback is null) and set it as the active job
  data.activeJobIndex = suggestedJobs.findIndex((job) => job.feedback === null)

  // Set the positive feedback count to the number of jobs that have already been rated positively
  data.positiveFeedbackCount = suggestedJobs.filter((job) => job.feedback !== null && job.feedback > 0).length
})
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/colors.scss";

.i_feedback {
  #career-feedback-experience {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $zIndexBackground;
    }

    .shade {
      background-color: rgba($pickleBluewood950, 0.96);
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: $zIndexBackground + 100;
    }

    .feedback-modal {
      z-index: $zIndexModal;
      border-radius: 16px;
      background-color: white;
      height: min(650px, 95vh);
      width: min(800px, 90vw);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;

      .header {
        @include flex($direction: row, $justify: space-between, $align: center);
        padding: 12px;

        .nav-button {
          background-color: $black50;
          border: none;
          cursor: pointer;
          padding: 4px;
          margin: 0;
          border-radius: 50%;

          &:hover {
            background-color: $black100;
          }

          .icon {
            height: 20px;
            width: 20px;
          }
        }
      }

      .main {
        @include flex($direction: column, $justify: flex-start, $align: center);

        flex-grow: 1;
        overflow-y: auto;
        padding: 12px 24px 24px 24px;

        @media screen and (min-width: $breakpointXs) {
        }
      }

      .footer {
        @include flex($direction: column, $justify: center, $align: center);
        padding: 12px 24px;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
</style>
