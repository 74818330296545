<template>
  <div
    class="i_base-loading-bar"
    :style="{ width: barWidth, height: barHeight, backgroundColor: black100 }"
  >
    <div
      class="fill"
      :style="{ '--start': props.start, ...fillStyle }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { black100, brandOpacity100 } from "@/styles/colors"

const props = defineProps({
  fillColor: {
    type: String,
    default: brandOpacity100
  },
  loadingAnimationDurationMs: {
    type: Number,
    default: 4000
  },
  barHeight: {
    type: String,
    default: "25px"
  },
  barWidth: {
    type: String,
    default: "100%"
  },
  infinite: {
    type: Boolean,
    default: true
  },
  animationStyle: {
    type: String,
    default: "ease-in-out"
  },
  start: {
    type: String,
    default: "0%"
  },
  isActive: {
    type: Boolean,
    default: true
  }
})

const fillStyle = computed(() => {
  return {
    backgroundColor: props.fillColor,
    height: "100%",
    animation: props.isActive
      ? `fill ${props.loadingAnimationDurationMs + 500}ms ${props.infinite ? "infinite" : ""} ${props.animationStyle}`
      : "none"
  }
})
</script>

<style lang="scss">
.i_base-loading-bar {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  .fill {
    position: absolute;
    left: 0;
    width: 100%;
  }
}
</style>

<style global lang="scss">
@keyframes fill {
  0% {
    width: var(--start);
  }
  100% {
    width: 100%;
  }
}
</style>
