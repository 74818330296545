<template>
  <div
    class="i_job-function-checkbox"
    @click="toggleCheckbox"
  >
    <div
      class="image"
      :class="{ checked: props.isChecked }"
    >
      <img
        :src="jobFunctionImage"
        :alt="props.label"
      />
    </div>

    <div class="label">
      {{ props.label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import iconAdministrativeAndOfficeSupport from "@/assets/jobFunctions/icon-administrative-and-office-support.svg"
import iconBusinessStrategyAndManagement from "@/assets/jobFunctions/icon-business-strategy-and-management.svg"
import iconCustomerServiceAndSupport from "@/assets/jobFunctions/icon-customer-service-and-support.svg"
import iconDesignAndCreative from "@/assets/jobFunctions/icon-design-and-creative.svg"
import iconEducationAndTraining from "@/assets/jobFunctions/icon-education-and-training.svg"
import iconEngineeringAndTechnology from "@/assets/jobFunctions/icon-engineering-and-technology.svg"
import iconFinanceAndAccounting from "@/assets/jobFunctions/icon-finance-and-accounting.svg"
import iconHealthcare from "@/assets/jobFunctions/icon-healthcare.svg"
import iconHospitalityAndTourism from "@/assets/jobFunctions/icon-hospitality-and-tourism.svg"
import iconHumanResources from "@/assets/jobFunctions/icon-human-resources.svg"
import iconLegal from "@/assets/jobFunctions/icon-legal.svg"
import iconManufacturingAndProduction from "@/assets/jobFunctions/icon-manufacturing-and-production.svg"
import iconMarketingAdvertisingPublicRelations from "@/assets/jobFunctions/icon-marketing-advertising-public-relations.svg"
import iconOther from "@/assets/jobFunctions/icon-other.svg"
import iconRealEstateAndConstruction from "@/assets/jobFunctions/icon-real-estate-and-construction.svg"
import iconResearchAndDevelopment from "@/assets/jobFunctions/icon-research-and-development.svg"
import iconSales from "@/assets/jobFunctions/icon-sales.svg"
import iconSupplyChainLogistics from "@/assets/jobFunctions/icon-supply-chain-logistics.svg"

// Props

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  isChecked: {
    type: Boolean,
    required: true
  },
  label: {
    type: String,
    required: true
  }
})

// Emits

const emit = defineEmits(["toggle"])

// Consts

const jobFunctionImageMap = {
  1: iconAdministrativeAndOfficeSupport,
  2: iconBusinessStrategyAndManagement,
  3: iconCustomerServiceAndSupport,
  4: iconDesignAndCreative,
  5: iconEducationAndTraining,
  6: iconEngineeringAndTechnology,
  7: iconFinanceAndAccounting,
  8: iconHealthcare,
  9: iconHospitalityAndTourism,
  10: iconHumanResources,
  11: iconLegal,
  12: iconManufacturingAndProduction,
  13: iconMarketingAdvertisingPublicRelations,
  14: iconRealEstateAndConstruction,
  15: iconResearchAndDevelopment,
  16: iconSales,
  17: iconSupplyChainLogistics,
  18: iconOther
}

// Computed

const jobFunctionImage = computed(() => {
  return jobFunctionImageMap[props.id as keyof typeof jobFunctionImageMap]
})

// Functions

function toggleCheckbox() {
  emit("toggle", !props.isChecked)
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";

.i_job-function-checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  &:hover {
    .image {
      background-color: $azureRadiance50;
      border: 1px solid $azureRadiance200;
    }
  }

  .image {
    border: 1px solid $black300;
    border-radius: 10px;
    margin-bottom: 8px;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;

    &.checked {
      background-color: $azureRadiance100;
      border: 1px solid $azureRadiance300;
    }
  }

  .label {
    font-weight: normal;
    margin-bottom: 12px;
  }
}
</style>
