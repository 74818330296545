<template>
  <div
    class="i_select-plan"
    v-if="data.defaultCoachLoaded"
  >
    <iSelectPlanCountdownNav v-if="defaultCoach?.fullName" />

    <div class="content">
      <div class="header">
        <div class="title">
          Customize your career success plan<template v-if="!!defaultCoach?.fullName">
            with {{ defaultCoach?.fullName }}</template
          >
        </div>
        <div class="sub">
          <template v-if="!!defaultCoach?.fullName">
            Based on your availability and criteria, you have been matched with {{ defaultCoach?.fullName }} at a
            <strong>{{ defaultCoach?.percentMatch }}% match score</strong>.
          </template>
          <template v-else>Please choose your desired career success plan.</template>
        </div>
      </div>

      <div class="main">
        <div class="left">
          <template v-if="!!defaultCoach?.fullName"
            ><div class="top-pick">Top pick: {{ defaultCoach?.percentMatch }}% Match</div></template
          >
          <img
            class="coach-image"
            :src="defaultCoach?.profilePhotoUrl || ''"
          />
        </div>
        <div class="right">
          <iCoachSummary />
          <iPlanOptions
            :coaching-plans="coachingPlans"
            :chosen-plan="data.chosenPlan"
            @plan-selected="setChosenPlan($event)"
          />
        </div>
      </div>
    </div>

    <iSelectPlanFooter
      :coaching-plans="coachingPlans"
      :chosen-plan="data.chosenPlan"
    />

    <iBaseModal
      visible
      class="zigpoll-modal"
      :class="{ visible: data.zigpollModalVisible }"
      @clickOnClose="data.zigpollModalVisible = false"
    >
      <div class="zigpoll-modal-wrap">
        <div class="zigpoll-modal-text">You can close this modal now!</div>
        <div id="zigpoll-embed-target"></div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, onMounted, reactive } from "vue"
import { breakpointLg } from "@/styles/variables"

import { GlobalVariables } from "@/helpers/globalVariables"

import { useCoachingStore } from "@/stores/coaching"
import { useUserStore } from "@/stores/user"
import { useReportStore } from "@/stores/report"

import iBaseModal from "@/components/base/BaseModal.vue"
import iCoachSummary from "@/components/coaching/selectPlan/CoachSummary.vue"
import iPlanOptions from "@/components/coaching/selectPlan/PlanOptions.vue"
import iSelectPlanCountdownNav from "@/components/coaching/selectPlan/SelectPlanCountdownNav.vue"
import iSelectPlanFooter from "@/components/coaching/selectPlan/SelectPlanFooter.vue"

// TYPES
import StripePrices from "@/shared/enums/StripePrices"
import UserStatus from "@/shared/enums/UserStatus"
import type { CoachingPlans } from "@/types"

// STORES
const coachingStore = useCoachingStore()
const userStore = useUserStore()
const reportStore = useReportStore()

// DATA
const data = reactive({
  chosenPlan: StripePrices.COACHING_BIWEEKLY,
  defaultCoachLoaded: false,
  zigpollModalVisible: false,
  zigpollModalDelayMs: 45000 // 45 seconds
})

// COMPUTED
const defaultCoach = computed(() => coachingStore.getDefaultCoach)

const isCoachingFinished = computed(() => userStore.isCoachingUser && userStore.isTierFinished)

const coachingPlans: ComputedRef<CoachingPlans> = computed(() => {
  return {
    [StripePrices.COACHING_ONCE_LOW]: {
      id: StripePrices.COACHING_ONCE_LOW,
      details: ["60-minute scoping session"],
      discounted: "",
      isSubscription: false,
      isVisible: !isCoachingFinished.value,
      stripePrice: StripePrices.COACHING_ONCE_LOW,
      upgradeTier: UserStatus.PREMIUM,
      price: "99.90",
      title: "Meet Once"
    },
    [StripePrices.COACHING_ONCE_HIGH]: {
      id: StripePrices.COACHING_ONCE_HIGH,
      details: ["60-minute scoping session"],
      discounted: "",
      isSubscription: false,
      isVisible: isCoachingFinished.value,
      stripePrice: StripePrices.COACHING_ONCE_HIGH,
      upgradeTier: UserStatus.PREMIUM,
      price: "149.90",
      title: "Meet Once"
    },
    [StripePrices.COACHING_BIWEEKLY]: {
      id: StripePrices.COACHING_BIWEEKLY,
      details: ["60-minute scoping session", "<u>Two</u> 30-minute sessions each month"],
      discounted: "199.90",
      isSubscription: true,
      isVisible: true,
      stripePrice: StripePrices.COACHING_BIWEEKLY,
      upgradeTier: UserStatus.PREMIUM,
      price: "149.90",
      title: "Meet Biweekly"
    },
    [StripePrices.COACHING_WEEKLY]: {
      id: StripePrices.COACHING_WEEKLY,
      details: ["60-minute scoping session", "<u>Four</u> 30-minute sessions each month"],
      discounted: "399.90",
      isSubscription: true,
      isVisible: true,
      stripePrice: StripePrices.COACHING_WEEKLY,
      upgradeTier: UserStatus.PREMIUM,
      price: "279.90",
      title: "Meet Weekly"
    }
  } as CoachingPlans
})

// FUNCTIONS
const setChosenPlan = (plan: StripePrices) => {
  data.chosenPlan = plan
}

const loadZigpoll = (delayMs: number) => {
  const isMobileView = window.innerWidth <= breakpointLg

  if (isMobileView) {
    if (window.Zigpoll) window.Zigpoll.refresh()
    reportStore.initMobileZigpollPrepayment(delayMs, GlobalVariables.zigpolls.finishedCoaching).then((modalVisible) => {
      data.zigpollModalVisible = modalVisible
    })
  } else {
    if (window.Zigpoll) reportStore.deactivateZigpollReload()
    reportStore.initDesktopZigpollPrepayment(delayMs)
  }
}

// LIFECYCLE HOOKS
onMounted(async () => {
  loadZigpoll(data.zigpollModalDelayMs)

  // We need to get the default coach again, because it will have changed after the user has booked a session
  await coachingStore.loadDefaultCoach()
  data.defaultCoachLoaded = true
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_select-plan {
  padding-bottom: 20px;

  @media screen and (min-width: $breakpointMd) {
    padding-bottom: 0px;
  }

  .content {
    margin: 0 20px;
    @include flex(column, center, center, $gap: 25px);

    @media screen and (min-width: $breakpointMd) {
      gap: 45px;
    }

    .header {
      max-width: 1000px;
      margin-top: 30px;

      @media screen and (min-width: $breakpointSm) {
        text-align: center;
      }

      .title {
        @include display-md;
        font-weight: 700;
        margin-bottom: 18px;
        color: $black950;

        @media screen and (min-width: $breakpointSm) {
          @include display-lg;
        }
      }

      .sub {
        @include text-md;
        font-weight: 400;
        color: $black700;
      }
    }

    .main {
      @include flex(column, start, start, $gap: 20px);

      @media screen and (min-width: $breakpointMd) {
        @include flex(row, center, start, $gap: 40px);
      }

      .left {
        width: 100%;
        max-width: 400px;
        align-self: center;
        position: relative;

        @media screen and (min-width: $breakpointMd) {
          position: sticky;
          top: 20px;
          align-self: unset;
          max-width: 556px;
        }

        .top-pick {
          width: fit-content;
          position: absolute;
          right: 12px;
          top: 12px;
          padding: 4px 12px;
          @include border-radius-xxl;
          color: $white;
          text-transform: uppercase;
          font-weight: 500;
          @include text-xs;
          background-image: linear-gradient(to right, #4a9dff, #be4fff);

          @media screen and (min-width: $breakpointSm) {
            @include text-sm;
            font-weight: 400;
          }
        }

        .coach-image {
          width: 100%;
          height: 100%;
          object-position: top;
          object-fit: cover;
          @include border-radius-md;
        }
      }

      .right {
        width: 100%;
        @include flex(column, center, stretch, $gap: 48px);
        margin-bottom: 30px;

        @media screen and (min-width: $breakpointMd) {
          max-width: 600px;
        }
      }
    }
  }

  .zigpoll-modal {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      min-height: 90px;
      max-height: 60vh;
      overflow: auto;
      position: relative;
    }
    &-text {
      font-weight: bold;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";

.i_select-plan {
  .pulse-circle {
    width: 10px;
    height: 10px;
    @include border-radius-rounded;
    background-color: $sorbus500;
    position: relative;

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
      }
      50% {
        box-shadow: 0 0 0 5px rgba(255, 146, 10, 0.2);
      }
      100% {
        box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      @include border-radius-rounded;
      z-index: 1;
      animation: pulse 2s forwards infinite;
      animation-timing-function: linear;
    }
  }
}
</style>
