import type { Notification } from "@/types"
import { GlobalVariables } from "@/helpers/globalVariables"

/*
  Since this is not a view component, we cannot use useRoute() and useRouter().
  Instead we need to import router object to get activeRoute and Query Params.
*/
import router from "@/router"
import scrollToTop from "./scrollToTop"

export const notifications = {
  fewJobRecommendations: {
    id: "fewJobRecommendations",
    message: `You do not have many career recommendations left.`,
    type: "info",
    action: {
      label: "Get More Careers",
      task: () => {
        scrollToTop()
        const routeName = GlobalVariables.urls.report.children.newCareers.routeName
        router.push({ name: routeName })
      }
    },
    actionRouteName: GlobalVariables.urls.report.children.newCareers.routeName
  },
  noJobRecommendations: {
    id: "noJobRecommendations",
    message: `You do not have any career recommendations left.`,
    type: "alert",
    action: {
      label: "Get More Careers",
      task: () => {
        scrollToTop()
        const routeName = GlobalVariables.urls.report.children.newCareers.routeName
        router.push({ name: routeName })
      }
    },
    actionRouteName: GlobalVariables.urls.report.children.newCareers.routeName
  },
  skippedQuestions: {
    id: "skippedQuestions",
    message: `Results may be inaccurate due to skipped questions. Consider a Quiz Retake for more accurate results.`,
    type: "info",
    action: {
      label: "Retake Quiz",
      task: () => {
        const routeName = GlobalVariables.urls.report.children.newCareers.routeName
        router.push({ name: routeName })
      }
    },
    actionRouteName: GlobalVariables.urls.report.children.newCareers.routeName // Don't show this notification on the Get New Careers page
  },
  reportOutdated: {
    id: "reportOutdated",
    message: `Results may be inaccurate due to outdated or incomplete data. Consider a Quiz Retake for new accurate and complete results.`,
    type: "alert",
    action: {
      label: "Retake Quiz",
      task: () => {
        const routeName = GlobalVariables.urls.report.children.testRetake.routeName
        router.push({ name: routeName })
      }
    },
    actionRouteName: GlobalVariables.urls.report.children.testRetake.routeName
  }
} as Record<string, Notification>

export default notifications
