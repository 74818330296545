<template>
  <section
    v-if="data.isTimerActive"
    class="i_report-countdown"
    :class="{
      fadeIn: props.hasFadeInAnimation,
      default: props.version === 'default',
      start: props.version === 'start'
    }"
  >
    <div
      class="wrap"
      :class="{ centered: !hasCallToAction }"
    >
      <div class="countdown">
        <template v-if="timerDisplay">
          <div class="text">
            You will lose access to your report in: <span class="time">{{ formattedTime }}</span>
          </div>
        </template>
        <template v-else>
          <div class="text">Your Personalized Career Report is Ready to View</div>
        </template>
      </div>

      <iBaseButton
        v-if="hasCallToAction"
        @click="$emit('scrollToPaymentTable')"
        v-bind="buttonData"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watchEffect, reactive } from "vue"
import { bittersweet400, black, sorbus400 } from "@/styles/colors"
import { breakpointMd } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"

const useMidButton = useIsScreenSize(breakpointMd)

// EXPOSED
defineExpose({
  makeActive: () => {
    startTimer()
    data.isTimerActive = true
  }
})

// PROPS
const props = defineProps({
  timerDurationMin: {
    type: Number,
    default: 1
  },
  hasCallToAction: {
    type: Boolean,
    default: true
  },
  version: {
    type: String,
    default: "default",
    validator: (value: string) => value === "default" || value === "start"
  },
  hasFadeInAnimation: {
    type: Boolean,
    default: false
  }
})

// EMITS
defineEmits(["scrollToPaymentTable"])

// DATA
const timeRemaining = ref(props.timerDurationMin * 60)
const data = reactive({
  isTimerActive: false
})

// COMPUTED
const buttonData = computed(() => {
  if (props.version === "start") {
    return {
      backgroundColor: sorbus400,
      borderColor: sorbus400,
      borderRadius: "8px",
      padding: "8px 70px",
      fontWeight: "500",
      textColor: black,
      text: "Unlock your full report",
      size: useMidButton.value ? "medium" : "small"
    }
  } else {
    return {
      backgroundColor: bittersweet400,
      padding: "8px 70px",
      textColor: "white",
      text: "GET MY RESULTS",
      size: useMidButton.value ? "medium" : "small"
    }
  }
})

const formattedTime = computed(() => {
  const minutes = Math.floor((timeRemaining.value / 60) % 60)
  const seconds = Math.floor(timeRemaining.value % 60)
  return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
})

const timerDisplay = computed(() => {
  return timeRemaining.value > 0 && data.isTimerActive
})

//FUNCTIONS
function startTimer() {
  const intervalId = setInterval(() => {
    timeRemaining.value--
    if (timeRemaining.value >= 0) {
      localStorage.setItem("report-countdown", timeRemaining.value.toString())
    }
    if (timeRemaining.value === 0) {
      clearInterval(intervalId)
    }
  }, 1000)
}

// WATCHERS
watchEffect(() => {
  const timer = localStorage.getItem("report-countdown")
  if (timer !== null) {
    timeRemaining.value = parseInt(timer)
  } else {
    localStorage.setItem("report-countdown", timeRemaining.value.toString())
  }
})

// LIFECYCLE HOOKS
onMounted(() => {
  if (props.version === "default") {
    startTimer()
    data.isTimerActive = true
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

$minContentWidth: 960px;
$maxContentWidth: 1200px;

.i_report-countdown {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $zIndexOverlay;

  &.default {
    border: 1px solid $black50;
    background-color: $white;
    color: $black;
  }

  &.start {
    background-color: $black;
    color: $white;
  }

  &.fadeIn {
    animation: fadeIn 2s ease 0s 1 normal forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .wrap {
    padding: 10px 0px;
    @include flex($direction: column, $align: center, $justify: space-between, $gap: 8px);

    &.centered {
      @include flex($align: center);
    }
    max-width: $minContentWidth;
    margin: 0 15px;

    @media screen and (min-width: $breakpointSm) {
      margin: 0 40px;
    }

    @media screen and (min-width: $breakpointMd) {
      flex-direction: row;
      padding-top: 8px;
      gap: 16px;
    }

    @media screen and (min-width: $breakpointLg) {
      margin: 0 auto;
    }

    @media screen and (min-width: $breakpointXl) {
      max-width: $maxContentWidth;
    }

    .countdown {
      @include flex($direction: row, $align: center, $justify: center, $gap: 2px);

      @media screen and (min-width: $breakpointXs) {
        gap: 8px;
      }

      .text {
        @include text-sm;
        align-items: center;
        display: flex;
        font-weight: 400;
        gap: 8px;
        text-align: center;

        @media screen and (min-width: $breakpointSm) {
          @include text-md;
        }

        .time {
          @include text-md;
          font-weight: 700;

          @media screen and (min-width: $breakpointSm) {
            @include text-xl;
          }
        }
      }
    }
  }
}
</style>
