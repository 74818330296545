<template>
  <div class="i_login">
    <iBackground
      :background-image="bgSquiggleGreen"
      :color="white"
    />

    <div class="header">
      <div class="logo">
        <a @click="goToHome()"
          ><img
            :src="logo"
            alt="JobTest.org"
            class="logo-img"
          />
        </a>
      </div>
      <iBaseButton
        v-bind="BUTTON_STYLE.header"
        size="small"
        text="Find Your Calling"
        @clicked="goToTest()"
      />
    </div>

    <div class="wrap">
      <div class="card">
        <template v-if="!(uiData.submissionSuccess || uiData.submissionError)">
          <h1 class="title">Sign in</h1>

          <form @submit.prevent="submitForm()">
            <label class="label">Email Address:</label>
            <input
              type="email"
              class="input-field"
              maxlength="256"
              v-model="userData.email"
              autofocus
            />

            <div>
              <iBaseButton
                class="cta"
                v-bind="BUTTON_STYLE.email"
                :text="uiData.submitting ? 'Please wait...' : 'Continue with Email'"
                :is-disabled="!userData.email || uiData.submitting"
                @clicked="submitForm()"
              />
            </div>
          </form>
        </template>

        <template v-if="uiData.submissionSuccess">
          <h1 class="title">Magic Link sent!</h1>

          <div class="info-body">
            You will shortly receive a magic link via email to access your account.<br />
            <strong>For your security, the link is valid for 24 hours.</strong>
          </div>

          <div>
            <iBaseButton
              class="cta"
              v-bind="BUTTON_STYLE.email"
              text="Back to Jobtest.org"
              @clicked="goToHome()"
            />
          </div>
        </template>

        <template v-if="uiData.submissionError">
          <h1 class="title c-error">Your request couldn&#x27;t be processed!</h1>

          <div class="info-body">
            <strong>Only paid users get lifetime access to their packages.</strong><br />
            Only one free package is granted per email address.<br />Please use a different email address if you wish to
            retake the test.
          </div>

          <div>
            <iBaseButton
              class="cta"
              v-bind="BUTTON_STYLE.email"
              text="Start the test!"
              :icon-url="iconUpArrowBlack"
              @clicked="goToTest()"
            />
          </div>
        </template>

        <template v-if="uiData.displayOAuth">
          <div class="separator">
            <iBaseDelimiter class="delimiter" /><span>OR</span><iBaseDelimiter class="delimiter" />
          </div>
          <iBaseButton
            class="cta"
            v-bind="BUTTON_STYLE.oAuth"
            text="Continue with LinkedIn"
            :pre-icon="iconLinkedIn"
            @clicked="null"
          />
          <iBaseButton
            class="cta"
            v-bind="BUTTON_STYLE.oAuth"
            text="Continue with Google"
            :pre-icon="iconGoogle"
            @clicked="null"
          />
        </template>
      </div>

      <div class="back-cta">
        <a
          @click="uiData.backToHome ? goToHome() : showSignIn()"
          class="back-link"
        >
          <img :src="iconChevron" /> Back to {{ uiData.backToHome ? "Jobtest.org" : "Sign In" }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"
import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"

import ApiService from "@/services/apiService"

import { black, brandOpacity100, sorbus400, white } from "@/styles/colors"

import iBackground from "@/components/global/Background.vue"
import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseDelimiter from "@/components/base/BaseDelimiter.vue"

import logo from "@/assets/logo/logo-dark.svg"
import iconUpArrowBlack from "@/assets/icons/icon-up-arrow-black.svg"
import iconChevron from "@/assets/icons/icon-chevron-left-blue.svg"
import iconLinkedIn from "@/assets/icons/icon-linkedin-color.svg"
import iconGoogle from "@/assets/icons/icon-google-color.svg"
import bgSquiggleGreen from "@/assets/jobTest/bg-squiggle-green.svg"

// DATA (reactive object with properties)

const uiData = reactive({
  submitting: false,
  submissionSuccess: false,
  submissionError: false,
  backToHome: true,
  // remove displayOAuth flag when BE OAuth feature is completed
  displayOAuth: false
})

const userData = reactive({
  email: ""
})

// CONSTANT
const SHARED_BUTTON_STYLES = {
  fontWeight: "500",
  textColor: black,
  width: "100%",
  borderRadius: "8px"
}

const BUTTON_STYLE = {
  header: {
    ...SHARED_BUTTON_STYLES,
    backgroundColor: sorbus400,
    width: "fit-content",
    borderRadius: "12px"
  },
  email: {
    ...SHARED_BUTTON_STYLES,
    backgroundColor: brandOpacity100
  },
  oAuth: {
    ...SHARED_BUTTON_STYLES,
    backgroundColor: white
  }
}

// FUNCTIONS
function showSignIn() {
  userData.email = ""
  uiData.submissionError = false
  uiData.submissionSuccess = false
  // uiData.displayOAuth = true
  uiData.backToHome = true
}

function goToHome() {
  const pageUrl = GlobalVariables.urls.root.path
  const domain = GlobalFunctions.getWebsiteDomain()
  window.location.href = `https://${domain}${pageUrl}`
}

function goToTest() {
  const pageUrl = GlobalVariables.urls.jobTest.path
  const domain = GlobalFunctions.getWebsiteDomain()
  window.location.href = `https://${domain}${pageUrl}`
}

function submitForm() {
  uiData.submitting = true
  // uiData.displayOAuth = false
  const email = userData.email
  ApiService.postMagicLinkEmail(email).then(successResponse).catch(errorResponse)
}

function successResponse() {
  uiData.submissionSuccess = true
  uiData.submissionError = false
  uiData.submitting = false
  uiData.backToHome = false
}

function errorResponse(error: any) {
  console.log(error)
  uiData.submissionError = true
  uiData.submissionSuccess = false
  uiData.submitting = false
  uiData.backToHome = false
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";
@import "@/styles/typography.scss";

$card-padding: 20px;
$card-padding-sm: 40px;
$card-padding-md: 40px;
$inputFieldBackground: white;

.i_login {
  @include flex($justify: center, $align: center);
  min-height: 100vh;

  .header {
    @include flex(row, $justify: space-between, $align: center);
    width: 100%;
    padding: 15px 15px;
    position: fixed;
    top: 0;

    @media screen and (min-width: $breakpointSm) {
      padding: 15px 40px;
    }

    .logo {
      a:hover {
        cursor: pointer;
      }
      .logo-img {
        width: 160px;
      }
    }
  }

  .wrap {
    width: 90%;
    max-width: 500px;

    .card {
      @include card($shadow: true, $padding: true);
      background-color: rgba(244.37, 244.37, 244.37, 0.6);
      @include border-radius-lg;
      text-align: start;

      .title {
        @include font-style("h5");
        margin-bottom: 24px;
        &.c-error {
          color: $bittersweet500;
        }
      }

      .label {
        @include text-sm;
        font-weight: 600;
      }

      .input-field {
        @include input-field();
        min-height: 48px !important;
        margin-top: 10px;
      }

      .info-body {
        margin: 24px 0px 28px 0px;
      }

      .cta {
        margin: 15px auto 20px auto;
      }

      .back-link {
        @include text-link();
        font-weight: bold;
      }
    }

    .separator {
      @include flex(row, $justify: space-around, $align: center);

      .delimiter {
        width: 40%;
      }
    }

    .back-cta {
      color: $azureRadiance950;
      text-align: center;
      margin-top: 70px;
      cursor: pointer;
    }
  }
}
</style>
