<template>
  <div class="i_calcom-scheduler">
    <div id="my-cal-inline"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue"
import { azureRadiance50, azureRadiance600 } from "@/styles/colors"
import { useUserStore } from "@/stores/user"

// STORES
const userStore = useUserStore()

const props = defineProps({
  embedLink: {
    type: String,
    default: ""
  },
  onBookingSuccessful: {
    type: Function,
    default: () => {}
  }
})

// LIFECYCLE HOOKS
onMounted(() => {
  ;(function (C: Window, A: string, L: string) {
    let p = function (a: any, ar: any) {
      a.q.push(ar)
    }
    let d = C.document
    C.Cal =
      C.Cal ||
      function () {
        let cal = C.Cal
        let ar = arguments
        if (!cal.loaded) {
          cal.ns = {}
          cal.q = cal.q || []
          let script = d.createElement("script")
          script.src = A
          d.head.appendChild(script)
          cal.loaded = true
        }
        if (ar[0] === L) {
          const api = function () {
            p(api, arguments)
          }
          const namespace = ar[1]

          // @ts-ignore
          api.q = api.q || []
          if (typeof namespace === "string") {
            cal.ns[namespace] = cal.ns[namespace] || api
            p(cal.ns[namespace], ar)
            p(cal, ["initNamespace", namespace])
          } else p(cal, ar)
          return
        }
        p(cal, ar)
      }
  })(window, "https://app.cal.com/embed/embed.js", "init")

  // Ensure Cal is available in the global context
  if (window.Cal) {
    window.Cal("init", { origin: "https://cal.com" })

    window.Cal("inline", {
      elementOrSelector: "#my-cal-inline",
      calLink: props.embedLink,
      layout: "month_view",
      config: {
        name: userStore.getFullName || "",
        email: userStore.getEmail || "",
        smsReminderNumber: userStore.getPhoneNumber ? `+1${userStore.getPhoneNumber}` : ""
      }
    })

    window.Cal("ui", {
      theme: "light",
      cssVarsPerTheme: { light: { "cal-brand": azureRadiance600, "cal-bg-emphasis": azureRadiance50 } },
      hideEventTypeDetails: false,
      layout: "month_view"
    })

    window.Cal("on", {
      action: "bookingSuccessful",
      callback: props.onBookingSuccessful
    })
  } else {
    console.error("Cal is not defined on the window object.")
  }
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_calcom-scheduler {
  width: 100%;

  #my-cal-inline {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}
</style>
