<template>
  <div
    class="i_career-card"
    :class="{ active: isActive }"
    v-if="career.isVisible"
    @click="emit('click')"
  >
    <div class="career-title">
      <iBaseTruncatedText
        :text="career.title"
        :max-length="32"
      />
    </div>
    <div class="right">
      <div
        class="badge"
        :style="getBadgeStyle(career.isFavorite, career.color)"
      >
        <img
          v-if="career.isFavorite"
          :src="iconStarOrange"
        />
        <template v-else>
          <span>{{ career.badge }}</span>
        </template>
      </div>
      <img
        v-if="showChevron"
        class="icon-chevron"
        :src="iconDropdownChevron"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useReportStore } from "@/stores/report"

import iBaseTruncatedText from "@/components/base/BaseTruncatedText.vue"

import { sorbus100, sorbus300 } from "@/styles/colors"

import iconStarOrange from "@/assets/icons/icon-star-orange-filled.svg"
import iconDropdownChevron from "@/assets/menu/icon-dropdown-chevron.svg"
import { computed } from "vue"
import { useRoute } from "vue-router"
import { GlobalVariables } from "@/helpers/globalVariables"

// STORE
const reportStore = useReportStore()
const activeRoute = useRoute()

// PROPS
const props = defineProps({
  career: {
    type: Object,
    required: true
  },
  showChevron: {
    type: Boolean,
    default: false
  }
})

// EMITS
const emit = defineEmits(["click"])

// COMPUTED
// const isActive = computed(() => props.career.id === reportStore.activeJobId)
// ALSO CHECK THAT WE ARE ON THE CAREERS PAGE
const isActive = computed(
  () =>
    props.career.id === reportStore.activeJobId &&
    activeRoute.name === GlobalVariables.urls.report.children.careers.routeName
)

// FUNCTIONS
const getBadgeStyle = (isFavorite: boolean, color: string) => {
  return {
    "--bd-color": isFavorite ? sorbus300 : color,
    "--bg-color": isFavorite ? sorbus100 : color,
    "--min-width": isFavorite ? "30px" : "45px"
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/borders.scss";
@import "@/styles/typography.scss";

.i_career-card {
  position: relative;
  @include flex(row, space-between, center, $gap: 8px);
  cursor: pointer;

  width: 100%;
  min-height: 64px;
  padding: 8px 12px;

  @include border-radius-md;
  border: 1px solid $zumthor100;
  background-color: $white;

  &:hover {
    border: 1px solid $azureRadiance200;
    background-color: $azureRadiance50;
  }

  &.active {
    background-color: $azureRadiance100;
    border: 1px solid $azureRadiance300;
  }

  @media screen and (min-width: $breakpointLg) {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      @include border-radius-md;
      z-index: 1;
      animation: pulse 2s forwards 5;
      animation-timing-function: linear;

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0px $azureRadiance100;
        }
        50% {
          box-shadow: 0 0 0 4px $azureRadiance100;
        }
        100% {
          box-shadow: 0 0 0 0px $azureRadiance100;
        }
      }
    }
  }

  .career-title {
    @include text-md;
    font-weight: 400;
    color: $black950;
  }

  .right {
    @include flex(row, center, center, $gap: 12px);
    min-width: 25%;

    .badge {
      @include flex(row, center, center);

      @include border-radius-md;
      padding: 2px 8px;
      min-width: var(--min-width);

      background-color: var(--bg-color);
      border: 1px solid var(--bd-color);

      @include text-xs;
      color: $white;
      font-weight: 500;
      text-align: center;
    }

    .icon-chevron {
      width: 14px;
      height: auto;
      margin-right: 12px;
    }
  }
}
</style>
