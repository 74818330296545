<template>
  <div class="i_career-skillset">
    <div class="card">
      <iBaseHeader
        highlight-text=""
        text="Hard Skills"
        align="start"
      />
      <div
        class="list"
        v-if="activeJobQualifications.hardSkills.length > 0"
      >
        <template v-for="entry in activeJobQualifications.hardSkills">
          <strong>{{ entry.title }}</strong>
          <br />
          <ul>
            <li>{{ entry.description }}</li>
          </ul>
        </template>
      </div>
      <div
        v-else
        class="none"
      >
        No hard skills requirements found.
      </div>
    </div>
    <div class="card">
      <iBaseHeader
        highlight-text=""
        text="Soft Skills"
        align="start"
      />
      <div
        class="list"
        v-if="activeJobQualifications.softSkills.length > 0"
      >
        <template v-for="entry in activeJobQualifications.softSkills">
          <strong>{{ entry.title }}</strong>
          <br />
          <ul>
            <li>{{ entry.description }}</li>
          </ul>
        </template>
      </div>
      <div
        v-else
        class="none"
      >
        No soft skills requirements found.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iBaseHeader from "@/components/base/BaseHeader.vue"

// STORES
const reportStore = useReportStore()

// COMPUTED
const activeJobQualifications = computed(() => {
  return reportStore.getActiveJobQualifications
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_career-skillset {
  @include flex(column, $justify: center, $align: start, $gap: 15px);
  color: $pickleBluewood900;
  @include text-sm;
  margin: 20px 0px;

  .card {
    @include flex(column, $justify: center, $align: start, $gap: 12px);

    @media screen and (min-width: $breakpointSm) {
      gap: 18px;
    }

    .list {
      margin-left: 25px;

      ul {
        margin: 8px 0 25px 0;
        padding-left: 30px;
      }

      li {
        color: $comet600;
      }
    }

    .none {
      @include text-md;
      font-weight: 500;
    }
  }
}
</style>
