<template>
  <div class="i_feedback-skip-footer">
    <iBaseButton
      v-bind="BUTTON_STYLE.transparent"
      text="Back"
      @clicked="emit('back')"
    />
    <iBaseButton
      v-bind="BUTTON_STYLE.red"
      text="Skip"
      @clicked="emit('skip')"
    />
  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import iBaseButton from "@/components/base/BaseButton.vue"
import { bittersweet600, black600, white } from "@/styles/colors"

// CONSTANTS
const SHARED_BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content"
}

const BUTTON_STYLE = {
  red: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: bittersweet600,
    borderRadius: "24px",
    textColor: white
  },
  transparent: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: "transparent",
    textColor: black600,
    isHoverable: false
  }
}

// EMITS
const emit = defineEmits(["skip", "back"])
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";

.i_feedback-skip-footer {
  width: 100%;
  @include flex($direction: row, $justify: space-between);
}
</style>
