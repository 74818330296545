<template>
  <div class="i_quiz-email-error">
    <template v-if="data.usesNewEmail">
      <div class="title">Provide new email address!</div>

      <form>
        <input
          type="email"
          class="input-field"
          maxlength="256"
          placeholder="Email Address"
          v-model="data.email"
          autofocus
        />

        <div class="text">
          <p>This email address will be associated to your new test results.</p>
        </div>

        <iBaseButton
          class="cta"
          :icon-url="iconUpArrowBlack"
          :text="data.submitting ? 'Please wait...' : 'Submit email'"
          :is-disabled="!data.email || data.submitting"
          @clicked="submitForm()"
        />

        <iBaseButton
          class="login"
          text="Back to previous page"
          :background-color="'transparent'"
          :is-hoverable="false"
          :text-color="azureRadiance600"
          @clicked="data.usesNewEmail = false"
        />
      </form>
    </template>

    <template v-else>
      <div class="title">This email is already in use.</div>

      <div class="text">
        <p>
          The email you've entered is already associated with an account. You can either
          <strong>use a different email</strong> to take the test again,
          <strong>or log in to view your previous test results.</strong>
        </p>
      </div>

      <div class="divider">
        <img :src="iconSeparator" />
      </div>

      <div class="tip">TIP: IF YOU DON'T HAVE MORE EMAIL ACCOUNTS</div>

      <div class="text">
        <p>
          To re-register for our quiz, use the '+' trick for a unique email, if supported by your email provider. Just
          add '+' with any word or number between your email and '@'. Example: '<span class="email"
            >johnsmith@gmail.com</span
          >' becomes '<span class="email">johnsmith+quiz1@gmail.com</span>'.
        </p>
      </div>

      <div class="text">
        <p>
          This lets you retake the quiz using email variants. Supported by Gmail, Outlook, Hotmail, Fastmail,
          Prontonmail, Apple iCloud Mail. Yahoo Mail uses '-' instead of '+'.
        </p>
      </div>

      <iBaseButton
        class="cta"
        text="I want to use a new email"
        @clicked="data.usesNewEmail = true"
      />

      <iBaseButton
        class="login"
        text="Login to view previous results"
        :background-color="'transparent'"
        :icon-url="iconUpArrowBlue"
        :is-hoverable="false"
        :text-color="azureRadiance600"
        @clicked="goToLogin()"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"
import { useRouter } from "vue-router"
import { GlobalVariables } from "@/helpers/globalVariables"

import { azureRadiance600 } from "@/styles/colors"

import iBaseButton from "@/components/base/BaseButton.vue"
import iconSeparator from "@/assets/icons/icon-separator.svg"
import iconUpArrowBlack from "@/assets/icons/icon-up-arrow-black.svg"
import iconUpArrowBlue from "@/assets/icons/icon-up-arrow-blue.svg"

// STORES AND ROUTER

const router = useRouter()

// EMITS

const emit = defineEmits(["submitNewEmail"])

// DATA

const data = reactive({
  email: "",
  submitting: false,
  usesNewEmail: false
})

// FUNCTIONS

function goToLogin() {
  router.push({ name: GlobalVariables.urls.login.routeName })
}

function submitForm() {
  data.submitting = true
  emit("submitNewEmail", data.email)
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";

.i_quiz-email-error {
  @include card($shadow: true, $padding: true);
  text-align: center;

  .cta {
    margin: 24px auto 0px auto;
  }

  .divider {
    margin-bottom: 16px;
  }

  .login {
    margin: 12px auto 0px auto;
  }

  .input-field {
    @include input-field();
    margin-top: 24px !important;
    margin-bottom: 22px !important;
    max-width: 350px;
    margin: auto;
  }

  .text {
    margin-bottom: 16px;

    .email {
      text-decoration: underline;
    }
  }

  .title {
    @include font-style("h5");
    font-weight: bold;
    margin-bottom: 16px;
  }

  .tip {
    @include font-style("caption");
    font-weight: bold;
    margin-bottom: 16px;
  }
}
</style>
