<template>
  <div class="i_profile">
    <section>
      <div class="intro">
        <iBaseHeader
          class="header"
          :highlight-text="firstName"
          :font-size-highlighted-text="headerSize"
          text="Here's your profile summary"
          align="start"
        />

        <div class="summary">
          <span class="email">{{ email }}</span>
          <div class="bubble-row">
            <div
              class="bubble gender"
              :style="{ backgroundColor: gender.color }"
            >
              {{ gender.type }}
            </div>
            <div class="bubble age-range">{{ currentAge }}</div>
          </div>
        </div>
      </div>

      <iGoalsAspirations />
      <iInformationBar />
      <iProfessionalBio />
    </section>

    <section v-if="!userStore.isTierPremium">
      <iBaseHeader
        highlight-text="Not sure on what to do next?"
        :font-size-highlighted-text="headerSize"
        text="Talk to our career coach to figure out what exactly you need to do."
        font-size-text="14px"
        font-weight-text="500"
        :color-text="comet600"
        align="start"
      />
      <iActionCard
        class="no-mobile-break"
        caption="Career Coach"
        text="Book a call, <br />dedicated and personalized."
        cta-text="Check Availability"
        @cta-clicked="linkToCoachSelection"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { astronaut600, bittersweet500, black, comet600 } from "@/styles/colors"
import { breakpointSm } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"
import linkToCoachSelection from "@/composables/linkToCoachSelection"

// COMPONENTS
import iBaseHeader from "@/components/base/BaseHeader.vue"
import iInformationBar from "@/components/report/profile/InformationBar.vue"
import iProfessionalBio from "@/components/report/profile/ProfessionalBio.vue"
import iGoalsAspirations from "@/components/report/profile/GoalsAspirations.vue"
import iActionCard from "@/components/global/ActionCard.vue"

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const userStore = useUserStore()
const isNotMobile = useIsScreenSize(breakpointSm)

// COMPUTED
const headerSize = computed(() => (isNotMobile.value ? "36px" : "32px"))

const currentAge = computed(() => reportStore.getAgeGroup)

const email = computed(() => userStore.getEmail)

const firstName = computed(() => {
  const fullName = userStore.getFullName
  return fullName ? `${fullName.split(" ")[0]}` : "Hi"
})

const gender = computed(() => {
  const type = reportStore.getGender
  return {
    type,
    color: type === "Female" ? bittersweet500 : type === "Male" ? astronaut600 : black
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_profile {
  max-width: 720px;
  margin: 0 auto 20px auto;
  @include flex($direction: column, $justify: start, $align: stretch, $gap: 40px);

  .intro {
    @include flex($direction: column, $justify: center, $align: start, $gap: 2px);

    @media screen and (min-width: $breakpointLg) {
      @include flex($direction: row, $justify: space-between, $align: stretch);
    }

    .header {
      width: fit-content;
    }

    .summary {
      align-content: end;

      .email {
        @include text-sm;
        color: $astronaut500;
      }

      .bubble-row {
        @include flex(row, $justify: start, $align: center, $gap: 10px);
        margin-top: 5px;

        @media screen and (min-width: $breakpointLg) {
          justify-content: end;
        }

        .bubble {
          padding: 2px 15px;
          border-radius: 24px;
          @include text-xs;
          font-weight: 500;

          &.gender {
            color: $white;
          }

          &.age-range {
            background-color: $black100;
            color: $black;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/styles/variables.scss";

.i_profile {
  .no-mobile-break {
    margin-top: 32px;

    br {
      display: none;

      @media screen and (min-width: $breakpointSm) {
        display: block;
      }
    }
  }
}
</style>
