<template>
  <div
    class="i_radar-chart"
    :id="props.chartId"
  >
    <div
      class="chart-container"
      :style="chartContainerStyle"
    >
      <div id="custom-labels">
        <div
          v-for="(label, index) in props.labels"
          :key="index"
          :style="computeLabelPosition(index, props.labels.length)"
        >
          <span
            :style="topIndices.includes(index) ? 'color: ' + props.labelHighlightColor : 'color: ' + props.labelColor"
          >
            {{ label }}</span
          >
        </div>
      </div>
      <div class="radar"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Chart, { type ChartConfiguration } from "chart.js/auto"
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { comet500, brandOpacity100, bittersweet600, brandOpacity60, comet800 } from "@/styles/colors"

// REFS
const isResizing = ref(false)
const isVisible = ref(false)

// PROPS
const props = defineProps({
  chartId: {
    type: String,
    required: true
  },
  canvasId: {
    type: String,
    required: true
  },
  colorMe: {
    type: String,
    default: brandOpacity60
  },
  colorOther: {
    type: String,
    default: bittersweet600
  },
  gridColor: {
    type: String,
    default: comet800
  },
  labelColor: {
    type: String,
    default: comet500
  },
  labelHighlightColor: {
    type: String,
    default: brandOpacity100
  },
  labels: {
    type: Array,
    required: true
  },
  maxDiameter: {
    type: String
  },
  range: {
    type: Object as () => { minimum: number; maximum: number },
    required: true
  },
  scoresMe: {
    type: Array,
    required: true
  },
  scoresOther: {
    type: Array,
    required: true
  },
  steps: {
    type: Number,
    default: 2
  }
})

// COMPUTED

const chartContainerStyle = computed(() => {
  return {
    maxWidth: props.maxDiameter ? props.maxDiameter : "100%",
    maxHeight: props.maxDiameter ? props.maxDiameter : "100%"
  }
})

const topIndices = computed(() => {
  const scoresMe = [...props.scoresMe] as number[]

  const sortedScores = scoresMe.map((score, index) => ({ score, index })).sort((a, b) => b.score - a.score)

  const secondHighest = sortedScores[1].score

  const topIndices = sortedScores.filter((item) => item.score >= secondHighest).map((item) => item.index)

  return topIndices
})

// FUNCTIONS

// Calculate the angle for each label, then use trigonometry to place it
function computeLabelPosition(index: number, total: number) {
  const angle = (index / total) * 2 * Math.PI
  const x = 50 + 40 * Math.cos(angle - Math.PI / 2) // Adjust 50 and 40 as needed
  const y = 50 + 40 * Math.sin(angle - Math.PI / 2) // Adjust 50 and 40 as needed
  return `position: absolute; left: ${x}%; top: ${y}%; transform: translate(-50%, -50%);`
}

async function generateRadarChart(chartSettings: {
  colorMe: string
  colorOther: string
  labels: any[]
  scoresMe: any[]
  scoresOther: any[]
  range: any
  steps: number
}) {
  const { colorMe, colorOther, labels, scoresMe, scoresOther, range, steps } = chartSettings

  if (
    !colorMe ||
    !colorOther ||
    labels.length === 0 ||
    scoresMe.length === 0 ||
    scoresOther.length === 0 ||
    Object.entries(range).length === 0 ||
    !steps
  )
    return

  const chartParent = document.querySelector(`#${props.chartId}`) as HTMLElement
  chartParent.style.display = "block"

  const chartCtnr = document.querySelector(`#${props.chartId} .radar`) as HTMLElement
  if (!chartCtnr) return

  chartCtnr.innerHTML = `<canvas id="${props.canvasId}"></canvas>`
  const chartStepSize = (range.maximum - range.minimum) / steps
  const chartConfig = {
    type: "radar",
    data: {
      labels: [...labels],
      datasets: [
        {
          data: [...scoresMe],
          backgroundColor: colorMe,
          borderColor: colorMe,
          pointBackgroundColor: colorMe,
          pointBorderColor: colorMe,
          pointHoverBackgroundColor: colorMe,
          pointHoverBorderColor: colorMe,
          fill: true,
          radius: 3
        },
        {
          data: [...scoresOther],
          backgroundColor: colorOther,
          borderColor: colorOther,
          pointBackgroundColor: colorOther,
          pointBorderColor: colorOther,
          pointHoverBackgroundColor: colorOther,
          pointHoverBorderColor: colorOther,
          fill: false,
          radius: 1
        }
      ]
    },
    options: {
      elements: {
        line: {
          borderWidth: 2
        },
        point: {
          radius: 2,
          fill: false
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      },
      scales: {
        r: {
          angleLines: {
            display: true,
            color: props.gridColor
          },

          min: range.minimum,
          max: range.maximum,
          grid: {
            display: true,
            color: props.gridColor
          },
          ticks: {
            display: false,
            stepSize: chartStepSize
          },
          pointLabels: {
            display: false
          }
        }
      }
    }
  }

  const canvas = document.querySelector(`#${props.canvasId}`) as HTMLCanvasElement
  if (canvas) new Chart(canvas, chartConfig as ChartConfiguration)
}

const handleResize = () => {
  if (isResizing.value) return
  isResizing.value = true

  setTimeout(() => {
    isResizing.value = false
    initChartGeneration()
  }, 200)
}

function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: number | undefined
  return (...args: any[]) => {
    if (timeout !== undefined) {
      clearTimeout(timeout)
    }
    timeout = window.setTimeout(() => func(...args), wait)
  }
}

const debouncedHandleResize = debounce(handleResize, 200)

const initChartGeneration = () => {
  if (isVisible.value) {
    generateRadarChart({
      colorMe: props.colorMe,
      colorOther: props.colorOther,
      labels: props.labels,
      scoresMe: props.scoresMe,
      scoresOther: props.scoresOther,
      range: props.range,
      steps: props.steps
    })
  }
}

// WATCHERS
watch(
  () => props.scoresOther, // Watch for changes to scoresOther
  () => initChartGeneration() // Re-generate chart when scoresOther changes
)

watch(
  () => isVisible.value, // Watch for changes to isVisible
  () => {
    if (isVisible.value) {
      initChartGeneration() // Re-generate chart when component is visible
    }
  }
)

// LIFECYCLE HOOKS

onMounted(() => {
  const observer = new IntersectionObserver(([entry], observer) => {
    if (entry.isIntersecting) {
      isVisible.value = true
      observer.disconnect()
    }
  })
  observer.observe(document.getElementById(props.chartId) as HTMLElement)

  window.addEventListener("resize", debouncedHandleResize)
  // Wait for DOM to load before generating chart
  setTimeout(() => {
    initChartGeneration()
  }, 200)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", debouncedHandleResize)
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";

.i_radar-chart {
  display: none;
  max-width: 100%;
  width: 100%;
  margin: auto;

  .chart-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: auto;

    #custom-labels {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      @include text-xs;
      font-weight: 600;
      text-align: center;
      color: $comet500;
    }

    .radar {
      width: 100%;
      height: 100%;
      margin: auto;
      padding: calc(32px + 15%);
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
