<template>
  <div class="i_start">
    <iBackground :color="white" />

    <template v-if="reportStore.invalidData">
      <iUnavailableCard
        text="Please try again later. If the issue persists please contact us at support@jobtest.org"
        title="Report unavailable"
      />
    </template>

    <template v-else>
      <iBaseModal
        visible
        class="zigpoll-modal"
        :class="{ visible: data.zigpollModalVisible }"
        @clickOnClose="data.zigpollModalVisible = false"
      >
        <div class="zigpoll-modal-wrap">
          <div class="zigpoll-modal-text">You can close this modal now!</div>
          <div id="zigpoll-embed-target"></div>
        </div>
      </iBaseModal>

      <iBaseModal
        :visible="data.showRefundPolicyModal"
        @click-on-close="data.showRefundPolicyModal = false"
        fully-customisable
      >
        <iRefundPolicyInModal />
      </iBaseModal>

      <template v-if="data.lightBoxVideo">
        <iBaseLightBox @close="data.lightBoxVideo = ''">
          <video
            :src="data.lightBoxVideo"
            autoplay
            controls
            class="light-box-video"
            type="video/mp4"
          ></video>
        </iBaseLightBox>
      </template>

      <div class="page">
        <iAnnouncementBar version="light" />

        <iReportCountdown
          version="start"
          ref="reportCountdown"
          hasFadeInAnimation
          :timerDurationMin="data.timerDurationMin"
          @scrollToPaymentTable="scrollToPaymentTable"
        />

        <div class="content">
          <section
            class="hero-intro"
            :style="{ '--offset': data.reportCountdownOffset }"
          >
            <div class="heading-large career-roadmap">Your Career Roadmap is Ready</div>
            <div
              class="hero-cta"
              @click="scrollToWeThink"
            >
              <div>{{ heroCta.text }}</div>
              <img :src="heroCta.icon" />
            </div>
          </section>

          <section class="we-think">
            <div class="heading-large is-we-think">
              <template v-if="!!userStore?.getFullName">{{ userStore?.getFullName }}, here's what we think</template>
              <template v-else>Here's what we think</template>
            </div>
            <div class="subheading is-we-think">Based on what you shared with us during the quiz.</div>
            <div class="career-factors"><iCareerFactors /></div>
          </section>

          <section class="we-know">
            <div class="padding">
              <div class="heading-large is-we-know">Here's what we know</div>
              <div class="subheading is-we-know">
                Based on 482 other {{ reportStore?.getCurrentJob || "customer" }} profiles we analyzed in the last 24
                hours.
              </div>
              <div class="benchmark-profile">
                <iBenchmarkedProfile />
              </div>
            </div>

            <div class="divider"></div>

            <div class="padding">
              <div class="peers-comparison"><iPeersComparison /></div>
            </div>
          </section>

          <section
            class="we-believe"
            ref="believeSection"
          >
            <div class="heading-large is-we-believe">Here's what we believe</div>
            <div class="subheading is-we-believe">
              Based on 700,000+ other people we helped fine, pursue, and succeed in their dream careers.
            </div>
            <div class="earning-potential"><iEarningPotential /></div>
          </section>

          <section class="we-transform">
            <div class="padding">
              <div class="heading-medium is-we-transform">
                We believe we can help you transform Mondays into your Favorite Day.
              </div>
              <div class="subheading is-we-transform">
                Your dream job is not a myth. Join thousands of others who found their calling where you can't wait to
                start each week.
              </div>
            </div>

            <div class="slider-testimonials">
              <template v-if="isSmallOrLarger">
                <iBaseAnimatedSlider
                  sliding-direction="left"
                  animation-duration-secs="60"
                >
                  <iSliderTestimonials @clicked="(testimonialVideo: string) => data.lightBoxVideo = testimonialVideo" />
                </iBaseAnimatedSlider>
              </template>
              <template v-else> <iSliderTestimonialsMobile /></template>
            </div>

            <iBaseAnimatedSlider
              sliding-direction="right"
              animation-duration-secs="210"
            >
              <iSliderReviews :set="1" />
            </iBaseAnimatedSlider>

            <iBaseAnimatedSlider
              sliding-direction="right"
              animation-duration-secs="140"
            >
              <iSliderReviews :set="2" />
            </iBaseAnimatedSlider>
          </section>

          <section class="we-promise">
            <div class="heading-large is-we-promise">Here's what we promise</div>
            <div class="subheading is-we-promise">
              We are on a mission to help you succeed in your career, and we stand by our mission.
            </div>

            <div class="benefits-row">
              <iBenefitsRow @show-refund-policy="data.showRefundPolicyModal = true" />
            </div>
          </section>

          <section
            id="payment-section"
            class="we-recommend"
          >
            <div class="heading-large is-we-recommend">Here's what we recommend</div>
            <div class="subheading is-we-recommend">
              Since you are looking to advance your career, you will find the most success with our
              <span>Comprehensive Report.</span>
            </div>

            <div class="payment-table">
              <iPaymentTable version="v2" />
            </div>
          </section>

          <section class="we-guarantee">
            <iMoneyBackGuarantee @show-refund-policy="data.showRefundPolicyModal = true" />
          </section>
        </div>

        <iFooter />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref, type Ref } from "vue"

// COMPOSABLES
import useIsScreenSize from "@/composables/useIsScreenSize"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"

// STYLES
import { white } from "@/styles/colors"
import { breakpointLg, breakpointSm } from "@/styles/variables"

// COMPONENTS
import iBaseAnimatedSlider from "@/components/base/BaseAnimatedSlider.vue"
import iBaseLightBox from "@/components/base/BaseLightBox.vue"
import iBaseModal from "@/components/base/BaseModal.vue"
import iBackground from "@/components/global/Background.vue"
import iSliderReviews from "@/components/global/SliderReviews.vue"
import iSliderTestimonials from "@/components/global/SliderTestimonials.vue"
import iSliderTestimonialsMobile from "@/components/global/SliderTestimonialsMobile.vue"
import iUnavailableCard from "@/components/global/UnavailableCard.vue"
import iPaymentTable from "@/components/pricing/PaymentTable.vue"
import iAnnouncementBar from "@/components/welcome/AnnouncementBar.vue"
import iBenchmarkedProfile from "@/components/welcome/BenchmarkedProfile.vue"
import iBenefitsRow from "@/components/welcome/BenefitsRow.vue"
import iCareerFactors from "@/components/welcome/CareerFactors.vue"
import iEarningPotential from "@/components/welcome/EarningPotential.vue"
import iFooter from "@/components/welcome/Footer.vue"
import iMoneyBackGuarantee from "@/components/welcome/MoneyBackGuarantee.vue"
import iPeersComparison from "@/components/welcome/PeersComparison.vue"
import iRefundPolicyInModal from "@/components/welcome/RefundPolicyInModal.vue"
import iReportCountdown from "@/components/welcome/ReportCountdown.vue"

// ASSETS
import iconArrowScroll from "@/assets/icons/icon-arrow-scroll.svg"
import iconMouseScroll from "@/assets/icons/icon-mouse-scroll.svg"

// STORES
const reportStore = useReportStore()
const userStore = useUserStore()

// REFS
const reportCountdown: Ref<HTMLElement | null> = ref(null)
const believeSection: Ref<HTMLElement | null> = ref(null)

// DATA
const isSmallOrLarger = useIsScreenSize(breakpointSm)
const isLargerThanTablet = useIsScreenSize(breakpointLg)

const data = reactive({
  lightBoxVideo: "",
  showRefundPolicyModal: false,
  timerDurationMin: 15,
  believeSectionDelayMs: 2000,
  zigpollModalVisible: false,
  zigpollModalDelayMs: 45000, // 45 seconds,
  reportCountdownOffset: "0px"
})

// COMPUTED
const heroCta = computed(() => {
  return isLargerThanTablet.value
    ? { text: "Scroll to learn", icon: iconMouseScroll }
    : { text: "Swipe to learn", icon: iconArrowScroll }
})

// FUNCTIONS
function loadZigpoll(delayMs: number) {
  const isMobileView = window.innerWidth <= breakpointLg

  if (isMobileView) {
    reportStore.initMobileZigpollPrepayment(delayMs).then((modalVisible) => {
      data.zigpollModalVisible = modalVisible
    })
  } else {
    reportStore.initDesktopZigpollPrepayment(delayMs)
  }
}

function scrollToPaymentTable() {
  const paymentSection = document.querySelector("#payment-section")
  if (!paymentSection) return

  // Calculate the target scroll position and scroll to
  const targetScrollPosition = paymentSection.getBoundingClientRect().top + window.scrollY - 16
  window.scrollTo({ top: targetScrollPosition, behavior: "smooth" })
}

function scrollToWeThink() {
  const weThinkSection = document.querySelector(".we-think")
  const reportCountdown = document.querySelector(".i_report-countdown")
  if (!weThinkSection) return

  let offset = 40
  if (reportCountdown) offset = reportCountdown.getBoundingClientRect().height + offset

  // Calculate the target scroll position and scroll to
  const targetScrollPosition = weThinkSection.getBoundingClientRect().top + window.scrollY - offset
  window.scrollTo({ top: targetScrollPosition, behavior: "smooth" })
}

function observeBelieveSection(delayMs: number) {
  setTimeout(() => {
    if (!believeSection.value || !reportCountdown.value) return
    setupObserver(believeSection, () => {
      ;(reportCountdown.value as any)?.makeActive?.()
    })
  }, delayMs)
}

function setupObserver(elementRef: Ref<HTMLElement | null>, callback: () => void) {
  if (!elementRef.value) return
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback() // Execute the callback function
          onReportCountdownResize()
          observer.unobserve(entry.target) // Stop observing after the first trigger
        }
      })
    },
    {
      root: null, // observing changes in the viewport
      rootMargin: "0px",
      threshold: 0.1 // callback will be executed when 10% of the target is visible
    }
  )
  observer.observe(elementRef.value)
}

function onReportCountdownResize() {
  nextTick(() => {
    const reportCountdown = document.querySelector(".i_report-countdown") as HTMLElement

    if (!reportCountdown) return

    const observer = new ResizeObserver(() => {
      const offsetHeight = `${reportCountdown.getBoundingClientRect().height}px`
      if (data.reportCountdownOffset != offsetHeight) data.reportCountdownOffset = offsetHeight
    })
    observer.observe(reportCountdown)
  })
}

// LIFECYCLE HOOKS
onMounted(() => {
  observeBelieveSection(data.believeSectionDelayMs)
  loadZigpoll(data.zigpollModalDelayMs)
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_start {
  .zigpoll-modal {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      min-height: 90px;
      max-height: 60vh;
      overflow: auto;
      position: relative;
    }
    &-text {
      font-weight: bold;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
    }
  }

  .page {
    overflow-x: clip;

    .content {
      @include flex($direction: column, $justify: start, $align: stretch, $wrap: nowrap, $gap: 0px);
      margin: auto;
      width: 100%;

      max-width: 960px;
      @media screen and (min-width: $breakpointXl) {
        max-width: 1410px;
      }

      section {
        &.hero-intro {
          height: calc(100svh - 50px - var(--offset));
          @include flex(row, center, center);
          position: relative;

          @media screen and (min-width: $breakpointMd) {
            height: calc(100svh - 60px - var(--offset));
          }

          .hero-cta {
            position: absolute;
            bottom: 25px;
            @include flex(column, ceter, center, $gap: 12px);
            @include text-md;
            font-weight: 700;
            animation: bobby 1.5s ease-in-out 0s infinite normal forwards;

            @keyframes bobby {
              0% {
                bottom: 25px;
              }
              50% {
                bottom: 40px;
              }
              100% {
                bottom: 25px;
              }
            }
          }
        }
        &.we-think {
          margin-top: 96px;
          margin-bottom: 80px;
          padding-left: 16px;
          padding-right: 16px;

          .career-factors {
            margin-top: 24px;
          }

          @media screen and (min-width: $breakpointSm) {
            padding-left: 40px;
            padding-right: 40px;
          }

          @media screen and (min-width: $breakpointMd) {
            margin-bottom: 104px;

            .career-factors {
              margin-top: 88px;
            }
          }

          @media screen and (min-width: $breakpointLg) {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        &.we-know {
          margin-top: 24px;
          margin-bottom: 80px;

          @media screen and (min-width: $breakpointMd) {
            margin-bottom: 104px;
          }

          .padding {
            padding-left: 16px;
            padding-right: 16px;

            @media screen and (min-width: $breakpointSm) {
              padding-left: 40px;
              padding-right: 40px;
            }

            @media screen and (min-width: $breakpointLg) {
              padding-left: 16px;
              padding-right: 16px;
            }
          }

          .divider {
            background-color: $black100;
            height: 2px;
            width: 100%;

            @media screen and (min-width: $breakpointSm) {
              display: none;
            }
          }

          .benchmark-profile {
            padding-top: 48px;
            padding-bottom: 56px;

            @media screen and (min-width: $breakpointSm) {
              border: 2px solid $black100;
              border-radius: 24px;
              overflow: hidden;
              padding-left: 40px;
              padding-right: 40px;
              margin-top: 40px;
              margin-bottom: 12px;
            }

            @media screen and (min-width: $breakpointMd) {
              margin-top: 88px;
              padding-top: 80px;
              padding-bottom: 80px;
            }
          }

          .peers-comparison {
            padding-top: 48px;
            padding-bottom: 56px;

            @media screen and (min-width: $breakpointSm) {
              margin-top: 12px;
              border: 2px solid $black100;
              border-radius: 24px;
              overflow: hidden;
              padding-left: 40px;
              padding-right: 40px;
            }

            @media screen and (min-width: $breakpointMd) {
              padding-top: 80px;
              padding-bottom: 80px;
            }
          }
        }
        &.we-believe {
          margin-top: 24px;
          margin-bottom: 80px;
          padding-left: 16px;
          padding-right: 16px;

          @media screen and (min-width: $breakpointSm) {
            padding-left: 40px;
            padding-right: 40px;
          }

          @media screen and (min-width: $breakpointLg) {
            padding-left: 16px;
            padding-right: 16px;
          }

          .earning-potential {
            margin-top: 40px;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 48px;
            border: 2px solid $black100;
            border-radius: 24px;
            overflow: hidden;

            @media screen and (min-width: $breakpointSm) {
              padding-left: 40px;
              padding-right: 40px;
            }

            @media screen and (min-width: $breakpointMd) {
              margin-top: 88px;
              padding-top: 80px;
            }

            @media screen and (min-width: $breakpointLg) {
              padding-top: 0px;
            }

            @media screen and (min-width: $breakpointXl) {
              padding-left: 16px;
              padding-right: 16px;
              max-width: unset;
            }
          }
        }
        &.we-transform {
          margin-top: 24px;
          margin-bottom: 80px;

          .padding {
            padding-left: 16px;
            padding-right: 16px;

            @media screen and (min-width: $breakpointSm) {
              padding-left: 40px;
              padding-right: 40px;
            }

            @media screen and (min-width: $breakpointLg) {
              padding-left: 16px;
              padding-right: 16px;
            }
          }

          .slider-testimonials {
            margin-top: 32px;
            margin-bottom: 0px;

            @media screen and (min-width: $breakpointMd) {
              margin-top: 60px;
              margin-bottom: 24px;
            }
          }
        }
        &.we-promise {
          margin-top: 24px;
          margin-bottom: 80px;
          padding-left: 16px;
          padding-right: 16px;

          @media screen and (min-width: $breakpointMd) {
            margin-bottom: 112px;
          }

          @media screen and (min-width: $breakpointSm) {
            padding-left: 40px;
            padding-right: 40px;
          }

          @media screen and (min-width: $breakpointLg) {
            padding-left: 16px;
            padding-right: 16px;
          }

          .benefits-row {
            margin-top: 48px;
            margin-left: auto;
            margin-right: auto;
            max-width: 800px;
          }
        }
        &.we-recommend {
          background-color: $pickleBluewood100;
          border-radius: 24px;
          max-width: 1410px;
          margin-left: auto;
          margin-right: auto;
          padding-top: 80px;
          padding-bottom: 80px;
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;

          @media screen and (min-width: $breakpointSm) {
            padding-left: 40px;
            padding-right: 40px;
          }

          @media screen and (min-width: $breakpointLg) {
            padding-top: 72px;
            padding-bottom: 96px;
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 16px;
            margin-right: 16px;
          }

          @media screen and (min-width: $breakpointXl) {
            padding-left: 16px;
            padding-right: 16px;
            max-width: unset;
          }

          .payment-table {
            margin-left: auto;
            margin-right: auto;
            max-width: 350px;

            @media screen and (min-width: $breakpointLg) {
              margin-top: 56px;
            }

            @media screen and (min-width: $breakpointLg) {
              max-width: 920px;
            }
          }
        }
        &.we-guarantee {
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          margin-top: 80px;
          margin-bottom: 80px;

          padding-left: 16px;
          padding-right: 16px;

          @media screen and (min-width: $breakpointSm) {
            padding-left: 40px;
            padding-right: 40px;
          }

          @media screen and (min-width: $breakpointLg) {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }

      .heading-large {
        @include display-lg;
        font-weight: 600;
        line-height: 1.1em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        text-align: center;

        &.career-roadmap,
        &.is-we-think {
          max-width: 350px;
        }

        &.is-we-know {
          max-width: 325px;
        }

        &.is-we-believe {
          max-width: 340px;
        }

        &.is-we-promise {
          max-width: 340px;
        }

        &.is-we-recommend {
          max-width: 360px;
        }

        @media screen and (min-width: $breakpointMd) {
          @include display-xl;
          margin-bottom: 24px;

          &.career-roadmap,
          &.is-we-think {
            max-width: 620px;
          }

          &.is-we-know {
            max-width: 420px;
          }

          &.is-we-believe {
            max-width: 425px;
          }

          &.is-we-promise {
            max-width: 425px;
          }

          &.is-we-recommend {
            max-width: 520px;
          }
        }

        @media screen and (min-width: $breakpointLg) {
          @include display-xxl;
          margin-bottom: 24px;

          &.career-roadmap {
            font-size: 82px;
            max-width: 720px;
          }
        }
      }

      .heading-medium {
        @include display-md;
        font-weight: 600;
        line-height: 1.3em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;
        text-align: center;

        &.is-we-transform {
          width: 95%;
          max-width: 450px;
        }

        @media screen and (min-width: $breakpointMd) {
          margin-bottom: 20px;

          &.is-we-transform {
            max-width: 660px;
          }
        }

        @media screen and (min-width: $breakpointLg) {
          margin-bottom: 24px;
        }
      }

      .subheading {
        @include text-md;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 500px;

        &.is-we-know {
          max-width: 580px;
        }

        &.is-we-believe {
          max-width: 450px;
        }

        &.is-we-promise {
          max-width: 410px;
        }

        &.is-we-recommend {
          max-width: 450px;

          span {
            background-color: $astronaut700;
            border-radius: 64px;
            color: $white;
            display: inline-block;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 2px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
}
</style>
