<template>
  <div
    class="i_unlock-careers"
    :class="{ 'not-compact': !props.isCompact }"
    @click="handleUpgradeClick()"
  >
    <div class="top">
      <img
        :src="iconLock"
        alt="Unlock icon"
      />
      <div>
        Unlock all career recommendations <span v-if="unlockQuantity"> (+{{ unlockQuantity }})</span>
      </div>
    </div>
    <div class="bottom">
      <div>Get them today</div>
      <a class="upgrade-button">
        <img
          :src="iconRocket"
          alt="Upgrade icon"
        />
        <span>Upgrade</span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user"
import { computed } from "vue"

// ASSETS
import iconLock from "@/assets/icons/icon-blue-lock.svg"
import iconRocket from "@/assets/icons/icon-rocket.svg"

// TYPES
import UserStatus from "@/shared/enums/UserStatus"

// EMITS
const emit = defineEmits(["upgradeClicked"])

// PROPS
const props = defineProps({
  isCompact: {
    type: Boolean,
    default: false
  }
})

// STORES
const userStore = useUserStore()

// COMPUTED
const unlockQuantity = computed(() => {
  switch (userStore.getUserStatus) {
    case UserStatus.FREE:
      return 10
    case UserStatus.BASIC:
      return 5
    case UserStatus.COMPREHENSIVE:
      return 3
    default:
      return null
  }
})

// FUNCTION
function handleUpgradeClick() {
  if (userStore.isTierPremium) return
  emit("upgradeClicked")
}
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_unlock-careers {
  width: 100%;
  @include flex(column, center, center, $gap: 15px);
  border: 1px solid $zumthor100;
  @include border-radius-md;
  padding: 24px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid $azureRadiance200;
    background-color: $azureRadiance50;
  }

  &.not-compact {
    @media screen and (min-width: $breakpointXxl) {
      @include flex(row, space-between, center, $gap: 5px);

      .top {
        @include flex(row, start, center, $gap: 10px);

        img {
          margin-top: 0px;
          z-index: 0;
        }
      }
    }
  }

  .top {
    @include flex(column, center, center, $gap: 10px);
    @include text-sm;
    color: $pickleBluewood950;

    img {
      margin-top: -42px;
      z-index: 1;
    }
  }

  .bottom {
    @include flex(row, center, center, $wrap: wrap, $gap: 10px);
    border: 1px solid $zumthor100;
    @include border-radius-sm;
    padding: 6px 14px;
    @include text-xs;
    color: $comet950;
    background-color: $white;

    .upgrade-button {
      @include flex(row, center, center, $gap: 4px);
      color: $azureRadiance700;
      text-decoration: none;
    }
  }
}
</style>
