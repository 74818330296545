<template>
  <div class="i_section-title">
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_section-title {
  @include font-style("h3");
  font-weight: 600;
  text-align: center;
  .highlight {
    color: $bilbao500;
  }

  .unlock-career-potential {
    color: #f79009;
    @include text-style($size: 32px, $weight: 700, $line-height: 38px, $align: center);

    @media screen and (max-width: $breakpointLg) {
      @include text-style($size: 42px, $weight: 700, $line-height: 50px, $align: center);
    }
  }
}
</style>
