<template>
  <div class="i_target-salary-slider">
    <div class="slider-labels">
      <span class="label-min">$20,000</span>
      <span class="label-max">$120,000+</span>
    </div>
    <input
      class="slider"
      type="range"
      :min="props.min"
      :max="props.max"
      v-model="sliderValue"
    />
    <div class="slider-value-label">
      <span>{{ sliderValueLabel }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { numberAsCurrency } from "@/composables/numberAsCurrency"
import { computed, onMounted, ref, watch } from "vue"

// PROPS
const props = defineProps({
  initialValue: {
    type: Number,
    required: true
  },
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  }
})

// EMITS
const emit = defineEmits(["updateValue", "updateJobsLeft"])

// REFS
const sliderValue = ref(props.initialValue)

// COMPUTED
const sliderValueLabel = computed(() => {
  return numberAsCurrency(sliderValue.value)
})

// FUNCTIONS
const calculateGradientColor = (value: number) => {
  const startColor = { r: 250, g: 122, b: 104 } // #FA7A68
  const middleColor = { r: 251, g: 188, b: 5 } // #FBBC05
  const endColor = { r: 43, g: 162, b: 117 } // #2BA275
  let r, g, b: number

  const midPoint = (props.max + props.min) / 2

  if (value <= midPoint) {
    const ratio = (value - props.min) / (midPoint - props.min) // Adjust ratio for first half
    r = Math.round(startColor.r + ratio * (middleColor.r - startColor.r))
    g = Math.round(startColor.g + ratio * (middleColor.g - startColor.g))
    b = Math.round(startColor.b + ratio * (middleColor.b - startColor.b))
  } else {
    const ratio = (value - midPoint) / (props.max - midPoint) // Adjust ratio for second half
    r = Math.round(middleColor.r + ratio * (endColor.r - middleColor.r))
    g = Math.round(middleColor.g + ratio * (endColor.g - middleColor.g))
    b = Math.round(middleColor.b + ratio * (endColor.b - middleColor.b))
  }

  return `rgb(${r}, ${g}, ${b})`
}

const handleSliderValue = () => {
  updateThumbColor()
  emit("updateValue", sliderValue.value)
}

const updateThumbColor = () => {
  const color = calculateGradientColor(sliderValue.value)
  const slider = document.querySelector(".slider") as HTMLElement | null
  slider?.style.setProperty("--thumb-color", color)
}

const updateThumbLabel = () => {
  const sliderThumb = document.querySelector(".slider::-webkit-slider-thumb") as HTMLElement | null
  const valueLabel = `${sliderValue.value}` // Convert the number to a string
  sliderThumb?.setAttribute("data-value", valueLabel)
}

// Watch for changes in the slider's value
watch(sliderValue, () => {
  handleSliderValue()
  updateThumbLabel()
  updateThumbColor()
})

onMounted(() => {
  updateThumbColor()
  updateThumbLabel()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
.i_target-salary-slider {
  width: 100%;
  max-width: 432px;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #fff;
  padding: 24px;
  margin: auto;

  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .label-min,
    .label-max {
      @include text-style($size: 16px, $weight: 700, $line-height: 18px);
    }

    .label-min {
      text-align: left;
    }

    .label-max {
      text-align: right;
    }
  }
  .slider {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #fa7a68 0%, #fbbc05 50%, #2ba275 100%);
    border-radius: var(--0, 0px);
    opacity: 0.8;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 8px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: var(--thumb-color); // Use the custom property here
      cursor: pointer;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 3px solid $white;
    }

    &:hover {
      opacity: 1;
    }
  }

  .slider-value-label {
    margin-top: 8px;
    @include flex();
    @include text-style($size: 16px, $weight: 500, $line-height: 18px);
  }
}
</style>
