<template>
  <div class="i_report">
    <iBackground color="white" />

    <div v-if="reportStore.adjustingReport || reportStore.regeneratingJobs">
      <iNavbarSimple />
      <iLoaderSimple :loading-animation-duration-ms="data.loadingAnimationDurationMs">
        <template v-slot:title>Test adjustment complete!</template>
        <template v-slot:subtitle
          >We are processing your test adjustments and generating your updated package. This process may take a few
          minutes, please do not refresh this page...</template
        >
      </iLoaderSimple>
    </div>

    <div v-else>
      <template v-if="reportStore.invalidData">
        <iNavbarSimple />
        <iUnavailableCard
          text="Please try again later. If the issue persists please contact us at support@jobtest.org"
          title="Report unavailable"
        />
      </template>

      <template v-else>
        <template v-if="isFullScreenSection">
          <RouterView @open-modal-payment="openModalPayment()" />
        </template>

        <template v-else>
          <div class="dashboard">
            <div class="wrap">
              <div>
                <template v-if="hasNotifications">
                  <iGlobalNotification />
                </template>
                <template v-else-if="!userStore.isTierPremium">
                  <iCoachingNotice />
                </template>

                <iReportNavbar />

                <iReportHero />

                <div class="content">
                  <div class="grid">
                    <div class="anchors">
                      <template v-if="data.showAnchors">
                        <iOnboardingTooltip :value="1" />
                        <h3 class="title">Explore your result</h3>
                        <div class="navigator">
                          <iPageAnchors
                            :anchors="data.anchors"
                            :active-anchor="data.activeAnchor"
                            @set-active="setAnchorActive($event)"
                          />
                        </div>
                      </template>
                    </div>

                    <div
                      class="router-view"
                      ref="routerViewRef"
                    >
                      <RouterView
                        @open-modal-payment="openModalPayment()"
                        @load-anchors="loadPageAnchors($event)"
                      />
                    </div>

                    <div class="career-navigator">
                      <iOnboardingTooltip
                        positionLeft
                        :value="2"
                      />
                      <h3 class="title">Your top careers</h3>
                      <div class="navigator">
                        <iCareerNavigator @open-modal-payment="openModalPayment" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="career-navigator-mobile print-hide">
                  <iCareerNavigatorMobile @open-modal-payment="openModalPayment" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <iModalPayment
          v-bind="data.modalPaymentDetails"
          @click-on-close="closeModalPayment"
        >
          <template v-slot:center>
            <iPricingTable />
          </template>
        </iModalPayment>

        <iModalMessage
          v-bind="data.modalMessageDetails"
          @click-on-button="closeModalMessage"
          @click-on-close="closeModalMessage"
        />

        <iBaseModal
          visible
          class="zigpoll-modal"
          :class="{ visible: data.showZigpollModal }"
          max-width="350px"
          :show-close-icon="false"
        >
          <div class="caption">Please complete this survey.</div>
          <div id="zigpoll-embed-target-report"></div>
          <div
            class="close"
            @click="data.showZigpollModal = false"
          >
            I decline to contribute.
          </div>
        </iBaseModal>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointXl } from "@/styles/variables"
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue"
import { RouterView, useRoute } from "vue-router"

// STORES AND HELPERS
import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"

import useIsScreenSize from "@/composables/useIsScreenSize"
import hasVisitedOnboarding from "@/composables/useOnboarding"

// COMPONENTS
import iBaseModal from "@/components/base/BaseModal.vue"
import iBackground from "@/components/global/Background.vue"
import iLoaderSimple from "@/components/global/LoaderSimple.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"
import iUnavailableCard from "@/components/global/UnavailableCard.vue"
import iModalMessage from "@/components/modals/ModalMessage.vue"
import iModalPayment from "@/components/modals/ModalPayment.vue"
import iPricingTable from "@/components/pricing/PricingTable.vue"
import iCareerNavigator from "@/components/report/CareerNavigator.vue"
import iCareerNavigatorMobile from "@/components/report/CareerNavigatorMobile.vue"
import iGlobalNotification from "@/components/report/GlobalNotification.vue"
import iCoachingNotice from "@/components/report/navbar/CoachingNotice.vue"
import iReportNavbar from "@/components/report/navbar/ReportNavbar.vue"
import iOnboardingTooltip from "@/components/report/OnboardingTooltip.vue"
import iPageAnchors from "@/components/report/PageAnchors.vue"
import iReportHero from "@/components/report/ReportHero.vue"

// TYPES
import type { PageAnchors } from "@/types/pageAnchors"

// STORES AND ROUTES
const activeRoute = useRoute()
const reportStore = useReportStore()
const userStore = useUserStore()

// COMPOSABLES
const isLargeScreen = useIsScreenSize(breakpointXl)

// CONSTANTS
const DEFAULT_MODAL_MESSAGE_DETAILS = {
  buttonText: "",
  text: "",
  title: "",
  visible: false
}

const ZIGPOLL_DELAY_MS = 120000

// REFS
const routerViewRef = ref<HTMLElement | null>(null)
const observer = ref<MutationObserver>()
const zigpollRef = ref<HTMLElement | null>(null)

// DATA (reactive object with properties)

const data = reactive({
  loadingAnimationDurationMs: 4000,
  modalMessageDetails: { ...DEFAULT_MODAL_MESSAGE_DETAILS },
  modalPaymentDetails: { visible: false },
  showZigpollModal: false,
  showAnchors: false,
  anchors: {} as PageAnchors,
  activeAnchor: ""
})

// COMPUTED

/**
 * Check if the current route is a full screen section, meaning that only the content of the route should be displayed, without the menu.
 */
const isFullScreenSection = computed(() => {
  return activeRoute.meta?.isFullScreen
})

const hasNotifications = computed(() => {
  return reportStore.notificationSystem.notifications.length > 0
})

// FUNCTIONS

function closeModalPayment() {
  data.modalPaymentDetails.visible = false
}

function openModalPayment() {
  data.modalPaymentDetails.visible = true
}

function closeModalMessage() {
  data.modalMessageDetails = { ...DEFAULT_MODAL_MESSAGE_DETAILS }
}

// FUNCTIONS (REPORT LOADING)

async function initPageSetup() {
  reportStore.notifyIfFewJobs()
  reportStore.notifyIfSkippedQuestions()
  reportStore.notifyIfNoJobs()
  reportStore.notifyIfReportOutdated()

  const handleZigpollByDelay = () => {
    setTimeout(() => {
      if (activeRoute.name === GlobalVariables.urls.report.children.feedback.routeName) return handleZigpollByDelay()
      if (sessionStorage.getItem("free-upgrade-report-zigpoll-loaded") || reportStore.reportZigpollLoaded) return

      reportStore.loadZigpoll()
      reportStore.renderZigpoll(reportStore.getReportZigpollId)
      if (reportStore.isFreeUpgradeEligible) sessionStorage.setItem("free-upgrade-report-zigpoll-loaded", "true")
    }, ZIGPOLL_DELAY_MS)
  }
}

const onEmbedZigpoll = (mutationsList: MutationRecord[]) => {
  for (const mutation of mutationsList) if (mutation.type === "childList") data.showZigpollModal = true
  if (observer.value) observer.value.disconnect()
  reportStore.deactivateZigpollReload()
}

const scrollToRouterView = () => {
  nextTick(() => {
    const onboardingTooltip = document.querySelector(".i_onboarding-tooltip")
    const offset = onboardingTooltip ? 206 : 98
    const element = onboardingTooltip ? onboardingTooltip : routerViewRef.value

    if (element) {
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    }
  })
}

const loadPageAnchors = (anchors: PageAnchors) => {
  data.anchors = anchors
  data.activeAnchor = Object.keys(anchors)[0]
  data.showAnchors = true

  // Listen to update active anchors on manual scroll
  window.addEventListener("scroll", updateActiveAnchorOnScroll)
}

const setAnchorActive = (anchor: string) => {
  GlobalFunctions.scrollInToElement(`#${anchor}`)
  data.activeAnchor = anchor
}

/**
 * Checks if a section meets every condition to set it's anchor as the active anchor
 * @param element - The element to check if it is actively visible
 * @param previousElement - The previous element to check if it's top is out of view
 * @returns - True if the element is scrolled into view, false otherwise
 */
const elementIsScrolledIntoView = (element: string, previousElement: string) => {
  const docViewTop = document.body.scrollTop
  const docViewMiddle = docViewTop + window.innerHeight / 1.5
  const docViewBottom = docViewTop + window.innerHeight

  const elemTop = (document.getElementById(element) as HTMLElement).getBoundingClientRect()?.top
  const elemBottom = elemTop + (document.getElementById(element) as HTMLElement).getBoundingClientRect()?.height

  const prevElemTop = (document.getElementById(previousElement) as HTMLElement).getBoundingClientRect()?.top

  // Return true if the top of the previous section is not still within the screen
  // AND
  // either it's top is above a third of the viewed screen or its bottom is within the viewed screen
  return prevElemTop < docViewTop + 85 && (elemTop <= docViewMiddle || elemBottom <= docViewBottom)
}

/**
 * Updates the active anchor as user scrolls on report page
 */
const updateActiveAnchorOnScroll = () => {
  const currentSection = Object.keys(data.anchors).reduce((a, b) => (elementIsScrolledIntoView(b, a) ? b : a))
  if (currentSection !== data.activeAnchor) data.activeAnchor = currentSection
}

// LICYCLE HOOKS

onMounted(() => {
  initPageSetup()
  if (!hasVisitedOnboarding()) reportStore.setTooltipData(isLargeScreen.value)

  observer.value = new MutationObserver(onEmbedZigpoll)
  const config = { childList: true, subtree: true, attributes: true }
  nextTick(() => {
    zigpollRef.value = document.querySelector("#zigpoll-embed-target-report")
    if (zigpollRef.value) observer.value?.observe(zigpollRef.value, config)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", updateActiveAnchorOnScroll)
})

// WATCHERS

watch(activeRoute, () => {
  scrollToRouterView()
})

watch(
  () => activeRoute.name,
  () => {
    // Default each new route to not show anchors
    data.showAnchors = false

    window.removeEventListener("scroll", updateActiveAnchorOnScroll)
  }
)
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_report {
  .dashboard {
    .wrap {
      width: 100%;
      .content {
        width: 100%;

        @include flex(column, start, center);
        .grid {
          width: 100%;
          padding: 24px 24px 96px 24px; // large padding bottom to avoid footer overlap
          display: block;

          @media screen and (min-width: $breakpointMd) {
            padding: 24px 48px 96px 48px; // large padding bottom to avoid footer overlap
          }

          @media screen and (min-width: $breakpointLg) {
            padding: 24px 48px;
            display: grid;
            gap: 24px;
            width: fit-content;
            grid-template-columns: minmax(auto, 720px) 268px;
          }

          @media screen and (min-width: $breakpointXl) {
            gap: 48px;
            grid-template-columns: 268px minmax(auto, 720px) 268px;
          }

          @media screen and (min-width: $breakpointXxl) {
            gap: 64px;
          }

          .router-view {
            width: 100%;
          }

          .anchors,
          .career-navigator {
            position: relative;
            display: none;
            width: 100%;

            .title {
              display: none;

              @media screen and (min-width: $breakpointLg) {
                display: block;
                @include text-md;
                font-weight: 700;
                color: $pickleBluewood900;
                margin: 0;
              }
            }

            .navigator {
              width: 100%;
              padding: 18px 0;

              @media screen and (min-width: $breakpointLg) {
                height: calc(100vh - 80px);
                position: sticky;
                top: 80px;
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }

          .career-navigator {
            @media screen and (min-width: $breakpointLg) {
              display: block;
            }
          }

          .anchors {
            @media screen and (min-width: $breakpointXl) {
              display: block;
            }

            .navigator {
              @media screen and (min-width: $breakpointLg) {
                height: auto;
              }
            }
          }
        }
      }

      .career-navigator-mobile {
        display: block;

        @media screen and (min-width: $breakpointLg) {
          display: none;
        }
      }
    }
  }

  .zigpoll-modal {
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    .caption {
      @include text-md;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .close {
      @include text-sm;
      color: $black300;
      margin-top: 15px;
      font-weight: 300;
      cursor: pointer;
    }
  }
}

@media print {
  .i_report {
    background-color: none !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    filter: opacity(1);
    -webkit-filter: opacity(1);

    .content {
      padding-bottom: 0px !important;
    }
  }

  .print-hide {
    display: none !important;
  }

  @page {
    size: 210mm 253mm;
    margin: 0mm;
  }
}
</style>
