<template>
  <div class="i_peers-comparison">
    <div class="wrap">
      <div class="title">
        You are currently earning <span>{{ currentPotential }}</span> than your peers
      </div>
      <div class="description">You have the opportunity to earn up to {{ potentialPeer }} than your peers.</div>
    </div>

    <div class="wrap is-right">
      <img
        class="image"
        :src="illustrationWeKnowChart"
        alt=""
      />

      <div class="current-salary">
        <div class="salary-text">Current salary</div>
        <div class="salary-value">{{ currentSalary }}</div>
      </div>

      <div class="peer-salary">
        <div class="salary-text">Peer salary</div>
        <div class="salary-value">{{ potentialSalary }}</div>
      </div>

      <div class="range-salary">
        <span>{{ salaryRanges.bottom }}</span>
        <span>{{ salaryRanges.middle }}</span>
        <span>{{ salaryRanges.high }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import illustrationWeKnowChart from "@/assets/welcome/illustration-we-know-chart.svg"

// STORES
const reportStore = useReportStore()

// COMPUTED
const potentialPeer = computed(() => {
  const comparison = reportStore.salaryStatistics?.comparisons?.potentialPeerPercent
  return `${comparison}% more`
})

const currentPotential = computed(() => {
  const comparison = reportStore.salaryStatistics?.comparisons?.currentPotentialPercent
  return `${-1 * comparison}% less`
})

const currentSalary = computed(() => reportStore.salaryStatistics?.currentSalary)
const potentialSalary = computed(() => reportStore.salaryStatistics?.potentialSalary)
const salaryRanges = computed(() => reportStore.salaryStatistics?.salaryRanges)
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_peers-comparison {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;

  @media screen and (min-width: $breakpointMd) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (min-width: $breakpointLg) {
      align-items: start;
    }

    &.is-right {
      position: relative;
      width: fit-content;
      margin: auto;
    }
  }

  .title {
    @include display-sm;
    font-weight: 600;
    margin-right: auto;
    max-width: 400px;

    @media screen and (min-width: $breakpointLg) {
      max-width: 350px;
    }

    span {
      background-color: $bittersweet700;
      border-radius: 64px;
      color: $white;
      display: inline-block;
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  .description {
    @include text-md;
    margin-right: auto;

    @media screen and (min-width: $breakpointLg) {
      max-width: 280px;
    }
  }

  .image {
    border: 1px solid $black100;
    border-radius: 22px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
    max-width: 375px;
    width: 100%;

    @media screen and (min-width: $breakpointLg) {
      max-width: 420px;
    }
  }

  .current-salary {
    position: absolute;
    top: 29%;
    left: 14%;
    border: 1px solid $black100;
    border-radius: 8px;
    padding: 8px;
    background-color: $white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .peer-salary {
    position: absolute;
    top: 4%;
    right: 25%;
    padding: 8px;
    background-color: $white;
  }

  .salary-text {
    color: $black500;
    font-size: 10px;
    text-align: center;
  }

  .salary-value {
    color: $black900;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  .range-salary {
    bottom: 5%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    left: 0%;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    right: 0%;
    width: 100%;

    span {
      color: $black700;
      font-size: 14px;
    }
  }
}
</style>
