<template>
  <div class="i_campaign-v1">
    <iBackground color="white" />

    <iCampaignCountdown
      ref="campaignCountdown"
      position="fixed"
      :timerDelayMs="INITIAL_DELAY"
      :timerDurationMin="data.timerDurationMin"
      @ctaClicked="onCtaClick()"
    />

    <div class="content">
      <div class="caption">
        {{ PAGE_DATA[activeRouteName].coach }}'s Message to
        <span :style="{ textTransform: 'capitalize' }">{{ name }}</span>
      </div>
      <div class="info">Watch the video below.</div>

      <video
        :src="PAGE_DATA[activeRouteName].videoUrl"
        controls
        autoplay
        muted
        playsinline
        type="video/mp4"
      ></video>

      <div class="cta">
        <iBaseButton
          v-bind="buttonStyle"
          text="Check Availability"
          @click="onCtaClick()"
        />
        <div class="slots">
          <div class="pulse-circle"></div>
          There are only <strong>{{ openSlots }} slots</strong> remaining
        </div>
      </div>

      <div
        v-if="data.showRemainingContent"
        class="extra"
      >
        <iCampaignRoadmap
          price="99.90"
          :coach="PAGE_DATA[activeRouteName].coach"
          @ctaClicked="onCtaClick()"
        />
        <div ref="embedCalendarRef">
          <iCalcomScheduler
            :embedLink="`realign-coach-${PAGE_DATA[activeRouteName].coach.toLocaleLowerCase()}/pe-60-me-dc`"
          />
        </div>
        <iCampaignReview
          @ctaClicked="onCtaClick()"
          :coach="PAGE_DATA[activeRouteName].coach"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue"
import { useRoute } from "vue-router"
import { useUserStore } from "@/stores/user"
import { breakpointMd } from "@/styles/variables"
import { astronaut600, azureRadiance100, white } from "@/styles/colors"

import { GlobalVariables } from "@/helpers/globalVariables"

import useIsScreenSize from "@/composables/useIsScreenSize"
import useRandomNumber from "@/composables/useRandomNumber"

import iBackground from "@/components/global/Background.vue"
import iBaseButton from "@/components/base/BaseButton.vue"
import iCampaignCountdown from "@/components/lifecycle/CampaignCountdown.vue"
import iCampaignRoadmap from "@/components/lifecycle/CampaignRoadmap.vue"
import iCampaignReview from "@/components/lifecycle/CampaignReview.vue"
import iCalcomScheduler from "@/components/global/CalcomScheduler.vue"

// ROUTES
const activeRoute = useRoute()
const activeRouteName = activeRoute.name as string
const name = activeRoute.query["name"] || "You"

// STORES
const userStore = useUserStore()

//COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)
const openSlots = useRandomNumber(userStore.getFullName || "Full Name", 3, 5)

//REFS
const embedCalendarRef = ref<HTMLElement | null>(null)
const campaignCountdown = ref<HTMLElement | null>(null)

// CONST
const INITIAL_DELAY = 60000
const PAGE_DATA = {
  [GlobalVariables.urls.lifecycle.children.lutz60.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/linda-lutz-60.mp4",
    coach: "Linda"
  },
  [GlobalVariables.urls.lifecycle.children.lutz60b.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/linda-lutz-60-b.mp4",
    coach: "Linda"
  },
  [GlobalVariables.urls.lifecycle.children.lutzrcs1.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/linda-lutz-rcs-1.mp4",
    coach: "Linda"
  },
  [GlobalVariables.urls.lifecycle.children.lutzrcs2.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/linda-lutz-rcs-2.mp4",
    coach: "Linda"
  },
  [GlobalVariables.urls.lifecycle.children.lutzrcs3.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/linda-lutz-rcs-3.mp4",
    coach: "Linda"
  },
  [GlobalVariables.urls.lifecycle.children.marlo60.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/Marlo_Intro_100K.mp4",
    coach: "Marlo"
  },
  [GlobalVariables.urls.lifecycle.children.marlorcs1.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/Marlo_RCS_1.mp4",
    coach: "Marlo"
  },
  [GlobalVariables.urls.lifecycle.children.marlorcs2.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/Marlo_RCS_2.mp4",
    coach: "Marlo"
  },
  [GlobalVariables.urls.lifecycle.children.marlorcs3.routeName]: {
    videoUrl: "https://jobtestorg-misc.s3.us-east-2.amazonaws.com/app-assets/lifecycle-campaign/Marlo_RCS_3.mp4",
    coach: "Marlo"
  }
}

// DATA
const data = reactive({
  timerDurationMin: 15,
  showRemainingContent: false
})

// COMPUTED
const buttonStyle = computed(() => {
  return {
    backgroundColor: astronaut600,
    borderRadius: "8px",
    fontWeight: "600",
    size: isDesktop.value ? "medium" : "small",
    textColor: white,
    width: "100%",
    pulseColor: azureRadiance100
  }
})

// FUNCTIONS
const scrollToEmbed = () => {
  const element = embedCalendarRef.value
  if (element) {
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - 76

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    })
  }
}

const onCtaClick = () => {
  if (!data.showRemainingContent) {
    // Force show remaining content of body
    data.showRemainingContent = true

    // Force show the report countdown
    if (campaignCountdown.value) (campaignCountdown.value as any)?.makeActive?.()

    // Scroll to embed after a second delay to allow calendar load
    setTimeout(() => {
      scrollToEmbed()
    }, 1000)
  } else scrollToEmbed()
}

//LIFECYCLE HOOKS
onMounted(() => {
  setTimeout(() => (data.showRemainingContent = true), INITIAL_DELAY)
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_campaign-v1 {
  .content {
    max-width: 960px;
    margin: auto 10px;
    padding-top: 105px;

    @media screen and (min-width: $breakpointMd) {
      margin: auto;
      padding-bottom: 50px;
    }

    .caption {
      @include display-sm;
      font-weight: 700;

      @media screen and (min-width: $breakpointSm) {
        text-align: center;
      }

      @media screen and (min-width: $breakpointMd) {
        @include display-lg;
      }
    }

    .info {
      @include text-sm;
      font-weight: 500;
      color: $black500;
      margin-top: 5px;

      @media screen and (min-width: $breakpointSm) {
        @include text-lg;
        margin-top: 10px;
        text-align: center;
      }
    }

    video {
      width: 100%;
      margin: 25px 0;
    }

    .cta {
      width: 100%;
      max-width: 500px;
      margin: auto;

      .slots {
        @include flex(row, $gap: 6px);
        width: 85%;
        text-align: center;
        margin: 15px auto 0 auto;

        @media screen and (min-width: $breakpointSm) {
          width: 70%;
        }

        .pulse-circle {
          width: 10px;
          height: 10px;
          @include border-radius-rounded;
          background-color: $sorbus500;
          position: relative;

          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
            }
            50% {
              box-shadow: 0 0 0 5px rgba(255, 146, 10, 0.2);
            }
            100% {
              box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
            }
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            @include border-radius-rounded;
            z-index: 1;
            animation: pulse 2s forwards infinite;
            animation-timing-function: linear;
          }
        }
      }
    }

    .extra {
      animation: fadeInAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
