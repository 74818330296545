<template>
  <div class="i_quiz-completion">
    <div
      id="js-card-1"
      class="card-simple"
      v-show="data.showCard1"
    >
      <div class="top">
        <div>
          <img
            class="img"
            :src="animationDB"
            alt=""
          />
        </div>

        <div>
          <span class="msg">Generating Your Report</span>
        </div>
      </div>

      <div class="bottom">
        <div>
          <img
            class="animation-mobile"
            :src="animationMobile"
            alt=""
          />
        </div>

        <div>
          <Vue3Lottie
            class="animation-desktop"
            :animationData="animationDesktop"
          />
        </div>
      </div>
    </div>

    <div
      id="js-card-2"
      class="card-detailed"
      v-show="data.showCard2"
    >
      <div class="section-top">
        <div class="title">
          <div>
            <img :src="iconPerson" />
          </div>
          <div>
            <span>Analyzing Your Career Personality</span>
          </div>
        </div>

        <div class="subtitle">
          <span>Your personality will be analyzed using the following attributes:</span>
        </div>

        <div class="items">
          <iBaseBulletItem
            v-for="(item, index) in data.listCareerPersonality"
            :key="index"
            :iconUrl="iconGreenCheckmark"
            :text="item.title"
          />
        </div>
      </div>

      <div class="section-animation">
        <img :src="animationExagram" />
      </div>

      <div class="section-loader">
        <span class="green-text">Analyzing your personality...</span>

        <i-base-loading-bar
          :fillColor="brandOpacity100"
          barWidth="100%"
          barHeight="24px"
          class="loading-bar"
          :loadingAnimationDurationMs="data.uiLoaderTimerInMs"
        />

        <span>Thank you for your patience...</span>
      </div>

      <div class="section-review">
        <div>
          <div>
            <span><strong>Renae A.</strong></span>
          </div>

          <div>
            <span class="report-type">COMPREHENSIVE</span>
          </div>

          <div class="location">
            <div>
              <img :src="iconLocation" />
            </div>

            <span> Nashville, TN </span>
          </div>

          <div class="stars">
            <iBaseStars :rating="5" />
          </div>
        </div>

        <div class="review">
          <div>
            <span><strong>Surprisingly insightful with just a few tweaks needed</strong></span>
          </div>

          <div>
            <span>
              I purchased their comprehensive career report and was genuinely taken aback by its accuracy. It mirrored a
              lot of my thoughts about potential job roles, and honestly, it felt like it really got me. There are minor
              design tweaks they could work on for a better experience, but when compared to other reports I've
              explored, this one truly stands out. If you're searching for some clarity on your career path, I'd give
              this a shot.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      id="js-card-3"
      class="card-detailed"
      v-show="data.showCard3"
    >
      <div class="section-top">
        <div class="title">
          <div>
            <img :src="iconFolder" />
          </div>
          <div>
            <span>Identifying Your Ideal Careers</span>
          </div>
        </div>

        <div class="subtitle">
          <span>
            Our algorithm uses machine learning to identify your ideal careers based on the following criteria:
          </span>
        </div>

        <div class="items">
          <iBaseBulletItem
            v-for="(item, index) in data.listIdealCareers"
            :key="index"
            :iconUrl="iconGreenCheckmark"
            :text="item.title"
          />
        </div>
      </div>

      <div class="section-animation">
        <img :src="animationLaptop" />
      </div>

      <div class="section-loader">
        <span class="green-text">Identifying ideal careers...</span>

        <i-base-loading-bar
          :fillColor="brandOpacity100"
          barWidth="100%"
          barHeight="24px"
          class="loading-bar"
          :loadingAnimationDurationMs="data.uiLoaderTimerInMs"
        />

        <span>Thank you for your patience...</span>
      </div>

      <div class="section-review">
        <div>
          <div>
            <span><strong>Mary D.</strong></span>
          </div>

          <div>
            <span class="report-type">COMPREHENSIVE</span>
          </div>

          <div class="location">
            <div>
              <img :src="iconLocation" />
            </div>

            <span>Plano, TX</span>
          </div>

          <div class="stars">
            <iBaseStars :rating="5" />
          </div>
        </div>

        <div class="review">
          <div>
            <span><strong>Best 20 minutes I spent in my life!</strong></span>
          </div>

          <div>
            <span>
              Ever since I graduated from a local community college, I have been job-hopping as a server from one
              fast-food chain to another for the past ten years or so, because I had to pay off my student loan. I
              wanted to change my career trajectory when I was laid off from McDonald’s because of COVID. I stumbled
              upon jobtest.org, and this changed my life. Not only they gave me solid job recommendations, but also,
              they connected me with different service providers that helped me realign my future.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      id="js-card-4"
      class="card-detailed"
      v-show="data.showCard4"
    >
      <div class="section-top">
        <div class="title">
          <div>
            <img :src="iconLight" />
          </div>
          <div>
            <span>Collecting Data on Your Ideal Careers </span>
          </div>
        </div>

        <div class="subtitle">
          <span
            >We’re searching thousands of databases in real time to compile the most accurate data possible about your
            matches, such as:
          </span>
        </div>

        <div class="items">
          <iBaseBulletItem
            v-for="(item, index) in data.listJobTraits"
            :key="index"
            :iconUrl="iconGreenCheckmark"
            :text="item.title"
          />
        </div>
      </div>

      <div class="section-animation">
        <img :src="animationSources" />
      </div>

      <div class="section-loader">
        <span class="green-text">Identifying ideal careers...</span>

        <i-base-loading-bar
          :fillColor="brandOpacity100"
          barWidth="100%"
          barHeight="24px"
          class="loading-bar"
          :loadingAnimationDurationMs="data.uiLoaderTimerInMs"
        />

        <span>Thank you for your patience...</span>
      </div>

      <div class="section-review">
        <div>
          <div>
            <span><strong>Jonathan P.</strong></span>
          </div>

          <div>
            <span class="report-type">PREMIUM</span>
          </div>

          <div class="location">
            <div>
              <img :src="iconLocation" />
            </div>

            <span>{{ data.userLocation }}</span>
          </div>

          <div class="stars">
            <iBaseStars :rating="5" />
          </div>
        </div>

        <div class="review">
          <div>
            <span><strong>Does what it promises and more.</strong></span>
          </div>

          <div>
            <span>
              I was looking to switch my career and wanted to get further insights by talking to an insider. After a
              week or so, I was paired with Daniel, a project manager at one of my dream companies. He not only shared
              insights into what he did to land a job and tips on resumes and interviews but also offered a reference
              for an open position near me. I am currently preparing a technical interview for this job, but my
              experience with jobtest.org has been nothing but incredible.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      id="js-card-5"
      class="card-detailed"
      v-show="data.showCard5"
    >
      <div class="section-top">
        <div class="title">
          <div>
            <img :src="iconCheckMark" />
          </div>
          <div>
            <span>Success! Creating Your Personalized Report</span>
          </div>
        </div>

        <div class="subtitle">
          <span>Your personalized report is unique to you and includes the following:</span>
        </div>

        <div class="items">
          <iBaseBulletItem
            v-for="(item, index) in data.listReportFeatures"
            :key="index"
            :iconUrl="iconGreenCheckmark"
            :text="item.title"
          />
        </div>
      </div>

      <div class="section-animation">
        <img
          :src="animationGeneratingReport"
          alt="animation"
        />
      </div>

      <div class="section-loader">
        <span class="green-text">Generating your personalized career report...</span>

        <i-base-loading-bar
          :fillColor="brandOpacity100"
          barWidth="100%"
          barHeight="24px"
          class="loading-bar"
          :loadingAnimationDurationMs="data.uiLoaderTimerInMs"
        />

        <span>Thank you for your patience...</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from "vue"

import { Vue3Lottie } from "vue3-lottie"

import { brandOpacity100 } from "@/styles/colors"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { capitalizeWords } from "@/composables/capitalizeWords"

import ApiService from "@/services/apiService"

import animationDB from "@/assets/jobTest/animation-loading-db.gif"
import animationDesktop from "@/assets/jobTest/animation-loading-desktop.json"
import animationGeneratingReport from "@/assets/jobTest/animation-generating-report.gif"
import animationMobile from "@/assets/jobTest/animation-loading-mobile-1.gif"
import animationExagram from "@/assets/jobTest/animation-exagram.gif"
import animationLaptop from "@/assets/jobTest/animation-laptop.gif"
import animationSources from "@/assets/jobTest/animation-sources.gif"
import iconGreenCheckmark from "@/assets/icons/icon-green-checkmark.svg"
import iconCheckMark from "@/assets/jobTest/icon-checkmark.svg"
import iconFolder from "@/assets/jobTest/icon-folder.png"
import iconLight from "@/assets/jobTest/icon-light.webp"
import iconLocation from "@/assets/jobTest/icon-location.svg"
import iconPerson from "@/assets/jobTest/icon-person.webp"

import iBaseBulletItem from "@/components/base/BaseBulletItem.vue"
import iBaseLoadingBar from "@/components/base/BaseLoadingBar.vue"
import iBaseStars from "@/components/base/BaseStars.vue"
// TYPES

type geoCodeResponse = {
  results: { address_components: { types: string[]; short_name: string }[] }[]
}

// EMITS

const emit = defineEmits(["animationCompleted"])

// CONST

const DEFAULT_USER_LOCATION = "Sacramento, CA"
const DEFAULT_USER_NAME = "You"

// PROPS

const props = defineProps({
  userName: {
    type: String,
    required: false
  },
  userZipCode: {
    type: String,
    required: false
  }
})

// DATA (reactive object with properties)

const data = reactive({
  cardId: "js-card",
  loadingReviewTitle: "Room for improvement, but better that anything out in the market",
  loadingReviewDescription:
    " purchased a comprehensive report to get a more thorough insight into the career path that would best\n              suit me. It described me well and even recommended jobs I was already considering. Although the design had some issues, like non-functional buttons, this was still better than any other report I tried elsewhere.",
  listCareerPersonality: [
    { title: "Communal" },
    { title: "Bold" },
    { title: "Analytical" },
    { title: "Orthodox" },
    { title: "Pragmatic" },
    { title: "Imaginative" }
  ],
  listIdealCareers: [
    { title: "Compensation" },
    { title: "Job Satisfaction" },
    { title: "Education Level" },
    { title: "Transferable Skill" },
    { title: "Personality Compatibility" },
    { title: "Alignment With Values" }
  ],
  listJobTraits: [
    { title: "Work-Life Balance" },
    { title: "Location and Accessibility" },
    { title: "Advancement Opportunities" },
    { title: "Salary Ranges" },
    { title: "Job Security" },
    {
      title: "Useful Skills and Experience",
      isCompleted: false
    }
  ],
  listReportFeatures: [
    { title: "Personality Analysis" },
    { title: "Recommended Careers" },
    { title: "Step-by-Step Career Guidance" },
    { title: "Industry Data Analysis" },
    { title: "100% Money-Back Guarantee" }
  ],
  showCard1: false,
  showCard2: false,
  showCard3: false,
  showCard4: false,
  showCard5: false,
  uiLoaderTimerInMs: 15000,
  uiLoaderOffset: 1000,
  userName: "",
  userLocation: ""
})

// FUNCTIONS
async function populateUI(): Promise<void> {
  data.userName = props.userName || DEFAULT_USER_NAME

  const userLocation = await getLocationGeocode(props.userZipCode)
  data.userLocation = userLocation || DEFAULT_USER_LOCATION
}

async function startUIAnimation(): Promise<void> {
  data.showCard1 = true
  await GlobalFunctions.delayInMiliseconds(data.uiLoaderTimerInMs * (2 / 3))
  data.showCard1 = false

  data.showCard2 = true

  await GlobalFunctions.delayInMiliseconds(data.uiLoaderTimerInMs - data.uiLoaderOffset)

  data.showCard3 = true

  await GlobalFunctions.delayInMiliseconds(data.uiLoaderOffset)
  GlobalFunctions.scrollInToElement(`#${data.cardId}-3`)
  await GlobalFunctions.delayInMiliseconds(data.uiLoaderTimerInMs - data.uiLoaderOffset)

  data.showCard4 = true

  await GlobalFunctions.delayInMiliseconds(data.uiLoaderOffset)
  GlobalFunctions.scrollInToElement(`#${data.cardId}-4`)
  await GlobalFunctions.delayInMiliseconds(data.uiLoaderTimerInMs - data.uiLoaderOffset)

  data.showCard5 = true

  await GlobalFunctions.delayInMiliseconds(data.uiLoaderOffset)
  GlobalFunctions.scrollInToElement(`#${data.cardId}-5`)
  await GlobalFunctions.delayInMiliseconds(data.uiLoaderTimerInMs - data.uiLoaderOffset)

  emit("animationCompleted")
}

function getLocationGeocode(zipCode: string | undefined): Promise<string | undefined> | undefined {
  if (!zipCode) return

  const endPoint = import.meta.env.VITE_APP_GOOGLE_CLOUD_GEOLOCATE_API_ENDPOINT as string
  const apiKey = import.meta.env.VITE_APP_GOOGLE_CLOUD_GEOLOCATE_API_KEY as string
  const fullUrl = `${endPoint}?address=${zipCode}&key=${apiKey}`

  return fetch(fullUrl).then(ApiService.verifyResponse).then(returnLocationGeoCode)
}

function returnLocationGeoCode(response: geoCodeResponse): string | undefined {
  if (response.results.length === 0 || !response.results[0].address_components) return

  let neighborhood
  let city
  let state

  response.results[0].address_components.forEach((entry) => {
    if (entry.types.includes("neighborhood")) neighborhood = entry.short_name
    if (entry.types.includes("locality")) city = entry.short_name
    if (entry.types.includes("administrative_area_level_1")) state = entry.short_name
  })

  // Sometimes City or Neighborhood are Uppercase
  city = city ? capitalizeWords(city) : city
  neighborhood = neighborhood ? capitalizeWords(neighborhood) : neighborhood

  return `${city || neighborhood}, ${state}`
}

// MOUNTED

onMounted(() => {
  populateUI()
  startUIAnimation()
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_quiz-completion {
  padding-bottom: 45vh;

  .card-simple {
    @include card($shadow: true);
    margin-bottom: 32px;

    .top {
      text-align: center;

      .img {
        height: 72px;
        width: 72px;
        min-width: 72px;
        min-height: 72px;
        margin: 0px auto 8px auto;
      }

      .msg {
        @include font-style("h3");
      }
    }

    .bottom {
      .animation-mobile {
        width: 100%;
        margin: auto;
      }

      .animation-desktop {
        width: 100%;
        margin: 12px auto 0px auto;
        display: none;
      }
    }
  }

  .card-detailed {
    @include card($shadow: true);
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 32px;
    text-align: center;

    @media screen and (min-width: $breakpointLg) {
      gap: 16px;
    }

    .section-top {
      .title {
        @include flex($justify: center);
        margin-bottom: 16px;
        margin-top: 16px;
        text-align: center;

        @media screen and (min-width: $breakpointSm) {
          margin-top: 0;
        }

        img {
          display: none;
          width: 60px;
          height: 60px;
          min-height: 60px;
          min-width: 60px;
          margin: auto;
          margin-right: 16px;

          @media screen and (min-width: $breakpointMd) {
            display: block;
          }
        }

        span {
          font-weight: 700;
          @include text-lg;

          @media screen and (min-width: $breakpointSm) {
            @include text-xl;
          }
          @media screen and (min-width: $breakpointMd) {
            @include display-xs;
          }
          @media screen and (min-width: $breakpointLg) {
            @include display-sm;
          }
        }
      }

      .subtitle {
        margin-bottom: 16px;
      }

      .items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 4px;
      }
    }

    .section-animation {
      img {
        width: 180px;
        height: 180px;
        margin: auto;
      }
    }

    .section-loader {
      .green-text {
        @include font-style("medium");
        color: $bilbao500;
        font-weight: bold;
      }

      .loading-bar {
        margin: 16px auto 16px auto;
      }
    }

    .section-review {
      margin-top: 16px;

      @media screen and (min-width: $breakpointLg) {
        margin-top: unset;
      }

      .report-type {
        @include font-style("caption");
        color: $black400;
        font-weight: bold;
      }

      .location {
        display: flex;
        align-items: center;
        margin-top: 8px;
        justify-content: center;

        img {
          margin-right: 8px;
        }
      }

      .stars {
        margin: 8px auto 16px auto;
      }

      .review {
        div {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpointSm) {
  .i_quiz-completion {
    .card-simple {
      .top {
        .img {
          height: 96px;
          width: 96px;
          min-width: 96px;
          min-height: 96px;
          margin-right: 16px;
        }
      }

      .bottom {
        .animation-mobile {
          display: none;
        }

        .animation-desktop {
          display: block;
        }
      }
    }

    .card-detailed {
      .section-top {
        .items {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpointMd) {
  .i_quiz-completion {
    .card-simple {
      margin-bottom: 64px;

      .top {
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: left;

        .img {
          height: 142px;
          width: 142px;
          min-width: 142px;
          min-height: 142px;
        }
      }

      .bottom {
        .animation-mobile {
          display: none;
        }

        .animation-desktop {
          display: block;
        }
      }
    }

    .card-detailed {
      margin-bottom: 64px;
    }
  }
}

@media screen and (min-width: $breakpointLg) {
  .i_quiz-completion {
    .card-detailed {
      grid-template-columns: 2fr 1fr;
      text-align: left;

      .section-top {
        .title {
          justify-content: start;
        }
      }

      .section-animation {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 90%;
          height: 90%;
        }
      }

      .section-loader {
        grid-area: 2 / 1 / 3 / 3;
      }

      .section-review {
        display: grid;
        grid-area: 3 / 1 / 4 / 3;
        grid-template-columns: 200px 1fr;
        gap: 16px;

        .location {
          justify-content: left;
        }
      }
    }
  }
}
</style>
