export const GlobalVariables = {
  affiliateCodes: ["launch"],
  domains: {
    api: {
      prod: "https://api.jobtest.org",
      staging: "https://stagapi.jobtest.org",
      dev: "https://devapi.jobtest.org", // Not used
      local: "http://localhost:3000"
    },
    app: {
      prod: "app.jobtest.org",
      staging: "stagingapp.jobtest.org",
      local: "localhost:5173"
    },
    website: {
      prod: "jobtest.org",
      staging: "jobtestorg.webflow.io",
      local: "localhost:8080"
    }
  },
  postSessionStripeLinks: {
    prod: {
      scopingSession: "https://buy.stripe.com/14kg2v9qT4dTf2E15k",
      biWeeeklySubscription: "https://buy.stripe.com/aEU3fJ0Unh0FbQs5lB",
      weeklySubscription: "https://buy.stripe.com/fZedUnfPhfWB2fS6pG"
    },
    staging: {
      scopingSession: "https://checkout.jobtest.org/b/test_7sI00k90P5YW95m3co",
      biWeeeklySubscription: "https://checkout.jobtest.org/b/test_cN2cN66SHfzw3L2bIV",
      weeklySubscription: "https://checkout.jobtest.org/b/test_28obJ25OD3QOa9q5ky"
    }
  },
  qublQuizIds: {
    prod: {
      jobTest: "f0ef0262-00b1-40af-a7d3-6e518ed9028f", // Report Flow - v5.0
      jobQuiz: "f0ef0262-00b1-40af-a7d3-6e518ed9028f", // Report Flow - v5.0
      testRetake: "d11da90a-4ecf-4f1b-a180-0c7431b802a2", // Report Flow - v5.0 RETAKE
      coaching: "e3de1d34-7d1e-49be-895b-65347ec7a11f" // Coaching Quiz
    },
    staging: {
      jobTest: "f0ef0262-00b1-40af-a7d3-6e518ed9028f", // Report Flow - v5.0
      jobQuiz: "f0ef0262-00b1-40af-a7d3-6e518ed9028f", // Report Flow - v5.0
      testRetake: "d11da90a-4ecf-4f1b-a180-0c7431b802a2", // Report Flow - v5.0 RETAKE
      coaching: "e3de1d34-7d1e-49be-895b-65347ec7a11f" // Coaching Quiz
    }
  },
  qublQuizStyles: {
    jobTest: "src/styles/qubl.css",
    jobQuiz: "src/styles/qubl.css",
    testRetake: "src/styles/qubl.css"
  },
  userSourceKeys: [
    "adposition",
    "campaignid",
    "fbclid",
    "gclid",
    "keyword",
    "landing_page",
    "subId1",
    "utm_campaign",
    "utm_content",
    "utm_medium",
    "utm_source",
    "utm_term"
  ],
  urls: {
    root: {
      path: "/",
      routeName: "root"
    },
    auth: {
      path: "/auth",
      routeName: "auth",
      children: {
        magic: {
          path: "/magic",
          routeName: "magic",
          routeTitle: "Magic Link Verification"
        }
      }
    },
    login: {
      path: "/login",
      routeName: "login"
    },
    error: {
      path: "/error",
      routeName: "error"
    },
    thankYou: {
      path: "/thank-you",
      routeName: "thankYou"
    },
    jobQuiz: {
      path: "/job-quiz",
      routeName: "jobQuiz"
    },
    jobTest: {
      path: "/job-test",
      routeName: "jobTest"
    },
    loading: {
      path: "/loading",
      routeName: "loading"
    },
    pleaseWait: {
      path: "/please-wait",
      routeName: "pleaseWait"
    },
    processing: {
      path: "/processing",
      routeName: "processing"
    },
    begin: {
      path: "/begin",
      routeName: "begin"
    },
    discover: {
      path: "/discover",
      routeName: "discover"
    },
    getStarted: {
      path: "/get-started",
      routeName: "getStarted"
    },
    welcome: {
      path: "/welcome",
      routeName: "welcome"
    },
    hello: {
      path: "/hello",
      routeName: "hello"
    },
    start: {
      path: "/start",
      routeName: "start"
    },
    coaching: {
      path: "/coaching",
      routeName: "coaching",
      children: {
        home: {
          path: "/home",
          routeName: "coachingHome",
          routeTitle: "Home"
        },
        quiz: {
          path: "/quiz",
          routeName: "coachingQuiz",
          routeTitle: "Quiz"
        },
        loading: {
          path: "/loading",
          routeName: "coachingLoading"
        },
        confirmation: {
          path: "/confirmation",
          routeName: "coachingConfirmation",
          routeTitle: "Confirmation"
        },
        selectCoach: {
          path: "/select-coach",
          routeName: "selectCoach",
          routeTitle: "Select Coach"
        },
        selectTime: {
          path: "/select-time"
        },
        selectPlan: {
          path: "/select-plan",
          routeName: "selectPlan",
          routeTitle: "Select Plan"
        }
      }
    },
    report: {
      path: "/report",
      routeName: "report",
      children: {
        welcome: {
          path: "/welcome"
        },
        home: {
          path: "/home",
          routeName: "reportHome",
          routeTitle: "Home"
        },
        profile: {
          path: "/profile",
          routeName: "reportProfile",
          routeTitle: "Profile"
        },
        careers: {
          path: "/careers",
          routeName: "reportCareers",
          routeTitle: "Careers"
        },
        newCareers: {
          path: "/get-new-careers",
          routeName: "reportNewCareers",
          routeTitle: "Get New Careers"
        },
        selectCoach: {
          path: "/select-coach"
        },
        testRetake: {
          path: "/test-retake",
          routeName: "testTetake",
          routeTitle: "Test Retake"
        },
        testAdjustment: {
          path: "/test-adjustment",
          routeName: "testAdjustment",
          routeTitle: "Test Adjustment"
        },
        checkout: {
          path: "/checkout",
          routeName: "reportCheckout",
          routeTitle: "Checkout"
        },
        outdated: {
          path: "/outdated",
          routeName: "reportOutdated",
          routeTitle: "Outdated"
        },
        feedback: {
          path: "/feedback",
          routeName: "reportFeedback",
          routeTitle: "Feedback"
        }
      }
    },
    website: {
      path: "https://www.jobtest.org"
    },
    lifecycle: {
      path: "/v",
      routeName: "lifecycle",
      children: {
        lutz60: {
          path: "/a4724lutz60",
          routeName: "lutz60"
        },
        lutz100: {
          path: "/a4724lutz100",
          routeName: "lutz100"
        },
        lutzrcs1: {
          path: "/a520124rcs",
          routeName: "lutzrcs1"
        },
        lutzrcs2: {
          path: "/a522024rcs",
          routeName: "lutzrcs2"
        },
        lutzrcs3: {
          path: "/a523324rcs",
          routeName: "lutzrcs3"
        },
        lutzrcs1p: {
          path: "/ap520124rcs",
          routeName: "lutzrcs1p"
        },
        lutzrcs2p: {
          path: "/ap522024rcs",
          routeName: "lutzrcs2p"
        },
        lutzrcs3p: {
          path: "/ap523324rcs",
          routeName: "lutzrcs3p"
        },
        lutz60b: {
          path: "/b5924lutz60",
          routeName: "lutz60b"
        },
        lutz100b: {
          path: "/b52024lutz100",
          routeName: "lutz100b"
        },
        lutz100c: {
          path: "/c82024lutz100",
          routeName: "lutz100c"
        },
        marlo60: {
          path: "/72524marlo60",
          routeName: "marlo60"
        },
        marlo100: {
          path: "/72524marlo100",
          routeName: "marlo100"
        },
        marlorcs1: {
          path: "/7250124rcs",
          routeName: "marlorcs1"
        },
        marlorcs2: {
          path: "/7252024rcs",
          routeName: "marlorcs2"
        },
        marlorcs3: {
          path: "/7253324rcs",
          routeName: "marlorcs3"
        },
        marlorcs1p: {
          path: "/p7250124rcs",
          routeName: "marlorcs1p"
        },
        marlorcs2p: {
          path: "/p7252024rcs",
          routeName: "marlorcs2p"
        },
        marlorcs3p: {
          path: "/p7253324rcs",
          routeName: "marlorcs3p"
        }
      }
    }
  },
  urlQueryKeys: {
    fullName: "fullName",
    zipCode: "zipCode"
  },
  zigpollId: "6408cf4a7efd2f0bec58e07c",
  zigpolls: {
    finishedCoaching: "66ba5a7e5bbae26d9124cc28",
    finishedReport: "65b54a540a08b10c2839d1dd",
    freeUpgradeEligible: "66175c4b2686e3bd7020bcc2",
    freeReport: "654a58c7662b850c1ce7f6d3",
    paidReport: "654a58a0662b850c1ce7f6c6"
  }
}
