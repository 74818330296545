<template>
  <div
    class="i_base-spacer"
    :class="{ [desktopSize]: true, [tabletSize]: true, [mobileSize]: true }"
  ></div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps({
  desktop: {
    type: String,
    default: "medium",
    validator(value: string) {
      return [
        "tiny",
        "xxsmall",
        "xsmall",
        "small",
        "medium",
        "large",
        "xlarge",
        "xxlarge",
        "huge",
        "xhuge",
        "xxhuge"
      ].includes(value)
    }
  },
  mobile: {
    type: String,
    default: "medium",
    validator(value: string) {
      return [
        "tiny",
        "xxsmall",
        "xsmall",
        "small",
        "medium",
        "large",
        "xlarge",
        "xxlarge",
        "huge",
        "xhuge",
        "xxhuge"
      ].includes(value)
    }
  },
  tablet: {
    type: String,
    validator(value: string | undefined) {
      if (!value) return true
      return [
        "tiny",
        "xxsmall",
        "xsmall",
        "small",
        "medium",
        "large",
        "xlarge",
        "xxlarge",
        "huge",
        "xhuge",
        "xxhuge"
      ].includes(value)
    }
  }
})

const desktopSize = computed(() => {
  return `desktop-${props.desktop}`
})

const mobileSize = computed(() => {
  return `mobile-${props.mobile}`
})

const tabletSize = computed(() => {
  if (!props.tablet) return `tablet-${props.mobile}`
  return `tablet-${props.tablet}`
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.i_base-spacer {
  &.mobile-tiny {
    height: 0.125rem;
  }
  &.mobile-xxsmall {
    height: 0.25rem;
  }
  &.mobile-xsmall {
    height: 0.5rem;
  }
  &.mobile-small {
    height: 1rem;
  }
  &.mobile-medium {
    height: 1.25rem;
  }
  &.mobile-large {
    height: 1.5rem;
  }
  &.mobile-xlarge {
    height: 2rem;
  }
  &.mobile-xxlarge {
    height: 3rem;
  }
  &.mobile-huge {
    height: 3.5rem;
  }
  &.mobile-xhuge {
    height: 4rem;
  }
  &.mobile-xxhuge {
    height: 4.5rem;
  }

  @media screen and (min-width: $breakpointMd) {
    &.tablet-tiny {
      height: 0.125rem;
    }
    &.tablet-xxsmall {
      height: 0.25rem;
    }
    &.tablet-xsmall {
      height: 0.5rem;
    }
    &.tablet-small {
      height: 1rem;
    }
    &.tablet-medium {
      height: 1.5rem;
    }
    &.tablet-large {
      height: 2.5rem;
    }
    &.tablet-xlarge {
      height: 3rem;
    }
    &.tablet-xxlarge {
      height: 4rem;
    }
    &.tablet-huge {
      height: 5rem;
    }
    &.tablet-xhuge {
      height: 6rem;
    }
    &.tablet-xxhuge {
      height: 8rem;
    }
  }

  @media screen and (min-width: $breakpointLg) {
    &.desktop-tiny {
      height: 0.125rem;
    }
    &.desktop-xxsmall {
      height: 0.25rem;
    }
    &.desktop-xsmall {
      height: 0.5rem;
    }
    &.desktop-small {
      height: 1rem;
    }
    &.desktop-medium {
      height: 2rem;
    }
    &.desktop-large {
      height: 3rem;
    }
    &.desktop-xlarge {
      height: 4rem;
    }
    &.desktop-xxlarge {
      height: 5rem;
    }
    &.desktop-huge {
      height: 6rem;
    }
    &.desktop-xhuge {
      height: 8rem;
    }
    &.desktop-xxhuge {
      height: 12rem;
    }
  }
}
</style>
