<template>
  <div class="i_new-careers">
    <a
      class="new-careers"
      @click="goToNewCareers"
    >
      <img :src="iconGetNewCareers" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router"

import { GlobalVariables } from "@/helpers/globalVariables"

import iconGetNewCareers from "@/assets/menu/icon-get-new-careers.svg"

// ROUTES
const router = useRouter()

// FUNCTIONS
const goToNewCareers = () => {
  router.push({ name: GlobalVariables.urls.report.children.newCareers.routeName })
}
</script>

<style scoped lang="scss">
.i_new-careers {
  .new-careers {
    max-width: fit-content;
    cursor: pointer;

    img {
      align-self: start;
      width: 17px;
    }
  }
}
</style>
