<template>
  <div class="i_goals-aspirations">
    <template v-if="goalsAspirations.length >= 1">
      <iBaseHeader
        highlight-text=""
        text="Goals & Aspirations"
        align="start"
      />

      <div class="goals">
        <template
          v-for="goal in goalsAspirations"
          :key="goal.routeName"
        >
          <div
            v-if="goal"
            class="goal"
            :style="{ backgroundColor: goal.backgroundColor, '--color': goal.textColor }"
          >
            <img
              :src="goal.icon"
              class="goal-icon"
            />
            <span class="goal-title">{{ goal.title }}</span>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

// COMPONENTS
import iBaseHeader from "@/components/base/BaseHeader.vue"

// STORES
const reportStore = useReportStore()

// COMPUTED
const goalsAspirations = computed(() => {
  return [
    reportStore.getTestTakingPurpose,
    reportStore.getCareerGoal,
    reportStore.getIdealCareerFeature,
    reportStore.getMissingNeeds
  ].filter((item) => item !== null)
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_goals-aspirations {
  margin-top: 30px;

  .goals {
    @include grid($cols: 1fr, $align-items: center, $justify-items: center, $gap: 15px);
    width: 100%;
    margin-top: 15px;
    grid-auto-rows: 1fr;

    @media screen and (min-width: $breakpointSm) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    @media screen and (min-width: $breakpointLg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .goal {
      @include grid($cols: 1fr, $rows: auto 1fr, $align-items: center, $justify-items: center, $gap: 10px);
      width: 100%;
      padding: 24px 0;
      align-self: stretch;
      @include border-radius-lg;

      .goal-icon {
        width: 42px;
        height: 42px;
        align-self: end;
        border: 2px solid var(--color);
        background-color: var(--color);
        @include border-radius-md;
        padding: 5px;
      }

      .goal-title {
        @include text-sm;
        font-weight: 600;
        color: var(--color);
        align-self: start;
        text-align: center;
        max-width: 90%;
      }
    }
  }
}

@media print {
  .i_goals-aspirations {
    .goals {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }
}
</style>
