<template>
  <div class="i_stripe-embedded-form">
    <div id="stripe-checkout"></div>
  </div>
</template>

<script setup lang="ts">
import { loadStripe, type Stripe, type StripeEmbeddedCheckout } from "@stripe/stripe-js"
import { onMounted, onUnmounted } from "vue"

// Services
import ApiService from "@/services/apiService"

// Types and Enums
import type StripePrices from "@/shared/enums/StripePrices"

const emit = defineEmits(["error"])

const props = defineProps({
  stripePrice: {
    type: String as () => StripePrices,
    required: true
  }
})

let stripeCheckout: StripeEmbeddedCheckout | null = null
let stripe: Stripe | null = null

async function initializeStripe() {
  let clientSecret: string

  try {
    const [checkoutResponse, stripeInstance] = await Promise.all([
      ApiService.postCheckoutSessionEmbedded(props.stripePrice),
      loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
        apiVersion: "2024-06-20"
      })
    ])

    clientSecret = checkoutResponse?.clientSecret
    if (!clientSecret) {
      throw new Error("Client secret not found in response")
    }

    if (!stripeInstance) {
      throw new Error("Stripe not loaded")
    }

    stripe = stripeInstance

    stripeCheckout = await stripe.initEmbeddedCheckout({
      clientSecret
    })

    if (!stripeCheckout) {
      throw new Error("Stripe checkout not loaded")
    }

    stripeCheckout.mount("#stripe-checkout")
  } catch (error) {
    console.error("Error loading Stripe checkout")
    console.error(error)
    emit("error")
  }
}

function cleanupStripe() {
  if (stripeCheckout) {
    console.log("Cleaning up Stripe checkout")
    stripeCheckout.destroy()
  }
}

onMounted(() => {
  initializeStripe()
})

onUnmounted(() => {
  cleanupStripe()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_stripe-embedded-form {
  width: 100%;

  #stripe-checkout {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}
</style>
