<template>
  <!-- Nothing needed here -->
</template>

<script lang="ts" setup>
import { GlobalVariables } from "@/helpers/globalVariables"
import ApiService from "@/services/apiService"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"
import { useRouter } from "vue-router"

const reportStore = useReportStore()
const userStore = useUserStore()

const router = useRouter()

const initAuthProcess = async (): Promise<void> => {
  // Get the authKey from the URL and remove it from the URL
  const url = new URL(window.location.href)
  const authKey = url.searchParams.get("authKey")
  const redirect = url.searchParams.get("redirect")

  if (authKey) {
    url.searchParams.delete("authKey")
    window.history.replaceState({}, document.title, url)

    try {
      // Attempt to login the user
      await ApiService.postLogout()
      await ApiService.postMagicLogin(authKey)

      await userStore.loadUserData()
      if (userStore.isReportUser) await reportStore.loadReport()

      if (!redirect) router.push(GlobalVariables.urls.report.path)
      else router.push(redirect)
    } catch (error) {
      router.push(GlobalVariables.urls.error.path)
    }
  }

  document.getElementById("loading")?.remove()
}

initAuthProcess()
</script>
