<template>
  <div
    class="i_median-salaries"
    v-if="hasAnyActiveJobSalaryPosition"
  >
    <iBaseHeader
      highlight-text=""
      text="Salary sorted by experience"
      align="start"
    />
    <div class="cards">
      <template v-for="salaryPositionData in computedActiveJobSalaryPositions">
        <div
          class="card"
          v-if="!!salaryPositionData.salaryAmount"
        >
          <div class="title">
            <img :src="salaryPositionData.image" />
            {{ salaryPositionData.text }}
          </div>
          <div class="amount">{{ salaryPositionData.salaryAmount }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iBaseHeader from "@/components/base/BaseHeader.vue"

// Icons
import iconBar1 from "@/assets/icons/icon-one-green-bar.svg"
import iconBar2 from "@/assets/icons/icon-two-green-bar.svg"
import iconBar3 from "@/assets/icons/icon-three-green-bar.svg"

// STORES
const reportStore = useReportStore()

// COMPUTED
const activeJobSalaryPositions = computed(() => reportStore.getActiveJobSalaryPositions)
const computedActiveJobSalaryPositions = computed(() => {
  const output = []

  for (const [key, value] of Object.entries(activeJobSalaryPositions.value)) {
    if (!value) continue
    output.push({
      image: key === "entry" ? iconBar1 : key === "mid" ? iconBar2 : iconBar3,
      salaryAmount: value,
      text: `${key}-Level`
    })
  }
  return output
})
const hasAnyActiveJobSalaryPosition = computed(
  () =>
    !!activeJobSalaryPositions.value?.entry ||
    !!activeJobSalaryPositions.value?.mid ||
    !!activeJobSalaryPositions.value?.senior
)
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_median-salaries {
  .cards {
    @include grid($cols: 1fr, $rows: auto, $gap: 16px);
    margin: 15px 0 40px 0;

    @media screen and (min-width: $breakpointLg) {
      @include grid($cols: 1fr 1fr 1fr, $rows: 1fr, $gap: 16px);
    }

    .card {
      @include flex($direction: row, $justify: space-between, $align: center, $gap: 10px);
      width: 100%;
      padding: 24px;
      @include border-radius-md;
      border: 1px solid $zumthor100;
      background-color: $white;
      color: $pickleBluewood900;

      @media screen and (min-width: $breakpointSm) {
        padding: 24px 35px;
      }

      @media screen and (min-width: $breakpointLg) {
        @include flex($direction: column, $justify: space-between, $align: start, $gap: 50px);
        padding: 20px 24px;
        max-width: 232px;
      }

      .title {
        @include flex(row, center, $gap: 4px);
        @include text-sm;
        font-weight: 500;
        text-transform: capitalize;

        @media screen and (min-width: $breakpointLg) {
          @include text-xs;
          font-weight: 600;
        }
      }

      .amount {
        @include display-sm;
        font-weight: 400;
      }
    }
  }
}
</style>
