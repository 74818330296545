<template>
  <div class="i_error">
    <iBackground
      :background-image="bgSquiggleGreen"
      :color="white"
    />

    <div class="header">
      <div class="logo">
        <a @click="goToHome()"
          ><img
            :src="logo"
            alt="JobTest.org"
            class="logo-img"
          />
        </a>
      </div>
    </div>

    <div class="wrap">
      <div class="card">
        <div class="card-top">
          <span>Something went wrong</span>
        </div>

        <div class="card-bottom">
          <span class="card-bottom-text-medium"
            >There was an error on our end. Please contact us at
            <strong><a href="mailto:support@jobtest.org">support@jobtest.org</a></strong> with the same email address
            you used when completing your test to assist you.</span
          >
          <span class="card-bottom-text-large">Thank you for your understanding!</span>
        </div>
      </div>

      <div class="back-cta">
        <a
          @click="goToWebsite"
          class="back-link"
        >
          <img :src="iconChevron" /> Back to Jobtest.org
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router"
import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"

import { white } from "@/styles/colors"

import iBackground from "@/components/global/Background.vue"

import logo from "@/assets/logo/logo-dark.svg"
import iconChevron from "@/assets/icons/icon-chevron-left-blue.svg"
import bgSquiggleGreen from "@/assets/jobTest/bg-squiggle-green.svg"

// FUNCTIONS

function goToHome() {
  router.push({ name: GlobalVariables.urls.login.routeName })
}

function goToWebsite() {
  const pageUrl = GlobalVariables.urls.root.path
  const domain = GlobalFunctions.getWebsiteDomain()
  window.location.href = `https://${domain}${pageUrl}`
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";

.i_error {
  @include flex($justify: center, $align: center);
  min-height: 100vh;

  .header {
    @include flex(row, $justify: space-between, $align: center);
    width: 100%;
    padding: 15px 15px;
    position: fixed;
    top: 0;

    @media screen and (min-width: $breakpointSm) {
      padding: 15px 40px;
    }

    .logo {
      a:hover {
        cursor: pointer;
      }
      .logo-img {
        width: 160px;
      }
    }
  }

  .wrap {
    width: 90%;
    max-width: 500px;

    .card {
      @include border-radius-lg;
      @include shadow();
      background-color: rgba(244.37, 244.37, 244.37, 0.6);
      text-align: start;
      overflow: hidden;

      .card-top {
        @include flex($direction: column, $justify: center, $align: center, $gap: 16px);
        @include display-sm;
        background-color: $azureRadiance950;
        color: $white;
        font-weight: bold;
        text-align: center;
        padding: 40px 24px;
        @media screen and (min-width: $breakpointLg) {
          padding: 48px 32px;
        }
      }

      .card-bottom {
        @include flex($direction: column, $justify: center, $align: center, $gap: 16px);
        padding: 24px;
        text-align: center;
        @media screen and (min-width: $breakpointLg) {
          padding: 32px 48px;
        }

        &-text-large {
          @include text-xl;
          font-weight: bold;
          max-width: 400px;
        }
        &-text-medium {
          @include text-lg;
          font-weight: medium;
          color: $comet600;
          max-width: 400px;
        }
      }
    }

    .back-cta {
      color: $azureRadiance950;
      text-align: center;
      margin-top: 70px;
      cursor: pointer;

      .back-link {
        @include text-link();
        font-weight: bold;
        text-decoration: none;
        border-bottom: 0px;
        font-weight: normal;

        img {
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>
