const getPluralJob = (job: string | null) => {
  if (!job) return "people"

  job = job.trim()
  const lastCharacter = job[job.length - 1]

  if (lastCharacter.match(/\)/i)) {
    const firstHalf = job.slice(0, job.lastIndexOf("(")).trim()
    const parenthesesHalf = job.slice(job.lastIndexOf("("))

    return `${firstHalf}s ${parenthesesHalf}`
  }

  return `${job}s`
}

export default getPluralJob
