import { Transform } from "class-transformer"

export const toBooleanOrNull = (value: any) => {
  if (typeof value === "string") {
    return value.trim().toLowerCase() === "true"
  }
  return typeof value === "boolean" ? value : null
}

export const toIntOrNull = (value: any) => {
  if (typeof value === "string") {
    const trimmedValue = value.trim()
    const parsedValue = parseInt(trimmedValue, 10)
    return isNaN(parsedValue) ? null : parsedValue
  }
  return typeof value === "number" && !isNaN(value) ? value : null
}

export const toIntArrayOrNull = (value: any) => {
  if (Array.isArray(value)) {
    const array = value
      .map((item) => {
        if (typeof item === "string") {
          const trimmedItem = item.trim()
          const parsedItem = parseInt(trimmedItem, 10)
          return isNaN(parsedItem) ? null : parsedItem
        }
        return typeof item === "number" && !isNaN(item) ? item : null
      })
      .filter((item) => item !== null)

    return array.length > 0 ? array : null
  }
  return null
}

export const toTrimmedStringOrNull = (value: any) => {
  if (typeof value === "string") {
    const trimmedValue = value.trim()
    return trimmedValue === "" ? null : trimmedValue
  }
  return null
}

// Custom Transform Decorators
export const TransformToBooleanOrNull = () => Transform(({ value }) => toBooleanOrNull(value))
export const TransformToIntOrNull = () => Transform(({ value }) => toIntOrNull(value))
export const TransformToIntArrayOrNull = () => Transform(({ value }) => toIntArrayOrNull(value))
export const TransformToTrimmedStringOrNull = () => Transform(({ value }) => toTrimmedStringOrNull(value))
