enum StripePrices {
  BASIC_LOW = "6d939631-f10f-45fc-b3c9-88018f1c8e3e", // $24.90
  BASIC_HIGH = "5ba69d1f-5b57-487f-af9d-79455fa95a7e", // $29.90
  COMPREHENSIVE_UPGRADE = "e543fa19-4c74-4a8b-8412-701679cf01fc", // $4.90
  COMPREHENSIVE_LOW = "5233a4ac-7e11-4417-b58f-f9c148ce29c9", // $34.90
  COMPREHENSIVE_HIGH = "eb30b116-235d-482e-8eec-318012b21127", // $39.90
  COACHING_ONCE_LOW = "8103792f-035b-4176-abed-d77ed225dce5", // $99.90
  COACHING_ONCE_HIGH = "c09bdc8b-5bba-4906-8758-408feb9f181b", // $149.90
  COACHING_BIWEEKLY = "43006418-ade4-4299-8c5e-5953677d356e", // $149.90/month
  COACHING_WEEKLY = "c2395eea-d3cd-4916-9abb-dcee3b761f49", // $279.90/month
  COACHING_ADDON = "4fd0c2ae-c82f-45e1-80fb-522be9a05bcd" // $299.90
}

export default StripePrices
