import { IsArray, IsBoolean, IsIn, IsInt, IsOptional, IsString, Min } from "class-validator"
import {
  TransformToBooleanOrNull,
  TransformToIntArrayOrNull,
  TransformToIntOrNull,
  TransformToTrimmedStringOrNull
} from "../../utils/transformations"

export type OneToFiveType = 1 | 2 | 3 | 4 | 5
export const OneToFiveValues = [1, 2, 3, 4, 5]
export type OneToThreeType = 1 | 2 | 3
export const OneToThreeValues = [1, 2, 3]

// Coaching information types
export type CoachAgeType = 30 | 40 | 50 | 60
export const CoachAgeValues = [30, 40, 50, 60]
export type CoachGenderType = "male" | "female" | "any"
export const CoachGenderValues = ["male", "female", "any"]
export type CoachPersonalityType = "friendly" | "reserved" | "energetic" | "calm"
export const CareerPersonalityValues = ["friendly", "reserved", "energetic", "calm"]
export type CoachPreferenceType = "technical" | "nonTechnical" | "either"
export const CoachPreferenceValues = ["technical", "nonTechnical", "either"]
export type CoachSpecialtyType = "specific" | "broad" | "any"
export const CoachSpecialtyValues = ["specific", "broad", "any"]

// Employment information types
export type EmploymentStatusType =
  | "fullTime"
  | "partTime"
  | "selfEmployed"
  | "freelance"
  | "unemployed"
  | "student"
  | "retired"
  | "other"
export const EmploymentStatusValues = [
  "fullTime",
  "partTime",
  "selfEmployed",
  "freelance",
  "unemployed",
  "student",
  "retired",
  "other"
]
export type UnemployedMonthsType = 1 | 3 | 6 | 12 | 24 | 36
export const UnemployedMonthsValues = [1, 3, 6, 12, 24, 36]
export type YearsOfExperienceType = 1 | 3 | 5 | 10 | 20 | 30 | 40
export const YearsOfExperienceValues = [1, 3, 5, 10, 20, 30, 40]

// Other information types
export type ApproachType = "structured" | "flexible" | "any"
export const ApproachValues = ["structured", "flexible", "any"]
export type FeedbackStyleType = "direct" | "encouraging" | "mix"
export const FeedbackStyleValues = ["direct", "encouraging", "mix"]
export type GoalFocusType = "short" | "long" | "both"
export const GoalFocusValues = ["short", "long", "both"]
export type HowSoonType = 1 | 3 | 7 | 14 | 30
export const HowSoonValues = [1, 3, 7, 14, 30]
export type LearningStyleType = "visual" | "auditory" | "written" | "moving" | "other"
export const LearningStyleValues = ["visual", "auditory", "written", "moving", "other"]
export type PriorityType = "satisfaction" | "money" | "value" | "fame"
export const PriorityValues = ["satisfaction", "money", "value", "fame"]
export type SuccessMetricType = "goal" | "skill" | "satisfaction" | "feedback" | "other"
export const SuccessMetricValues = ["goal", "skill", "satisfaction", "feedback", "other"]
export type UltimateGoalType = "leadership" | "expert" | "balance" | "impact" | "unsure"
export const UltimateGoalValues = ["leadership", "expert", "balance", "impact", "unsure"]

/**
 * This DTO is the expected request body for the postCoachingQuizAnswers endpoint.
 */
export default class QuizPostCoachingIn {
  //
  // QUBL variables
  //

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  qublInstanceUuid: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  qublQuizUuid: string | null

  //
  // Desired coach information
  //

  @IsOptional()
  @IsInt()
  @IsIn(CoachAgeValues)
  @TransformToIntOrNull()
  coachAge: CoachAgeType | null

  @IsOptional()
  @IsInt()
  @IsIn(OneToFiveValues)
  @TransformToIntOrNull()
  coachAvailabilityImportance: OneToFiveType | null

  @IsOptional()
  @IsInt()
  @IsIn(OneToFiveValues)
  @TransformToIntOrNull()
  coachExecutiveImportance: OneToFiveType | null

  @IsOptional()
  @IsIn(CoachGenderValues)
  @TransformToTrimmedStringOrNull()
  coachGender: CoachGenderType | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  coachPersonalDevelopment: boolean | null

  @IsOptional()
  @IsIn(CareerPersonalityValues)
  @TransformToTrimmedStringOrNull()
  coachPersonality: CoachPersonalityType | null

  @IsOptional()
  @IsIn(CoachPreferenceValues)
  @TransformToTrimmedStringOrNull()
  coachPreference: CoachPreferenceType | null

  @IsOptional()
  @IsInt()
  @IsIn(OneToFiveValues)
  @TransformToIntOrNull()
  coachPsychologyImportance: OneToFiveType | null

  @IsOptional()
  @IsIn(CoachSpecialtyValues)
  @TransformToTrimmedStringOrNull()
  coachSpecialty: CoachSpecialtyType | null

  //
  // Employment information
  //

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  currentJob: string | null

  @IsOptional()
  @IsInt()
  @TransformToIntOrNull()
  currentSalary: number | null

  @IsOptional()
  @IsString()
  @IsIn(EmploymentStatusValues)
  @TransformToTrimmedStringOrNull()
  employmentStatus: EmploymentStatusType | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  employmentStatusOther: string | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  hasEmploymentHistory: boolean | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  hasGoodWorkEnvironment: boolean | null

  @IsOptional()
  @IsInt()
  @IsIn(UnemployedMonthsValues)
  @TransformToIntOrNull()
  unemployedMonths: UnemployedMonthsType | null

  @IsOptional()
  @IsInt()
  @IsIn(YearsOfExperienceValues)
  @TransformToIntOrNull()
  yearsOfExperience: YearsOfExperienceType | null

  //
  // Other information
  //
  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  accomplishment: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  accomplishmentMore: string | null

  @IsOptional()
  @IsIn(ApproachValues)
  @TransformToTrimmedStringOrNull()
  approach: ApproachType | null

  @IsOptional()
  @IsIn(OneToThreeValues)
  @TransformToIntOrNull()
  balanceImportance: OneToThreeType | null

  @IsOptional()
  @IsInt()
  @IsIn(OneToFiveValues)
  @TransformToIntOrNull()
  careerConfidence: OneToFiveType | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  challenges: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  challengesOther: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  coachingGoal: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  coachingGoalOther: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  communicationMethod: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  copingMechanism: string | null

  @IsOptional()
  @IsIn(FeedbackStyleValues)
  @TransformToTrimmedStringOrNull()
  feedbackStyle: FeedbackStyleType | null

  @IsOptional()
  @IsInt()
  @IsIn(OneToFiveValues)
  @TransformToIntOrNull()
  feelStuck: OneToFiveType | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  freeTimeActivities: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  freeTimeActivityOther: string | null

  @IsOptional()
  @IsIn(GoalFocusValues)
  @TransformToTrimmedStringOrNull()
  goalFocus: GoalFocusType | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  hasClearGoals: boolean | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  hasWorkStress: boolean | null

  @IsOptional()
  @IsInt()
  @IsIn(HowSoonValues)
  @TransformToIntOrNull()
  howSoon: HowSoonType | null

  @IsOptional()
  @IsArray()
  @IsInt({ each: true })
  @Min(1, { each: true })
  @TransformToIntArrayOrNull()
  industrySelection: number[] | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  isOpenToNewPath: boolean | null

  @IsOptional()
  @IsBoolean()
  @TransformToBooleanOrNull()
  isSatisfiedWithProgress: boolean | null

  @IsOptional()
  @IsIn(LearningStyleValues)
  @TransformToTrimmedStringOrNull()
  learningStyle: LearningStyleType | null

  @IsOptional()
  @IsIn(PriorityValues)
  @TransformToTrimmedStringOrNull()
  priority: PriorityType | null

  @IsOptional()
  @IsIn(SuccessMetricValues)
  @TransformToTrimmedStringOrNull()
  successMetric: SuccessMetricType | null

  @IsOptional()
  @IsIn(UltimateGoalValues)
  @TransformToTrimmedStringOrNull()
  ultimateGoal: UltimateGoalType | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  workEnvironment: string | null

  @IsOptional()
  @IsInt()
  @IsIn(OneToThreeValues)
  @TransformToIntOrNull()
  workLifeBalance: 1 | 2 | 3 | null

  //
  // Strengths
  //

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  analyticalStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  communicationStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  innovationStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  leadershipStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  marketingStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  otherStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  personalStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  teamStrengths: string | null

  @IsOptional()
  @IsString()
  @TransformToTrimmedStringOrNull()
  technicalStrengths: string | null
}
