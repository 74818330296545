<template>
  <div class="i_requirements-banner">
    <div class="content">
      <img
        :src="props.image"
        :alt="props.id"
      />
      <div class="description">{{ props.description }} {{ activeJob.title }}!</div>
    </div>
    <div class="button-wrapper">
      <button
        class="regenerate"
        @click="openUrl(props.url)"
      >
        <div class="icon">
          <img
            :src="iconLinkExternalBlue"
            alt="icon-link-external"
          />
        </div>
        <div class="text">{{ props.buttonText }}</div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

// ICONS
import iconLinkExternalBlue from "@/assets/icons/icon-link-external-blue.svg"

// STORES
const reportStore = useReportStore()

// PROPS
const props = defineProps({
  buttonText: String,
  description: String,
  id: String,
  image: String,
  url: String
})

// COMPUTED
const activeJob = computed(() => {
  return reportStore.getActiveJob
})

//FUNCTIONS
function openUrl(redirectUrl: string | undefined) {
  window.open(redirectUrl, "_blank")
}
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_requirements-banner {
  @include flex(column, $align: center, $gap: 12px);
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 18px;
  @include border-radius-md;
  background: $astronaut50;
  margin: 15px 0;

  @media screen and (min-width: $breakpointMd) {
    min-height: 76px;
  }

  @media screen and (min-width: $breakpointLg) {
    @include flex(row, $align: center, $gap: 24px);
  }

  .content {
    @include flex(column, $align: center, $gap: 24px);

    @media screen and (min-width: $breakpointMd) {
      @include flex(row, $align: center, $gap: 24px);
    }

    .description {
      width: 100%;
      @include text-sm;
      font-weight: 500;
      letter-spacing: 0;
      text-align: center;
      @media screen and (min-width: $breakpointMd) {
        text-align: left;
        max-width: 280px;
      }
    }

    img {
      height: 50px;
      @media screen and (min-width: $breakpointMd) {
        height: 35px;
      }
    }
  }

  .button-wrapper {
    button {
      @include flex(row, $justify: center, $align: center, $gap: 8px);
      padding: 8px 16px;
      width: 100%;
      min-width: 155px;
      height: 40px;
      @include hoverTop;
      @include hoverShadow;
      @include border-radius-sm;
      @include text-sm;
      font-weight: 500;
      color: $astronaut800;
      background: $white;
      letter-spacing: 0;
      text-align: center;

      .icon {
        @include flex(row, $justify: center, $align: center);
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
