import { computed, type ComputedRef } from "vue"
import sha256 from "crypto-js/sha256"

/**
 * Generates a consistent random number based on a provided variable and a specified range.
 * The function hashes the input variable and uses it to generate a pseudo-random number.
 *
 * @param {string | number} hashedVariable - The variable that is to be hashed and used as the basis for generating a random number.
 * @param {number} minValue - The minimum value for returned random number.
 * @param {number} maxValue - The maximum value for returned random number.
 * @return {ComputedRef<number>} The calculated pseudo-random number within the provided range.
 */
const useRandomNumber = (
  hashedVariable: string | number,
  minValue: number,
  maxValue: number,
  decimalPlaces: number = 0
): ComputedRef<number> => {
  const randomNumber = computed(() => {
    // Generate a hash of the hashedVariable
    const hash = sha256(hashedVariable ? hashedVariable.toString() : "default")
    // Convert the first 8 characters of the hash to a number between 0 and 1
    const randomPercentage = parseInt(hash.toString().slice(0, 8), 16) / 0xffffffff
    // Calculate the random number within the range
    const range = maxValue - minValue
    const generatedNumber = minValue + randomPercentage * range
    // Round the generated number to the specified decimal places
    const roundedNumber = parseFloat(generatedNumber.toFixed(decimalPlaces))
    // Return the generated random number
    return roundedNumber
  })

  return randomNumber
}

export default useRandomNumber
