<template>
  <div class="i_customer-reviews-v3">
    <div class="title">Real Stories, <span class="highlighted">Real Impact.</span></div>

    <div class="based-on-reviews">
      <img
        :src="imageStars"
        alt="Five Stars"
        class="stars"
      />
      <p class="reviews-text">Based on <b>89,503 reviews</b> by happy customers</p>
    </div>

    <div class="testimonials">
      <iCardTestimonial
        v-for="(testimonial, index) in TESTIMONIALS"
        v-bind="testimonial"
        :key="index"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import iCardTestimonial from "@/components/welcome/CardTestimonial.vue"

// ASSETS
import iconPersonGreen from "@/assets/welcome/icon-person-green.svg"
import iconPersonOrange from "@/assets/welcome/icon-person-orange.svg"
import iconPersonPurple from "@/assets/welcome/icon-person-purple.svg"
import imageStars from "@/assets/welcome/stars.svg"

// CONSTANTS
const TESTIMONIALS = [
  {
    age: "25-29",
    imgUrl: iconPersonGreen,
    location: "Chicago, IL",
    profession: "Accountant",
    name: "Darius C.",
    rating: 5,
    reportType: "COMPREHENSIVE REPORT",
    text: "Raised by small business owners, I naturally started a business degree after high school. Two years in, I was burnt out and almost quit. My professor suggested JobTest.org, and to my surprise, it showed I'd be happier as a tradesman. I found an electrician apprenticeship right away. Now, I love my hands-on job, I have great hours, and I'm even making more money.",
    timeAgo: "50 MINS AGO"
  },
  {
    age: "40-49",
    imgUrl: iconPersonOrange,
    location: "Austin, TX",
    profession: "Entrepreneur",
    name: "Ellie F.",
    rating: 5,
    reportType: "COMPREHENSIVE REPORT",
    text: "After a nearly three-decade-long career in graphic design post-college, I realized I lacked passion for it. With my 50th birthday on the horizon, I decided to chase my passion. I used Jobtest.org and discovered I had the needed skills to open an art gallery in my town. Now, I'm making the exciting transition to running the gallery full-time and I couldn't be happier!",
    timeAgo: "JUST NOW"
  },
  {
    age: "30-39",
    imgUrl: iconPersonPurple,
    location: "San Francisco, CA",
    profession: "Project Manager",
    name: "Kate A.",
    rating: 4.5,
    reportType: "COMPREHENSIVE REPORT",
    text: "I was satisfied at my job, but a new position opened up in California, and I was unsure about it. I've always wanted to live there, but the role was different. Unsure if I'd do well, I took an aptitude test on Jobtest.org. The results boosted my confidence, so I made the move. Now, I'm doing great in my new role and enjoying beach weekends!",
    timeAgo: "43 MINS AGO"
  }
]
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_customer-reviews-v3 {
  .title {
    text-align: center;
    @include display-xs;
    font-weight: 600;

    @media screen and (min-width: $breakpointMd) {
      font-size: 32px;
      line-height: 40px;
    }

    .highlighted {
      color: $bilbao500;
    }
  }

  .based-on-reviews {
    @include flex($direction: column, $justify: center, $align: center);
    margin-top: 12px;
    gap: 4px;
    text-align: center;
    @include text-md;
    font-weight: 400;

    @media screen and (min-width: $breakpointLg) {
      @include text-xl;
    }

    .reviews-text {
      margin-top: 12px;
    }
  }

  .testimonials {
    display: grid;
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-top: 16px;

    @media screen and (min-width: $breakpointLg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: $breakpointXl) {
      column-gap: 24px;
      row-gap: 24px;
    }
  }
}
</style>
