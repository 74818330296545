<template>
  <div class="i_get-new-careers">
    <iBaseHeader
      highlight-text="Get New Careers"
      text="Choose one of the following options."
      :align="isDesktop ? 'start' : 'center'"
    />

    <iBaseDelimiter />

    <div class="grid">
      <div class="subtitle">
        <template v-if="hasRetakesLeft">You can get new careers up to {{ retakesLeft }} additional times.</template>
        <template v-else> You have <span class="red">0</span> retakes left. </template>
      </div>

      <template v-for="options in OPTIONS">
        <div
          class="card"
          :class="hasRetakesLeft ? '' : 'no-retakes'"
          @click="hasRetakesLeft ? options.ctaAction() : null"
        >
          <div class="image">
            <img
              :src="options.image"
              alt="option-image"
            />
          </div>

          <div class="description">
            <div class="title">{{ options.title }}</div>
            <div class="text">{{ options.description }}</div>
          </div>

          <div class="icon">
            <img
              :src="IconArrowRight"
              alt="icon-arrow-right"
            />
          </div>
        </div>
      </template>
    </div>

    <iBaseModal
      :visible="data.showConfirmationModal"
      :fully-customisable="true"
      @click-on-close="data.showConfirmationModal = false"
    >
      <div class="modal">
        <div class="top">
          <div class="caption">Confirm Retake</div>
          <div class="info">
            If you retake the test, your current results (profile, top careers, and next steps) will be replaced with
            new information. Are you sure you want to continue?
          </div>
        </div>

        <div class="bottom">
          <iBaseButton
            v-bind="BUTTON_STYLE.transparent"
            text="Cancel"
            @clicked="data.showConfirmationModal = false"
          />
          <iBaseButton
            v-bind="BUTTON_STYLE.red"
            text="Proceed"
            @clicked="clickOnRetake()"
          />
        </div>
      </div>
    </iBaseModal>

    <iJobsFeedbackModal
      :showModal="data.showFeedbackModal"
      @click-on-close="data.showFeedbackModal = false"
      @proceed="regenerateCareers"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue"
import { useRouter } from "vue-router"
import { useReportStore } from "@/stores/report"

import { breakpointMd } from "@/styles/variables"
import { bittersweet600, black600, white } from "@/styles/colors"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseModal from "@/components/base/BaseModal.vue"
import iBaseDelimiter from "@/components/base/BaseDelimiter.vue"
import iBaseHeader from "@/components/base/BaseHeader.vue"
import iJobsFeedbackModal from "@/components/report/JobsFeedbackModal.vue"

import imageChange from "@/assets/images/image-enhance-change.svg"
import imageReview from "@/assets/images/image-enhance-review.svg"
import imageRetake from "@/assets/images/image-enhance-retake.svg"
import IconArrowRight from "@/assets/icons/icon-arrow-right.svg"

import { GlobalVariables } from "@/helpers/globalVariables"

// STORES
const router = useRouter()
const reportStore = useReportStore()

// COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)

// CONSTANTS
const OPTIONS = [
  {
    title: "Get New Careers",
    description:
      "You can select which of your current careers you would like to keep and which one you would like to replace.",
    image: imageChange,
    ctaAction: () => (data.showFeedbackModal = true)
  },
  {
    title: "Adjust Answers",
    description: "You have the option to modify some of your quiz answers and receive fresh career suggestions.",
    image: imageReview,
    ctaAction: () => clickOnAdjust()
  },
  {
    title: "Retake Quiz",
    description: "You can retake your test completely, then get new career suggestions.",
    image: imageRetake,
    ctaAction: () => (data.showConfirmationModal = true)
  }
]

const SHARED_BUTTON_STYLE = {
  size: "small",
  fontWeight: "500",
  width: "fit-content"
}

const BUTTON_STYLE = {
  red: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: bittersweet600,
    borderRadius: "24px",
    textColor: white
  },
  transparent: {
    ...SHARED_BUTTON_STYLE,
    backgroundColor: "transparent",
    textColor: black600,
    isHoverable: false
  }
}

// DATA
const data = reactive({
  showConfirmationModal: false,
  showFeedbackModal: false
})

// COMPUTED
const hasRetakesLeft = computed(() => reportStore.hasRetakeQuizAttempts)

const retakesLeft = computed(() => {
  return reportStore.reportData?.user?.quizRetakesLeft
})

// FUNCTIONS
async function regenerateCareers() {
  if (hasRetakesLeft.value) {
    try {
      await reportStore.regenerateJobs()

      // Need to reload report for fresh data
      await reportStore.loadReport()
      router.push({ name: GlobalVariables.urls.report.routeName })
    } catch (error) {
      console.error("Error regenerating new careers")
    }
  }
}

function clickOnAdjust() {
  if (hasRetakesLeft.value) router.push({ name: GlobalVariables.urls.report.children.testAdjustment.routeName })
}

function clickOnRetake() {
  if (hasRetakesLeft.value) router.push({ name: GlobalVariables.urls.report.children.testRetake.routeName })
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_get-new-careers {
  width: fit-content;
  max-width: 720px;
  margin: 0 auto;
  @include grid($cols: 1fr, $rows: auto, $gap: 20px);

  .grid {
    @include flex($direction: column, $justify: start, $align: start, $gap: 12px);

    .subtitle {
      @include text-lg;
      color: $comet600;
      align-self: center;

      @media screen and (min-width: $breakpointLg) {
        align-self: start;
      }

      .red {
        color: $bittersweet600;
      }
    }

    .card {
      @include flex($direction: column, $justify: space-between, $align: center, $gap: 20px);
      background-color: #fff;
      @include border-radius-lg;
      border: 1px solid $zumthor100;
      padding: 30px;
      cursor: pointer;
      width: 100%;

      @media screen and (min-width: $breakpointLg) {
        flex-direction: row;
      }

      &.no-retakes {
        background-color: $black50;
        cursor: not-allowed;
      }

      .image {
        width: 100%;

        @media screen and (min-width: $breakpointLg) {
          width: 40%;
        }

        img {
          width: 100%;
        }
      }

      .description {
        width: 100%;
        text-align: start;

        @media screen and (min-width: $breakpointLg) {
          width: 40%;
        }

        .title {
          @include display-xs;
          font-weight: 700;
          margin-bottom: 15px;
        }

        .text {
          @include text-sm;
          font-weight: 400;
        }
      }

      .icon {
        display: none;

        @media screen and (min-width: $breakpointLg) {
          display: block;
          width: 20%;
          text-align-last: center;
        }
      }
    }
  }

  .modal {
    .top {
      @include flex($direction: column, $justify: start, $align: center, $gap: 20px);
      margin: 25px;

      .caption {
        font-weight: 700;
        @include display-sm;
        text-align: center;
      }

      .info {
        @include text-lg;
        text-align: center;
        font-weight: 500;
        color: $comet600;
      }
    }

    .bottom {
      @include flex($direction: row, $justify: space-between, $align: center, $gap: 0px);
      background-color: $black50;
      padding: 12px 25px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
