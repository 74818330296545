<template>
  <div
    class="i_what-people-say"
    v-if="activeJobReviews.length > 0"
  >
    <iBaseHeader
      highlight-text=""
      :text="`Insights from actual ${getPluralJob(activeJob.title)}`"
      align="start"
      :printBreakBefore="true"
    />
    <div class="main">
      <Carousel
        :autoplay="30000"
        :items-to-scroll="1"
        :items-to-show="isDesktop ? 2 : 1"
        class="reviews-carousel"
        pause-autoplay-on-hover
        wrap-around
        snap-align="start"
      >
        <Slide
          v-for="(review, index) in activeJobReviews"
          :key="index"
        >
          <div class="card">
            <div class="header">{{ review.persona }}</div>
            <div class="text">{{ review.review }}</div>
          </div>
        </Slide>
        <template #addons>
          <navigation>
            <template #next>
              <span>
                <img
                  :src="iconArrowRightGray"
                  alt="next-icon"
                />
              </span>
            </template>
            <template #prev>
              <span>
                <img
                  :src="iconArrowLeftGray"
                  alt="prev-icon"
                />
              </span>
            </template>
          </navigation>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Carousel, Navigation, Slide } from "vue3-carousel"
import { useReportStore } from "@/stores/report"
import { breakpointLg } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"
import getPluralJob from "@/composables/getPluralJob"

import iBaseHeader from "@/components/base/BaseHeader.vue"

// ICONS
import iconArrowRightGray from "@/assets/icons/icon-arrow-right-gray.svg"
import iconArrowLeftGray from "@/assets/icons/icon-arrow-left-gray.svg"

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const isDesktop = useIsScreenSize(breakpointLg)

// COMPUTED
const activeJob = computed(() => reportStore.getActiveJob)
const activeJobReviews = computed(() =>
  // Have the longest review show first for better UI
  reportStore.getActiveJobReviews.sort((a, b) => b.review.length - a.review.length)
)
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_what-people-say {
  .main {
    @include flex($direction: column, $justify: center, $align: center);
    margin: 15px 0 45px 0;

    .reviews-carousel {
      width: calc(100% - 25px);

      @media screen and (min-width: $breakpointSm) {
        width: 100%;
      }

      .card {
        @include flex(column, $justify: start, $align: start, $gap: 15px);
        height: 100%;
        padding: 24px;
        @include border-radius-lg;
        background-color: $white;
        border: 1px solid $zumthor100;
        text-align: start;
        cursor: pointer;

        @media screen and (min-width: $breakpointSm) {
          margin: 0 8px;
        }

        .header {
          @include text-lg;
          font-weight: 700;
          color: $pickleBluewood900;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
          }
        }

        .text {
          @include text-sm;
          font-weight: 400;
          letter-spacing: 0;
          color: $comet600;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_what-people-say {
  .carousel__next,
  .carousel__prev {
    width: 15px;
    margin: 0 -18px;
  }
}

@media print {
  .i_what-people-say {
    padding-top: 50px;

    .reviews-carousel {
      .card {
        margin: 0 8px;
      }
    }
  }
}
</style>
