<template>
  <div
    class="i_current-position"
    v-if="reportStore.hasCurrentJob"
  >
    <div class="caption">Current Position</div>
    <div class="title">
      <iBaseTruncatedText
        :text="jobTitle"
        :max-length="35"
      />
    </div>
    <div class="content">
      <div class="bubble median">Average: {{ medianSalary }}</div>
      <div class="bubble current">Current: {{ currentSalary }}</div>
    </div>
    <div class="background">
      <img :src="bgIllustrationCircles" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { useReportStore } from "@/stores/report"

import bgIllustrationCircles from "@/assets/cards/bg-illustration-circles.svg"
import iBaseTruncatedText from "@/components/base/BaseTruncatedText.vue"

// STORES
const reportStore = useReportStore()

// COMPUTED
const jobTitle = computed(() => reportStore.reportData?.user?.currentJob)

const currentSalary = computed(() => {
  const fullCurrentSalary = reportStore.getIncomePotential?.currentSalary
  return fullCurrentSalary ? `$${Math.round(fullCurrentSalary / 1000)}K` : "N/A"
})

const medianSalary = computed(() => {
  const fullMedianSalary = reportStore.getIncomePotential?.medianSalary
  return fullMedianSalary ? `$${Math.round(fullMedianSalary / 1000)}K` : "N/A"
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_current-position {
  @include border-radius-lg;
  min-height: 342px;
  padding: 48px 24px;
  overflow: hidden;
  position: relative;
  background-color: $azureRadiance600;

  .caption {
    @include text-xs;
    font-weight: 700;
    color: $white;
    text-align: center;
    margin-bottom: 8px;
    text-transform: uppercase;
    z-index: 2;
  }

  .title {
    @include display-xs;
    text-align: center;
    font-weight: 600;
    color: $white;
    margin-bottom: 24px;
    z-index: 2;
  }

  .content {
    min-height: 128px;
    position: absolute;
    padding: 0 22px;
    left: 0;
    bottom: 35px;
    width: 100%;
    @include flex($direction: column, $justify: space-between, $align: center);

    @media screen and (min-width: $breakpointSm) {
      padding: 0px 60px;
    }

    @media screen and (min-width: $breakpointLg) {
      padding: 0px 22px;
    }

    .bubble {
      @include flex(column, $justify: center, $align: center);
      z-index: 2;
      padding: 10px 20px;
      background-color: $azureRadiance50;
      @include border-radius-xxl;
      @include text-lg;
      color: $azureRadiance600;
      font-weight: 700;
      text-align: center;

      &.median {
        align-self: end;
      }

      &.current {
        align-self: start;
      }
    }
  }

  .background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
