<template>
  <div
    class="i_career-summary"
    :style="{ justifyContent }"
  >
    <div class="title">{{ job.jobName }}</div>
    <div class="description">
      <iBaseExpandableParagraph
        ref="expandableParagraphRef"
        :text="job.description || ''"
        text-name="full description"
        cta-position="center"
        :collapsed-length="descriptionCollapsedLength"
        :max-search-range="20"
      />
    </div>

    <div class="stats">
      <template v-for="stat in stats">
        <div class="stat">
          <img
            :src="stat.icon"
            alt="stats-icon"
          />
          <div class="texts">
            <div class="caption">{{ stat.caption }}</div>
            <div class="info">{{ stat.info }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, type PropType } from "vue"

import { numberAsCurrency } from "@/composables/numberAsCurrency"

// COMPONENTS
import iBaseExpandableParagraph from "@/components/base/BaseExpandableParagraph.vue"

// ASSETS
import iconDollar from "@/assets/icons/icon-dollar-blue.svg"
import iconStudy from "@/assets/icons/icon-study-blue.svg"

// TYPES
import type { SuggestedJob } from "@/shared/dtos/out/JobGetSuggestedOut"

// REFS
const expandableParagraphRef = ref(null)

// PROPS
const props = defineProps({
  job: {
    type: Object as PropType<SuggestedJob>,
    required: true
  },
  contentArea: {
    type: Number,
    required: true
  }
})

// COMPUTED
const descriptionCollapsedLength = computed(() => {
  if (props.contentArea > 500000) {
    return 1000
  } else if (props.contentArea > 400000) {
    return 500
  } else if (props.contentArea > 300000) {
    return 250
  } else if (props.contentArea > 200000) {
    return 150
  } else {
    return 100
  }
})

const justifyContent = computed(() => {
  return props.contentArea > 400000 ? "center" : "start"
})

const stats = computed(() => {
  return [
    {
      caption: "Median Salary",
      info: numberAsCurrency(props.job.medianSalary),
      icon: iconDollar
    },
    {
      caption: "Industry",
      info: props.job.industryName,
      icon: iconStudy
    }
  ]
})

// WATCHER
watch(
  () => props.job,
  () => {
    // @ts-ignore - TS doesn't know that expandableParagraphRef has exposed methods
    expandableParagraphRef?.value?.collapseText()
  },
  { immediate: true }
)
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_career-summary {
  height: 100%;
  @include flex($direction: column, $justify: start, $gap: 22px);

  @media screen and (min-width: $breakpointXs) {
    padding-top: 0;
  }

  @media screen and (min-width: $breakpointMd) {
    padding: 0;
  }

  .title {
    text-align: left;
    font-weight: 700;
    @include display-xs;
  }

  .description {
    @include text-md;
    font-weight: 500;
    color: $black600;
  }

  .stats {
    @include grid($cols: auto, $rows: auto, $gap: 16px);
    margin-top: 12px;

    @media screen and (min-width: $breakpointMd) {
      @include grid($cols: 1fr 1fr, $rows: auto, $gap: 16px);
    }

    .stat {
      @include flex($direction: row, $justify: start, $align: start, $gap: 10px);
      @include border-radius-md;
      background-color: $pickleBluewood50;
      padding: 20px 25px;
      text-align: start;
      max-width: 400px;

      img {
        margin-bottom: auto;
      }

      .texts {
        @include text-lg;

        .caption {
          color: $azureRadiance950;
          font-weight: 700;
        }

        .info {
          color: $azureRadiance900;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
