<template>
  <div class="i_report-checkout">
    <iBackground :color="white" />
    <iAnnouncementBar />
    <iReportCountdown
      :timerDurationMin="15"
      :hasCallToAction="false"
    />

    <section class="content">
      <div
        class="number-of-purchases grey-sub"
        v-if="numberOfPurchases"
      >
        <strong>{{ numberOfPurchases }}</strong> people purchased this report in the past 24 hours.
      </div>
      <div class="right">
        <div id="embedded-form">
          <iStripeEmbeddedForm
            :stripe-price="stripePrice"
            @error="onStripeError"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"

// Services
import GtmService from "@/services/gtmService"

// Components
import iBackground from "@/components/global/Background.vue"
import iStripeEmbeddedForm from "@/components/global/StripeEmbeddedForm.vue"
import iAnnouncementBar from "@/components/welcome/AnnouncementBar.vue"
import iReportCountdown from "@/components/welcome/ReportCountdown.vue"

// Types
import StripePrices from "@/shared/enums/StripePrices"
import UserStatus from "@/shared/enums/UserStatus"

// Styles
import { white } from "@/styles/colors"

// Router
const router = useRouter()
const stripePrice = router.currentRoute.value.query.uuid as StripePrices

// Ref
const numberOfPurchases = ref<number>(112)

// Functions
function onStripeError() {
  // Go back to the previous page if there is an error with the Stripe form
  router.go(-1)
}

/**
 * Set the purchase data based on the upgrade status
 *
 * Basic report is set by default
 *
 * TODO: Once this goes live again, we also need to fire begin checkout event. See getPaymentAction.ts
 */
async function fireAddToCarts(stripePrice: StripePrices) {
  if (
    stripePrice === StripePrices.COMPREHENSIVE_HIGH ||
    stripePrice === StripePrices.COMPREHENSIVE_LOW ||
    stripePrice === StripePrices.COMPREHENSIVE_UPGRADE
  ) {
    await GtmService.addToCartEvent(UserStatus.COMPREHENSIVE)
  } else if (stripePrice === StripePrices.BASIC_HIGH || stripePrice === StripePrices.BASIC_LOW) {
    await GtmService.addToCartEvent(UserStatus.BASIC)
  }
}

// Lifecycle
onMounted(async () => {
  // Get uuid from the URL query
  const uuid = router.currentRoute.value.query.uuid as StripePrices
  if (uuid) {
    await fireAddToCarts(uuid)
  }

  // Get the number of purchases from URL query
  const purchases = router.currentRoute.value.query["purchase-number"]
  if (purchases) {
    numberOfPurchases.value = parseInt(purchases as string)
  }

  scrollTo({ top: 0, behavior: "instant" as ScrollBehavior })
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_report-checkout {
  .content {
    width: 100%;

    padding: 8px;

    @media screen and (min-width: $breakpointXs) {
      padding: 12px;
    }

    @media screen and (min-width: $breakpointSm) {
      padding: 24px;
    }

    .number-of-purchases {
      @include text-md;
      font-weight: 400;
      color: $black700;

      text-align: center;
      margin: 0 auto 16px auto;
    }

    #embedded-form {
      width: 100%;
      @media screen and (min-width: $breakpointSm) {
        width: calc(100% + 48px);
        margin: 0 -24px;
      }
    }
  }
}
</style>
