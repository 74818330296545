<template>
  <div class="i_rater">
    <div class="ask">Would you be interested in pursuing this career?</div>
    <div class="meter">
      <template
        v-for="i in Object.keys(RATES).length"
        :key="i"
      >
        <div class="rate">
          <div
            class="bar"
            :style="{ '--color': RATES[i].color, opacity: isMobile ? 1 : 0.8 }"
            @click="emitRating(RATES[i].rate)"
          >
            {{ i }}
          </div>
          <div
            class="text"
            v-if="[1, 3, 5].includes(i)"
          >
            {{ i === 1 ? "Never" : i === 3 ? "Maybe" : "Yes" }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIsMobile } from "@/composables/useIsMobile"
import { defineEmits } from "vue"

// TYPES
import type { Feedback } from "@/shared/types/UserJobFeedback"

// STYLES
import { bilbao400, bilbao500, bittersweet600, sorbus400, sorbus600 } from "@/styles/colors"

// EMITS
const emit = defineEmits(["rating-selected"])

const isMobile = useIsMobile()

const RATES: { [x: number]: { color: string; rate: Feedback } } = {
  1: { color: bittersweet600, rate: -2 },
  2: { color: sorbus600, rate: -1 },
  3: { color: sorbus400, rate: 0 },
  4: { color: bilbao400, rate: 1 },
  5: { color: bilbao500, rate: 2 }
}

// METHODS
const emitRating = (rating: number) => {
  emit("rating-selected", rating)
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";

.i_rater {
  text-align: center;

  .ask {
    @include text-xs;
    font-weight: 500;
    color: $comet700;
  }

  .meter {
    @include flex($direction: row, $align: start, $gap: 4px);

    .rate {
      margin-top: 10px;

      .bar {
        cursor: pointer;
        border-radius: 4px;
        background-color: var(--color);
        color: $white;

        &:hover {
          opacity: 1 !important;
        }

        padding: 2px 14px;

        @media screen and (min-width: $breakpointXxs) {
          padding: 4px 18px;
        }

        @media screen and (min-width: $breakpointXs) {
          padding: 8px 24px;
        }
      }

      .text {
        @include text-xs;
        font-weight: 700;
        color: $comet500;
        margin-top: 10px;
      }
    }
  }
}
</style>
