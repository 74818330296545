<template>
  <div
    class="i_payment-card-purchase"
    :class="{ highlighted: props.isRecommended }"
  >
    <div class="recommended">
      <span>{{ highlightedText }}</span>
    </div>
    <div class="main">
      <img
        :src="props.imageUrl"
        class="img"
        loading="lazy"
      />

      <div class="package">
        <span>{{ props.subtitle }}</span>
        <br />
        <span>{{ props.packageText }}</span>
      </div>

      <div class="content">
        <div class="title">
          <span>{{ props.title }}</span>
        </div>

        <div class="price">
          <div class="current">
            <span class="currency">{{ props.currentPriceWithCents ? "$" : " " }}</span>
            <span class="amount">{{ props.currentPriceWithCents }}</span>
          </div>
          <div class="previous">
            <span class="currency">{{ props.previousPriceWithCents ? "$" : " " }}</span>
            <span class="amount">{{ props.previousPriceWithCents }}</span>
            <div class="discount">{{ discountText }}</div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div
        v-if="hasBenefits"
        class="benefits"
      >
        <div
          v-for="(benefit, index) in props.benefits"
          :key="index"
        >
          <iTierBenefit
            :iconUrl="benefit.iconUrl"
            :title="benefit.title"
          />
        </div>
      </div>

      <div class="call-to-action">
        <iBaseButton
          v-bind="buttonProps"
          @click="firePaymentAction"
        />
      </div>

      <div
        class="number-of-purchases"
        v-if="props.numberOfPurchases && props.isRecommended"
      >
        {{ props.numberOfPurchases }} people purchased this package in the past 24 hours.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { useUserStore } from "@/stores/user"

// COMPONENTS
import iBaseButton from "@/components/base/BaseButton.vue"
import iTierBenefit from "@/components/pricing/TierBenefit.vue"

// ASSETS
import imageProTier from "@/assets/images/image-pro-tier.png"

// STYLES
import { astronaut600, black, black100, black900, white } from "@/styles/colors"

// STORES
const userStore = useUserStore()

// EMITS
const emit = defineEmits(["clicked", "payment-action-error"])

// PROPS
const props = defineProps({
  benefits: {
    type: Array as () => { iconUrl: string; title: string }[],
    default: () => []
  },
  paymentAction: {
    type: Function,
    default: () => {
      console.log("Payment action loading...")
    }
  },
  buttonColorBg: {
    type: String,
    default: black
  },
  buttonColorText: {
    type: String,
    default: white
  },
  buttonText: {
    type: String,
    default: "GET MY RESULTS"
  },
  currentPriceWithCents: {
    type: String,
    required: true
  },
  imageUrl: {
    type: String,
    default: imageProTier
  },
  isRecommended: {
    type: Boolean,
    default: false
  },
  numberOfPurchases: {
    type: Number
  },
  subtitle: {
    type: String,
    default: "A complete toolkit for those ready to independently navigate their career options"
  },
  title: {
    type: String,
    default: "Comprehensive"
  },
  packageTier: {
    type: String,
    default: "Package Tier"
  },
  packageText: {
    type: String,
    default: "Self Guided"
  },
  isButtonDisabled: {
    type: Boolean,
    default: false
  },
  discountPercentageValue: {
    type: Number,
    default: 0
  },
  creditAppliedValue: {
    type: Number,
    default: 0
  },
  previousPriceWithCents: {
    type: String,
    default: ""
  },
  isPaymentButtonDisabled: {
    type: Boolean,
    default: false
  }
})

// COMPUTED
const hasBenefits = computed<Boolean>(() => {
  return props.benefits.length > 0
})

const buttonProps = computed(() => {
  return {
    backgroundColor: props.isButtonDisabled ? black100 : props.isRecommended ? astronaut600 : props.buttonColorBg,
    letterSpacing: "1.28px",
    size: "medium",
    text: props.buttonText,
    textColor: props.isButtonDisabled ? black900 : props.isRecommended ? white : props.buttonColorText,
    width: "100%",
    version: props.isRecommended ? "solid" : "outline",
    borderRadius: "36px",
    isHoverable: props.isButtonDisabled ? false : true,
    isDisabled: props.isPaymentButtonDisabled
  }
})

const highlightedText = computed(() => {
  return userStore.isTierFinished ? "Recommended" : "Most Popular"
})

const discountText = computed(() => {
  return props.discountPercentageValue
    ? `${props.discountPercentageValue}% Off Today`
    : props.creditAppliedValue
    ? `$${props.creditAppliedValue} Credit Applied`
    : ""
})

// FUNCTIONS
const firePaymentAction = async () => {
  if (props.isPaymentButtonDisabled) return
  emit("clicked")
  try {
    await props.paymentAction()
  } catch (error) {
    emit("payment-action-error")
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";
@import "@/styles/variables.scss";

.i_payment-card-purchase {
  .recommended {
    padding: 15px 0px 30px 0px;

    span {
      visibility: hidden;
    }
  }

  .main {
    @include card($shadow: true, $padding: false, $height: unset);
    max-width: 400px;
    padding: 15px;
    margin-top: -15px;

    .img {
      width: 100%;
      @include border-radius-md;
      height: 200px;
      object-fit: cover;
    }

    .package {
      @include text-lg;
      font-weight: 600;
      margin: 18px 0px 5px 0px;
      min-height: 84px;

      span:last-child {
        @include text-md;
        font-weight: 500;
      }
    }

    .content {
      padding: 12px 0px 16px 0px;

      .title {
        @include text-md;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .price {
        @include flex($direction: row, $justify: start, $align: center, $gap: 30px);

        .currency {
          margin-right: 2px;
          font-weight: 900;
          @include text-lg;
          vertical-align: top;
        }

        .amount {
          @include display-lg;
          font-weight: 600;
        }

        .previous {
          color: #7d838a;

          .currency {
            @include text-xs;
          }

          .amount {
            @include display-xs;
            position: relative;
          }

          .amount:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 2px solid;
            border-color: inherit;

            -webkit-transform: rotate(-10deg);
            -moz-transform: rotate(-10deg);
            -ms-transform: rotate(-10deg);
            -o-transform: rotate(-10deg);
            transform: rotate(-10deg);
          }

          .discount {
            color: #353d49;
            margin-right: 2px;
            font-weight: 900;
            @include text-sm;
          }
        }
      }
    }

    .benefits {
      padding: 20px 0px 8px 0px;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: $comet100;
    }

    .call-to-action {
      padding-bottom: 12px;
    }

    .number-of-purchases {
      @include text-md;
      color: $azureRadiance600;
      font-weight: 500;
      padding: 0 32px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}

.i_payment-card-purchase.highlighted {
  .recommended {
    @include text-lg;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white;
    font-weight: 600;
    width: 100%;
    padding: 15px 0px 30px 0px;
    text-align: center;
    background-image: linear-gradient(120deg, #55cbff 0%, #5494ff 45%, #c057ff 75%);

    span {
      visibility: visible;
    }
  }
}
</style>
