<template>
  <div
    class="i_coaching-notice"
    @click="linkToCoachSelection"
  >
    <div class="wrap">
      <div class="icon">
        <img
          :src="profileMiniCoach"
          alt="Profile Mini Coach"
        />
      </div>
      <div class="text white">
        <span v-if="isMobileOrLarger">Need some assistance?</span>
        <span>Talk to our career coaches.</span>
      </div>
      <div
        class="text highlight"
        v-if="isTabletOrLarger"
      >
        Get Started
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import profileMiniCoach from "@/assets/images/profile-mini-coach.png"

import useIsScreenSize from "@/composables/useIsScreenSize"
import linkToCoachSelection from "@/composables/linkToCoachSelection"

import { breakpointMd, breakpointSm } from "@/styles/variables"

const isMobileOrLarger = useIsScreenSize(breakpointSm)
const isTabletOrLarger = useIsScreenSize(breakpointMd)
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";

.i_coaching-notice {
  cursor: pointer;
  background-color: $blackPearl950;
  width: 100%;
  height: 48px;

  @include flex($direction: row, $justify: center, $align: center);
  .wrap {
    @include flex($direction: row, $justify: center, $align: center, $gap: 18px);

    .icon {
      position: relative;
      width: 26px;
      height: 26px;

      img {
        width: 100%;
        height: 100%;
      }

      // Green Dot
      &::after {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: $bilbao400;
        border-radius: 50%;
        right: 0px;
        bottom: 0px;
      }
    }

    .text {
      @include text-sm;
      @include flex($direction: row, $justify: center, $align: center, $gap: 4px);
      font-weight: 500;

      &.white {
        color: $white;
      }

      &.highlight {
        color: $azureRadiance300;
      }
    }
  }
}

@media print {
  .i_coaching-notice {
    display: none;
  }
}
</style>
