<template>
  <div class="i_coaching-loading">
    <div
      class="content"
      v-if="data.activeLoader != null"
    >
      <img
        class="logo"
        :src="logo"
        loading="lazy"
      />

      <template v-if="!data.startRedirect">
        <div class="main-header">Finding you the perfect coach...</div>

        <div class="loading-section">
          <div
            class="loading"
            v-for="(loader, index) in LOADING_DATA"
          >
            <div
              class="text"
              :style="{ color: loader.color }"
            >
              <div>{{ loader.caption }}</div>
              <div>
                {{ index === data.activeLoader ? `${data.loaderPercent}%` : data.activeLoader > index ? "100%" : "0%" }}
              </div>
            </div>
            <iBaseLoadingBar
              :fillColor="data.activeLoader < index ? black100 : loader.color"
              barWidth="100%"
              barHeight="10px"
              :infinite="false"
              :isActive="index === data.activeLoader"
              :loadingAnimationDurationMs="LOADING_ANIMATION_DURATION_MS"
            />
          </div>
        </div>

        <div class="review-section">
          <div class="header">Trusted by over 700,000 users</div>
          <div class="review">
            <div class="info">
              <img :src="LOADING_DATA[data.activeLoader].review.image" /><strong>{{
                LOADING_DATA[data.activeLoader].review.name
              }}</strong>
              ({{ LOADING_DATA[data.activeLoader].review.stats }})
            </div>
            <div
              class="progress"
              v-html="LOADING_DATA[data.activeLoader].review.progress"
            ></div>
            <img
              class="stars"
              :src="iconStars"
            />
            <div class="comment">{{ LOADING_DATA[data.activeLoader].review.comment }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="redirect-section">
          <div class="main-header">We Found Your Match!</div>
          <div>
            <iBaseButton
              v-bind="buttonStyle"
              @clicked="goToSelectCoach"
            />
            <div class="teeny">You will be redirected in: {{ data.redirectDelay }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue"
import {
  astronaut600,
  azureRadiance500,
  azureRadiance600,
  bilbao300,
  black100,
  brandOpacity100,
  white
} from "@/styles/colors"
import { breakpointMd } from "@/styles/variables"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"
import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseLoadingBar from "@/components/base/BaseLoadingBar.vue"

// ASSETS
import logo from "@/assets/logo/logo-dark.svg"
import imageChirita from "@/assets/lifecycle/chirita.png"
import imageYoonmi from "@/assets/lifecycle/yoonmi.png"
import imageBradley from "@/assets/images/bradley.png"
import imageJames from "@/assets/images/james.png"
import iconStars from "@/assets/icons/icon-five-stars.svg"

// COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)

// CONSTANTS
const REDIRECT_PATH = GlobalVariables.urls.coaching.path + GlobalVariables.urls.coaching.children.selectCoach.path
const LOADING_DATA = [
  {
    caption: "Assessing your strengths & weaknesses",
    color: astronaut600,
    review: {
      name: "James",
      image: imageJames,
      stats: "60, Loan Officer",
      progress: "Pursuing his dream job <strong>now</strong>.",
      comment:
        "My first meeting with Nancy was transformative. She reviewed my results and explored my possibilities with genuine honesty. Being in the latter stages of my career, I appreciated her straightforward advice and realistic expectations. The best part? We’re working on two paths: making my current job more rewarding and finding a new career."
    }
  },
  {
    caption: "Factoring in your preferences",
    color: azureRadiance500,
    review: {
      name: "Bradley",
      image: imageBradley,
      stats: "35, Project Manager",
      progress: "Landed his dream job <strong>in 1 month</strong>.",
      comment:
        "I wanted to pursue my dream job in the golf industry but didn’t know how to transition. With my coach's support, I successfully made the switch in less than a month. Now, I'm living my passion every day!"
    }
  },
  {
    caption: "Curating top coaches",
    color: brandOpacity100,
    review: {
      name: "Yoon Mi",
      image: imageYoonmi,
      stats: "29, Pilates Instructor",
      progress: "Increased salary from $70K <strong>to $110K</strong>.",
      comment:
        "I was struggling to market myself because I had dropped out of college. Working with a dedicated coach to highlight my strengths and mask my weaknesses helped me reach my full potential."
    }
  },
  {
    caption: "Finding your ideal match",
    color: bilbao300,
    review: {
      name: "Chirita",
      image: imageChirita,
      stats: "40, Recruiter",
      progress: "Increased salary from $40K <strong>to $120K</strong>.",
      comment:
        "I met Linda almost 5 years ago, and she has been my mentor, coach, and a friend. She changed my professional life and is the reason I can spend more time with my family."
    }
  }
]
const LOADING_ANIMATION_DURATION_MS = 15000

// DATA
const data = reactive({
  activeLoader: undefined as number | undefined,
  loaderPercent: 0,
  startRedirect: false,
  redirectDelay: 3
})

//COMPUTED
const buttonStyle = computed(() => {
  return {
    backgroundColor: azureRadiance600,
    borderRadius: "12px",
    fontWeight: "500",
    size: "small",
    text: "See My Match",
    textColor: white,
    width: isDesktop.value ? "fit-content" : "100%",
    padding: isDesktop.value ? "12px 120px" : "12px 70px"
  }
})

// FUNCTIONS
function goToSelectCoach() {
  // Do not use native Vue router push method because it affects the way conversions are marked in Convert
  try {
    const protocol = window.location.protocol
    const baseUrl = `${protocol}//${window.location.host}`
    console.log("Redirecting to select-coach page...")
    console.log("baseUrl", baseUrl)
    console.log("redirectPath", REDIRECT_PATH)
    GlobalFunctions.redirectToPage({ baseUrl, pageUrl: REDIRECT_PATH, queryParams: "" })
  } catch (error) {
    console.error("Error redirecting to select-coach page", error)
  }
}

const startLoader = async () => {
  for (let i = 0; i < LOADING_DATA.length; i++) {
    data.activeLoader = i
    data.loaderPercent = 0

    // Load 100% for each loading bar
    while (data.loaderPercent < 100) {
      await GlobalFunctions.delayInMiliseconds(LOADING_ANIMATION_DURATION_MS / 100)
      data.loaderPercent++
    }
  }

  // Show redirect screen and start countdown to redirect to select-coach
  data.startRedirect = true
  while (data.redirectDelay > 0) {
    await GlobalFunctions.delayInMiliseconds(1000)
    data.redirectDelay--
  }
  goToSelectCoach()
}

// LIFECYCLE HOOKS
onMounted(() => {
  startLoader()
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/borders.scss";

.i_coaching-loading {
  background-color: $white;
  min-height: 100vh;
  @include flex(row, center, center);

  @media screen and (min-width: $breakpointMd) {
    background-color: rgb(0, 0, 0, 0.6);
  }

  .content {
    max-width: 750px;
    background-color: white;
    padding: 30px;
    @include flex(column, center, stretch, $gap: 45px);

    @media screen and (min-width: $breakpointMd) {
      @include border-radius-xl;
      margin: 50px 0;
    }

    .logo {
      width: 150px;
      height: 40px;
      margin: auto;
    }

    .main-header {
      @include display-xs;
      font-weight: 700;
      text-align: center;

      @media screen and (min-width: $breakpointSm) {
        @include display-sm;
      }
    }

    .loading-section {
      @include flex(column, center, stretch, $gap: 20px);

      .loading {
        @include flex(column, center, stretch, $gap: 12px);

        .text {
          @include flex(row, space-between, center, $gap: 5px);
          @include text-sm;
          font-weight: 600;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
          }
        }
      }
    }

    .review-section {
      @include flex(column, center, center, $gap: 15px);

      .header {
        @include text-lg;
        font-weight: 700;
      }

      .review {
        @include flex($direction: column, $justify: start, $align: start, $gap: 5px);
        background-color: $black50;
        @include border-radius-sm;
        padding: 15px;
        width: 100%;

        @media screen and (min-width: $breakpointSm) {
          padding: 22px 25px;
          @include border-radius-xl;
        }

        .info {
          @include text-md;
          font-weight: 400;

          @media screen and (min-width: $breakpointSm) {
            @include text-xl;
          }

          > img {
            width: 32px;
            height: 32px;
            @include border-radius-rounded;
            margin-right: 10px;
            object-fit: cover;

            @media screen and (min-width: $breakpointSm) {
              width: 48px;
              height: 48px;
            }
          }
        }

        .progress {
          @include text-xs;
          font-weight: 400;

          @media screen and (min-width: $breakpointSm) {
            @include text-md;
          }
        }

        .stars {
          height: 18px;

          @media screen and (min-width: $breakpointSm) {
            height: 24px;
          }
        }

        .comment {
          @include text-sm;
          font-weight: 500;
          margin-top: 25px;

          @media screen and (min-width: $breakpointSm) {
            @include text-lg;
          }
        }
      }
    }

    .redirect-section {
      @include flex(column, start, center, $gap: 150px);

      .teeny {
        @include text-xs;
        margin-top: 8px;
        font-weight: 300;
        color: #088bf5;
        text-align: center;
      }
    }
  }
}
</style>
