import { useUserStore } from "@/stores/user"

export default class UrlService {
  /**
   * Takes a base URL and generates a URL with the specified query parameters.
   */
  static generateUrlWithParams(baseUrl: string, params: Record<string, string | number | null>): string {
    const queryParams = Object.entries(params)
      .filter(([, value]) => value !== null)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join("&")

    return baseUrl + (baseUrl.includes("?") ? "&" : "?") + queryParams
  }

  /**
   * Takes a cal.com base URL and generates a URL with the user's email, name, and obfuscated user ID as query parameters.
   */
  static generateCalComUrl(baseUrl: string): string {
    const userStore = useUserStore()

    const email = userStore.getEmail
    const name = userStore.getFullName
    const userId = userStore.getUserId ? +userStore.getUserId : null
    const obfuscatedUserId = userId ? UrlService.getObfuscatedUserId(userId) : null

    const params = {
      email,
      name,
      "metadata[userId]": obfuscatedUserId
    }

    return UrlService.generateUrlWithParams(baseUrl, params)
  }

  /**
   * Takes a cal.com embed link and generates a link with obfuscated user ID as query parameter.
   */
  static generateCalComEmbedLink(baseLink: string): string {
    const userStore = useUserStore()

    const userId = userStore.getUserId ? +userStore.getUserId : null
    const obfuscatedUserId = userId ? UrlService.getObfuscatedUserId(userId) : null

    const params = { "metadata[userId]": obfuscatedUserId }

    return UrlService.generateUrlWithParams(baseLink, params)
  }

  /**
   * Takes a Stripe base URL and generates a URL with the user's email and obfuscated user ID as query parameters.
   */
  static generateStripeUrl(baseUrl: string): string {
    const userStore = useUserStore()

    const email = userStore.getEmail
    const userId = userStore.getUserId ? +userStore.getUserId : null
    const obfuscatedUserId = userId ? UrlService.getObfuscatedUserId(userId) : null

    const params = {
      prefilled_email: email,
      client_reference_id: obfuscatedUserId
    }

    return UrlService.generateUrlWithParams(baseUrl, params)
  }

  // PRIVATE STATIC METHODS

  private static getObfuscatedUserId(userId: number): number {
    const PRIME = 123456789
    return userId * PRIME + PRIME
  }
}
