<template>
  <div class="i_career-roadmap">
    <div class="text">
      <div class="title">Your Career Roadmap is Ready...</div>
      <span class="subtitle">
        <span class="highlighted">Transform your career narrative,</span> just one report away.
      </span>
      <div class="summary">{{ personalityText }}</div>
      <iBaseButton
        @click="$emit('scrollToPaymentTable')"
        v-bind="buttonStyle"
        text="GET MY RESULTS"
      />
    </div>
    <div class="image">
      <img
        :src="imageProductScreenshot"
        loading="lazy"
        alt="product-preview"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"
import { bittersweet400 } from "@/styles/colors"

import iBaseButton from "@/components/base/BaseButton.vue"

// ASSETS
import imageProductScreenshot from "@/assets/welcome/product-screenshot-4.png"

// STORES
const reportStore = useReportStore()

// EMITS
defineEmits(["scrollToPaymentTable"])

// COMPUTED
const personalityText = computed(() => reportStore.reportData?.results?.welcomePersonalityText)

const buttonStyle = computed(() => ({
  backgroundColor: bittersweet400,
  padding: "8px 70px",
  textColor: "white",
  size: "medium"
}))
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";

.i_career-roadmap {
  @include flex($direction: column-reverse, $gap: 40px);
  text-align: center;

  @media screen and (min-width: $breakpointLg) {
    @include flex($direction: row, $gap: 30px);
    text-align: start;
  }

  .text {
    width: 100%;
    @include flex($direction: column, $align: center, $gap: 15px);

    @media screen and (min-width: $breakpointLg) {
      width: 45%;
      $align-items: start;
      @include flex($direction: column, $align: start, $gap: 15px);
    }

    .title {
      @include display-xs;
      font-weight: 700;

      @media screen and (min-width: $breakpointMd) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .subtitle {
      @include text-lg;
      font-weight: 400;
      line-height: 25px;

      @media screen and (min-width: $breakpointMd) {
        @include text-xl;
        line-height: 25px;
      }

      .highlighted {
        color: $bilbao500;
        font-weight: 700;
      }
    }

    .summary {
      @include text-md;
      font-weight: 400;
      line-height: 25px;
      margin-top: 12px;
    }
  }

  .image {
    width: 90%;
    @include flex($direction: column, $align: center, $gap: 15px);

    @media screen and (min-width: $breakpointLg) {
      width: 55%;
    }
  }
}
</style>
