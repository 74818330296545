export const numberAsCurrency = (number: number): string => {
  if (number === null || number === undefined) {
    return ""
  }

  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return currency.format(number)
}
