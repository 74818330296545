<template>
  <div class="i_home">
    <section
      class="intro"
      id="summary"
    >
      In short, your personality type is what we call {{ getArticle(personalityType) }}
      <a
        href="#personality_type"
        class="highlighted"
        >{{ personalityType }}</a
      >. Your potential salary is <span class="highlighted">{{ potentialSalary }}</span> per month.
    </section>

    <section id="top_recommendations">
      <iBaseHeader
        v-if="jobs && jobs.length > 0"
        highlight-text="Top Recommendations"
        :font-size-highlighted-text="headerSize"
        text="Based on your answers, here are the top career recommendations."
        font-size-text="14px"
        font-weight-text="500"
        :color-text="comet600"
        align="start"
      />
      <iProfessionsList
        v-if="jobs"
        :jobs="jobs"
        @job-page-redirect="(jobId: number) => pageRedirect(REPORT_ROUTES.careers.routeName, jobId)"
        @upgrade-clicked="emit('open-modal-payment')"
      />

      <iActionCard
        :imageUrl="imageOnlineCoaching"
        text="Over <span class='highlighted'>600,000 people</span> have found their dream career with Jobtest.org.<br/><br/><span class='highlighted'>You're up next.</span>"
      />
    </section>

    <section id="personality_type">
      <iBaseHeader
        highlight-text="Understanding Your Personality Type"
        :font-size-highlighted-text="headerSize"
        text="Based on your answers, here is an overview of your personality type."
        font-size-text="14px"
        font-weight-text="500"
        :color-text="comet600"
        align="start"
      />
      <iPersonalityAnalysis />
    </section>

    <section
      id="need_help"
      v-if="!userStore.isTierPremium"
    >
      <iBaseHeader
        highlight-text="Not sure on what to do next?"
        :font-size-highlighted-text="headerSize"
        text="Talk to our career coach to figure out what exactly you need to do."
        font-size-text="14px"
        font-weight-text="500"
        :color-text="comet600"
        align="start"
      />
      <iActionCard
        class="no-mobile-break"
        caption="Career Coach"
        text="Book a call, <br />dedicated and personalized."
        cta-text="Check Availability"
        @cta-clicked="linkToCoachSelection"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue"
import { useRouter } from "vue-router"

import { GlobalVariables } from "@/helpers/globalVariables"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/user"

// COMPOSABLES
import { getArticle } from "@/composables/getArticle"
import linkToCoachSelection from "@/composables/linkToCoachSelection"
import scrollToTop from "@/composables/scrollToTop"
import useIsScreenSize from "@/composables/useIsScreenSize"

// COMPONENTS
import iBaseHeader from "@/components/base/BaseHeader.vue"
import iActionCard from "@/components/global/ActionCard.vue"
import iPersonalityAnalysis from "@/components/report/home/PersonalityAnalysis.vue"
import iProfessionsList from "@/components/report/home/ProfessionsList.vue"

// ASSETS
import imageOnlineCoaching from "@/assets/home/welcome-action-1.png"
import iconNeedHelpBlue from "@/assets/icons/icon-info-circle-blue.svg"
import iconNeedHelp from "@/assets/icons/icon-info-circle.svg"
import iconPersonalityBlue from "@/assets/icons/icon-personality-blue.svg"
import iconPersonality from "@/assets/icons/icon-personality.svg"
import iconSummaryBlue from "@/assets/icons/icon-summary-blue.svg"
import iconSummary from "@/assets/icons/icon-summary.svg"
import iconTopRecBlue from "@/assets/icons/icon-top-rec-blue.svg"
import iconTopRec from "@/assets/icons/icon-top-rec.svg"

// STYLES
import { comet600 } from "@/styles/colors"
import { breakpointSm } from "@/styles/variables"

// TYPES
import type { PageAnchors } from "@/types/pageAnchors"

// EMITS
const emit = defineEmits(["open-modal-payment", "load-anchors"])

// Router
const router = useRouter()

// STORES AND COMPOSABLES
const reportStore = useReportStore()
const userStore = useUserStore()
const isNotMobile = useIsScreenSize(breakpointSm)

// COMPUTED
const headerSize = computed(() => (isNotMobile.value ? "36px" : "32px"))

const potentialSalary = computed(() => {
  const potentialSalary = reportStore.getIncomePotential?.potentialSalary
  return `$${(Math.round(potentialSalary / 12 / 100) * 100).toLocaleString()}`
})

const personalityType = computed(() => reportStore.reportData?.results?.personalityType)

const jobs = computed(() => {
  const recommendedJobs = reportStore.getRecommendedJobs

  if (!recommendedJobs) return null

  return recommendedJobs
    ? userStore.isTierAnyPaid
      ? recommendedJobs
          .filter((job) => job.isVisible && !job.isCurrentJob && !job.isActivelyExploringJob && !job.isSampleJob)
          .slice(0, 3)
      : recommendedJobs.filter((job) => job.isVisible && job.isSampleJob)
    : null
})

// CONSTANTS
const REPORT_ROUTES = GlobalVariables.urls.report.children
const CONSTANT_PAGE_ANCHORS = {
  summary: {
    text: "Summary",
    icon: iconSummary,
    iconActive: iconSummaryBlue
  },
  top_recommendations: {
    text: "Top Recommendations",
    icon: iconTopRec,
    iconActive: iconTopRecBlue
  },
  personality_type: {
    text: "Personality Type",
    icon: iconPersonality,
    iconActive: iconPersonalityBlue
  }
} as PageAnchors

// FUNCTIONS
const pageRedirect = (routeName: string, jobId?: number) => {
  if (jobId) {
    router.push({ name: routeName, params: { jobId } })
  } else {
    router.push({ name: routeName })
  }
  scrollToTop()
}

// LIFECYCLE HOOKS
onMounted(() => {
  if (!userStore.isTierPremium)
    CONSTANT_PAGE_ANCHORS.need_help = {
      text: "Need Help?",
      icon: iconNeedHelp,
      iconActive: iconNeedHelpBlue
    }

  emit("load-anchors", CONSTANT_PAGE_ANCHORS)
})
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/borders.scss";
@import "@/styles/typography.scss";

.i_home {
  max-width: 720px;
  margin: 0 auto 20px auto;
  @include grid($cols: 1fr, $rows: auto, $gap: 56px);

  section {
    scroll-margin-top: 90px;
  }

  .intro {
    border-left: 5px solid $azureRadiance500;
    color: $pickleBluewood900;
    padding-left: 25px;
    @include text-xl;
    font-weight: 500;

    @media screen and (min-width: $breakpointSm) {
      padding-left: 30px;
      margin: 10px auto;
    }

    .highlighted {
      background-color: $azureRadiance500;
      padding: 0 6px;
      color: $white;
      @include border-radius-sm;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}
</style>

<style lang="scss">
@import "@/styles/variables.scss";

.i_home {
  .no-mobile-break {
    margin-top: 32px;

    br {
      display: none;

      @media screen and (min-width: $breakpointSm) {
        display: block;
      }
    }
  }
}
</style>
