<template>
  <div class="i_download-pdf">
    <iOnboardingTooltip :value="3" />
    <a
      id="gtm-selector-download-pdf-interest"
      class="unlock-download-pdf"
      @click="onDownloadClick()"
    >
      <img :src="iconDownload" />
    </a>
    <div
      v-if="data.showShade"
      class="shade"
    ></div>

    <iBaseModal
      :visible="data.showRedirectModal"
      @click-on-close="data.showRedirectModal = false"
    >
      <div class="confirmation-modal">
        <div class="title">Mobile download unavailable</div>

        <div>
          Downloading as PDF is not available on mobile and tablet devices, please use a laptop or desktop computer to
          access this feature.
        </div>
      </div>
    </iBaseModal>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"

import iBaseModal from "@/components/base/BaseModal.vue"
import iOnboardingTooltip from "@/components/report/OnboardingTooltip.vue"

import iconDownload from "@/assets/icons/icon-downloader.svg"

const data = reactive({
  showShade: false,
  showRedirectModal: false
})

// FUNCTIONS
const openPrintWindow = () => {
  // display shade to hide scrolling
  data.showShade = true

  // scroll to page end so all radar charts render
  window.scroll({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth"
  })

  // wait 5 ms to allow charts render
  setTimeout(() => {
    window.print()
  }, 600)

  // remove shade and go back to top after print modal closed
  window.onafterprint = () => {
    data.showShade = false
    scroll({
      top: 0,
      left: 0,
      behavior: "instant" as ScrollBehavior
    })
  }
}

const openRedirectModal = () => {
  data.showRedirectModal = true
}

const onDownloadClick = () => {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    openRedirectModal()
  } else {
    openPrintWindow()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_download-pdf {
  position: relative;
  flex: 0 0 18px;

  .unlock-download-pdf {
    @include flex($direction: row, $justify: center, $align: center, $gap: 4px);
    max-width: fit-content;
    cursor: pointer;

    img {
      align-self: end;
      width: 18px;
      height: 18px;
    }
  }

  .shade {
    position: fixed;
    z-index: $zIndexModal;
    width: 100vw;
    height: 100vh;
    background-color: #000000eb;
    left: 0;
    top: 0;
  }

  .confirmation-modal {
    @include flex($direction: column, $justify: start, $align: center, $gap: 25px);

    .title {
      @include display-xs;
      font-weight: 700;
      text-align: center;
    }
  }
}

@media print {
  .i_download-pdf {
    display: none;
  }
}
</style>

<style lang="scss">
@import "@/styles/variables.scss";

.i_download-pdf {
  .i_onboarding-tooltip {
    transform: translate(0%, -100%);

    @media screen and (min-width: $breakpointMd) {
      transform: translate(-14%, -100%);
    }

    .tippy {
      transform: translate(15%, -1px);

      @media screen and (min-width: $breakpointMd) {
        transform: translate(100%, -1px);
      }
    }
  }
}
</style>
