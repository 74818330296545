<template>
  <div class="i_earning-potential">
    <div class="wrap is-left">
      <div class="salary"><span>$</span>{{ earningPotentialAsCurrency }}</div>

      <div class="title">We believe your earning potential can be {{ earningPotentialAsString }} and more.</div>
      <div class="description">
        That's a <span>{{ earningPotentialText }}</span> from your current salary, and you have the qualification and
        skillset to get there.
      </div>
    </div>

    <div class="wrap">
      <img
        class="image-desktop"
        :src="illustrationearningPotentialOne"
        alt=""
      />

      <img
        class="image-mobile"
        :src="illustrationearningPotentialTwo"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import illustrationearningPotentialOne from "@/assets/welcome/illustration-earning-potential-one.webp"
import illustrationearningPotentialTwo from "@/assets/welcome/illustration-earning-potential-two.webp"
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

// STORES
const reportStore = useReportStore()

// COMPUTED

const earningCurrently = computed<number>(() => reportStore.reportData?.results?.incomePotential?.currentSalary ?? 0)
const earningPotential = computed<number>(() => reportStore.salaryStatistics?.salaryRangesAsNumbers?.top ?? 0)
const earningPotentialAsString = computed<string>(() => reportStore.salaryStatistics?.salaryRanges?.top ?? 0)
const earningPotentialAsCurrency = computed<string>(() => earningPotential.value.toLocaleString("en-US"))

const earningPotentialText = computed<string>(() => {
  if (earningCurrently.value && earningPotential.value) {
    const increasePercentage = ((earningPotential.value - earningCurrently.value) / earningCurrently.value) * 100
    return increasePercentage > 0 ? `${increasePercentage.toFixed(0)}% increase` : "increase"
  } else {
    return "increase"
  }
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_earning-potential {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;

  @media screen and (min-width: $breakpointLg) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 500px;
    align-content: flex-end;
  }

  .wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;

    @media screen and (min-width: $breakpointLg) {
      align-items: start;
      text-align: left;

      .is-left {
        margin-top: 32px;
      }
    }
  }

  .salary {
    @include display-lg;
    font-weight: 500;

    @media screen and (min-width: $breakpointSm) {
      font-size: 56px;
    }

    @media screen and (min-width: $breakpointLg) {
      @include display-xl;
      font-size: 72px;
      margin-bottom: 12px;
    }

    @media screen and (min-width: $breakpointXl) {
      @include display-xxl;
      font-size: 84px;
      margin-left: -64px;
      margin-bottom: 12px;
    }

    span {
      color: $black200;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .title {
    @include display-xs;
    font-weight: 600;
    max-width: 450px;

    span {
      background-color: $bittersweet400;
      border-radius: 64px;
      color: $white;
      padding-left: 14px;
      padding-right: 10px;
    }
  }

  .description {
    @include text-md;
    max-width: 430px;

    span {
      font-weight: bold;
    }

    @media screen and (min-width: $breakpointLg) {
      border-left: 4px solid $sorbus400;
      padding-left: 16px;
    }
  }

  .image-desktop {
    display: none;
    left: -15%;
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;
    position: relative;
    width: 150%;

    @media screen and (min-width: $breakpointLg) {
      display: block;
    }
  }

  .image-mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
    width: 100%;

    @media screen and (min-width: $breakpointLg) {
      display: none;
    }
  }
}
</style>
