<template>
  <div class="i_campaign-roadmap">
    <div class="comparison">
      <div class="header">
        Get your personalized roadmap to
        <template v-if="props.price === '99.90'">breaking <span class="highlighted">$100K salary</span></template>
        <template v-else><span class="highlighted">career satisfaction</span></template
        >!
      </div>
      <div class="compare">
        <div class="profile">
          <img :src="imageNow" />
          <div class="text">
            <div class="light">Salary</div>
            <div class="heavy">{{ props.price === "99.90" ? "$70,000" : "$100,000" }}</div>
            <div class="light">Job Satisfaction</div>
            <img :src="imageRedBar" />
          </div>
        </div>
        <img :src="iconChevron" />
        <div class="profile">
          <img :src="imageAfter" />
          <div class="text">
            <div class="light">Salary</div>
            <div class="heavy">{{ props.price === "99.90" ? "$100,000+" : "$120,000+" }}</div>
            <div class="light">Job Satisfaction</div>
            <img :src="imageBlueBar" />
          </div>
        </div>
      </div>
    </div>
    <div class="inclusion">
      <div class="inner">
        <div class="details">
          <div class="top">
            <div class="discount">Exclusive Discount</div>
            <div class="caption">
              Supercharge your career with {{ props.version === 2 ? "our Recommended Coach" : props.coach }}
              <span class="grey">(60-min)</span>
            </div>
            <div class="subtitle">
              <div class="pulse-circle"></div>
              There are only <strong>{{ openSlots }} slots</strong> remaining
            </div>
            <div class="price">${{ props.price }} <span class="blue">$199.90</span></div>
          </div>
          <iBaseDelimiter :color="astronaut100" />
          <div class="bottom">
            <div class="include">Includes:</div>
            <div class="list-item"><img :src="iconCheck" /> Career goal discussion</div>
            <div class="list-item"><img :src="iconCheck" /> Skills and knowledge assessment</div>
            <div class="list-item"><img :src="iconCheck" /> Step-by-step action planning</div>
            <div class="list-item">
              <img :src="iconCheck" /> Resume & LinkedIn optimization <span class="grey">(Optional)</span>
            </div>
            <div class="list-item">
              <img :src="iconCheck" /> Continued collaboration with
              {{ props.version === 2 ? "an expert coach" : props.coach }}
              <span class="grey">(Optional)</span>
            </div>
          </div>
        </div>
        <div class="cta">
          <iBaseButton
            v-bind="buttonStyle"
            :text="props.version === 2 ? `Check ${props.coach}'s Recommendation` : 'Check Availability'"
            @click="$emit('ctaClicked')"
          />
          <div class="slots">
            <div class="pulse-circle"></div>
            There are only <strong>{{ openSlots }} slots</strong> remaining
          </div>
        </div>
      </div>
    </div>
    <div class="graph">
      <div class="caption">Results that pay for themselves</div>
      <div class="info">Proven record of supporting people's career success.</div>
      <img :src="props.version === 2 ? imageGraph2 : imageGraph" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useUserStore } from "@/stores/user"

import { breakpointMd } from "@/styles/variables"
import { astronaut100, astronaut600, azureRadiance200, white } from "@/styles/colors"

import useIsScreenSize from "@/composables/useIsScreenSize"
import useRandomNumber from "@/composables/useRandomNumber"

import iBaseButton from "@/components/base/BaseButton.vue"
import iBaseDelimiter from "@/components/base/BaseDelimiter.vue"

import imageBlueBar from "@/assets/lifecycle/bar-fill-blue-5.svg"
import imageRedBar from "@/assets/lifecycle/bar-fill-red-1.svg"
import imageGraph from "@/assets/lifecycle/campaign-roadmap-graph.png"
import imageGraph2 from "@/assets/lifecycle/campaign-roadmap-graph.svg"
import imageNow from "@/assets/lifecycle/campaign-roadmap-now.png"
import imageAfter from "@/assets/lifecycle/campaign-roadmap-after.png"
import iconChevron from "@/assets/icons/icon-chevron-right-double.svg"
import iconCheck from "@/assets/icons/icon-blue-gray-check.svg"

// STORES
const userStore = useUserStore()

// EMITS
defineEmits(["ctaClicked"])

const props = defineProps({
  price: {
    type: String,
    default: "99.90"
  },
  coach: {
    type: String,
    default: ""
  },
  version: {
    type: Number,
    default: 1
  }
})

//COMPOSABLES
const isDesktop = useIsScreenSize(breakpointMd)
const openSlots = useRandomNumber(userStore.getFullName || "Full Name", 3, 5)

// COMPUTED
const buttonStyle = computed(() => {
  return {
    backgroundColor: astronaut600,
    borderRadius: "8px",
    fontWeight: "600",
    size: isDesktop.value ? "medium" : "small",
    textColor: white,
    width: "100%",
    pulseColor: azureRadiance200
  }
})
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_campaign-roadmap {
  padding: 40px 0;

  .comparison {
    .header {
      @include display-xs;
      font-weight: 700;

      @media screen and (min-width: $breakpointSm) {
        @include display-sm;
        text-align: center;
      }

      @media screen and (min-width: $breakpointMd) {
        @include display-lg;
      }

      .highlighted {
        background-color: $azureRadiance100;
        padding: 0 6px;
        color: $black;
        @include border-radius-sm;
        white-space: nowrap;
      }
    }

    .compare {
      @include flex($direction: row, $justify: center, $align: center, $gap: 3px);
      margin: 30px auto;

      > img {
        width: 25px;
        transform: translateY(-150%);

        @media screen and (min-width: $breakpointMd) {
          width: unset;
          transform: translateY(-100%);
        }
      }

      .profile {
        .text {
          padding: 0 5px;

          @media screen and (min-width: $breakpointMd) {
            padding: 0 10px;
          }

          .light {
            @include text-sm;
            font-weight: 500;
            margin-top: 5px;

            @media screen and (min-width: $breakpointMd) {
              @include text-lg;
            }
          }

          .heavy {
            @include text-lg;
            font-weight: 700;

            @media screen and (min-width: $breakpointMd) {
              @include text-xl;
            }
          }

          > img {
            width: 100%;
          }
        }
      }
    }
  }

  .inclusion {
    @include border-radius-sm;
    background-color: $azureRadiance100;
    padding: 10px;
    margin-bottom: 25px;

    @media screen and (min-width: $breakpointSm) {
      padding: 50px;
      @include border-radius-xl;
    }

    .inner {
      width: 100%;
      max-width: 500px;
      margin: auto;
      position: relative;

      .details {
        @include flex($direction: column, $justify: center, $align: normal, $gap: 18px);
        @include border-radius-sm;
        background-color: $white;
        padding: 50px 0 30px 0;
        margin-bottom: 28px;

        @media screen and (min-width: $breakpointSm) {
          @include border-radius-xl;
        }

        .top,
        .bottom {
          padding: 0 15px;

          @media screen and (min-width: $breakpointSm) {
            padding: 0 30px;
          }
        }

        .top {
          .discount {
            width: fit-content;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $azureRadiance600;
            padding: 4px 12px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 8px;
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            @include text-xs;

            @media screen and (min-width: $breakpointSm) {
              @include text-md;
              border-top-right-radius: 32px;
              padding: 4px 20px 4px 12px;
            }
          }

          .caption {
            @include text-xl;
            font-weight: 700;

            @media screen and (min-width: $breakpointSm) {
              @include display-sm;
            }

            .grey {
              color: $black400;
              @include text-sm;
              font-weight: 500;

              @media screen and (min-width: $breakpointSm) {
                @include text-lg;
              }
            }
          }

          .subtitle {
            @include flex($direction: row, $justify: start, $gap: 6px);
            @include text-xs;
            font-weight: 400;

            @media screen and (min-width: $breakpointSm) {
              @include text-md;
            }
          }

          .price {
            margin-top: 25px;
            font-size: 24px;
            line-height: 33px;
            font-weight: 700;

            .blue {
              @include text-md;
              font-weight: 600;
              color: $azureRadiance600;
              margin-left: 5px;
              text-decoration: line-through;
            }
          }
        }

        .bottom {
          .include {
            @include text-md;
            font-weight: 700;
          }

          .list-item {
            @include text-sm;
            font-weight: 500;
            margin: 6px 0;

            @media screen and (min-width: $breakpointSm) {
              @include text-md;
              margin: 8px 0;
            }

            > img {
              margin-right: 3px;
            }

            .grey {
              font-size: 8px;
              font-weight: 400;
              color: $black500;

              @media screen and (min-width: $breakpointSm) {
                @include text-xs;
              }
            }
          }
        }
      }

      .cta {
        .slots {
          @include flex(row, $gap: 6px);
          width: 85%;
          text-align: center;
          margin: 15px auto 0 auto;

          @media screen and (min-width: $breakpointSm) {
            width: 70%;
          }
        }
      }
    }
  }

  .graph {
    @include border-radius-sm;
    border: 1px solid $azureRadiance100;
    padding: 15px 10px;

    @media screen and (min-width: $breakpointSm) {
      padding: 50px;
      @include border-radius-xl;
      text-align: center;
    }

    .caption {
      @include display-sm;
      font-weight: 700;

      @media screen and (min-width: $breakpointMd) {
        @include display-lg;
      }
    }

    .info {
      @include text-sm;
      font-weight: 500;
      color: $black500;
      margin-top: 5px;

      @media screen and (min-width: $breakpointSm) {
        @include text-lg;
        margin-top: 10px;
      }
    }

    > img {
      margin-top: 20px;
      min-width: 80%;

      @media screen and (min-width: $breakpointMd) {
        margin-top: 30px;
      }
    }
  }

  .pulse-circle {
    width: 10px;
    height: 10px;
    @include border-radius-rounded;
    background-color: $sorbus500;
    position: relative;

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
      }
      50% {
        box-shadow: 0 0 0 5px rgba(255, 146, 10, 0.2);
      }
      100% {
        box-shadow: 0 0 0 0px rgba(255, 146, 10, 0.2);
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      @include border-radius-rounded;
      z-index: 1;
      animation: pulse 2s forwards infinite;
      animation-timing-function: linear;
    }
  }
}
</style>
