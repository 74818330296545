<template>
  <div class="i_information-bar">
    <iBaseHeader
      highlight-text=""
      text="Your Details"
      align="start"
    />

    <div
      class="info-bar"
      v-if="infoBarData.length >= 1"
    >
      <template v-for="info in infoBarData">
        <template v-if="info.value">
          <div class="info-item">
            <img
              :src="info.icon"
              class="icon"
            />
            <iBaseTruncatedText
              class="label"
              :text="info.value"
              :max-length="39"
            />
          </div>
        </template>
      </template>
    </div>

    <iCurrentPosition class="job-position" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { useReportStore } from "@/stores/report"

import iBaseTruncatedText from "@/components/base/BaseTruncatedText.vue"
import iBaseHeader from "@/components/base/BaseHeader.vue"
import iCurrentPosition from "@/components/report/profile/CurrentPosition.vue"

import iconGlobe from "@/assets/icons/icon-profile-globe-black.svg"
import iconLocation from "@/assets/icons/icon-profile-location-black.svg"
import iconDegree from "@/assets/icons/icon-certificate.svg"
import iconStudy from "@/assets/icons/icon-profile-study-black.svg"

// STORES
const reportStore = useReportStore()

//CONSTANTS
const CUSTOMIZED_EDUCATION_LEVELS: { [x: number]: string } = {
  6: "Associate's Degree</br>(2-Year Program)",
  7: "Bachelor's Degree</br>(4-Year Program)"
}

// COMPUTED
const infoBarData = computed(() => {
  const educationLevelKey = reportStore.reportData?.user?.educationLevel?.value
  let degree = CUSTOMIZED_EDUCATION_LEVELS[educationLevelKey] ?? reportStore.getEducationLevel

  return [
    { icon: iconLocation, value: reportStore.getLocation },
    { icon: iconGlobe, value: reportStore.getEthnicity },
    { icon: iconDegree, value: degree },
    { icon: iconStudy, value: reportStore.getSchoolMajor }
  ]
})
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_information-bar {
  margin-top: 45px;

  .info-bar {
    @include flex(column, $justify: center, $align: center, $gap: 15px);
    width: 100%;
    margin-top: 15px;

    @media screen and (min-width: $breakpointSm) {
      flex-direction: row;
    }

    .info-item {
      @include grid($cols: 1fr, $rows: auto 1fr, $align-items: center, $justify-items: center, $gap: 5px);
      width: 100%;
      border: 1px solid $zumthor100;
      @include border-radius-lg;
      background-color: $white;
      align-self: stretch;
      padding: 30px 0px;
      overflow-wrap: break-word;

      .icon {
        align-self: end;
        border: 1px solid $zumthor100;
        @include border-radius-md;
        padding: 5px;
      }

      .label {
        @include text-sm;
        color: $black900;
        font-weight: 500;
        align-self: start;
        text-align: center;
        max-width: 80%;
      }
    }
  }

  .job-position {
    margin-top: 30px;
  }
}
</style>
