<template>
  <div
    class="i_payment-card"
    :class="{ highlighted: props.isHighlighted, [props.version]: true }"
  >
    <div
      v-if="props.isHighlighted"
      class="popular"
    >
      <div>{{ props.highlightedText }}</div>
    </div>

    <div class="inner">
      <img
        :src="props.imageUrl"
        loading="lazy"
        alt="Basic Report"
        class="img"
      />

      <div class="content">
        <div class="title">
          <span>{{ props.title }}</span>
        </div>

        <div class="subtitle">
          <span>{{ props.subtitle }}</span>
        </div>

        <div class="price">
          <div
            v-if="!!props.previousPriceWithCents"
            class="previous-price"
          >
            ${{ props.previousPriceWithCents }}
          </div>
          <div
            class="current-price"
            v-if="!isPriceHidden"
          >
            <span class="currency-sign">$</span><span>{{ props.currentPriceWithCents }}</span>
          </div>
          <div class="currency">
            <span v-if="!isPriceHidden">USD</span>
          </div>
        </div>

        <div v-if="hasBenefits">
          <div
            v-for="(benefit, index) in props.benefits"
            :key="index"
          >
            <iBulletItemDetailed v-bind="benefit" />
          </div>
        </div>
      </div>

      <div
        class="call-to-action"
        v-if="!props.isButtonHidden"
      >
        <iBaseButton
          v-bind="buttonProps"
          @click="firePaymentAction"
        />
      </div>

      <div
        class="number-of-purchases"
        v-if="numberOfPurchases"
      >
        {{ props.numberOfPurchases }} people purchased this report in the past 24 hours.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

// COMPONENTS
import iBaseButton from "@/components/base/BaseButton.vue"
import iBulletItemDetailed from "@/components/pricing/BulletItemDetailed.vue"

// STYLES
import { astronaut700, bittersweet400, black, white } from "@/styles/colors"

// ASSETS
import iconUpArrowWhite from "@/assets/icons/icon-up-arrow-white.svg"
import imageReportBasic from "@/assets/images/image-report-basic.png"

// EMITS
const emit = defineEmits(["clicked", "payment-action-error"])

// PROPS
const props = defineProps({
  benefits: {
    type: Array as () => { iconUrl: string; title: string; titleColor: string }[],
    default: () => []
  },
  buttonText: {
    type: String,
    default: "GET MY RESULT"
  },
  currentPriceWithCents: {
    type: String
  },
  highlightedText: {
    type: String,
    default: "Most popular"
  },
  imageUrl: {
    type: String,
    default: imageReportBasic
  },
  isButtonDisabled: {
    type: Boolean,
    default: false
  },
  isButtonHidden: {
    type: Boolean,
    default: false
  },
  isHighlighted: {
    type: Boolean,
    default: false
  },
  numberOfPurchases: {
    type: Number
  },
  paymentAction: {
    type: Function,
    default: () => {
      console.log("Button clicked...")
    }
  },
  previousPriceWithCents: {
    type: String,
    default: ""
  },
  subtitle: {
    type: String,
    default: "Card Subtitle"
  },
  title: {
    type: String,
    default: "Card Title"
  },
  version: {
    type: String,
    default: "v1",
    validator: (value: string) => {
      return value === "v1" || value === "v2"
    }
  }
})

// CONST
const isVersion1 = props.version === "v1"

// COMPUTED
const isPriceHidden = computed<Boolean>(() => {
  return props.currentPriceWithCents === ""
})

const hasBenefits = computed<Boolean>(() => {
  return props.benefits.length > 0
})

const buttonProps = computed(() => {
  const defaultButtonProps = {
    backgroundColor: black,
    iconUrl: iconUpArrowWhite,
    isHoverable: true,
    letterSpacing: "1.28px",
    padding: "20px 32px",
    text: props.buttonText,
    textColor: white,
    width: "100%",
    isDisabled: false
  }

  if (props.isHighlighted) defaultButtonProps.backgroundColor = isVersion1 ? bittersweet400 : astronaut700
  if (props.isButtonDisabled) defaultButtonProps.isDisabled = true

  return defaultButtonProps
})

// FUNCTIONS
const firePaymentAction = async () => {
  if (props.isButtonDisabled) return
  emit("clicked")
  try {
    await props.paymentAction()
  } catch (error) {
    emit("payment-action-error")
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

// Note: Unsure what this class does
.c-hide-button {
  display: none;
  @media screen and (min-width: $breakpointXl) {
    opacity: 0;
    display: unset;
  }
}

.i_payment-card.v1 {
  @include card($shadow: true, $padding: false, $height: unset);

  @media screen and (min-width: $breakpointXl) {
    margin-top: 51px;
  }

  &.highlighted {
    border-radius: 12px;
    border: 5px solid #4cacdc;

    @media screen and (min-width: $breakpointXl) {
      margin-top: 0px;
    }
  }

  .popular {
    padding: 10px 16px;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background-image: linear-gradient(120deg, #6b9bee 0%, #57a3e1 45%, #41afd3 75%);
  }

  .inner {
    padding: 8px 12px;

    @media screen and (min-width: $breakpointLg) {
      padding: 16px 24px;
    }

    .img {
      margin-top: 8px;
      width: -webkit-fill-available;
      border-radius: 12px;
    }

    .content {
      padding: 0px 16px;

      .title {
        margin-top: 12px;
        margin-bottom: 8px;
        height: 48px;
        @include flex($direction: row, $justify: start, $align: center);
        @include font-style("h5");
      }

      .subtitle {
        @include flex($direction: row, $justify: start, $align: center);
        height: 36px;
        @include font-style("regular");
        font-weight: 500;
        line-height: 24px;
        color: $pickleBluewood900;
        opacity: 0.7;
        max-width: 200px;
      }

      .price {
        @include flex($direction: row, $justify: start, $align: center);
        gap: 8px;
        height: 72px;

        .previous-price {
          display: inline-block;
          @include font-style("h5");
          letter-spacing: -0.02em;
          opacity: 0.7;
          text-decoration: line-through;
        }
        .current-price {
          display: inline-block;
          @include font-style("h3");
          letter-spacing: -0.02em;
        }

        .currency {
          display: inline-block;
          opacity: 0.7;
          @include font-style("h5");
        }
      }
    }

    .call-to-action {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .number-of-purchases {
      @include font-style("medium");
      text-align: center;
      font-weight: bold;
      padding: 0px 32px;
      color: $azureRadiance600;
    }
  }
}

.i_payment-card.v2 {
  border-radius: 24px;
  border: 2px solid $pickleBluewood100;
  overflow: hidden;

  @media screen and (min-width: $breakpointLg) {
    margin-top: 52px;
  }

  &.highlighted {
    background-color: $astronaut700;
    border: 2px solid $astronaut700;

    @media screen and (min-width: $breakpointLg) {
      margin-top: 0px;
    }
  }

  .popular {
    @include text-lg;
    align-items: center;
    color: $white;
    display: flex;
    font-weight: 500;
    height: 52px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }

  .inner {
    background-color: $white;
    border-radius: 24px;
    justify-content: center;
    overflow: hidden;

    .content,
    .call-to-action,
    .number-of-purchases {
      padding-left: 16px;
      padding-right: 16px;
    }

    .content {
      padding-top: 24px;
      padding-bottom: 24px;

      .title {
        @include text-xl;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .subtitle {
        @include text-sm;
        color: $black600;
        font-weight: 300;
        max-width: 175px;
      }

      .price {
        @include flex($direction: row, $justify: start, $align: center);
        gap: 8px;
        height: 72px;

        .previous-price {
          @include text-xl;
          display: inline-block;
          font-weight: 600;
          opacity: 0.6;
          text-decoration: line-through;
        }
        .current-price {
          @include display-md;
          font-weight: 600;
          display: inline-block;

          .currency-sign {
            font-size: 24px;
            margin-right: 3px;
          }
        }

        .currency {
          @include text-xl;
          display: inline-block;
          font-weight: 600;
          opacity: 0.6;
        }
      }
    }

    .number-of-purchases {
      @include text-xs;
      color: $timberwolf800;
      margin: auto;
      max-width: 220px;
      padding-bottom: 32px;
      padding-top: 12px;
      text-align: center;
    }
  }
}
</style>
