import { defineStore } from "pinia"
import type Coach from "@/shared/types/Coach"
import ApiService from "@/services/apiService"
import useRandomNumber from "@/composables/useRandomNumber"

export const useCoachingStore = defineStore("coaching", {
  state: () => ({
    coaches: [] as Coach[],
    defaultCoach: null as Coach | null
  }),
  getters: {
    getCoachesForSelectionPage(state) {
      if (!state.coaches || state.coaches.length === 0) {
        return []
      }

      const coaches = [...state.coaches]
      return coaches.filter((coach) => coach.biography && coach.fullName && coach.profilePhotoUrl)
    },
    getDefaultCoach(state) {
      const percentMatch = useRandomNumber(state.defaultCoach?.fullName || "Full Name", 90, 98).value
      return { ...state.defaultCoach, percentMatch }
    },
    hasDefaultCoach(state) {
      return !!state.defaultCoach && !!state.defaultCoach?.id
    }
  },
  actions: {
    async loadCoaches() {
      try {
        const data = await ApiService.getCoaches()
        this.coaches = data.coaches || []
      } catch (error) {
        console.error("Error loading coaches", error)
      }
    },

    async loadDefaultCoach() {
      try {
        const data = await ApiService.getDefaultCoach()
        this.defaultCoach = data.coach || null
      } catch (error) {
        console.error("Error loading default coach", error)
      }
    }
  }
})
