<template>
  <div class="i_text-block">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.i_text-block {
  @include font-style("regular");
  text-align: center;
  max-width: $breakpointMd;
  margin: auto;
}
</style>
