<template>
  <div class="i_career-requirements">
    <div class="card">
      <div class="heading">
        <div class="number">1</div>
        <iBaseHeader
          highlight-text=""
          text="Get a Degree"
          align="start"
        />
      </div>
      <div class="border-box">
        <div class="info">
          Getting a degree is optional, but it drastically increases your chances of an interview and a higher salary.
        </div>
        <iRequirementsBanner v-bind="BANNERS_DATA[0]" />
        <div>
          <div
            class="list"
            v-if="activeJobEducationRequired.length > 0"
          >
            <div class="info">
              Many in this career often have one of these educational backgrounds, although it's not a requirement.
            </div>
            <br />
            <template v-for="entry in activeJobEducationRequired">
              <strong>{{ entry.schoolMajor }}</strong>
              <br />
              <ul>
                <li>{{ entry.description }}</li>
              </ul>
            </template>
          </div>
          <div
            class="list"
            v-if="activeJobEducationOptional.length > 0"
          >
            <template v-for="entry in activeJobEducationOptional">
              <div class="optional">
                <strong>{{ entry.schoolMajor }}</strong>
                <div class="badge">Optional</div>
              </div>
              <ul>
                <li>{{ entry.description }}</li>
              </ul>
            </template>
          </div>
        </div>
        <div
          v-if="!(activeJobEducationRequired.length > 0) && !(activeJobEducationOptional.length > 0)"
          class="none"
        >
          No education requirements found.
        </div>
      </div>
    </div>
    <div class="card">
      <div class="heading">
        <div class="number">2</div>
        <iBaseHeader
          highlight-text=""
          text="Gain Experience"
          align="start"
        />
      </div>
      <div class="border-box">
        <div
          v-if="activeJobExperience.experience"
          class="info"
        >
          {{ activeJobExperience.experience }}
        </div>
        <iRequirementsBanner v-bind="BANNERS_DATA[1]" />
        <div
          class="list"
          v-if="activeJobExperience.waysToGain.length > 0"
        >
          <strong>Ways to Gain Experience</strong>
          <br />
          <ul>
            <li
              v-for="entry in activeJobExperience.waysToGain"
              class="multi"
            >
              {{ entry }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="heading">
        <div class="number">3</div>
        <iBaseHeader
          highlight-text=""
          text="Explore a License or Certificate"
          align="start"
        />
      </div>
      <div class="border-box">
        <div>
          <div
            class="list"
            v-if="activeJobCertificationsRequired.length > 0"
          >
            <div class="info">Many in this profession often have one of the following licenses/credentials.</div>
            <br />
            <template v-for="entry in activeJobCertificationsRequired">
              <strong>{{ entry.title }}</strong>
              <br />
              <ul>
                <li>{{ entry.description }}</li>
              </ul>
            </template>
          </div>
          <div
            class="list"
            v-if="activeJobCertificationsOptional.length > 0"
          >
            <template v-for="entry in activeJobCertificationsOptional">
              <div class="optional">
                <strong>{{ entry.title }}</strong>
                <div class="badge">Optional</div>
              </div>
              <ul>
                <li>{{ entry.description }}</li>
              </ul>
            </template>
          </div>
        </div>
        <div
          v-if="!(activeJobCertificationsRequired.length > 0) && !(activeJobCertificationsOptional.length > 0)"
          class="none"
        >
          No license or certificate requirement found.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useReportStore } from "@/stores/report"

import iBaseHeader from "@/components/base/BaseHeader.vue"
import iRequirementsBanner from "@/components/report/careers/RequirementsBanner.vue"

// ASSETS
import ImageDegreeSnap from "@/assets/careers/image-degree-snap.png"
import ImageJobScan from "@/assets/careers/image-job-scan.png"

// TYPES
type BannerDataType = {
  buttonText: string
  description: string
  id: string
  image: string
  url: string
}

// STORES
const reportStore = useReportStore()

// CONSTANTS
const BANNERS_DATA: BannerDataType[] = [
  {
    buttonText: "Begin Journey",
    description: "Start your educational journey to become a",
    id: "degree-snap",
    image: ImageDegreeSnap,
    url: "https://degtrk.com/?a=3490&oc=5240&c=10607&s1=&c_id=640128e1d1a2d500139fd6a1"
  },
  {
    buttonText: "Go to JobScan",
    description: "Optimize your resume to transition to a",
    id: "go-to-jobscan",
    image: ImageJobScan,
    url: "https://jobscanco.pxf.io/jobtestorg"
  }
]

// COMPUTED
const activeJobQualifications = computed(() => {
  return reportStore.getActiveJobQualifications
})

const activeJobEducationRequired = computed(() => {
  return activeJobQualifications.value?.education?.filter((entry) => !entry.isOptional)
})

const activeJobEducationOptional = computed(() => {
  return activeJobQualifications.value?.education?.filter((entry) => entry.isOptional)
})

const activeJobExperience = computed(() => {
  return {
    experience: activeJobQualifications.value?.relevantExperience?.experience,
    waysToGain: activeJobQualifications.value?.relevantExperience?.waysToGain
  }
})

const activeJobCertificationsRequired = computed(() => {
  return activeJobQualifications.value?.certifications?.filter((entry) => !entry.isOptional)
})

const activeJobCertificationsOptional = computed(() => {
  return activeJobQualifications.value?.certifications?.filter((entry) => entry.isOptional)
})
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_career-requirements {
  @include flex(column, $justify: center, $align: start);
  color: $pickleBluewood900;
  @include text-sm;
  margin: 20px 0;

  .card {
    .heading {
      @include flex(row, $justify: start, $align: center, $gap: 12px);

      @media screen and (min-width: $breakpointSm) {
        gap: 15px;
      }

      .number {
        @include text-md;
        padding: 4px 12px;
        @include border-radius-rounded;
        border: 1px solid $azureRadiance500;
      }
    }

    .border-box {
      @include flex(column, $justify: center, $align: start, $gap: 12px);
      margin-left: 17px;
      padding: 15px 0 20px 30px;
      border-left: 1px solid $zumthor100;

      @media screen and (min-width: $breakpointSm) {
        padding-left: 32px;
      }

      .info {
        color: $comet600;
      }

      .list {
        .optional {
          @include flex(row, $justify: space-between, $align: start, $gap: 8px);

          .badge {
            padding: 2px 0px;
            @include border-radius-lg;
            white-space: nowrap;
            padding: 2px 10px;
            color: $white;
            background-color: $sorbus400;
            @include text-xs;
            font-weight: 500;

            @media screen and (min-width: $breakpointSm) {
              @include text-sm;
            }
          }
        }

        strong {
          @include text-md;
          font-weight: 600;

          @media screen and (min-width: $breakpointSm) {
            @include text-lg;
          }
        }

        ul {
          margin: 8px 0 25px 0;
          padding-left: 30px;
        }

        li {
          color: $comet600;

          &.multi {
            margin: 8px 0 15px 0;
          }
        }
      }

      .none {
        @include text-md;
        font-weight: 500;
      }
    }

    &:last-child {
      .border-box {
        border-left: none;
      }
    }
  }
}
</style>
