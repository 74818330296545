<template>
  <div class="i_job-test">
    <template v-if="isQuizCompleted || isQuizError">
      <iBackground />
    </template>

    <iNavbarSimple />

    <div v-show="isQuizStarted">
      <div class="container-qubl">
        <iQublQuiz
          ref="qublQuiz"
          :quiz-id="GlobalFunctions.getQublQuizId()"
          :quiz-style="GlobalFunctions.getQublStyle()"
          @quiz-completed="onQuizCompleted"
          @quiz-success="onQuizSuccess"
          @quiz-error="onQuizError"
        />
      </div>
    </div>

    <div v-if="isQuizCompleted">
      <div class="container-loader">
        <iLoaderSimple :loading-animation-duration-ms="data.loadingAnimationDurationMs">
          <template v-slot:title>Success!</template>
          <template v-slot:subtitle>We are processing your response...</template>
        </iLoaderSimple>
      </div>
    </div>

    <div v-if="isQuizError">
      <template v-if="isEmailError">
        <div class="container-error-email">
          <iQuizEmailError @submit-new-email="submitNewEmail" />
        </div>
      </template>

      <template v-else>
        <div class="container-error-general">
          <iBaseErrorCard
            buttonText="Back to Jobtest.org"
            :error-message-one-HTML="data.quizErrorMessageHTML"
            @clicked-logo="goToHome()"
            @clicked-button="goToHome()"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue"

import { GlobalFunctions } from "@/helpers/globalFunctions"
import { GlobalVariables } from "@/helpers/globalVariables"
import QuizService from "@/services/quizService"

import type { QuizData } from "@/types/quizData"

import iBaseErrorCard from "@/components/base/BaseErrorCard.vue"
import iBackground from "@/components/global/Background.vue"
import iLoaderSimple from "@/components/global/LoaderSimple.vue"
import iNavbarSimple from "@/components/global/NavbarSimple.vue"

import iQublQuiz from "@/views/jobTest/sections/QublQuiz.vue"
import iQuizEmailError from "@/views/jobTest/sections/QuizEmailError.vue"

// REFS

const qublQuiz = ref(null)

// PROPS

const props = defineProps({
  loadingPath: {
    type: String,
    default: GlobalVariables.urls.loading.path
  }
})

// DATA

const data = reactive({
  loadingAnimationDurationMs: 4000,
  quizData: {} as QuizData,
  quizStatus: QuizService.QUIZ_STATUSES.STARTED,
  quizErrorMessageHTML: QuizService.ERROR_MESSAGES["DEFAULT"],
  userName: "",
  userZipCode: ""
})

// COMPUTED

const isQuizStarted = computed(() => {
  return data.quizStatus === QuizService.QUIZ_STATUSES.STARTED
})

const isQuizCompleted = computed(() => {
  return data.quizStatus === QuizService.QUIZ_STATUSES.COMPLETED
})

const isQuizError = computed(() => {
  return data.quizStatus === QuizService.QUIZ_STATUSES.ERROR
})

const isEmailError = computed(() => {
  return data.quizErrorMessageHTML === QuizService.ERROR_MESSAGES[403]
})

// FUNCTIONS

function goToHome() {
  window.location.href = GlobalVariables.urls.website.path
}

function onQuizCompleted() {
  data.quizStatus = QuizService.QUIZ_STATUSES.COMPLETED
}

function onQuizSuccess(payload: { full_name: string; zip_code: string }) {
  // Do not use native Vue router push method because it affects the way conversions are marked in Convert
  try {
    const protocol = window.location.protocol
    const baseUrl = `${protocol}//${window.location.host}`
    const pageUrl = props.loadingPath
    const queryUrl = `?${GlobalVariables.urlQueryKeys.fullName}=${payload.full_name}&${GlobalVariables.urlQueryKeys.zipCode}=${payload.zip_code}`
    GlobalFunctions.redirectToPage({ baseUrl, pageUrl, queryUrl })
  } catch (error) {
    console.error("Error redirecting to loading page", error)
  }
}

function onQuizError(error: { message: string }, quizData: QuizData) {
  if (error.message === "400") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[400]
  else if (error.message === "401") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[401]
  else if (error.message === "403") {
    data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[403]
    data.quizData = quizData
  } else if (error.message === "500") data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES[500]
  else data.quizErrorMessageHTML = QuizService.ERROR_MESSAGES["DEFAULT"]

  data.quizStatus = QuizService.QUIZ_STATUSES.ERROR
}

function submitNewEmail(email: string) {
  if (!qublQuiz.value) return
  data.quizData.answers.email = email
  // @ts-ignore - qublQuiz is a ref
  qublQuiz.value.processQuizCompletionWithNewEmail(data.quizData)
}
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.i_job-test {
  .container-qubl,
  .container-loader,
  .container-error-email,
  .container-error-general {
    height: calc(100vh - 90px);
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .container-qubl {
    margin-top: 10px;
    max-width: $sectionMaxWidth;
  }

  .container-loader {
    margin-top: -60px;
    max-width: $sectionMaxWidth;
  }

  .container-error-email {
    @include flex($justify: center, $align: center);
    max-width: 550px;
    margin-top: -30px;
  }

  .container-error-general {
    @include flex($justify: center, $align: center);
    max-width: 450px;
    margin-top: -30px;
  }
}
</style>
