<template>
  <div
    class="i_base-tab"
    :style="{ '--bg-color': props.bgColor, '--tab-color': props.activeTabColor }"
  >
    <div
      v-for="tab in props.tabs"
      class="tab"
      :key="tab.id"
      @click="changeTab(tab.id)"
      :class="{ active: tab.id === props.activeTab }"
    >
      {{ tab.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue"

import { black100, white } from "@/styles/colors"

// Props
const props = defineProps({
  tabs: Array as () => { id: number; name: string }[],
  activeTab: Number,
  bgColor: {
    type: String,
    default: black100
  },
  activeTabColor: {
    type: String,
    default: white
  }
})

// Functions
const emit = defineEmits(["setActiveTab"])

function changeTab(id: number) {
  emit("setActiveTab", id)
}
</script>

<style scoped lang="scss">
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";

.i_base-tab {
  @include border-radius-md;
  @include text-xs;
  background-color: var(--bg-color);
  color: $comet500;
  display: flex;
  font-weight: bold;
  padding: 3px;

  .tab {
    min-height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
  }

  .tab:hover {
    color: black;
  }

  .tab.active {
    @include border-radius-sm;
    background-color: var(--tab-color);
    color: black;
  }
}
</style>
