enum UserStatus {
  FINISHED = "finished",
  FREEMIUM = "freemium",
  BASIC = "basic",
  COMPREHENSIVE = "comprehensive",
  PREMIUM = "premium",
  REFUND = "refund",
  FREE = "free"
}

export default UserStatus
