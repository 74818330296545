<template>
  <div class="i_confirm-footer">
    <iBaseDelimiter />
    <div class="main">
      <div class="links">
        <a
          href="https://www.jobtest.org/terms-of-service"
          target="_blank"
          >Terms of Use</a
        >
        <a
          href="https://www.jobtest.org/privacy-policy"
          target="_blank"
          >Privacy Policy</a
        >
      </div>
      <div class="logo">
        <img
          :src="logo"
          alt="JobTest.org"
        />
        <span v-if="!isTablet">Realign, Inc. (Jobtest.org)</span>
      </div>
      <div v-if="isTablet">
        <span>Realign, Inc. (Jobtest.org)</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointMd } from "@/styles/variables"

import useIsScreenSize from "@/composables/useIsScreenSize"

import iBaseDelimiter from "@/components/base/BaseDelimiter.vue"

// ASSETS
import logo from "@/assets/logo/logo-dark.svg"

// COMPOSABLES
const isTablet = useIsScreenSize(breakpointMd)
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/mixins.scss";

.i_confirm-footer {
  .main {
    @include flex($direction: row-reverse, $justify: space-between, $align: center, $gap: 10px);
    margin-top: 40px;
    color: $black400;
    @include text-xs;

    @media screen and (min-width: $breakpointMd) {
      flex-direction: row;
      @include text-sm;
    }

    .links {
      text-decoration: underline;
      @include flex($direction: row, $justify: center, $align: center, $gap: 10px);
      align-self: end;

      @media screen and (min-width: $breakpointMd) {
        align-self: center;
      }

      a,
      a:active {
        color: $black400;
      }
    }

    .logo {
      @include flex($direction: column, $justify: center, $align: start, $gap: 10px);

      img {
        width: 104px;

        @media screen and (min-width: $breakpointLg) {
          width: 150px;
        }
      }
    }
  }
}
</style>
