<template>
  <div class="i_benchmarked-profile">
    <div class="wrap">
      <div class="title">
        We benchmarked your profile against <span>700,000+</span> users in our database to identify ideal career paths
      </div>
      <div class="description">
        We utilize advanced machine-learning algorithms to pinpoint careers that perfectly match your unique profile.
      </div>
    </div>

    <div class="wrap">
      <img
        class="image"
        :src="illustrationWeKnowCube"
        alt=""
      />
      <div class="tag">
        <div class="tag_dot"></div>
        <div class="tag_text">
          Ideal Careers for <span>{{ userStore?.getFullName || "you" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import illustrationWeKnowCube from "@/assets/welcome/illustration-we-know-cube.svg"

import { useUserStore } from "@/stores/user"
const userStore = useUserStore()
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables.scss";

.i_benchmarked-profile {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;

  @media screen and (min-width: $breakpointMd) {
    align-items: center;
    display: grid;
    grid-template-columns: 1.3fr 1fr;
  }

  .wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .title {
    @include display-sm;
    font-weight: 600;

    span {
      background-color: $bittersweet400;
      border-radius: 64px;
      color: $white;
      display: inline-block;
      padding-left: 14px;
      padding-right: 10px;
    }
  }

  .description {
    @include text-md;
  }

  .image {
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .tag {
    align-items: center;
    background-color: #ccdbee;
    border-radius: 8px;
    border: 2px solid #b6cae3;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 8px 12px;

    .tag_dot {
      background-color: #288bff;
      border-radius: 50%;
      height: 8px;
      width: 8px;
    }

    .tag_text {
      @include text-sm;

      span {
        display: inline-block;
        font-weight: 600;
      }
    }
  }
}
</style>
