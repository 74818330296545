<template>
  <div class="i_salary-statistics-graph">
    <div class="title">
      <span>
        Your current salary <br />
        <strong>is {{ reportStore.salaryStatistics.comparisons.currentPotential }} than your potential.</strong>
      </span>
    </div>

    <figure class="graph">
      <img
        class="marker peer-marker"
        :src="imagePeerSalary"
        loading="lazy"
      />
      <img
        class="marker current-marker"
        :src="imageCurrentSalary"
        loading="lazy"
      />
      <div class="salary-range">{{ reportStore.salaryStatistics.currentSalary }}</div>
      <div class="bar bar1">
        <div class="label label-start">{{ salaryRanges.bottom }}</div>
      </div>
      <div class="bar bar2">
        <div
          class="label"
          :class="isScreenSizeMd ? 'label-start' : 'label-middle'"
        >
          <span>{{ isScreenSizeMd ? salaryRanges.middle : salaryRanges.high }}</span>
        </div>
      </div>
      <div class="bar bar3">
        <div
          class="label label-start"
          v-if="isScreenSizeMd"
        >
          {{ salaryRanges.high }}
        </div>

        <div
          class="label"
          :class="isScreenSizeMd ? 'label-end' : 'label-middle'"
        >
          {{ salaryRanges.top }}
        </div>
      </div>
    </figure>

    <div class="key-wrap">
      <div class="salary">
        <div>
          <img
            :src="imageCurrentSalaryKey"
            loading="lazy"
          />
          Current Salary
        </div>
        <div>
          <img
            :src="imagePeerSalaryKey"
            loading="lazy"
          />
          Your Peer Group
        </div>
      </div>
      <div class="potential">
        <div>
          <img
            :src="imageThreeYearsKey"
            loading="lazy"
          />
          Your Potential in 3 Years
        </div>
        <div>
          <img
            :src="imageFiveYearsKey"
            loading="lazy"
          />
          Your Potential in 5 Years
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useIsScreenSize from "@/composables/useIsScreenSize"
import { useReportStore } from "@/stores/report"
import { breakpointMd } from "@/styles/variables"
import { computed } from "vue"

// ASSETS
import imagePeerSalary from "@/assets/graphs/peer-salary-marker.svg"
import imageCurrentSalary from "@/assets/graphs/current-salary-marker.svg"
import imageCurrentSalaryKey from "@/assets/graphs/key-current-salary.svg"
import imagePeerSalaryKey from "@/assets/graphs/key-peer-salary.svg"
import imageThreeYearsKey from "@/assets/graphs/key-in-three-years.svg"
import imageFiveYearsKey from "@/assets/graphs/key-in-five-years.svg"

const isScreenSizeMd = useIsScreenSize(breakpointMd)

// STORES
const reportStore = useReportStore()

// COMPUTED
const salaryRanges = computed(() => reportStore.salaryStatistics?.salaryRanges)
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_salary-statistics-graph {
  width: 100%;
  @include border-radius-md;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px -10px 20px rgba(0, 0, 0, 0.04);
  padding: 25px;

  .title {
    text-align: center;
    @include text-xl;
    font-weight: 400;

    @media screen and (min-width: $breakpointMd) {
      font-size: 24px;
    }
  }

  .graph {
    display: flex;
    position: relative;
    flex-direction: row;
    margin: 80px 0 40px 0;

    .marker,
    .salary-range,
    .bar {
      position: absolute;
      z-index: $zIndexBase + $above;
    }

    .marker {
      top: -24px;
    }

    .peer-marker {
      left: 58%;
      @media screen and (min-width: $breakpointMd) {
        left: 42%;
      }
    }

    .current-marker {
      left: 21%;
      @media screen and (min-width: $breakpointMd) {
        left: 18%;
      }
    }

    .salary-range {
      display: block;
      padding: 5px 24px;
      @include border-radius-sm;
      white-space: nowrap;
      background-color: $sorbus100;
      @include text-lg;
      font-weight: 700;
      top: -57px;
      left: 20%; // Matches the left position of the current marker
      transform: translateX(calc(-50% + 15px)); // 15px is half of the width of the marker

      @media screen and (min-width: $breakpointMd) {
        top: -60px;
        left: 18%; // Matches the left position of the current marker
        transform: translateX(calc(-50% + 15px)); // 15px is half of the width of the marker
        @include text-xl;
      }
    }

    .bar {
      flex-grow: 1;
      height: 20px;
      position: relative;

      &.bar1 {
        background-color: $bilbao300;
        flex-basis: 100px;
        border-radius: 8px 0px 0px 8px;

        @media screen and (min-width: $breakpointMd) {
          flex-basis: 173px;
        }
      }

      &.bar2 {
        background-color: $bilbao500;
        flex-basis: 100px;

        @media screen and (min-width: $breakpointMd) {
          flex-basis: 228px;
        }
      }

      &.bar3 {
        background-color: $bilbao600;
        flex-basis: 100px;
        border-radius: 0px 8px 8px 0px;

        @media screen and (min-width: $breakpointMd) {
          flex-basis: 379px;
        }
      }

      .label {
        position: absolute;
        @include text-lg;
        font-weight: 700;
        top: 20px;

        @media screen and (min-width: $breakpointMd) {
          @include text-xl;
        }
      }

      .label-start {
        left: 0;
      }

      .label-end {
        right: 0;
      }

      .label-middle {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .key-wrap {
    width: fit-content;
    margin: auto;
    margin-top: 55px;
    @include text-sm;
    font-weight: 700;

    .salary,
    .potential {
      @include flex(column, $align: start, $gap: 10px);

      @media screen and (min-width: $breakpointSm) {
        @include flex(row, $gap: 15px);
      }

      img {
        vertical-align: baseline;
        margin-right: 2px;
      }
    }

    .potential {
      margin-top: 10px;
    }
  }
}
</style>
