<template>
  <div
    class="i_base-pill-tag"
    :style="tagStyle"
    @click="clicked"
  >
    <div
      class="circle"
      :style="circleStyle"
    ></div>
    <div
      class="text"
      :style="textStyle"
    >
      {{ props.tagText }}
    </div>
    <div
      v-if="props.iconHtml"
      class="icon"
    >
      {{ props.iconHtml }}
    </div>
  </div>
</template>

<script setup lang="ts">
/** BasePillTag
 * displays a tag with a circle and text
 *
 * @param {Boolean} isBlurred - Blurs entire tag.
 * @param {Boolean} isBold - Bolds text inside tag.
 * @param {Boolean} isClickable - Add hover cursor to entire tag.
 * @param {Boolean} isOpaqued - Opaques entire tag.
 * @param {String} circleColor - The color of the circle.
 * @param {String} iconHtml - Icon HTML Entity.
 * @param {String} minWidth - The min width of the tag.
 * @param {String} tagColor - The color of the tag.
 * @param {String} tagHeight - The height of the tag.
 * @param {String} tagText - The text inside the tag.
 */

import { black50 } from "@/styles/colors"
import { computed, type CSSProperties } from "vue"

const emit = defineEmits(["clicked"])

const props = defineProps({
  circleColor: {
    type: String,
    default: "gray"
  },
  isBlurred: {
    type: Boolean,
    default: false
  },
  isBold: {
    type: Boolean,
    default: false
  },
  isClickable: {
    type: Boolean,
    default: false
  },
  isOpaqued: {
    type: Boolean,
    default: false
  },
  iconHtml: {
    type: String,
    default: ""
  },
  maxWidth: {
    type: String,
    default: "auto"
  },
  minWidth: {
    type: String,
    default: "auto"
  },
  tagColor: {
    type: String,
    default: black50
  },
  tagHeight: {
    type: String,
    default: "25px"
  },
  tagText: {
    type: String,
    default: "Tag Text"
  },
  textStyle: {
    type: String,
    default: "#000"
  }
})

const circleStyle = computed(() => {
  return { backgroundColor: props.circleColor }
})

const tagStyle = computed(() => {
  return {
    backgroundColor: props.tagColor,
    cursor: props.isClickable ? "pointer" : "default",
    filter: props.isBlurred ? "blur(5px)" : "none",
    fontWeight: props.isBold ? "bold" : "normal",
    height: props.tagHeight,
    maxWidth: props.maxWidth,
    minWidth: props.minWidth,
    opacity: props.isOpaqued ? 0.5 : 1
  } as CSSProperties
})

const textStyle = computed(() => {
  return { color: props.textStyle }
})

function clicked() {
  emit("clicked")
}
</script>

<style lang="scss" scoped>
@import "@/styles/borders.scss";
@import "@/styles/typography.scss";

.i_base-pill-tag {
  align-items: center;
  border-radius: 16px;
  display: flex;
  padding: 1px 10px 1px 5px;
  width: fit-content;
  .circle {
    @include border-radius-rounded;
    height: 8px;
    margin-right: 8px;
    min-height: 8px;
    min-width: 8px;
    width: 8px;
  }
  .icon {
    font-size: 24px;
    line-height: 0px;
    margin-left: auto;
    margin-top: -10px;
    padding-left: 8px;
  }
  .text {
    @include text-xs;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
  }
}
</style>
