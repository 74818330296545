<template>
  <div class="i_professions-list">
    <div
      class="list"
      v-if="jobs && jobs.length > 0"
    >
      <div
        class="list-item"
        v-for="job in formattedJobsList"
        :key="job.id"
        @click="$emit('jobPageRedirect', job.id)"
      >
        <div class="main">
          <div class="top">
            <div class="job-title">
              <span
                >{{ job.title }}
                <span
                  :style="{ color: bittersweet600 }"
                  v-if="userStore.isTierFree"
                >
                  (Sample Job)
                </span>
              </span>
            </div>
            <div class="job-details">
              <div
                v-if="job.match"
                class="percent-match"
                :style="{
                  backgroundColor: job.isFavorite ? sorbus100 : job.color,
                  borderColor: job.isFavorite ? sorbus300 : job.color
                }"
              >
                <img
                  v-if="job.isFavorite"
                  :src="iconStarOrange"
                />
                <template v-else>{{ job.match }}</template>
              </div>
              <div
                class="salary"
                v-if="job.salary"
              >
                Avg. Salary: {{ job.salary }}
              </div>
            </div>
          </div>
          <div class="bottom">{{ job.briefDescription[0] }}</div>
        </div>
      </div>
    </div>
    <div
      class="unlock"
      v-if="!userStore.isTierPremium"
    >
      <iUnlockCareers @upgrade-clicked="emit('upgradeClicked')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from "vue"
import { bittersweet600, sorbus100, sorbus300 } from "@/styles/colors"

import { useUserStore } from "@/stores/user"

import iUnlockCareers from "@/components/report/UnlockCareers.vue"

// ASSETS
import iconStarOrange from "@/assets/icons/icon-star-orange-filled.svg"

//TYPES
import type { ExtendedRecommendedJob } from "@/types"

//EMITS
const emit = defineEmits(["jobPageRedirect", "upgradeClicked"])

// STORES
const userStore = useUserStore()

// PROPS
const props = defineProps({
  jobs: {
    type: Array as PropType<ExtendedRecommendedJob[]>,
    required: true
  }
})

// COMPUTED
const formattedJobsList = computed(() => {
  return (
    props.jobs.map((job) => ({
      ...job,
      briefDescription: job.overview?.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|"),
      match: job.matchScore ? `${job.matchScore}% Match` : null,
      salary: job.medianSalary ? `$${(Math.trunc(job.medianSalary / 100) * 100).toLocaleString()}` : null
    })) || []
  )
})
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";
@import "@/styles/typography.scss";
@import "@/styles/borders.scss";

.i_professions-list {
  width: 100%;

  .list {
    @include flex(column, $justify: start, $align: center);
    margin-bottom: 25px;
    margin-top: 5px;

    @media screen and (min-width: $breakpointXxl) {
      margin-bottom: 20px;
    }

    .list-item {
      @include flex(row, $justify: start, $align: start, $gap: 15px);
      background-color: $white;
      padding: 24px 20px;
      width: 100%;
      border-top: 1px solid $zumthor100;
      border-bottom: 1px solid transparent;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
      position: relative;
      cursor: pointer;

      @media screen and (min-width: $breakpointLg) {
        padding: 32px 22px;
      }

      &:first-child {
        border-top: 1px solid transparent;
      }

      &:hover {
        border: 1px solid $azureRadiance200;
        @include border-radius-md;
        background-color: $azureRadiance50;

        & + .list-item {
          border-top: 1px solid transparent;
        }
      }

      .main {
        @include flex(column, $justify: start, $align: start, $gap: 15px);
        width: 100%;

        .top {
          width: 100%;
          @include flex(column, $justify: start, $align: start, $gap: 6px);

          @media screen and (min-width: $breakpointLg) {
            @include flex(row, $justify: space-between, $align: center, $gap: 6px);
          }

          .job-title {
            @include text-lg;
            font-weight: 600;
            color: $pickleBluewood900;
            text-align: left;
          }

          .job-details {
            @include flex(row, $justify: start, $align: center, $wrap: wrap, $gap: 10px);
            @include text-sm;
            font-weight: 500;
            color: $pickleBluewood900;

            @media screen and (min-width: $breakpointXs) {
              flex-wrap: nowrap;
            }

            .percent-match,
            .salary {
              padding: 2px 0px;
              @include border-radius-lg;
              white-space: nowrap;
            }

            .percent-match {
              padding: 2px 10px;
              color: $white;
              border-width: 1px;
              border-style: solid;

              img {
                vertical-align: text-bottom;
              }
            }
          }
        }

        .bottom {
          width: 100%;
          @include text-sm;
          color: $comet600;
          font-weight: 400;
          text-align: left;

          @media screen and (min-width: $breakpointSm) {
            width: 85%;
          }

          @media screen and (min-width: $breakpointLg) {
            width: 60%;
          }
        }
      }
    }
  }
}

.unlock {
  width: 100%;
  margin: 0px auto 32px auto;

  @media screen and (min-width: $breakpointSm) {
    padding: 0 48px;
  }
}
</style>
